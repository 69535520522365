import SearchElement from "../SearchElement.vue";
import WrapWithDropdownBody from "../WrapWithDropdownBody.vue";
import { SelectedSection } from "../sections";
import CurrencyFilterItem from "../items/CurrencyFilterItem.vue";
import ListSection from "../sections/ListSection.vue";
export default {
  name: "CurrencyFilterBody",
  components: {
    WrapWithDropdownBody,
    SearchElement,
    SelectedSection,
    CurrencyFilterItem,
    ListSection
  },
  emits: ["clear", "select", "deselect", "apply", "search", "hidden"],
  data() {
    return {
      searchValue: ""
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    isModified: {
      type: Boolean,
      default: false
    },
    searching: {
      type: Boolean,
      default: false
    }
  }
};
import { getCurrencySymbol } from "@/utils";
export default {
  state: {
    config: {
      full: false,
      currency: "EUR",
      symbol: "€",
      precision: 0,
      showCurrencySign: true,
      digitSeparator: ".",
      limit: 10_000
    }
  },
  mutations: {
    currencyConfigCurrencySet(state, payload) {
      state.config.currency = payload;
      state.config.symbol = getCurrencySymbol(payload);
    }
  },
  getters: {
    currencyConfigGet(state) {
      return () => state.config;
    },
    currencyConfigQueryGet(state) {
      return () => ({
        currency: state.config.currency
      });
    },
    currencyConfigInCurrencyGet(state) {
      return () => `(in ${state.config.symbol})`;
    }
  },
  actions: {
    currencyConfigSetCurrency({
      commit
    }, payload) {
      if (payload && payload !== "") commit("currencyConfigCurrencySet", payload);
    }
  }
};
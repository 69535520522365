import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 20 21`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M10.5 10L8.325 7.825M4 5H7M9.5 5C9.5 7.20914 7.70914 9 5.5 9C3.29086 9 1.5 7.20914 1.5 \n          5C1.5 2.79086 3.29086 1 5.5 1C7.70914 1 9.5 2.79086 9.5 5Z",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}
import AppIcon from "@/components/AppIcon";
export default {
  name: "AppVersion",
  emits: ["select"],
  components: {
    AppIcon
  },
  props: {
    versions: {
      type: Array,
      default: () => []
    },
    version: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.onVersionClick(this.version);
  },
  data() {
    return {
      versionOpen: true
    };
  },
  computed: {
    displayedVersionText() {
      console.log("Versions: ", this.versions);
      console.log("Version: ", this.version);
      const visible_version = this.versions.find(item => item?.title === this.version?.title);
      if (visible_version && "title" in visible_version) {
        console.log(visible_version.title);
        return visible_version.title;
      }
      return 'Local version';
    }
  },
  methods: {
    onVersionToggle() {
      this.versionOpen = !this.versionOpen;
    },
    onVersionClick(version) {
      this.versionOpen = !this.versionOpen;
      this.$emit("select", version);
    }
  }
};
import { mapState } from "vuex";
import AppCheckbox from "@/components/AppCheckbox";
import AppSwitch from "@/components/AppSwitch";
import AppIcon from "@/components/AppIcon";
import moment from "moment";
import { cogs_currencies } from "@/utils/cogs_currencies";
import { fiscal_codes_list } from "@/utils/fiscal_codes_list";
import CurrencyValue from "@/components/tableElements/CurrencyValue.vue";
import FiscalCodeFilter from "@/components/new-filters/FiscalCodeFilter.vue";
import VendorCodeFilter from "@/components/new-filters/VendorCodeFilter.vue";
import ProductSegmentFilter from "@/components/new-filters/ProductSegmentFilter.vue";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
//import ProductDataMixin from "@/mixins/ProductDataMixin";

export default {
  name: "ProductDataContentRow",
  //mixins: [ProductDataMixin],
  emits: ["select", "save-product-data", "update", "updateVendor", "updateFocusedCell"],
  components: {
    AppCheckbox,
    AppSwitch,
    AppIcon,
    CurrencyValue,
    FiscalCodeFilter,
    VendorCodeFilter,
    ProductSegmentFilter
  },
  mixins: [ValidateMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    productSegmentsList: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Boolean,
      default: false
    },
    uniqueIndex: {
      type: Number
    },
    productSegmentsList: {
      type: Array,
      default: () => []
    },
    accountProvider: {
      type: String,
      default: "Amazon Seller Central"
    },
    focusedCell: String // Receive the globally focused cell from parent
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      manualOverride: 0,
      isSearchActive: false,
      default_orderable: true,
      selectedCells: {},
      isInputActive: false,
      convertedInputValues: {},
      modifiedItemValue: "",
      vendorSkuValue: "",
      currencies: cogs_currencies,
      fiscal_codes: fiscal_codes_list,
      showError: {
        vendor_sku: false,
        vendor_code: false
      },
      isValid: true,
      isEditing: false,
      validate: [{
        variable: "cost",
        rule: "cogs"
      }]
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      token: state => state.auth.token,
      current_workspace: state => state.workspace.current_workspace
    }),
    cost: {
      get() {
        return this.modifiedItemValue.toString();
      },
      set(val) {
        //console.log(val);
        this.modifiedItemValue = val;
      }
    },
    realSelected() {
      return this.item.orderable && this.selected;
    },
    formattedLastModified() {
      if (!this.item.current_version_from) {
        return "";
      }
      return moment(this.item.current_version_from).format("DD MMM,YYYY");
    },
    computedCurrency() {
      if (this.item.currency == null || this.item.currency === "") {
        this.item.currency = this.current_workspace.workspace_currency;
        return this.item.currency;
      }
      return this.item.currency;
    },
    computedFiscalCode() {
      const fiscal_code = this.item.fiscal_code; // Get the plain string value
      return {
        text: fiscal_code,
        value: fiscal_code
      };
    },
    computedProductSegment() {
      const product_segment = this.item.product_segment;
      if (!product_segment || product_segment.trim() === '') {
        return {
          text: '',
          value: ''
        };
      }
      return {
        text: product_segment,
        value: product_segment
      };
    },
    computedVendorCode() {
      const vendor_code = this.item.vendor_code; // Get the plain string value
      return {
        text: vendor_code,
        value: vendor_code
      };
    },
    computedVendorFieldsDisabled() {
      return this.accountProvider === "Amazon Vendor Central" && (!this.item.vendor_sku || this.item.vendor_sku.trim() === "" || !this.item.vendor_code);
    },
    computedVendorInputDisabled() {
      return !!this.item.vendor_sku && !!this.item.vendor_code;
    },
    computedVendorCodeList() {
      if (!this.item.vendor_code_list || this.item.vendor_code_list.length === 0) {
        // return [{ text: "No vendor codes available", value: null }];
      }
      return this.item.vendor_code_list.map(code => ({
        text: code,
        value: code
      }));
    },
    computedId() {
      return `${this.item.asin || 'asin-' + this.uniqueIndex}-${this.item.sku || 'sku-' + this.uniqueIndex}`;
    },
    computedProductSegmentList() {
      if (this.productSegmentsList && Array.isArray(this.productSegmentsList) && this.productSegmentsList.length > 0) {
        return this.productSegmentsList.filter(segment => segment.product_segment && segment.product_segment.trim() !== "") // Exclude null & empty strings
        .map(segment => ({
          text: segment.product_segment,
          value: segment.product_segment
        }));
      }
      return [{
        text: "No segments available",
        value: null
      }];
    }
  },
  methods: {
    onSelect() {
      if (this.item.orderable) {
        //this.$emit('select', { item: this.item, value: value });
      }
    },
    onExpirationDateRequired() {
      this.$emit("expiration", this.item);
    },
    async initModifiedValue(field) {
      if (!this.isEditing) {
        this.modifiedItemValue = this.item[field] || "";
        this.isValid = await this.mValidate();
      }
    },
    async initModifiedVendorSkuValue(field, refName) {
      this.vendorSkuValue = this.item[field] || "";
    },
    updateVendorSku() {
      const modifiedValueStr = String(this.vendorSkuValue).trim();
      if (!modifiedValueStr && !this.item.vendor_code) {
        //console.log("[if both vendor_sku and vendor_code are empty]");
        return; // Prevent emitting an update if both vendor_sku and vendor_code are empty
      }
      if (!modifiedValueStr && this.item.vendor_code) {
        this.showError.vendor_sku = true;
        //console.log("[vendor_sku is empty but vendor_code is filled]");
        return;
      }
      if (modifiedValueStr && !this.item.vendor_code) {
        this.item.vendor_sku = this.vendorSkuValue;
        this.showError.vendor_code = true;
        this.showError.vendor_sku = true;
        return;
      }
      if (this.item.vendor_sku !== modifiedValueStr && !!modifiedValueStr) {
        this.item.vendor_sku = this.vendorSkuValue;
        this.$emit("updateVendor", this.item, {
          vendor_sku: this.item.vendor_sku,
          vendor_code: this.item.vendor_code
        });
        this.showError.vendor_code = false;
        this.showError.vendor_sku = false;
        this.vendorSkuValue = '';
        this.focusNextCell(`${this.computedId}-fiscal-code`);
      }
    },
    onUpdate(editedField) {
      if (editedField === "vendor_code" && this.modifiedItemValue.length !== 5) return;
      if (editedField == 'cost' && !this.isValid) {
        this.$refs[refName]?.focus();
        return;
      }
      this.selectedCells = {};
      this.isInputActive = false;
      this.isEditing = false;
      const modifiedValueStr = String(this.modifiedItemValue);
      const currentValueStr = String(this.item[editedField]);
      if (currentValueStr !== modifiedValueStr && !!modifiedValueStr) {
        this.item[editedField] = this.modifiedItemValue;
        this.$emit("update", this.item, editedField);
      }
      this.modifiedItemValue = "";
    },
    onSettings() {
      //console.log("Settings");
    },
    focusInput(refName) {
      this.$emit("updateFocusedCell", refName);
      this.$nextTick(() => {
        this.selectedCells = {};
        this.$refs[refName]?.focus();
        this.selectedCells[refName] = true;
      });
    },
    async focusNextCell(id) {
      this.isValid = await this.mValidate();
      if (id.includes('currency') && !this.isValid) {
        this.$refs[id.replace('currency', 'cost-input')]?.focus();
        return;
      }
      this.isEditing = false;
      this.focusInput(id);
    },
    handleEnterKey(event) {
      event.preventDefault();
      this.focusNextCell(`${this.computedId}-sku`);
    },
    handleDropdownChange(event, editedField) {
      this.item[editedField] = event.value || event;
      this.$emit("update", this.item, editedField);
    },
    handleDeleteProductSegment(editedField) {
      this.item[editedField] = "";
      this.$emit("update", this.item, editedField);
    },
    handleProductSegmentDropdownChange(event, editedField) {
      this.item[editedField] = event.value || event;
      this.$emit("update", this.item, editedField);
      this.focusNextCell(`${this.computedId}-external-sku-input`);
    },
    handleVendorCodeDropdownChange(event, editedField) {
      const dropDownValue = event.value || event;
      this.showError.vendor_sku = !dropDownValue && this.item.vendor_sku;
      if (!dropDownValue && !this.item.vendor_sku) {
        return; // Prevent emitting an update if both vendor_code and vendor_sku are empty
      }
      if (!dropDownValue && this.item.vendor_sku) {
        return;
      }
      if (dropDownValue && !this.item.vendor_sku) {
        this.item.vendor_code = dropDownValue;
        this.showError.vendor_code = true;
        this.showError.vendor_sku = true;
        return;
      }
      if (this.item.vendor_code !== dropDownValue && !!dropDownValue) {
        this.item.vendor_code = dropDownValue;
        this.$emit("updateVendor", this.item, {
          vendor_sku: this.item.vendor_sku,
          vendor_code: this.item.vendor_code
        });
        this.showError.vendor_code = false;
        this.showError.vendor_sku = false;
        this.vendorSkuValue = '';
        this.focusNextCell(`${this.computedId}-fiscal-code`);
      }
    },
    onUpdateCogsItem(event, refName) {
      this.modifiedItemValue = event.target.value;
    },
    async onUpdateCost(event) {
      this.isEditing = true;
      this.isValid = await this.mValidate();
      this.onUpdateCogsItem(event, 'cost');
    },
    handleSpecialInputPattern(event, field) {
      this.enforcePattern(event);
      this.onUpdateCogsItem(event, field);
    },
    handleVendorInputPattern(event, field) {
      this.enforcePattern(event);
      if (this.modifiedItemValue.length !== 5) return;
      this.onUpdateCogsItem(event, field);
    },
    enforcePattern(event) {
      const input = event.target.value;
      if (!/^[a-zA-Z0-9]*$/.test(input)) {
        event.target.value = input.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
      }
      this.modifiedItemValue = event.target.value;
    },
    hideTableCell() {
      console.log("[hideTableCell activated]");
      this.isSearchActive = true; // Hides the regular table cell, shows only search bar
    },
    showTableCell() {
      console.log("[showTableCell activated]");
      this.isSearchActive = false; // Shows the table cell again when search is blurred
    }
  }
};
import AppChoiceMaker from "@/components/AppChoiceMaker.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import WrapWithInfo from "@/components/WrapWithInfo.vue";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
export default {
  name: "PpcStrategiesModalAllocation",
  components: {
    AppChoiceMaker,
    FlexColumn,
    WrapWithInfo
  },
  mixins: [ValidateMixin],
  props: {
    value: {
      type: Number
    },
    title: {
      type: String
    },
    type: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    method: {
      type: String,
      default: ""
    },
    info: {
      type: String,
      default: ""
    },
    afterLabel: {
      type: String,
      default: ""
    },
    currencyCode: {
      type: String,
      default: "USD"
    },
    validateRule: {
      required: true,
      default: "USD_strategies_rule"
    }
  },
  emits: ["input", "select", "change", "valid"],
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isValid: true,
      validate: [{
        variable: "inputValue",
        rule: this.validateRule
      }]
    };
  },
  computed: {
    checked: {
      get() {
        return this.type === this.method;
      }
    },
    inputValue: {
      get() {
        return this.value.toString();
      },
      async set(val) {
        this.$emit("input", val);
        this.isValid = await this.mValidate();
        this.$emit("valid", this.isValid);
        if (this.isValid) {
          this.$emit("update:value", val);
          this.$emit("change", val);
          console.log("value", this.value);
        }
      }
    }
  },
  methods: {
    async onInput($event) {
      this.$emit("input", $event.target.value);
      this.isValid = await this.mValidate();
      this.$emit("valid", this.isValid);
    },
    onBlur() {
      let ppc_strategies_input_ref = this.$refs["ppc_strategies_input"];
      if (!ppc_strategies_input_ref) return;
      if (this.isValid) {
        ppc_strategies_input_ref.blur();
      } else {
        ppc_strategies_input_ref.focus();
      }
    }
  }
};
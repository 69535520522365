import Filter from "./filter";
import { Currencies } from "@/utils/currencies";
import { request } from "@/store";
const profitAndLoss = {
  state: {
    sellers: new Filter([], [], "id"),
    marketplaces: new Filter([], [], "sales_channel"),
    products: new Filter([], [], "products"),
    currencies: new Filter(Currencies, [], "symbol"),
    aggregateTableGroupBy: {
      key: "aggregate",
      name: "Aggregate"
    },
    aggregateTablePeriod: {
      title: "Period",
      start: {
        date: null,
        format: 'YYYY-MM-DD'
      },
      end: {
        date: null,
        format: 'YYYY-MM-DD'
      },
      key: "monthly",
      range: {
        start: null,
        end: null
      },
      compare: {
        start: null,
        end: null
      }
    }
  },
  mutations: {
    profitAndLossDefaultCurrencySet(state, payload) {
      // We need to update the currency filter
      state.currencies.selectOnly({
        symbol: payload
      }, 'symbol');
      state.currencies.apply();
    }
  },
  getters: {
    profitAndLossGetSellers(state) {
      return () => {
        return state.sellers.filterValues;
      };
    },
    profitAndLossGetMarketplaces(state) {
      return () => {
        return state.marketplaces.filterValues.map(v => v.toLowerCase());
      };
    },
    profitAndLossGetSkus(state) {
      return () => {
        return state.products.selected.map(product => product.sku);
      };
    },
    profitAndLossGetAsins(state) {
      return () => {
        return state.products.selected.map(product => product.asin);
      };
    },
    profitAndLossGetAggregateGroupBy(state) {
      return () => {
        return state.aggregateTableGroupBy;
      };
    },
    profitAndLossGetAggregatePeriod(state) {
      return () => {
        return state.aggregateTablePeriod;
      };
    }
  },
  actions: {
    profitAndLossSetAggregatePeriod({
      state
    }, period) {
      state.aggregateTablePeriod = period;
    },
    profitAndLossSetDefaultCurrency({
      getters,
      commit
    }) {
      commit('profitAndLossDefaultCurrencySet', getters.currencyConfigQueryGet().currency);
    },
    async profitAndLossGenerateGoogleSheet(_, payload) {
      try {
        let result = await request("/profit_and_loss/generate/googlesheet", "POST", payload);
        if (result) {
          return result;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};
export default profitAndLoss;
import { getFormattedCurrency, getFormattedDecimal, getFormattedPercentage } from "@/utils";
export const PL_EXPORT_LEVEL = {
  LEVEL_1: "LEVEL_1",
  LEVEL_2: "LEVEL_2",
  DEFAULT: "DEFAULT"
};
export const PL_EXPORT_HEADERS = {
  [PL_EXPORT_LEVEL.DEFAULT]: {
    row0: ["", "", "", "", "", "", "", "", "% of net revenue", "", ""],
    row1: ["", "", ""],
    row2: ["Level 1", "Level 2", "Level 3"]
  },
  [PL_EXPORT_LEVEL.LEVEL_1]: {
    row0: ["", "", "", "", "", "", "% of net revenue", "", ""],
    row1: [""],
    row2: ["Level 1"]
  },
  [PL_EXPORT_LEVEL.LEVEL_2]: {
    row0: ["", "", "", "", "", "", "", "% of net revenue", "", ""],
    row1: ["", ""],
    row2: ["Level 1", "Level 2"]
  }
};
class InternalTransformers {
  round(value) {
    return value !== null ? getFormattedDecimal(value, 2, true) : '-';
  }
  currencyFormat(value, currency, showSign = false) {
    return value !== null ? parseInt(value) : '-';
  }
  percentageFormat(value, precision = 0, limit = Number.NEGATIVE_INFINITY, showSymbol = false) {
    return value !== null ? getFormattedPercentage(value, precision, limit, showSymbol) + '%' : '-';
  }
}
class PandLEvent extends InternalTransformers {
  name;
  constructor(obj, currency) {
    super();
    this.name = obj.event_type;
    this.current_currency = this.currencyFormat(obj.currency_amount_euro, currency);
    this.previous_currency = this.currencyFormat(obj.currency_amount_euro_previous);
    this.evolution_currency = this.currencyFormat(obj.currency_amount_evolution, currency, true);
    this.evolution_percentage = this.percentageFormat(obj.currency_amount_evolution_pc);
    this.current_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue, 1);
    this.previous_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue_previous, 1);
    this.net_revenue_evolution = this.percentageFormat(obj.pc_net_revenue_evol, 1, undefined, false);
    this.priority = obj.priority;
  }
  get getNetRevenue() {
    return this.priority >= 100 ? [this.current_net_revenue_percentage, this.previous_net_revenue_percentage, this.net_revenue_evolution] : ["", "", ""];
  }
  toRow() {
    return ["", "", this.name, this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue];
  }
}
class PandLDetail extends InternalTransformers {
  name;
  events = [];
  renameChecker = {
    "Net Revenue": "Quantity"
  };
  constructor(obj, currency, level) {
    super();
    this.name = this.renameChecker[obj.pl_detail] ?? obj.pl_detail;
    this.current_currency = this.currencyFormat(obj.currency_amount_euro, currency);
    this.previous_currency = this.currencyFormat(obj.currency_amount_euro_previous, currency);
    this.evolution_currency = this.currencyFormat(obj.currency_amount_evolution, currency, true);
    this.evolution_percentage = this.percentageFormat(obj.currency_amount_evolution_pc);
    this.current_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue, 1);
    this.previous_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue_previous, 1);
    this.net_revenue_evolution = this.percentageFormat(obj.pc_net_revenue_evol, 1, undefined, false);
    this.priority = obj.priority;
    this.events = obj.events.map(el => new PandLEvent(el, currency));
    this.level = level;
  }
  get getNetRevenue() {
    return this.priority >= 100 ? [this.current_net_revenue_percentage, this.previous_net_revenue_percentage, this.net_revenue_evolution] : ["", "", ""];
  }
  toRow() {
    switch (this.level) {
      case PL_EXPORT_LEVEL.DEFAULT:
        return [["", this.name, "", this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue], ...this.events.map(event => event.toRow())];
      case PL_EXPORT_LEVEL.LEVEL_2:
        return [["", this.name, this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue]];
    }
  }
}
export class PandLAggregate extends InternalTransformers {
  name;
  details = [];
  renameChecker = {
    "Net Revenue": "Gross Merchandise Value",
    "Gross Margin": "Revenue net of Amazon commissions"
  };
  constructor(obj, currency, level) {
    super();
    this.name = this.renameChecker[obj.pl_aggregate] ?? obj.pl_aggregate;
    this.current_currency = this.currencyFormat(obj.currency_amount_euro, currency);
    this.previous_currency = this.currencyFormat(obj.currency_amount_euro_previous, currency);
    this.evolution_currency = this.currencyFormat(obj.currency_amount_evolution, currency, true);
    this.evolution_percentage = this.percentageFormat(obj.currency_amount_evolution_pc);
    this.current_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue, 1);
    this.previous_net_revenue_percentage = this.percentageFormat(obj.pc_net_revenue_previous, 1);
    this.net_revenue_evolution = this.percentageFormat(obj.pc_net_revenue_evol, 1, undefined, false);
    this.priority = obj.priority;
    this.level = level;
    this.details = obj.details.map(el => new PandLDetail(el, currency, level));
  }
  get getNetRevenue() {
    return this.priority >= 100 ? [this.current_net_revenue_percentage, this.previous_net_revenue_percentage, this.net_revenue_evolution] : ["", "", ""];
  }
  toRow() {
    switch (this.level) {
      case PL_EXPORT_LEVEL.LEVEL_1:
        return [[this.name, this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue]];
      case PL_EXPORT_LEVEL.LEVEL_2:
        return [[this.name, "", this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue], ...this.details.flatMap(detail => detail.toRow())];
      case PL_EXPORT_LEVEL.DEFAULT:
        return [[this.name, "", "", this.current_currency, this.previous_currency, this.evolution_currency, this.evolution_percentage, "", ...this.getNetRevenue], ...this.details.flatMap(detail => detail.toRow())];
    }
  }
}
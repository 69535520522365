import { CM_TO_INCH_COEF, INCH_TO_CM_COEF, KG_TO_LB_COEF, LB_TO_KG_COEF } from '@/utils/constants';
export default {
  name: "ProductDataMixin",
  methods: {
    productDataScaleTypeChangeHandler(productData, scaleType, defaultScaleType) {
      productData.forEach(item => {
        item.product_package_height_cm = this.convertLengthValue(item.product_package_height_cm, scaleType, defaultScaleType);
        item.product_package_width_cm = this.convertLengthValue(item.product_package_width_cm, scaleType, defaultScaleType);
        item.product_package_length_cm = this.convertLengthValue(item.product_package_length_cm, scaleType, defaultScaleType);
        item.product_package_weight_g = this.convertWeightValue(item.product_package_weight_g, scaleType, defaultScaleType);
        item.box_length_cm = this.convertLengthValue(item.box_length_cm, scaleType, defaultScaleType);
        item.box_height_cm = this.convertLengthValue(item.box_height_cm, scaleType, defaultScaleType);
        item.box_width_cm = this.convertLengthValue(item.box_width_cm, scaleType, defaultScaleType);
        item.box_weight_g = this.convertWeightValue(item.box_weight_g, scaleType, defaultScaleType);
      });
      return productData;
    },
    convertLengthValue(value, scaleType, defaultScaleType) {
      if (value == null || isNaN(value)) {
        return '';
      }
      if (defaultScaleType) {
        return value;
      }
      let convertedValue = scaleType === 'imperial' ? value * CM_TO_INCH_COEF : value;
      return convertedValue.toFixed(2);
    },
    convertWeightValue(value, scaleType, defaultScaleType) {
      if (value == null || isNaN(value)) {
        return '';
      }
      if (defaultScaleType) {
        return value;
      }
      let convertedValue = scaleType === 'imperial' ? value * KG_TO_LB_COEF : value;
      return convertedValue.toFixed(2);
    },
    reverseConvertLengthValue(value, scaleType) {
      if (scaleType === 'imperial') {
        return parseFloat(value) * INCH_TO_CM_COEF;
      }
      return parseFloat(value);
    },
    reverseConvertWeightValue(value, scaleType) {
      if (scaleType === 'imperial') {
        return parseFloat(value) * LB_TO_KG_COEF;
      }
      return parseFloat(value);
    }
  }
};
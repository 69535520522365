import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    d: "M8.66536 3.33333C8.66536 4.06971 7.02374 4.66667 4.9987 4.66667C2.97365 4.66667 1.33203 4.06971 1.33203 3.33333M8.66536 3.33333C8.66536 2.59695 7.02374 2 4.9987 2C2.97365 2 1.33203 \n    2.59695 1.33203 3.33333M8.66536 3.33333V4.33333M1.33203 3.33333V11.3333C1.33203 12.0697 2.97365 12.6667 4.9987 12.6667M4.9987 \n    7.33333C4.88633 7.33333 4.77515 7.3315 4.66536 7.3279C2.79653 7.26666 1.33203 6.69552 1.33203 6M4.9987 10C2.97365 10 1.33203 \n    9.40305 1.33203 8.66667M14.6654 7.66667C14.6654 8.40305 13.0237 9 10.9987 9C8.97365 9 7.33203 8.40305 7.33203 7.66667M14.6654 \n    7.66667C14.6654 6.93029 13.0237 6.33333 10.9987 6.33333C8.97365 6.33333 7.33203 6.93029 7.33203 7.66667M14.6654 7.66667V12.6667C14.6654 \n    13.403 13.0237 14 10.9987 14C8.97365 14 7.33203 13.403 7.33203 12.6667V7.66667M14.6654 10.1667C14.6654 10.903 13.0237 11.5 10.9987 \n    11.5C8.97365 11.5 7.33203 10.903 7.33203 10.1667",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}
import OverviewRowContainer from '@/components/OverviewRowContainer';
import TabSelector from '@/components/TabSelector';
import GraphController from '@/components/GraphController';
import ButtonController from '@/components/shared/ButtonController';
import FilterItem from '@/components/filters/FilterItem.vue';
import DatePickerWrapper from '@/components/DatePickerWrapper.vue';
import DatePickerPopup from "@/components/DatePickerPopup.vue";
export default {
  name: 'KpisControllersSection',
  emits: ['changeTab', 'changeView', 'changePeriod'],
  expose: ['defaultDatePickerDate', 'selectRange'],
  props: {
    pages: {
      type: Array,
      required: true
    },
    tabPeriods: {
      type: Array,
      required: true,
      default: () => []
    },
    graphControllers: {
      type: Array,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    currentView: {
      type: String,
      required: true
    },
    currentPeriod: {
      type: Object,
      required: true
    },
    showFilterSection: {
      type: Boolean,
      default: true
    },
    showTabSection: {
      type: Boolean,
      default: true
    },
    showViewSection: {
      type: Boolean,
      default: true
    },
    showFilterSectionLine: {
      type: Boolean,
      default: true
    },
    showTabSectionLine: {
      type: Boolean,
      default: true
    },
    searchLoading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    showDatePicker: {
      type: Boolean,
      default: false
    },
    datePickerDate: {
      type: Object
    }
  },
  components: {
    OverviewRowContainer,
    FilterItem,
    TabSelector,
    GraphController,
    ButtonController,
    DatePickerWrapper,
    DatePickerPopup
  },
  mounted() {
    // this.$store.dispatch('datePickerSetPeriod',  this.getDateFromRangeCompare())
    window.$bus.on('SELECT_RANGE', this.selectRange);
  },
  computed: {
    viewPeriodStyle() {
      return {
        padding: '0.2rem 0'
      };
    }
  },
  methods: {
    defaultDatePickerDate() {
      console.log("Refs: ", this.$refs);
      return this.$refs.datePickerWrapper.defaultData();
    },
    selectRange({
      range,
      compare
    }) {
      console.log("Range: ", range);
      console.log("Compare: ", compare);
      const data = this.$refs.datePickerWrapper.selectRange({
        range,
        compare
      });
      console.log("Returned: ", data);
      return data;
    },
    handleTriggerRefresh({
      trigger
    }) {
      window.$bus.trigger(trigger, {
        period: this.currentPeriod
      });
    },
    handleDatePickerSubmit(date) {
      this.$emit("changePeriod", date);
    }
  }
};
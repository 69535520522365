import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { EVENTS, isObjectEmpty } from "@/utils";
import Filter from "@/store/filter";
import moment from "moment";
import ValidateMixin from "@/mixins/ValidateMixin";
import ProductDataMixin from "@/mixins/ProductDataMixin";
import AppInput from "@/components/AppInput";
import AppPagination from "@/components/AppPagination";
import CogsDataTopSection from "@/components/CogsDataTopSection";
import CogsDataContent from "@/components/CogsDataContent";
import UploadProductCogsDataSidebar from "@/components/UploadProductCogsDataSidebar.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
export default {
  name: "CogsDataPage",
  mixins: [AccountsMixin, ValidateMixin, ProductDataMixin, DownloadMixin, InitializeFiltersMixin],
  components: {
    AppInput,
    AppPagination,
    CogsDataTopSection,
    CogsDataContent,
    UploadProductCogsDataSidebar,
    ErrorOccured
  },
  data() {
    return {
      dataLoading: false,
      errorOccured: false,
      page: 1,
      perPage: 20,
      search: "",
      sortField: "product_name",
      sortDirection: "desc",
      isSideBarCollapsed: true,
      openModal: false,
      vendorAccounts: {},
      selected: [],
      currentSettings: {},
      sortValueForKey: {
        product_name: this.sortByObjectValue
      }
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      cogs_data: state => state.cogs_data.list,
      cogs_data_download: state => state.cogs_data.download_list,
      total_rows: state => state.cogs_data.total_rows,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.cogs_data.sellers_filter,
      isOmnyWorkspace: state => state.workspace.isOmnyWorkspace
    }),
    ...mapGetters(["productCogsDataSellersFilterValuesIdsGet", "productCogsDataGetProductSkus"]),
    dfDataInitialized() {
      return !isObjectEmpty(this.cogs_data);
    },
    accountProvider() {
      //return 'Amazon Vendor Central';
      return this.sellers_filter?.filterItems.slice(-1).pop()?.account_provider;
    },
    dfFiltered() {
      return this.cogs_data.sort((a, b) => {
        const aValue = this.sortValueForKey[this.sortField](a);
        const bValue = this.sortValueForKey[this.sortField](b);
        if (this.sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else if (this.sortDirection === "desc") {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
        return 0;
      }).filter(item => {
        const s = this.search.trim().toLowerCase();
        if (s != "") {
          return item?.sku?.toLowerCase().includes(s) || item?.asin?.toLowerCase().includes(s) || item?.product_name?.toLowerCase().includes(s) || item?.external_sku?.toLowerCase().includes(s);
        }
        return true;
      });
    },
    selectedFilteredSkus() {
      return this.selected.filter(sku => {
        return this.dfFiltered.find(el => el.sku == sku && el);
      });
    },
    selectedFilteredItems() {
      return this.dfFiltered.filter(item => this.selected.includes(item.sku));
    },
    selectedSellerAccountId() {
      return this.productCogsDataSellersFilterValuesIdsGet().slice(-1).pop();
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.initializeSellersFilter();
      this.sellers_filter.selectOnly(this.sellers_filter.filterItems.slice(-1).pop(), "id");
      this.sellers_filter.apply();
      await this.refreshCogsDataPage();
    }
  },
  methods: {
    ...mapMutations(["productCogsDataListSet", "productCogsDataSellersFilterSet"]),
    ...mapActions(["accountList", "getProductCogsDataList", "getAllProductCogsDataList", "getProductCogsDataListToDownload", "getAllProductCogsDataListToDownload", "authStatus", "dfUpdateProductCogsData"]),
    onSelect(values) {
      this.selected = values;
    },
    onIsSidebarCollapsed($event) {
      this.isSideBarCollapsed = $event;
    },
    sortByObjectValue(obj) {
      return obj[this.sortField];
    },
    onSort(values) {
      this.sortField = values[0];
      this.sortDirection = values[1];
    },
    closeModal() {
      this.openModal = false;
    },
    onUpdate(items) {
      this.productDataListSet(items);
    },
    onSaveProductData(data) {
      //this.UpdateProductCogsData(data);
    },
    async changePage(newPage) {
      this.page = newPage;
      await this.refreshContent();
    },
    async changeElements(elementsPerPage) {
      this.page = 1;
      this.perPage = elementsPerPage;
      await this.refreshContent();
    },
    async initializeSellersFilter() {
      let accounts = await this.getOmnyAccountSellers();
      if (accounts.length > 0) {
        this.sellers_filter.initialize({
          list: accounts,
          selected: [],
          name: "sellers_filter",
          key: "id"
        });
      }
    },
    async refreshContent() {
      try {
        this.dataLoading = true;
        const seller_list = this.productCogsDataSellersFilterValuesIdsGet();
        if (seller_list.length > 0) {
          let result = await this.getProductCogsDataList({
            seller_list: seller_list,
            page_offset: this.page,
            page_limit: this.perPage
          });
          this.throwIfError(result);
          result = await this.getAllProductCogsDataList({
            seller_list: seller_list,
            sku_list: this.productCogsDataGetProductSkus(),
            page_offset: this.page,
            page_limit: this.perPage
          });
        }
        this.dataLoading = false;
        this.errorOccured = false;
      } catch (error) {
        this.errorOccured = true;
      }
    },
    async refreshCogsDataPage() {
      window.$bus.on(EVENTS.REFRESH_COGS_DATA_PAGE, this.refreshContent);
      await this.refreshContent();
    },
    handleSideOpened() {
      console.log("[Clicked COGS Data Sidebar to open]");
    },
    async downloadCogsCSV() {
      try {
        let headersMapping = {
          seller_id: "seller_id",
          sku: "sku",
          asin: "asin",
          product_name: "product_name",
          external_sku: "external_sku",
          cogs: "cost",
          currency: "currency"
        };
        if (this.accountProvider === "Amazon Vendor Central") {
          headersMapping.vendor_code = "vendor_code";
          headersMapping.vendor_sku = "vendor_sku";
          headersMapping.fiscal_code = "fiscal_code";
        }
        let csv_headers = this.extractHeaders(headersMapping);
        this.seller_list = this.productCogsDataSellersFilterValuesIdsGet();
        if (!this.seller_list.length) {
          this.throwIfError({});
        }
        window.$bus.trigger("showSuccess", {
          visible: true,
          message: "CSV download request initiated successfully.",
          delay: 5999 // Show alert less than 1 minute
        });
        let fileName = "product_cogs_data_" + this.seller_list.join("_") + "_" + moment().format("YYYY_MM_DD").toString() + ".csv";

        // Function to fetch data in chunks/pages and accumulate CSV content
        const totalPages = this.totalPagetoDownload();
        let csv_string = await this.generateCsvStringWithPagination(csv_headers, totalPages, this.downloadProductCogsDataRows);
        this.throwIfError(csv_string);
        this.downloadCsv(csv_string, fileName);
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
      }
    },
    async downloadProductCogsDataRows(currentPage) {
      let result = await this.getProductCogsDataListToDownload({
        seller_list: this.seller_list,
        page_offset: currentPage,
        page_limit: this.perPage
      });
      this.throwIfError(result);
      const sku_list = this.cogs_data_download.map(product => product.sku);
      result = await this.getAllProductCogsDataListToDownload({
        seller_list: this.seller_list,
        sku_list: sku_list,
        page_offset: currentPage,
        page_limit: this.perPage
      });
      return this.cogs_data_download;
    },
    totalPagetoDownload() {
      return Math.ceil(this.total_rows / this.perPage);
    },
    extractHeaders(headersMapping) {
      // Ensure data is valid and not empty
      return Object.fromEntries(new Map(
      //Object.entries(headersMapping).map((fieldName) => [fieldName, fieldName])
      Object.entries(headersMapping).map(([key, value]) => [key, value])));
    }
  }
};
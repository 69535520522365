import { BarWithValue, CurrencyValue, NumberValue, PercentageValue, BoxedValue, ValueElement, MarketplaceValue, HugValue, ProductGroupValue, CampaignValue, TargetingValue, WrapWithArrow, ProductTableGroupBy } from "@/components/ppc";
import CompoundTable from "@/components/CompoundTable.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { EVENTS, TREND_DIRECTION_TYPE, KPI_DIRECTION_TYPE, CVR_HEATMAP_COLORS, KEYWORD_BID_HEATMAP_COLORS, CPC_DECILE_COLORS, ANALYTICS_CSV_PAGE_SIZE } from "@/utils/constants";
import DatePickerPopup from "@/components/DatePickerPopup.vue";
import FlexRow from "@/components/FlexRow.vue";
import { OverlapBarValue } from "@/components/tableElements";
import AppIconGroup from "@/components/AppIconGroup.vue";
import ProductTableTotal from "@/components/ppc/ProductTableTotal.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import AppPagination from "@/components/AppPagination.vue";
import ProductValue from "@/components/tableElements/ProductValue.vue";
import { generateColorBasedOnState, getColorForDecile, getColorsBasedOnRange, getDescriptionBasedOnKpiKey, getCampaignsStateColor } from "@/utils/transformers";
import { mapState, mapGetters } from "vuex";
import { capitalize } from "vue";
import { processDate } from "@/utils";
import DownloadMixin from "@/mixins/DownloadMixin";
import { SPONSORED_ASIN_FIELD, SPONSORED_SALES_FIELD, UNITS_SOLD_FIELD, TACOS_FIELD, CPC_FIELD, CVR_FIELD, ROAS_FIELD, SPEND_FIELD, CLICKS_FIELD, CAMPAIGN_BUDGET_FIELD, TIME_IN_BUDGET_FIELD, MARKETPLACE_FIELD, SELLER_FIELD, PRODUCT_FIELD, CAMPAIGN_GROUP_FIELD, CAMPAIGN_NAME_FIELD, TARGETING_FIELD, KEYWORD_BID_FIELD } from "@/utils/table_fields";
export default {
  name: "ProductTable",
  components: {
    CompoundTable,
    ProductTableGroupBy,
    BarWithValue,
    BoxedValue,
    CurrencyValue,
    WrapWithPopover,
    NumberValue,
    PercentageValue,
    ValueElement,
    MarketplaceValue,
    HugValue,
    ProductGroupValue,
    CampaignValue,
    TargetingValue,
    WrapWithArrow,
    DatePickerPopup,
    FlexRow,
    OverlapBarValue,
    AppIconGroup,
    ProductTableTotal,
    AppPagination,
    ProductValue
  },
  emits: ["refresh", "handleSort", "changeDate"],
  mixins: [LiteTableMixin, DownloadMixin],
  expose: ["getFirstGroupBy"],
  unmounted() {
    window.$bus.off(EVENTS.CLOSE_PRODUCT_TABLE_COLLAPSE, this.closeCollapse);
    window.$bus.off(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE, this.openCollapse);
  },
  mounted() {
    window.$bus.on(EVENTS.CLOSE_PRODUCT_TABLE_COLLAPSE, this.closeCollapse);
    window.$bus.on(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE, this.openCollapse);
  },
  props: {
    thresholds: {
      type: Function,
      required: true
    },
    fields_heatmap: {
      type: Function,
      required: true
    },
    datePickerDate: {
      type: [Object, null],
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      fullSize: false,
      visible: false,
      showShadow: false,
      KPI_DIRECTION_TYPE,
      TREND_DIRECTION_TYPE,
      tableHeight: 0,
      scrollHeight: 0,
      showBottomRow: false,
      bottomRowHeight: "auto",
      CVR_HEATMAP_COLORS,
      KEYWORD_BID_HEATMAP_COLORS,
      CPC_DECILE_COLORS,
      CLICK_TO_FILTER_OPTIONS: {
        seller: this.sellersClickToFilter,
        marketplace: this.marketplacesClickToFilter,
        product: this.productsClickToFilter,
        campaign_group: this.campaignGroupsClickToFilter,
        campaign: this.campaignsClickToFilter,
        targeting: this.targetingsClickToFilter
      },
      csvSources: {
        seller: {
          DEFAULT_FIELDS: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key,
            [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
          },
          TARGETING_FIELDS: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          }
        },
        marketplace: {
          DEFAULT_FIELDS: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key,
            [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
          },
          TARGETING_FIELDS: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key,
            [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
          }
        },
        product: {
          DEFAULT_FIELDS: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Sku: "sku",
            Asin: "asin",
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          },
          TARGETING_FIELDS: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Sku: "sku",
            Asin: "asin",
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          }
        },
        campaign_group: {
          DEFAULT_FIELDS: {
            [CAMPAIGN_GROUP_FIELD.label]: CAMPAIGN_GROUP_FIELD.key,
            [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          },
          TARGETING_FIELDS: {
            [CAMPAIGN_GROUP_FIELD.label]: CAMPAIGN_GROUP_FIELD.key,
            [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          }
        },
        campaign: {
          DEFAULT_FIELDS: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
            "Ad Product": "ad_product",
            [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          },
          TARGETING_FIELDS: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
            "Ad Product": "ad_product",
            [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          }
        },
        targeting: {
          DEFAULT_FIELDS: {
            [TARGETING_FIELD.label]: TARGETING_FIELD.key,
            [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          },
          TARGETING_FIELDS: {
            [TARGETING_FIELD.label]: TARGETING_FIELD.key,
            [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            [CVR_FIELD.label]: CVR_FIELD.key,
            [ROAS_FIELD.label]: ROAS_FIELD.key
          }
        }
      }
    };
  },
  watch: {
    // We update the {scroll,table} height when these things change:
    // - items
    // - visible (collapse open/close)
    // - fullSize (full size toggle)
    // - productTableGroupBy (group by change)

    items: {
      handler: "updateTableScrollHeight",
      deep: true,
      immediate: true
    },
    visible: {
      handler: "updateTableScrollHeight",
      immediate: true
    },
    fullSize: {
      handler: "updateTableScrollHeight",
      immediate: true
    }
  },
  computed: {
    ...mapState({
      productTable: state => state.ppc.productTable,
      groupBy: state => state.ppc.productTable.groupByText,
      currencyConfig: state => state.ppc.currencyConfig,
      sellers: state => state.ppc.sellers,
      marketplaces: state => state.ppc.marketplaces,
      products: state => state.ppc.products,
      campaigns: state => state.ppc.campaigns,
      targetings: state => state.ppc.targetings,
      keywords: state => state.ppc.keywords
    }),
    ...mapGetters(['ppcTargetingFiltersEnabled']),
    items() {
      return this.productTable.groupBys[this.groupBy]?.items;
    },
    fields() {
      console.log("fields", this.productTable.groupBys[this.groupBy]?.fields);
      return this.productTable.groupBys[this.groupBy]?.fields;
    },
    total() {
      return this.productTable.groupBys[this.groupBy]?.total[0];
    },
    minMax() {
      console.log("this.productTable.groupBys[this.groupBy]?.minMax", this.productTable.groupBys[this.groupBy]?.minMax);
      return this.productTable.groupBys[this.groupBy]?.minMax;
    },
    maxCharCount() {
      return this.productTable.groupBys[this.groupBy]?.maxCharCount;
    },
    sort() {
      return this.productTable.groupBys[this.groupBy]?.sort;
    },
    computedTotalForColumn() {
      return column => {
        return ['seller_name', 'sales_channel', 'campaign_group_name', 'campaign_name', 'targeting'].includes(column) ? 'TOTAL' : this.total[column];
      };
    },
    getFirstGroupBy() {
      return this.$refs.productTableGroupBy.getFirstGroupBy;
    },
    page: {
      get() {
        return this.$store.state.ppc.productTable.page;
      },
      set(val) {
        this.$store.dispatch("ppcSetProductTablePage", val);
      }
    },
    perPage: {
      get() {
        return this.$store.state.ppc.productTable.perPage;
      },
      set(val) {
        this.$store.dispatch("ppcSetProductTablePerPage", val);
      }
    },
    totalPages: {
      get() {
        return this.$store.getters.ppcGetProductTableTotalPages();
      },
      set(val) {
        this.$store.dispatch("ppcSetProductTableTotalPages", val);
      }
    },
    csvFileName() {
      const dateRange = `${processDate(this.datePickerDate?.range.start, 'DDMMM')}-${processDate(this.datePickerDate?.range.end, 'DDMMMYYYY')}`;
      return `PPC_${capitalize(this.groupBy)}_${dateRange}.csv`;
    },
    groupByFetcher() {
      return this.productTable.groupBys[this.groupBy].fetch;
    },
    targetingEnabled() {
      return this.ppcTargetingFiltersEnabled() ? 'TARGETING_FIELDS' : 'DEFAULT_FIELDS';
    }
  },
  methods: {
    generateColorBasedOnState,
    getColorsBasedOnRange,
    getDescriptionBasedOnKpiKey,
    getCampaignsStateColor,
    getColorForDecile,
    getHeatMapRanges(column) {
      return this.fields_heatmap(column);
    },
    getThresholdsForColumn(column) {
      return this.thresholds(column);
    },
    setCurrentGroupBy(groupBy, refresh = true) {},
    toggleFullSize() {
      this.fullSize = !this.fullSize;
      this.visible = true;

      // Update the Product Table state in `ppc` store
      // If full size, -> full-size
      // If not full size, -> intermediate

      this.$store.dispatch("ppcSetProductTableState", this.fullSize ? "full-size" : "intermediate");
    },
    toggleCollapse(event) {
      event.stopPropagation();
      this.visible = !this.visible;
      this.fullSize = false;

      // Update the Product Table state in `ppc` store
      // If visible, -> intermediate
      // If not visible, -> CLOSED

      this.$store.dispatch("ppcSetProductTableState", this.visible ? "intermediate" : "closed");
    },
    sellersClickToFilter(item) {
      // Set the `seller` filter
      this.sellers.select({
        id: item.seller_id
      }, 'id');

      // Set the current group by to `marketplace`

      this.sellers.apply();
      window.$bus.trigger(EVENTS.REFRESH_SELLERS_SUB_FILTERS);
      this.goToNextGroupBy();
    },
    marketplacesClickToFilter(item) {
      this.marketplaces.select({
        sales_channel: item.sales_channel
      }, 'sales_channel');

      // Set the current group by to `product`
      this.marketplaces.apply();
      window.$bus.trigger(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS);
      this.goToNextGroupBy();
    },
    productsClickToFilter(item) {
      this.products.select({
        sku: item.sku
      }, 'sku');

      // Set the current group by to `product_group`
      this.products.apply();
      this.goToNextGroupBy();
    },
    async campaignGroupsClickToFilter(item) {
      // Set the `campaign_group` filter
      const campaign_id_list = item?.campaign_id_list ?? [];
      let res = await this.campaigns.asyncSearchAndSelect({
        keyword: campaign_id_list,
        args: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        }
      }, {
        campaign_id: campaign_id_list
      }, 'campaign_id');
      if (!res) {
        return;
      }
      this.campaigns.apply();
      window.$bus.trigger(EVENTS.SET_MORE_FILTERS, true);
      // Set the current group by to `campaign`
      this.goToNextGroupBy();
    },
    campaignsClickToFilter(item) {
      // Set the `campaign` filter
      this.campaigns.select({
        id: item.campaign_id
      }, 'id');
      this.campaigns.apply();
      window.$bus.trigger(EVENTS.SET_MORE_FILTERS, true);

      // Set the current group by to `targeting`
      this.goToNextGroupBy();
    },
    targetingsClickToFilter(item) {
      // Set the `targeting` filter
      this.targetings.select({
        targeting: item.targeting
      }, 'targeting');
      this.targetings.apply();
      window.$bus.trigger(EVENTS.SET_MORE_FILTERS, true);
      this.$emit('refresh');
    },
    async handleRowClick(item) {
      await this.CLICK_TO_FILTER_OPTIONS[this.groupBy](item);
    },
    handleHeadClick(fieldKey) {
      const directionFlip = {
        "asc": "desc",
        "desc": "asc"
      };
      if (this.loading) return;
      const direction = directionFlip[this.sort[fieldKey]] ?? "desc";
      this.handleSort({
        sort: fieldKey,
        direction
      });
    },
    handleDatePickerSubmit(date) {
      this.$emit("changeDate", date);
      window.$bus.trigger(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE);
    },
    getHeaderWrapWithPopoverStyle(col) {
      if (['seller_name', 'sales_channel', 'campaign_group_name', 'campaign_name', 'targeting', 'spend', 'sponsored_sales', 'sponsored_asin'].includes(col)) {
        return {
          width: '100%'
        };
      } else {
        return {};
      }
    },
    openCollapse() {
      this.$refs.productTableCollapse.open();
      this.$store.dispatch("ppcSetProductTableState", "intermediate");
    },
    closeCollapse() {
      this.$refs.productTableCollapse.close();
      this.$store.dispatch("ppcSetProductTableState", "closed");
    },
    listenForTaps() {
      // if the collapse is close, any tap on the table should open it
      if (!this.visible) {
        this.openCollapse();
        // Toggle to half size view
        if (this.fullSize) {
          this.toggleFullSize();
        }
      }
    },
    updateTableScrollHeight() {
      if (!this.loading) {
        this.$nextTick(() => {
          // Get the height for one `td` height
          let tdHeight = this.$refs?.productTable?.$el.querySelector('tbody td')?.clientHeight;
          // Compute the height by multiplying the number of rows with the height of one row
          tdHeight = (this.items?.length ?? 0) * tdHeight;
          let tableHeaderHeight = 0,
            tableFooterHeight = 0;
          this.tableHeight = this.$refs?.productTable?.$el.clientHeight;
          const thead = this.$refs?.productTable?.$el.querySelector('thead');
          if (thead) {
            tableHeaderHeight = thead.clientHeight;
          }
          const tfoot = this.$refs?.productTable?.$el.querySelector('tfoot');
          if (tfoot) {
            tableFooterHeight = tfoot.clientHeight;
          }

          // Add a row at the end of `tbody` to make that the table is occupying the full height
          // of the container

          if (tdHeight < this.tableHeight) {
            this.showBottomRow = true;
            this.bottomRowHeight = `calc(var(--product-table-height) - ${tableHeaderHeight}px - ${tableFooterHeight}px - ${tdHeight}px - 2px)`;
          } else {
            this.showBottomRow = false;
            this.bottomRowHeight = "auto";
          }
        });
      }
    },
    handleRefresh() {
      this.$emit('refresh');
    },
    handleClicked() {
      window.$bus.trigger(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE);
      this.updateTableScrollHeight();
    },
    goToNextGroupBy() {
      this.$store.dispatch("ppcSetProductTableGroupBy", this.$store.getters.ppcGetProductTableGroupBy() + 1);
      this.$emit('refresh');
    },
    async downloadPPCCSV() {
      const csv_headers = this.csvSources[this.groupBy][this.targetingEnabled];
      const csvFileName = this.csvFileName;
      const download = async page => await this.downloader(page, ANALYTICS_CSV_PAGE_SIZE, csv_headers, this.groupByFetcher);
      const str = await this.generateCsvStringWithPagination(csv_headers, Math.ceil(this.totalPages / ANALYTICS_CSV_PAGE_SIZE), download, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CurrencyFilterBody = _resolveComponent("CurrencyFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "currencies",
    "no-count": "",
    title: $options.getCurrencyTitle,
    selectedCount: $props.currencies.filterState.selectedCount
  }, {
    body: _withCtx(() => [_createVNode(_component_CurrencyFilterBody, {
      title: "Currency",
      selected: $props.currencies.filterState.selected,
      isModified: $props.currencies.isModified,
      searching: $props.currencies.searching,
      list: $props.currencies.filterState.list,
      onClear: _cache[0] || (_cache[0] = $event => {
        $props.currencies.clear();
        _ctx.$emit('cleared');
      }),
      onSelect: _cache[1] || (_cache[1] = ({
        symbol
      }) => {
        if (this.onlyMode) {
          $props.currencies.selectOnly({
            symbol
          }, 'symbol');
        } else {
          $props.currencies.select({
            symbol
          }, 'symbol');
        }
        $props.currencies.select({
          symbol
        }, 'symbol');
      }),
      onSelectOnly: _cache[2] || (_cache[2] = ({
        symbol
      }) => $props.currencies.selectOnly({
        symbol
      }, 'symbol')),
      onDeselect: _cache[3] || (_cache[3] = ({
        symbol
      }) => {
        $props.currencies.deselect({
          symbol
        }, 'symbol');
        if ($props.currencies.filterState.selected.length === 0) _ctx.$emit('cleared');
      }),
      onApply: _cache[4] || (_cache[4] = $event => _ctx.$emit('apply')),
      onSearch: _cache[5] || (_cache[5] = ({
        value
      }) => $props.currencies.listSearch(value, ['currency', 'country', 'region'])),
      onHidden: _cache[6] || (_cache[6] = $event => _ctx.$emit('apply'))
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount"]);
}
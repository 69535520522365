import { mapState, mapGetters, mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import PpcBudgetAllocation from "@/components/PpcBudgetAllocation";
import AppPagination from "@/components/AppPagination";
import { EVENTS } from "../utils";
export default {
  name: "PpcProductPrioritization",
  components: {
    AppIcon,
    AppPagination,
    PpcBudgetAllocation
  },
  props: {
    currency: {
      type: String
    },
    selected_profile_id: {
      type: String
    }
  },
  data() {
    return {
      dataLoading: true,
      page: 1,
      perPage: 20
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      product_prioritization: state => state.ppc_settings.product_prioritization,
      total_rows: state => state.ppc_settings.product_prioritization_total_rows
    }),
    ...mapGetters(["workspaceCurrent"])
  },
  async created() {
    var is_authenticated = await this.authStatus();
    // if (is_authenticated) {
    //   sessionStorage.setItem("useFaker", true);
    //   await Promise.all([
    //     this.workspaceList(),
    //     this.refreshPpcBudgetAllocation(),
    //   ]);
    //   sessionStorage.setItem("useFaker", false);
    //   this.dataLoading = false;
    // }
  },
  methods: {
    ...mapActions(["authStatus", "workspaceList", "refreshPpcBudgetAllocation", "ppcBudgetAllocationUpdate", "ppcProductPrioritizationUpdate"]),
    onItemUpdate(item) {
      // this.ppcBudgetAllocationUpdate(item);
      this.ppcProductPrioritizationUpdate(item);
    },
    async changePage(newPage) {
      this.page = newPage;
      await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_PRODUCT_PRIORITIZATION, {
        page_limit: this.perPage,
        page_offset: newPage
      });
    },
    async changeElements(elementsPerPage) {
      this.page = 1;
      this.perPage = elementsPerPage;
      await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_PRODUCT_PRIORITIZATION, {
        page_limit: elementsPerPage,
        page_offset: 1
      });
    }
  }
};
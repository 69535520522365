import headerDescription from "./table_descriptions";
import { COLORS, KPIS_COLORS_BACKGROUND, CAMPAIGNS_STATES, KPI_DIRECTION_TYPE, TREND_DIRECTION_TYPE, GLOBAL_KPIS_DIRECTIONS, PROFIT_DECILE_COLORS, TARGETING_SPLIT_TYPES_COLORS } from "@/utils/constants";
import { getCurrencyMaxCharCount, getNumberMaxCharCount, getPercentageMaxCharCount } from ".";
export const generateThresholds = (max, {
  bad,
  good
}) => {
  // [] -> {bad: val in array, good: val in array, excellent: val in array}
  return {
    bad: bad * max,
    good: good * max,
    excellent: max
  };
};
export const generateColorBasedOnState = (value, kpiDirection, {
  bad,
  good
}) => {
  // iF small is good, bad -> green , good -> yellow, excellent -> red
  // if big is good, bad -> red, good -> yellow, excellent -> green

  /*
    colors:
      green: #47D16C
      yellow: #D7AA0F
      red: #E7747A
  */

  const colorMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: [COLORS.GREEN, COLORS.YELLOW, COLORS.RED],
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: [COLORS.RED, COLORS.YELLOW, COLORS.GREEN]
  };
  const [badColor, goodColor, excellentColor] = colorMap[kpiDirection];
  if (value <= bad) return badColor;else if (value >= bad && value < good) return goodColor;
  return excellentColor;
};
export const getTrendDirectionBasedOnColor = (color, kpiDirection) => {
  const trendMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: [TREND_DIRECTION_TYPE.UP, TREND_DIRECTION_TYPE.DOWN],
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: [TREND_DIRECTION_TYPE.DOWN, TREND_DIRECTION_TYPE.UP]
  };
  const [upTrend, downTrend] = trendMap[kpiDirection];
  return color === COLORS.GREEN ? upTrend : color === COLORS.YELLOW ? downTrend : downTrend;
};

// Trend Direction based on Values
export const getTrendDirectionBasedOnValues = (current, previous, kpiDirection) => {
  const trendMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: {
      positive: TREND_DIRECTION_TYPE.UP,
      negative: TREND_DIRECTION_TYPE.DOWN
    },
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: {
      positive: TREND_DIRECTION_TYPE.DOWN,
      negative: TREND_DIRECTION_TYPE.UP
    }
  };
  const trend = current > previous ? 'positive' : current < previous ? 'negative' : TREND_DIRECTION_TYPE.OFF;
  return trendMap[kpiDirection][trend] ?? TREND_DIRECTION_TYPE.OFF;
};
export const getColorBasedOnValues = (current, previous, kpiDirection) => {
  const colorMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: [COLORS.GREEN, COLORS.RED],
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: [COLORS.RED, COLORS.GREEN]
  };
  const [goodColor, badColor] = colorMap[kpiDirection];
  return current <= previous ? goodColor : badColor;
};
export const getColorsBasedOnRange = (value, rangesWithColors) => {
  if (value === null || value === undefined) return COLORS.TRANSPARENT;
  // Convert to float with 2 decimals
  value = parseFloat(value.toFixed(2));
  const inRange = ([lower, upper]) => value >= lower && value < upper;
  const color = rangesWithColors.find(([range]) => inRange(range))?.[1];
  return color ?? COLORS.TRANSPARENT;
};
export const getColorForDecile = (value, min_value, max_value, colors, maxRange = 8, limit = 7) => {
  if (value === null || value === undefined) return COLORS.TRANSPARENT;
  if (value > max_value) {
    // For clipping
    return colors[limit];
  }
  // Calculate the range of each decile
  const decileRange = (max_value - min_value) / maxRange;

  // Determine which decile the value falls into
  const decileIndex = decileRange === 0 ? 0 : Math.min(parseInt((value - min_value) / decileRange), limit);

  // Map the decile to the corresponding color
  return colors[decileIndex] ?? COLORS.TRANSPARENT;
};
export const getDescriptionBasedOnKpiKey = kpiKey => {
  kpiKey = kpiKey?.toLowerCase();
  return headerDescription("", "").find(({
    key,
    title
  }) => key?.toLowerCase() === kpiKey || title?.toLowerCase() === kpiKey) ?? {
    title: "",
    detail: ""
  };
};
export const getCampaignsStateColor = state => {
  return CAMPAIGNS_STATES?.[state] ?? COLORS.TRANSPARENT;
};
export const computeColorsForField = (data, field, kpiDirection) => {
  if (!data) return [];
  if (data.length === 0) {
    return [];
  }
  if (!(field in data[0])) return data;
  const last = data.length - 1;
  for (let i = last - 1; i >= 0; i--) {
    const current = data[i];
    const previous = data[i + 1];
    data[i] = {
      ...current,
      [`${field}_trend_color`]: getColorBasedOnValues(current[field], previous[field], kpiDirection)
    };
  }
  return data;
};
export const computeTrendForField = (data, field, kpiDirection) => {
  // check if field in data
  if (!data) return [];
  if (data.length === 0) {
    return [];
  }
  if (!(field in data[0])) return data;
  const last = data.length - 1;
  for (let i = last - 1; i >= 0; i--) {
    const current = data[i];
    const previous = data[i + 1];
    let currDireciton = getTrendDirectionBasedOnValues(current[field], previous[field], kpiDirection);
    data[i] = {
      ...current,
      [`${field}_trend_direction`]: currDireciton
    };
  }
  return data;
};
export const getKpiDirection = (evolution, kpiDirection) => {
  /*
    If evolution is positive, and the SMALL_IS_GOOD(BIG_IS_GOOD) is false, 
    then the trend is up
     If evolution is negative, and the SMALL_IS_GOOD(BIG_IS_GOOD) is false, 
    then the trend is down
     If evolution is positive, and the SMALL_IS_GOOD(BIG_IS_GOOD) is true, 
    then the trend is down
     If evolution is negative, and the SMALL_IS_GOOD(BIG_IS_GOOD) is true, 
    then the trend is up
     Otherwise, the trend is up
   */

  const directionMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: {
      positive: TREND_DIRECTION_TYPE.DOWN,
      negative: TREND_DIRECTION_TYPE.UP
    },
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: {
      positive: TREND_DIRECTION_TYPE.UP,
      negative: TREND_DIRECTION_TYPE.DOWN
    }
  };
  const direction = evolution > 0 ? 'positive' : evolution < 0 ? 'negative' : TREND_DIRECTION_TYPE.OFF;
  return directionMap[kpiDirection][direction] ?? TREND_DIRECTION_TYPE.OFF;
};
export const getKpiColor = (evolution, kpiDirection) => {
  /*
    If evolution is positive, and the SMALL_IS_GOOD(BIG_IS_GOOD) is false, 
    then the color is green
     If evolution is negative, and the SMALL_IS_GOOD(BIG_IS_GOOD) is false, 
    then the color is red
     If evolution is positive, and the SMALL_IS_GOOD(BIG_IS_GOOD) is true, 
    then the color is red
     If evolution is negative, and the SMALL_IS_GOOD(BIG_IS_GOOD) is true, 
    then the color is green
     Otherwise, the color is green
   */

  const colorMap = {
    [KPI_DIRECTION_TYPE.SMALL_IS_GOOD]: {
      positive: COLORS.RED,
      negative: COLORS.DARK_GREEN,
      flat: COLORS.GRAY
    },
    [KPI_DIRECTION_TYPE.BIG_IS_GOOD]: {
      positive: COLORS.DARK_GREEN,
      negative: COLORS.RED,
      flat: COLORS.GRAY
    }
  };
  const color = evolution > 0 ? "positive" : evolution < 0 ? "negative" : "flat";
  return colorMap[kpiDirection][color] ?? 'transparent';
};
export const computeDetailsForKpis = (data, kpis) => {
  let out = [];
  for (const kpi of kpis) {
    const res = getDescriptionBasedOnKpiKey(kpi.key);
    const {
      title,
      detail
    } = res;
    if (!(kpi.key in GLOBAL_KPIS_DIRECTIONS)) {
      console.log(`${kpi.key} not in GLOBAL_KPIS_DIRECTIONS`);
      continue;
    }
    const trendDirection = getKpiDirection(data?.[`${kpi.key}_evol`], GLOBAL_KPIS_DIRECTIONS[kpi.key]);
    const percentageColor = 'percentageColor' in kpi ? kpi.percentageColor : getKpiColor(data?.[`${kpi.key}_evol`], GLOBAL_KPIS_DIRECTIONS[kpi.key]);
    const percentageBgColor = 'percentageBgColor' in kpi ? kpi.percentageBgColor : KPIS_COLORS_BACKGROUND[percentageColor];
    let body = {
      key: kpi.key,
      name: kpi.name,
      amount: data?.[kpi.key],
      percentage: data?.[`${kpi.key}_evol`],
      trendDirection,
      percentageColor,
      percentageBgColor,
      title,
      detail
    };
    if ('subtitle' in kpi) {
      const subtitlePercentageColor = 'subtitlePercentageColor' in kpi ? kpi.subtitlePercentageColor : getKpiColor(data?.[`${kpi.subtitle.key}_evol`], GLOBAL_KPIS_DIRECTIONS[kpi.subtitle.key]);
      body = {
        ...body,
        subtitle: kpi.subtitle.name,
        subtitleAmount: data?.[kpi.subtitle.key],
        subtitlePercentage: data?.[`${kpi.subtitle.key}_evol`],
        subtitlePercentageColor
      };
    }
    if ('split_subtitle' in kpi) {
      let split_subtitle = [];
      for (const split of kpi.split_subtitle) {
        const evolColor = getKpiColor(data?.[`${split.key}_evol`], GLOBAL_KPIS_DIRECTIONS[split.key]);
        split_subtitle.push({
          name: split.name,
          amount: data?.[split.key],
          percentage: data?.[`${split.key}_evol`],
          percentageColor: evolColor
        });
      }
      body = {
        ...body,
        split_subtitle
      };
    }
    out.push(body);
  }
  return out;
};
export const computePercentageMaxCharCount = (data, precision = 0) => {
  if (!data) return {};
  if (!data.length) return {};
  const keys = Object.keys(data[0] || {});
  if (!keys) return {};
  if (keys.length === 0) return {};

  // Check if not allowed fields are in keys
  let charCount = {};
  let showSign = false;
  let showSymbol = false;
  keys.forEach(key => {
    if (key.includes("_evol")) {
      showSign = true;
    }
    charCount[key] = getPercentageMaxCharCount(data, key, precision, undefined, showSymbol, showSign);
  });
  return charCount;
};
export const computeNumberMaxCharCount = data => {
  if (!data) return {};
  if (!data.length) return {};
  const keys = Object.keys(data[0] || {});
  if (!keys) return {};
  if (keys.length === 0) return {};

  // Check if not allowed fields are in keys
  let charCount = {};
  keys.forEach(key => {
    charCount[key] = getNumberMaxCharCount(data, key);
  });
  return charCount;
};
export const computeCurrencyMaxCharCount = (data, full = false, currency = "EUR", precision = 0, showCurrencySign = true, showSign = false, limit = 10_000, locale) => {
  if (!data) return {};
  if (!data.length) return {};
  const keys = Object.keys(data[0] || {});
  if (!keys) return {};
  if (keys.length === 0) return {};

  // Check if not allowed fields are in keys
  let charCount = {};
  keys.forEach(key => {
    charCount[key] = getCurrencyMaxCharCount(data, key, full, currency, precision, showCurrencySign, showSign, limit, locale);
  });
  return charCount;
};
const formatAmount = (amount, options) => {
  const formatter = new Intl.NumberFormat(options.locale, {
    style: "currency",
    currency: options.currency,
    minimumFractionDigits: options.precision,
    maximumFractionDigits: options.precision,
    currencyDisplay: options.full ? "symbol" : "narrowSymbol",
    signDisplay: options.showSign ? "exceptZero" : "auto",
    notation: options.full || Math.abs(amount) <= options.limit ? "standard" : "compact"
  });
  return formatter;
};
const findValue = parts => type => parts.find(part => part.type === type)?.value || "";
const formatFrenchAmount = (formatter, amount, showCurrencySign) => {
  let parts = formatter.formatToParts(amount);
  let out = "";
  out += findValue(parts)('minusSign') || findValue(parts)('plusSign');
  out += parts.filter(part => part.type === 'integer').map(part => part.value).join(' ');
  out += findValue(parts)('decimal') !== '' ? '.' : '';
  out += findValue(parts)('fraction');
  out += findValue(parts)('literal');
  out += findValue(parts)('compact');
  if (showCurrencySign) out += findValue(parts)('currency');
  return out;
};
const formatLessThan1000 = (formatter, amount, showCurrencySign, compactSymbol, precision = 1) => {
  // Sample K symbol
  // Format `11000`

  let parts = formatter.formatToParts(amount);
  // Extract decimal
  // Note the position of the currency: either front or back
  const indexOfCurreny = parts.findIndex(({
    type
  }) => type === "currency");
  const indexOfInteger = parts.findIndex(({
    type
  }) => type === "integer");
  let value = findValue(parts)('integer');
  value = (value / 1000).toFixed(precision);
  // Split value on `.` and Take the first value
  value = value.split('.')[1];

  // Set value <- `0${decimal}${value}`
  value = `0.${value}`;
  let currency = (() => {
    if (showCurrencySign) return findValue(parts)("currency");
    return '';
  })();
  if (indexOfCurreny < indexOfInteger) {
    // When currency comes before integer
    return `${currency}${value}${compactSymbol}`;
  } else {
    return `${value}${compactSymbol}${currency}`;
  }
};
const formatWithIntlApi = ({
  amount,
  currency,
  full = false,
  precision = 0,
  showCurrencySign = true,
  showSign = false,
  limit = 10000,
  locale,
  zeroInitials = false
}) => {
  const formatter = formatAmount(amount, {
    currency,
    full,
    precision,
    showSign,
    limit,
    locale
  });
  if (zeroInitials && amount < 1000) {
    const parts = formatAmount(11_000, {
      currency,
      full: false,
      precision: 1,
      showSign,
      limit,
      locale
    }).formatToParts(11_000);
    return formatLessThan1000(formatter, amount, showCurrencySign, findValue(parts)('literal') + findValue(parts)('compact'), precision);
  }
  if (formatter.resolvedOptions().locale === 'fr-FR') {
    return formatFrenchAmount(formatter, amount, showCurrencySign);
  }
  let formattedValue = formatter.format(amount);
  if (!showCurrencySign) {
    const currencySymbol = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol"
    }).formatToParts(0).find(part => part.type === "currency").value;
    formattedValue = formattedValue.replace(currencySymbol, "");
  }
  return formattedValue;
};
const createCurrencyFormatter = currency => (amount, full = false, precision = 0, showCurrencySign = true, showSign = false, limit = 10000, locale, zeroInitials) => {
  return formatWithIntlApi({
    amount,
    currency,
    full,
    precision,
    showCurrencySign,
    showSign,
    limit,
    locale,
    zeroInitials
  });
};
export const CURRENCY_POST_PROCESSORS = {
  EUR: createCurrencyFormatter("EUR"),
  USD: createCurrencyFormatter("USD"),
  GBP: createCurrencyFormatter("GBP"),
  CAD: createCurrencyFormatter("CAD"),
  AED: createCurrencyFormatter("AED"),
  AUD: createCurrencyFormatter("AUD"),
  BRL: createCurrencyFormatter("BRL"),
  EGP: createCurrencyFormatter("EGP"),
  INR: createCurrencyFormatter("INR"),
  JPY: createCurrencyFormatter("JPY"),
  MXN: createCurrencyFormatter("MXN"),
  PLN: createCurrencyFormatter("PLN"),
  SAR: createCurrencyFormatter("SAR"),
  SEK: createCurrencyFormatter("SEK"),
  SGD: createCurrencyFormatter("SGD"),
  TRY: createCurrencyFormatter("TRY")
};
function floatConverter(value, decimals = 2) {
  // Check if the value is a valid number
  if (typeof value !== "number" || isNaN(value)) {
    return value; // Return the original value if it's not a number
  }

  // Convert to float and round to specified decimal places
  return Number(value.toFixed(decimals));
}
export const intersectKeys = (obj1, mappingObj, decimals = 2) => {
  const resultMap = new Map();
  Object.values(mappingObj).forEach(key => {
    resultMap.set(key, floatConverter(obj1[key], decimals));
  });
  return Object.fromEntries(resultMap);
};
export const explodeInnerArrays = data => {
  return data.map(item => {
    let newItem = {
      ...item
    };
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key])) {
        item[key].forEach(subItem => {
          newItem[`${key}_${subItem.key}`] = subItem.value;
        });
        delete newItem[key];
      }
    });
    return newItem;
  });
};
export const getProfitPercentageDecileColor = (row, key) => {
  return getColorForDecile(row.item?.[key], 0, 0.30, PROFIT_DECILE_COLORS, 15, 14);
};
export const getTargetingTypeSplitDecileColor = (row, key, splitType) => {
  const {
    value,
    minMax
  } = row.item?.[key].find(item => item.key === splitType) ?? {
    value: null,
    minMax: {
      min: 0,
      max: 0
    }
  };
  return getColorForDecile(value, minMax.min, minMax.max, TARGETING_SPLIT_TYPES_COLORS[splitType], 5, 4);
};
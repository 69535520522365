import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2bbfaef6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "quarter-name selector"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DateValue = _resolveComponent("DateValue");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['quarter-item', {
      active: $props.active
    }, {
      previous: $props.previous
    }, {
      disabled: $props.disabled
    }]),
    onClick: _cache[0] || (_cache[0] = () => {
      if (!$props.disabled) _ctx.$emit('click', $props.quarter.months[0].date);
    })
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString(`Q${$props.quarter.quarter}`), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.quarter.months, ({
    date
  }, index) => {
    return _openBlock(), _createBlock(_component_DateValue, {
      value: date,
      format: $props.format,
      class: _normalizeClass(['selector']),
      key: index
    }, null, 8 /* PROPS */, ["value", "format"]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */);
}
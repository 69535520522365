import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = {
  "clip-path": "url(#clip0_5482_45248)"
};
const _hoisted_3 = ["stroke"];
const _hoisted_4 = {
  id: "clip0_5482_45248"
};
const _hoisted_5 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 16 16",
    fill: "none"
  }, [_createElementVNode("g", _hoisted_2, [_createElementVNode("path", {
    d: "M6.0026 5.9987H6.00927M10.0026 9.9987H10.0093M10.6693 5.33203L5.33594 10.6654M6.33594 5.9987C6.33594 6.18279 6.1867 6.33203 6.0026 6.33203C5.81851 6.33203 5.66927 6.18279 5.66927 5.9987C5.66927 5.8146 5.81851 5.66536 6.0026 5.66536C6.1867 5.66536 6.33594 5.8146 6.33594 5.9987ZM10.3359 9.9987C10.3359 10.1828 10.1867 10.332 10.0026 10.332C9.81851 10.332 9.66927 10.1828 9.66927 9.9987C9.66927 9.8146 9.81851 9.66536 10.0026 9.66536C10.1867 9.66536 10.3359 9.8146 10.3359 9.9987ZM14.6693 7.9987C14.6693 11.6806 11.6845 14.6654 8.0026 14.6654C4.32071 14.6654 1.33594 11.6806 1.33594 7.9987C1.33594 4.3168 4.32071 1.33203 8.0026 1.33203C11.6845 1.33203 14.6693 4.3168 14.6693 7.9987Z",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_3)]), _createElementVNode("defs", null, [_createElementVNode("clipPath", _hoisted_4, [_createElementVNode("rect", {
    width: $props.width,
    height: $props.height,
    fill: "white"
  }, null, 8 /* PROPS */, _hoisted_5)])])], 8 /* PROPS */, _hoisted_1);
}
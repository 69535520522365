export default {
  name: 'ChevronRightIcon',
  props: {
    color: {
      type: String,
      default: '#4D4D4D'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
};
import LoadingElement from "../shared/LoadingElement.vue";
export default {
  name: "HugValue",
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "center"
    },
    background: {
      type: String,
      default: "#FFF"
    },
    loading: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    computedStyle() {
      const justify = {
        center: "center",
        left: "flex-start",
        right: "flex-end"
      }[this.align];
      const textAlign = {
        center: "center",
        left: "left",
        right: "right"
      }[this.align];
      return {
        display: "flex",
        justifyContent: justify,
        alignItems: "center",
        padding: this.tight ? 0 : "6px 4px",
        height: "100%",
        textAlign,
        ...this.styles
      };
    }
  },
  components: {
    LoadingElement
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3fbc84ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "icon-content"
};
const _hoisted_2 = {
  class: "status-item"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "edit-actions"
};
const _hoisted_5 = {
  class: "copy-action-icon"
};
const _hoisted_6 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_Popper = _resolveComponent("Popper");
  const _component_app_textarea = _resolveComponent("app-textarea");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['edit-page-content-edit', {
      'error': $options.errorClass
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Popper, {
    placement: "right",
    hover: "",
    arrow: ""
  }, {
    content: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_app_icon, {
      icon: $options.validation.status == 'success' ? 'check' : 'warning',
      color: $options.validation.status == 'success' ? '#198754' : $options.validation.status == 'warning' ? '#ffc107' : '#dc3545',
      size: 12
    }, null, 8 /* PROPS */, ["icon", "color"]), _createElementVNode("span", null, _toDisplayString($options.validation.text), 1 /* TEXT */)])]),
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      size: 12,
      icon: $options.validation.status == 'success' ? 'check' : 'warning',
      color: $options.validation.status == 'success' ? '#198754' : $options.validation.status == 'warning' ? '#ffc107' : '#dc3545'
    }, null, 8 /* PROPS */, ["icon", "color"])]),
    _: 1 /* STABLE */
  })]), !$props.show_comparison ? (_openBlock(), _createBlock(_component_app_textarea, {
    key: 0,
    modelValue: $options.editText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.editText = $event),
    rows: $props.scroll ? 12 : 3,
    v2: "",
    background: "transparent",
    onClick: _cache[1] || (_cache[1] = $event => $options.hideTranslation())
  }, null, 8 /* PROPS */, ["modelValue", "rows"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    innerHTML: $props.text_with_diffs,
    class: "comparison",
    onClick: _cache[2] || (_cache[2] = $event => $options.hideComparison())
  }, null, 8 /* PROPS */, _hoisted_3)), _renderSlot(_ctx.$slots, "translate", {}, undefined, true), _withDirectives(_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [$props.show_dropdown ? (_openBlock(), _createBlock(_component_b_dropdown, {
    key: 0,
    "no-caret": "",
    "no-flip": "",
    dropup: "",
    class: "amazon",
    strategy: "fixed",
    onShown: _cache[3] || (_cache[3] = $event => $options.updateAmazonIconColor('#E94E1B')),
    onHidden: _cache[4] || (_cache[4] = $event => $options.updateAmazonIconColor('#736BC9'))
  }, {
    "button-content": _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "amazon",
      size: "16",
      color: $data.amazon_icon_color
    }, null, 8 /* PROPS */, ["color"])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "copy-icon",
    onClick: _cache[5] || (_cache[5] = (...args) => $options.onCopy && $options.onCopy(...args))
  }, [!$data.copied ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "copy-v2",
    size: "16",
    color: "#736BC9"
  })) : (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "check",
    size: 13,
    color: "#22863D"
  }))])]), _createElementVNode("div", {
    class: "edit-actions-icon",
    id: $props.status + $data.id
  }, [$props.status == 'draft' ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "draft"
  })) : _createCommentVNode("v-if", true), $props.status == 'synced' ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "synced"
  })) : _createCommentVNode("v-if", true), $props.status == 'published' ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 2,
    icon: "check",
    size: 13,
    color: "#22863D"
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(['edit-actions-text', {
      error: $options.errorClass
    }])
  }, _toDisplayString($options.characterCountInfo), 3 /* TEXT, CLASS */)], 8 /* PROPS */, _hoisted_6), _createCommentVNode(" <app-popover :target=\"`draft${id}`\">Local draft</app-popover>\n      <app-popover :target=\"`synced${id}`\">Synced with Amazon</app-popover>\n      <app-popover :target=\"`published${id}`\">Published on Amazon</app-popover> ")], 512 /* NEED_PATCH */), [[_vShow, $props.showActions]])], 2 /* CLASS */);
}
import HelperMixin from '@/mixins/HelperMixin';
import PpcBudgetAllocationCircle from '@/components/PpcBudgetAllocationCircle';
import PpcBudgetAllocationWeeklyBudgetInput from '@/components/PpcBudgetAllocationWeeklyBudgetInput';
export default {
  name: 'PpcBudgetAllocationWeeklyBudgetCampaign',
  emits: ['update-campaign'],
  mixins: [HelperMixin],
  components: {
    PpcBudgetAllocationCircle,
    PpcBudgetAllocationWeeklyBudgetInput
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    localModel: {
      get() {
        return this.item.budgetCurrent;
      },
      set(value) {
        this.$emit('update-campaign', {
          ...this.item,
          budgetCurrent: value
        });
      }
    },
    getPercent() {
      return this.item.roas / this.item.maxRoas * 100;
    }
  }
};
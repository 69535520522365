import { mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import AppInput from "@/components/AppInput";
import Popper from "vue3-popper";
import ProductScore from "@/components/ProductScore";
import { stringsAreTheSame } from "@/utils";
import TableSkeletonV2 from '@/components/TableSkeletonV2.vue';
import ApplyKeywordsIcon from '@/components/icons/ApplyKeywordsIcon.vue';
import AppPopover from "@/components/AppPopover";
export default {
  name: "EditPageKeywords",
  components: {
    AppIcon,
    AppInput,
    Popper,
    ProductScore,
    TableSkeletonV2,
    ApplyKeywordsIcon,
    AppPopover
  },
  emits: ['keywords'],
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  async created() {
    this.loading = true;
    await this.getSuggestedKeywords();
    this.addKeywordUsage();
    this.loading = false;
    /*if (!this.keywords.length) {
        window.$bus.trigger("showWarning", {
        visible: true,
        dismissSecs: 5,
        message: "No keywords found for product.",
        });
        return;
    }
    this.$emit('keywords');*/
  },
  data() {
    return {
      keywords: [],
      currently_hovered_word: "",
      search: "",
      sortField: 'score',
      sortDirection: "desc",
      sortValueForKey: {
        score: this.sortByObjectValue,
        search_volume: this.sortByObjectValue,
        keyword_usage: this.sortByObjectValue
      },
      loading: false,
      fields: [{
        label: "Keyword",
        key: "Keyword",
        thStyle: {
          width: "40%"
        }
      }, {
        label: "Usage",
        key: "Usage",
        thStyle: {
          width: "20%"
        }
      }, {
        label: "Score",
        key: "Score",
        thStyle: {
          width: "20%"
        }
      }, {
        label: "Search Volume",
        key: "Search Volume",
        thStyle: {
          width: "20%"
        }
      }],
      apply_keywords_loading: false
    };
  },
  computed: {
    keywordsFiltered() {
      return this.keywords.sort((a, b) => {
        const aValue = this.sortValueForKey[this.sortField](a);
        const bValue = this.sortValueForKey[this.sortField](b);
        if (this.sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else if (this.sortDirection === "desc") {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
        return 0;
      }).filter(item => {
        const s = this.search.trim().toLowerCase();
        if (s != "") {
          return item.keyword.full.toLowerCase().includes(s);
        }
        return true;
      });
    }
  },
  watch: {
    product() {
      this.addKeywordUsage();
    }
  },
  methods: {
    ...mapActions(["contentGetSuggestedKeywords", "contentApplySuggestedKeywords"]),
    onClose() {
      this.$emit("close");
    },
    onSort(field) {
      const newDirection = field == this.sortField ? this.sortDirection == "asc" ? "desc" : "asc" : "asc";
      this.sortField = field;
      this.sortDirection = newDirection;
    },
    sortByObjectValue(obj) {
      return obj[this.sortField];
    },
    async getSuggestedKeywords() {
      const payload = {
        seller_id: this.product.seller_id,
        asin: this.product.asin,
        marketplace_id: this.product.marketplace_id,
        product_name: this.product.product_name,
        product_description: this.product.product_description,
        bullet_points: [this.product.bullet_point_1, this.product.bullet_point_2, this.product.bullet_point_3, this.product.bullet_point_4, this.product.bullet_point_5]
      };
      const keywords_suggested = await this.contentGetSuggestedKeywords(payload);
      keywords_suggested.forEach(keyword => {
        const suggested = keyword.keyword;
        keyword.keyword = {
          full: suggested,
          individual_words: suggested.split(" ")
        };
        keyword.score = Math.round(keyword.score);
        this.keywords.push(keyword);
      });
      this.keywords.filter(item => {
        return item.similarity && item.similarity > 0.35;
      });
    },
    addKeywordUsage() {
      this.keywords.forEach(keyword => {
        keyword["keyword_usage"] = this.keywordUsage(keyword.keyword.full);
      });
    },
    isKeywordInListing(keyword) {
      let present_in_listing = [];
      const present_in_search_terms = this.removeHtmlTags(this.product.generic_keyword).toLowerCase().split(" ").includes(keyword.toLowerCase());
      present_in_listing.push(present_in_search_terms);
      const present_in_title = this.removeHtmlTags(this.product.product_name).toLowerCase().split(" ").includes(keyword.toLowerCase());
      present_in_listing.push(present_in_title);
      const present_in_description = this.removeHtmlTags(this.product.product_description).toLowerCase().split(" ").includes(keyword.toLowerCase());
      present_in_listing.push(present_in_description);
      const bullet_points = [this.removeHtmlTags(this.product.bullet_point_1), this.removeHtmlTags(this.product.bullet_point_2), this.removeHtmlTags(this.product.bullet_point_3), this.removeHtmlTags(this.product.bullet_point_4), this.removeHtmlTags(this.product.bullet_point_5)];
      bullet_points.forEach(point => {
        const present_in_point = point.toLowerCase().split(" ").includes(keyword.toLowerCase());
        present_in_listing.push(present_in_point);
      });
      return present_in_listing.some(Boolean);
    },
    addWordToSearchTerms(word) {
      this.product.generic_keyword = this.product.generic_keyword.concat(" ", word);
      this.addKeywordUsage();
    },
    onWordClick(word) {
      if (stringsAreTheSame(word, this.currently_hovered_word) && !this.isKeywordInListing(word)) {
        this.addWordToSearchTerms(word);
      }
      ;
    },
    keywordUsage(keyword) {
      const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`${escapedKeyword}\\b`, 'gui');
      let overall_matches = 0;
      const product_name_matches = this.removeHtmlTags(this.product.product_name).match(regex);
      overall_matches += product_name_matches ? product_name_matches.length : 0;
      const product_description_matches = this.removeHtmlTags(this.product.product_description).match(regex);
      overall_matches += product_description_matches ? product_description_matches.length : 0;
      const product_search_terms_matches = this.removeHtmlTags(this.product.generic_keyword).match(regex);
      overall_matches += product_search_terms_matches ? product_search_terms_matches.length : 0;
      const bullet_points = [this.product.bullet_point_1, this.product.bullet_point_2, this.product.bullet_point_3, this.product.bullet_point_4, this.product.bullet_point_5];
      bullet_points.forEach(point => {
        const point_matches = this.removeHtmlTags(point).match(regex);
        overall_matches += point_matches ? point_matches.length : 0;
      });
      return overall_matches;
    },
    removeHtmlTags(text) {
      return text.replace(/<[^>]*>/g, "");
    },
    highlightKeyword(keyword) {
      console.log("HKW Called");
      const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`${escapedKeyword}\\b`, 'gui');
      this.product.product_name = this.product.product_name.replace(regex, `<span class="highlight">$&</span>`);
      this.product.product_description = this.product.product_description.replace(regex, `<span class="highlight">$&</span>`);
      this.product.generic_keyword = this.product.generic_keyword.replace(regex, `<span class="highlight">$&</span>`);
      const bullet_points = [this.product.bullet_point_1, this.product.bullet_point_2, this.product.bullet_point_3, this.product.bullet_point_4, this.product.bullet_point_5];
      bullet_points.forEach(point => {
        point = point.replace(regex, `<span class="highlight">$&</span>`);
      });
    },
    unHighlightKeyword() {
      this.product.product_name = this.removeHtmlTags(this.product.product_name);
      this.product.product_description = this.removeHtmlTags(this.product.product_description);
      this.product.generic_keyword = this.removeHtmlTags(this.product.generic_keyword);
      const bullet_points = [this.product.bullet_point_1, this.product.bullet_point_2, this.product.bullet_point_3, this.product.bullet_point_4, this.product.bullet_point_5];
      bullet_points.forEach(point => {
        point = this.removeHtmlTags(point);
      });
    },
    async onApplyKeywords() {
      this.apply_keywords_loading = true;
      const keywords = await this.contentApplySuggestedKeywords({
        seller_id: this.product.seller_id,
        asin: this.product.asin,
        marketplace_id: this.product.marketplace_id,
        product_name: this.product.product_name,
        product_description: this.product.product_description,
        bullet_points: [this.product.bullet_point_1, this.product.bullet_point_2, this.product.bullet_point_3, this.product.bullet_point_4, this.product.bullet_point_5],
        brand: this.product.brand
      });
      this.product.generic_keyword = keywords;
      this.addKeywordUsage();
      this.apply_keywords_loading = false;
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-224ecd98"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "seo-content-search"
};
const _hoisted_2 = {
  class: "search-wrapper"
};
const _hoisted_3 = {
  class: "export-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seo_top_section = _resolveComponent("seo-top-section");
  const _component_app_input = _resolveComponent("app-input");
  const _component_content_export_drop_down = _resolveComponent("content-export-drop-down");
  const _component_content_optimisation_table_new = _resolveComponent("content-optimisation-table-new");
  const _component_app_pagination = _resolveComponent("app-pagination");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  const _component_app_card_seo = _resolveComponent("app-card-seo");
  return _withDirectives((_openBlock(), _createBlock(_component_app_card_seo, {
    loading: $data.dataLoading,
    "min-height": 300,
    scrollable: false,
    hasBorder: false,
    pushModal: _ctx.pushModal,
    onBack: $options.onPushModalCancel
  }, {
    default: _withCtx(() => [_createVNode(_component_omny_ui_layout, {
      contentPadding: "0",
      showPagination: true
    }, {
      "topbar-contents": _withCtx(() => [_createVNode(_component_seo_top_section, {
        onPushModal: $options.onPushModal,
        onPushSubmitModifedContents: $options.onPushModalSubmitModifiedContents
      }, null, 8 /* PROPS */, ["onPushModal", "onPushSubmitModifedContents"])]),
      "topbar-sub": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_app_input, {
        class: "seo-search-input",
        modelValue: $data.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
        placeholder: "Filter items...",
        debounce: "300",
        v2: "",
        version: "search",
        onChange: $options.searchItem
      }, null, 8 /* PROPS */, ["modelValue", "onChange"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_content_export_drop_down, {
        isDownloadRunning: $data.isDownloadRunning,
        onDownloadContentCsv: $options.handleSelectionDownloadTypeCSV
      }, null, 8 /* PROPS */, ["isDownloadRunning", "onDownloadContentCsv"])])])]),
      pagination: _withCtx(() => [_createVNode(_component_app_pagination, {
        page: _ctx.page,
        "onUpdate:page": [_cache[1] || (_cache[1] = $event => _ctx.page = $event), $options.changePage],
        perPage: _ctx.perPage,
        "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => _ctx.perPage = $event), $options.changeElements],
        total: _ctx.total_count
      }, null, 8 /* PROPS */, ["page", "perPage", "total", "onUpdate:page", "onUpdate:perPage"])]),
      default: _withCtx(() => [_ctx.currentTab === 'content_optimisation' ? (_openBlock(), _createBlock(_component_content_optimisation_table_new, {
        key: 0,
        onResetPagingValues: $options.onResetPagingValues,
        onDataLoading: $options.onDataLoading,
        onEditSingleItem: $options.onEditSingleItem
      }, null, 8 /* PROPS */, ["onResetPagingValues", "onDataLoading", "onEditSingleItem"])) : _createCommentVNode("v-if", true)]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "pushModal", "onBack"])), [[_vShow, !$data.editSingleItem]]);
}
import TableWithTitle from '@/components/TableWithTitle.vue';
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { getDescriptionBasedOnKpiKey, getColorsBasedOnRange, getColorForDecile } from "@/utils/transformers";
import { SELECTOR, PERIOD_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, SPEND_FIELD, SPONSORED_ASIN_FIELD, SPONSORED_SALES_FIELD, UNITS_SOLD_FIELD, TACOS_FIELD, CVR_FIELD } from "@/utils/table_fields";
import AppIcon from '@/components/AppIcon.vue';
import { mapState } from "vuex";
import { HugValue, DateValue, RateChangeValue, CurrencyValue, NumberValue, BoxedValue, BarWithValue, PercentageValue, RatingValue, DottedValue, OverlapBarValue } from "@/components/tableElements";
import LiteTableMixin from '@/mixins/LiteTableMixin.vue';
import { DEFAULT_DATE_FORMATS, DAYS_INVENTORY_HEATMAP_COLORS, ORGANIC_CVR_HEATMAP_COLORS, CVR_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, PERIOD_COLUMN_TITLES, CPC_DECILE_COLORS } from '@/utils/constants';
import moment from 'moment';
import BarSlotWithValue from '@/components/tableElements/BarSlotWithValue.vue';
import LayerBars from '@/components/tableElements/LayerBars.vue';
import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import FlexRow from "@/components/FlexRow";
import CompareValue from '@/components/tableElements/CompareValue.vue';
import DownloadMixin from '@/mixins/DownloadMixin.vue';
import { capitalize } from 'vue';
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
export default {
  name: "AdvertisingPeriodTable",
  components: {
    TableWithTitle,
    WrapWithPopover,
    AppIcon,
    HugValue,
    DateValue,
    RateChangeValue,
    CurrencyValue,
    NumberValue,
    BoxedValue,
    BarWithValue,
    PercentageValue,
    RatingValue,
    DottedValue,
    OverlapBarValue,
    BarSlotWithValue,
    LayerBars,
    VariableStyledBar,
    FlexRow,
    CompareValue,
    SlotWithEvolutionValue,
    EvolutionValue
  },
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  emits: ["setDateRange", "refresh"],
  data() {
    return {
      DAYS_INVENTORY_HEATMAP_COLORS,
      ORGANIC_CVR_HEATMAP_COLORS,
      BUYBOX_HEATMAP_COLORS,
      PERIOD_COLUMN_TITLES,
      CPC_DECILE_COLORS,
      CVR_HEATMAP_COLORS,
      fields: [SELECTOR, PERIOD_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
      csvSources: period => ({
        [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
        [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
        ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
        [SPEND_FIELD.label]: SPEND_FIELD.key,
        ...this.getEvolutionsForField(SPEND_FIELD),
        [TACOS_FIELD.label]: TACOS_FIELD.key,
        [CLICKS_FIELD.label]: CLICKS_FIELD.key,
        [CPC_FIELD.label]: CPC_FIELD.key,
        [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
        ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
        [CVR_FIELD.label]: CVR_FIELD.key,
        [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
        "Sponsored Asin": SPONSORED_ASIN_FIELD.key
      })
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.dashboard_v2.periodTable,
      tab: state => state.dashboard_v2.currentTabValue,
      period: state => state.dashboard_v2.period,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    csvFileName() {
      return `Dashboard_Advertising_Evolution_${capitalize(this.period)}.csv`;
    }
  },
  methods: {
    getDescriptionBasedOnKpiKey,
    getColorsBasedOnRange,
    getColorForDecile,
    handleDateClick(period) {
      period = moment(period);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    async downloadPerformancePeriodCSV() {
      const csv_headers = this.csvSources(this.period);
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};
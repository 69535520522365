import { mapMutations, mapActions } from "vuex";
import TheSidebarMenuItem from "@/components/TheSidebarMenuItem";
export default {
  name: "TheSidebarMenu",
  emits: ["menu-click", "handleCollapse"],
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: false
    },
    useSettings: {
      type: Boolean,
      default: false
    },
    openItemText: String
  },
  components: {
    TheSidebarMenuItem
  },
  computed: {
    menuItems() {
      return this.useSettings ? this.settingsItems : this.nonSettingsItems;
    }
  },
  data() {
    return {
      //openItemText: null,
      nonSettingsItems: [{
        text: "Dashboard",
        url: "/",
        icon: "home-new-ui",
        color: "#FFFFFF",
        featureName: "default"
      }, {
        text: "Products",
        icon: "products-new-ui",
        color: "#FFFFFF",
        featureName: "default",
        children: [
        // { text: 'Translation', url: '/products'},
        // { text: 'Perfomance', url: '/perfomance' },
        // { text: "Product Analytics", url: "/product-analytics" }
        {
          text: "Content",
          url: "/seo"
        }, {
          text: "Parent View",
          url: "/seo-parent-view",
          featureName: "seo-parent-view"
        }, {
          text: "Pricing and Margin",
          url: "/pricing-and-margin"
        }, {
          text: "COGS",
          url: "/cogs-data",
          featureName: "cogsdatafeature"
        }]
      }, {
        text: "Inventory",
        icon: "checklist",
        color: "#FFFFFF",
        children: [{
          text: "Demand Forecasting",
          url: "/demand-forecasting"
        }, {
          text: "Product Data",
          url: "/product-data"
        }, {
          text: "Inventory Coverage",
          url: "/inventory-coverage"
        }],
        featureName: "inventoryfeature"
      }, {
        text: "Advertising",
        icon: "advertisement-new-ui",
        url: "/advertising",
        color: "#FFFFFF",
        featureName: "advertisingv2feature"
      }, {
        text: "Profit and Loss",
        icon: "coins-stacked",
        url: "/profit-and-loss",
        color: "#FFFFFF",
        featureName: "profitandlossfeature"
      }],
      settingsItems: [{
        text: "Notifications",
        url: "/alerts",
        icon: "notification-new-ui",
        color: "#FFFFFF",
        featureName: "alertsfeature"
      },
      // {
      //   text: "Connections",
      //   icon: "account-gear",
      //   color: "#FFFFFF",
      //   featureName: "default",
      //   children: [
      //     { text: "Amazon Seller Central", url: "/amz-seller-central" },
      //     { text: "Amazon Advertising", url: "/amz-advertising" },
      //     { text: "Amazon Vendor Central", url: "/amz-vendor-central" },
      //   ],
      // },
      {
        text: "Help",
        action: "openIntercom",
        icon: "help-new-ui",
        color: "#FFFFFF",
        featureName: "helpmenufeature"
      }, {
        text: "Settings",
        icon: "settings-new-ui",
        color: "#FFFFFF",
        featureName: "default",
        children: [{
          text: "Connections",
          url: "/connections"
        },
        //{ text: "Manage Account Members"}, //url: "/manageaccountmembers", no design yet so disabled it
        {
          text: "Account Management",
          url: "/account"
        }, {
          text: "User Profile",
          url: "/profile"
        }, {
          text: "Sign-Out",
          action: "logout"
        }]
      }]
    };
  },
  methods: {
    ...mapMutations(["authTokenSet"]),
    ...mapActions(["authLogout"]),
    async onClick(item) {
      if (item.action == "logout") {
        //this.authTokenSet(null)
        this.loading = true;
        await this.authLogout();
        this.loading = false;
        this.$router.push("/login");
      }
      if (item.action == "openIntercom") {
        if (window.Intercom) {
          window.Intercom("show");
        }
      }
      if (item.children && item.children.length > 0) {
        this.$emit("menu-click", item);
      }
      if (item.text === "Dashboard") {
        //logic has been moved from child to the parent
        this.$emit("menu-click", item);
      }
    }
  }
};
import moment from "moment";
import DOMPurify from "dompurify";
import ValidateMixin from "./ValidateMixin.vue";
import ContentMixin from "./ContentMixin.vue";
import { stringsAreTheSame, stringsAreTheSameWithCaseSensitive, isEmptyString } from "@/utils";
export default {
  name: "ContentDisplayRulesMixin",
  mixins: [ValidateMixin, ContentMixin],
  computed: {
    pushStatusMessages() {
      const errors = this.current_item?.sync_errors?.length == 0 ? "<li>Amazon did not provide any information.</li>" : this.current_item?.sync_errors[0] == "Internal Server Error" ? "<li style='font-weight:500;font-size:14px'>A technical error occurred. Please contact Omny support.</li>" : this.current_item?.sync_errors.map(error => `<li style="font-weight:600;font-size:14px;">${error}</li>`).join("");
      return {
        sync_error: {
          title: `<div style="font-weight:600;color:#db333b;font-size:15px">Sync with Seller/Vendor Central failed</div>`,
          text: `<div style="font-weight: 400;color: #595959; padding: 10px 12px;background: #FFF5F6">Please review the error message below, correct the issue, and attempt to re-sync your content.<br />
          </div>` + `<div style="display:flex; padding: 10px 12px; flex-direction: column; gap:8px">` + `<b style="font-weight: 600;color: #FF8F94;font-size: 13px;letter-spacing: 0.26px;text-transform: uppercase;line-height: 18.2px;">Issues:</b>` + `<div style="font-weight: 700;color: #595959;">` + `<ul style="padding-left: 1rem; margin: 0;">` + errors + `</ul>
            </div>
          </div>`,
          color_text: "#ff8933",
          background_color: "#fff0f1",
          background_title: "#FFEBEB"
        },
        draft: {
          title: `<div style="font-weight:600;color:#423994;font-size:15px">Draft</div>`,
          text: `<div style="font-size:14px; font-weight: 500;color: #595959; padding: 10px 12px"> Listing edited on Omny but not pushed to Amazon yet.</div>`,
          color_text: "#ffffff",
          background_color: "#F8F9FF",
          background_title: "#F0F2FF"
        },
        processing: {
          title: `<div style="font-weight:600;color:#423994;font-size:15px">Processing</div>`,
          text: `<div style="font-size:14px; font-weight: 500;color: #595959; padding: 10px 12px">The latest Omny version is currently being pushed to Amazon Seller/Vendor Central</div>`,
          color_text: "#595959",
          background_color: "#FAFAFA",
          background_title: "#F4F4F4"
        },
        sync_finished: {
          title: `<div style="font-weight:600;color:#2172E1;font-size:15px">Sync with Amazon in progress</div>`,
          text: `<div style="font-size:14px; font-weight: 500;color: #595959; padding: 10px 12px">Last contribution has been pushed to Seller/Vendor Central and is currently being processed by Amazon (can take up to 72h)</div>`,
          color_text: "#2172e1",
          background_color: "#F4F8FF",
          background_title: "#EDF5FF"
        },
        accepted: {
          title: `<div style="font-weight:600;color:#22863D;font-size:15px">Synced with Amazon</div>`,
          text: `<div style="font-size:14px; font-weight: 500;color: #595959; padding: 10px 12px">The last contribution has been published on Amazon</div>`,
          color_text: "#22863d",
          background_color: "#F6FEF8",
          background_title: "#DEFBE6"
        },
        not_accepted: {
          title: `<div style="font-weight:600;color:#FF8933;font-size:15px">Mismatch with Amazon</div>`,
          text: `<div style="font-weight: 500;color: #595959; padding: 10px 12px">
            <ul style="padding-left: 1rem; margin: 0;">
              <li style="font-weight:500;font-size:14px">Last contribution was not accepted by Amazon </li>
              <li style="font-weight:500;font-size:14px">Last contribution was edited by Amazon</li>
              <li style="font-weight:500;font-size:14px">Amazon selected a contribution submitted by another seller.</li>
            </ul>
          </div>`,
          color_text: "#ffffff",
          background_color: "#FFF6F0",
          background_title: "#FFF3EB"
        },
        seller_central_not_accepted: {
          title: `<div style="font-weight:600;color:#DB2473;font-size:15px">Mismatch with Seller/Vendor Central</div>`,
          text: `<div style="font-weight: 500;color: #595959; padding: 10px 12px">
            <ul style="padding-left: 1rem; margin: 0;">
              <li style="font-weight:500;font-size:14px">Last contribution was not accepted by Seller/Vendor Central</li>
              <li style="font-weight:500;font-size:14px">Last contribution was edited by Seller/Vendor Central</li>
              <li style="font-weight:500;font-size:14px">Someone contributed directly to Seller/Vendor Central.</li>
            </ul>
          </div>`,
          color_text: "#ffffff",
          background_color: "#FDF7FA",
          background_title: "#FCEEF5"
        },
        /*empty_omny: {
          title:`<div style="font-weight:600;color:#4D4D4D;font-size:15px">Missing content</div>`,
          text: `<div style="font-weight: 500;color: #757575; padding: 10px 12px">
            Listing not fully saved on Omny yet. <br/> 
            <span style="font-weight:700">Amazon version</span>&nbsp; is currently displayed and is synced with Amazon Seller/Vendor Central
          </div>`,
          color_text: "#ffffff",
          background_color: "#FFF",
          background_title: "#FAFAFA"
        },*/
        unknown: ""
      };
      // return {
      //   sync_error:
      //     "<b>Sync Error:</b> Synchronization with Seller/Vendor Central failed. Please review the error message below, correct the issue, and attempt to re-sync your content.<br />" +
      //     "<b>Issues:</b> <br />"+
      //       this.current_item?.sync_errors?.join("<br />") ?? "",
      //   draft: "<b>Draft:</b> An updated listing has been created in the platform",
      //   processing:
      //     "<b>Processing:</b> An updated listing is currently being pushed to Amazon Seller Central",
      //   sync_finished:
      //     "<b>Synced with Seller Central:</b> Synced with Seller/Vendor Central successful. Daily sync with Amazon will confirm publication on the Amazon website by tomorrow.",
      //   accepted:
      //     "<b>Synced with Amazon:</b> The last modifications has been published on Amazon",
      //   not_accepted: "The last modification has been rejected by Amazon",
      //   unknown: "",
      // };
    }
  },
  data() {
    return {
      current_item: {},
      push_status_colors: {
        sync_error: "#fff",
        draft: "#f3eeff",
        processing: "#fff",
        sync_finished: "#edf5ff",
        accepted: "#defbe6",
        not_accepted: "#fff2e8",
        seller_central_not_accepted: "#fff5fa",
        //empty_omny:"",
        unknown: ""
      },
      push_status_icons: {
        sync_error: "sync-error-status",
        draft: "draft-status",
        processing: "processing-status",
        sync_finished: "seller-central-sync-status",
        accepted: "amazon-sync-status",
        not_accepted: "not-accepted-status",
        seller_central_not_accepted: "seller-central-not-accepted-status",
        //empty_omny: "empty-omny-status",
        unknown: ""
      }
    };
  },
  methods: {
    isPropertyAbsent(obj, prop) {
      return obj[prop] === undefined || obj[prop] === null || obj[prop] === "";
    },
    allModifiedAttributesAccepted(item) {
      let previously_modified_attribute = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return previously_modified_attribute.length > 0 && !!item?.last_push_to_amazon_time && !isEmptyString(item?.last_push_to_amazon_time) && this.allAttributeExistAmazonSellerCentralVersionEqualAmazonVersionWithCaseSensitive(item) && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 ? previously_modified_attribute.every(attribute => this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, attribute)) : false;
    },
    allAttributeOmnyVersionEqualAmazon(item) {
      let all_attributes = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazon(item, attribute));
    },
    allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) {
      let all_attributes = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, attribute));
    },
    allAttributeOmnyVersionEqualAmazonSellerCentral(item) {
      let all_attributes = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazonSellerCentral(item, attribute));
    },
    allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) {
      let all_attributes = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, attribute));
    },
    allAttributeAmazonIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonVersionEmptyString(item, attribute));
    },
    allAttributeAmazonVersionEqualAmazonSellerCentral(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionSameAsAmazon(item, attribute));
    },
    allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, attribute));
    },
    allAttributeExistAmazonSellerCentralVersionEqualAmazonVersionWithCaseSensitive(item) {
      let all_attributes = Object.keys(item.amazon_version).filter(attribute => !this.isAmazonSellerCentralVersionEmptyString(item, attribute));
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, attribute));
    },
    someSellerCentralAttributeEmptyButAmazonExist(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.some(attribute => this.isAmazonSellerCentralVersionEmptyString(item, attribute) && !this.isAmazonVersionEmptyString(item, attribute));
    },
    someAmazonAttributeEmptyButSellerCentralExist(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.some(attribute => !this.isAmazonSellerCentralVersionEmptyString(item, attribute) && this.isAmazonVersionEmptyString(item, attribute));
    },
    allAttributeAmazonSellerCentralIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionEmptyString(item, attribute));
    },
    allAttributeOmnyIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionEmptyString(item, attribute));
    },
    noContentOnSCAndOnOmny(item, content_evaluation) {
      //test-case-1
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_sc_and_on_omny");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCEqualAmazon(item, content_evaluation) {
      //test-case-2
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_equal_amazon");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCDifferenceFromAmazon(item, content_evaluation) {
      //test-case-3
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_difference_from_amazon");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCDifferenceFromAmazonButSomeSCAttributeEmpty(item, content_evaluation) {
      //test-case-3b
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.someSellerCentralAttributeEmptyButAmazonExist(item) && !this.someAmazonAttributeEmptyButSellerCentralExist(item) && this.allAttributeExistAmazonSellerCentralVersionEqualAmazonVersionWithCaseSensitive(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_difference_from_amazon_but_some_sc_attribute_empty");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCDifferenceFromAmazonButSomeAmazonAttributeEmpty(item, content_evaluation) {
      //test-case-3c
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.someAmazonAttributeEmptyButSellerCentralExist(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_difference_from_amazon_but_some_amazon_attribute_empty");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndOmnyDifferenceSCWithoutPush(
    //test-case-4
    item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_omny_difference_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndOmnyDifferenceSCWithPush(item, content_evaluation) {
      //test-case-5
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_omny_difference_sc_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndSCWithoutPush(item, content_evaluation) {
      //test-case-6
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !this.allModifiedAttributesPushed(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithoutPush(
    //test-case-7
    item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !this.allModifiedAttributesPushed(item) && item.push_status == "draft" && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_equal_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonDifferenceSCWithoutPush(
    //test-case-7b
    item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !this.allModifiedAttributesPushed(item) && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_difference_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithSyncError(
    //test-case-8
    item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !!item?.last_push_to_amazon_time && item.push_status == "sync_error" && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_sync_error");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithRecentPush(
    //test-case-9
    item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff < 1000 * 60 * 60 * 24 && item.push_status == "sync_finished" && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_recent_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithOldPushContributionModified(
    //test-case-10
    item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && item.push_status == "sync_finished" && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_contribution_modified");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithOldPushDirectModification(
    //test-case-11
    item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && (item.push_status == "sync_finished" || item.push_status == "accepted") && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_direct_modification");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualSCAndOmnyDifferenceFromAmazonWithPush(
    //test-case-12
    item, content_evaluation) {
      let all_attributes = Object.keys(item.omny_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_sc_and_omny_difference_from_amazon_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDiffrenceFromSCAndSCDifferenceAmazonWithPush(item, content_evaluation) {
      //test-case-13
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentralWithCaseSensitive(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_diffrence_from_sc_and_sc_difference_amazon_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndSCWithPush(item, content_evaluation) {
      //test-case-14
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazonWithCaseSensitive(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentralWithCaseSensitive(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_sc_with_push");
      }
      return content_evaluation;
    },
    contentEvaluateItem(item) {
      let content_evaluation = [];
      this.noContentOnSCAndOnOmny(item, content_evaluation);
      this.NoContentOnOmnyAndSCEqualAmazon(item, content_evaluation);
      this.NoContentOnOmnyAndSCDifferenceFromAmazonButSomeSCAttributeEmpty(item, content_evaluation);
      this.NoContentOnOmnyAndSCDifferenceFromAmazonButSomeAmazonAttributeEmpty(item, content_evaluation);
      this.NoContentOnOmnyAndSCDifferenceFromAmazon(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndOmnyDifferenceSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndOmnyDifferenceSCWithPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonDifferenceSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithSyncError(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithRecentPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithOldPushContributionModified(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndAmazonEqualSCWithOldPushDirectModification(item, content_evaluation);
      this.ContentOnOmnyEqualSCAndOmnyDifferenceFromAmazonWithPush(item, content_evaluation);
      this.ContentOnOmnyDiffrenceFromSCAndSCDifferenceAmazonWithPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndSCWithPush(item, content_evaluation);
      return content_evaluation;
    },
    contentToDisplayTextStyle(item, key) {
      // item.push_status = !this.isOmnyVersionSameAsAmazonAndAmazonSellerCentral(item, key)
      //   ? "draft"
      //   : item.push_status;
      const statusValidationMap = {
        product_name: this.mValidateTitle,
        product_description: this.mValidateDescription,
        bullet_point_1: this.mValidateBulletPoints,
        bullet_point_2: this.mValidateBulletPoints,
        bullet_point_3: this.mValidateBulletPoints,
        bullet_point_4: this.mValidateBulletPoints,
        bullet_point_5: this.mValidateBulletPoints,
        generic_keyword: this.mValidateKeyword,
        count_images: this.mValidateNumborOfImage
      };
      let validationStatus;
      if (statusValidationMap[key]) {
        validationStatus = statusValidationMap[key].call(this, this.contentToDisplay(item, key));
      }
      let styleContent = this.contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonStatus(item, key) ? "content_accepted_sc_not_accepted_on_amazon_status" : this.contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonWithOldPushStatus(item, key) ? "content_accepted_sc_not_accepted_on_amazon_with_old_push_status" : this.contentAcceptedAmazonSellerCentralAndNotAcceptedAmazonWithCurrentPushStatus(item, key) ? "content_accepted_sc_and_not_accepted_amazon_with_current_push_status" : this.contentNotAcceptedAmazonSellerCentralAndNotAcceptedOnAmazonStatus(item, key) ? "content_not_accepted_sc_and_not_accepted_on_amazon_status" : this.noContentOnOmnyAndAmazonSellerCentralEmptyButAmazonExistStatus(item, key) ? "no_content_on_omny_and_sc_empty_but_amazon_exist_status" : this.noContentOnOmnyAndAmazonSellerCentralExistButAmazonEmptyStatus(item, key) ? "no_content_on_omny_and_sc_exist_but_amazon_empty_status" : this.contentNotAcceptedAmazonSellerCentralWithSuccessOldPushStatus(item, key) ? "content_not_accepted_sc_with_success_old_push_status" : this.noContentOnOmnyAndSCDifferenceFromAmazonStatus(item, key) ? "no_content_on_omny_and_sc_difference_from_amazon_status" : this.contentNotAcceptedAmazonSellerCentralWithSuccessPushStatus(item, key) ? "content_not_accepted_sc_with_success_push_status" : this.contentNotAcceptedAmazonSellerCentralOrDraftStatus(item, key) ? "content_not_accepted_sc_or_draft_status" : !this.isOmnyVersionEmptyString(item, key) ? "content_on_omny_difference_sc_and_sc_equal_amazon_status" : "no_content_on_omny_and_sc_equal_amazon";
      let textStatus = {
        content_accepted_sc_not_accepted_on_amazon_status: {
          style: "color:#ff8933;font-weight:660",
          status: "not_accepted",
          text: "Mismatch with Amazon"
        },
        content_accepted_sc_not_accepted_on_amazon_with_old_push_status: {
          style: "color:#ff8933;font-weight:660",
          status: "not_accepted",
          text: "Mismatch with Amazon"
        },
        content_accepted_sc_and_not_accepted_amazon_with_current_push_status: {
          style: "color:#2172e1;font-weight:660",
          status: "sync_finished",
          text: "Sync with Amazon in progress"
        },
        content_not_accepted_sc_and_not_accepted_on_amazon_status: {
          style: "color:#ff8933;font-weight:660",
          status: "not_accepted",
          text: "Mismatch with Amazon"
        },
        no_content_on_omny_and_sc_empty_but_amazon_exist_status: {
          style: "color:#999;font-weight:500",
          status: "empty_omny",
          text: "No local version"
        },
        no_content_on_omny_and_sc_exist_but_amazon_empty_status: {
          style: "color:#ff8933;font-weight:660",
          status: "not_accepted",
          text: "Mismatch with Amazon"
        },
        content_not_accepted_sc_with_success_old_push_status: {
          style: "color:#db2473;font-weight:660",
          status: "seller_central_not_accepted",
          text: "Mismatch with Seller/Vendor Central"
        },
        no_content_on_omny_and_sc_difference_from_amazon_status: {
          style: "color:#ff8933;font-weight:660",
          status: "not_accepted",
          text: "Mismatch with Amazon"
        },
        content_not_accepted_sc_with_success_push_status: {
          style: "color:#2172e1;font-weight:660",
          status: "sync_finished",
          text: "Sync with Amazon in progress"
        },
        content_not_accepted_sc_or_draft_status: {
          style: "color:#5a52ae;font-weight:660",
          status: "draft",
          text: "Draft"
        },
        content_on_omny_difference_sc_and_sc_equal_amazon_status: {
          style: "color:#4d4d4d;font-weight:500",
          status: "unknown"
        },
        no_content_on_omny_and_sc_equal_amazon: {
          style: "color:#999;font-weight:500",
          status: "empty_omny",
          text: "No local version"
        }
      };
      let style = textStatus[styleContent].style;
      let status = textStatus[styleContent].status;
      let tooltipText = textStatus[styleContent].text;
      return {
        style,
        status,
        tooltipText,
        validationStatus
      };
    },
    contentShowOmnyValidationStatus(item) {
      return Object.keys(item.amazon_version).some(attribute => this.contentToDisplayTextStyle(item, attribute).style == "color:#5a52ae;font-weight:660");
    },
    contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && !item?.last_push_to_amazon_time;
    },
    contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonWithOldPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24;
    },
    contentAcceptedAmazonSellerCentralAndNotAcceptedAmazonWithCurrentPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff <= 1000 * 60 * 60 * 24;
    },
    contentNotAcceptedAmazonSellerCentralAndNotAcceptedOnAmazonStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && !this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, key) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && !item?.modified_attributes.length;
    },
    noContentOnOmnyAndAmazonSellerCentralEmptyButAmazonExistStatus(item, key) {
      return this.isOmnyVersionEmptyString(item, key) && this.isAmazonSellerCentralVersionEmptyString(item, key) && !this.isAmazonVersionEmptyString(item, key);
    },
    noContentOnOmnyAndAmazonSellerCentralExistButAmazonEmptyStatus(item, key) {
      return this.isOmnyVersionEmptyString(item, key) && !this.isAmazonSellerCentralVersionEmptyString(item, key) && this.isAmazonVersionEmptyString(item, key);
    },
    contentNotAcceptedAmazonSellerCentralOrDraftStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key);
    },
    noContentOnOmnyAndSCDifferenceFromAmazonStatus(item, key) {
      return this.isOmnyVersionEmptyString(item, key) && !this.isAmazonSellerCentralVersionEmptyString(item, key) && !this.isAmazonVersionEmptyString(item, key) && !this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, key);
    },
    contentNotAcceptedAmazonSellerCentralWithSuccessPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, key) && item.push_status == "sync_finished" && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff < 1000 * 60 * 60 * 24 && !item?.modified_attributes.length;
    },
    contentNotAcceptedAmazonSellerCentralWithSuccessOldPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) && this.isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, key) && item.push_status == "sync_finished" && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24;
    },
    isOmnyVersionSameAsAmazon(item, key) {
      return stringsAreTheSame(item?.omny_version[key], item?.amazon_version[key]);
    },
    isOmnyVersionSameAsAmazonWithCaseSensitive(item, key) {
      return stringsAreTheSameWithCaseSensitive(item?.omny_version[key], item?.amazon_version[key]);
    },
    isOmnyVersionEmptyString(item, key) {
      return isEmptyString(item?.omny_version[key]);
    },
    isAmazonSellerCentralVersionEmptyString(item, key) {
      return isEmptyString(item?.amazon_seller_central_version[key]);
    },
    isAmazonVersionEmptyString(item, key) {
      return isEmptyString(item?.amazon_version[key]);
    },
    isOmnyVersionSameAsAmazonAndAmazonSellerCentral(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && this.isOmnyVersionSameAsAmazon(item, key) && this.isOmnyVersionSameAsAmazonSellerCentral(item, key);
    },
    isOmnyVersionSameAsAmazonSellerCentral(item, key) {
      return stringsAreTheSame(item?.omny_version[key], item?.amazon_seller_central_version[key]);
    },
    isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, key) {
      return stringsAreTheSameWithCaseSensitive(item?.omny_version[key], item?.amazon_seller_central_version[key]);
    },
    contentToDisplay(item, key, displayHTMLTags = false) {
      const rawContent = item.omny_version?.[key] || item.amazon_seller_central_version?.[key] || item.amazon_version?.[key] || "";
      const processedContent = DOMPurify.sanitize(rawContent, {
        ALLOWED_TAGS: []
      });
      // return (
      //   item.omny_version?.[key] ||
      //   item.amazon_version?.[key] ||
      //   item.amazon_seller_central_version?.[key] ||
      //   ""
      // );
      return displayHTMLTags ? rawContent : processedContent;
    },
    contentLastPushDate(item) {
      let last_push_to_amazon_time = item?.last_push_to_amazon_time ? item?.last_push_to_amazon_time.includes("Z") ? item?.last_push_to_amazon_time : item?.last_push_to_amazon_time + "Z" : undefined;
      last_push_to_amazon_time = last_push_to_amazon_time ? moment(last_push_to_amazon_time) : moment().add(1, "week");
      let diffInMilliseconds = moment.utc().diff(last_push_to_amazon_time);
      if (diffInMilliseconds < 0) {
        return {
          text: "",
          diff: 0
        };
      }
      let last_push_date = diffInMilliseconds < 1000 * 60 * 60 ? Math.floor(diffInMilliseconds / (1000 * 60)) + "m" : diffInMilliseconds < 1000 * 60 * 60 * 24 ? Math.floor(diffInMilliseconds / (1000 * 60 * 60)) + "h" : Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + "d";
      return {
        text: last_push_date,
        diff: diffInMilliseconds
      };
    },
    allModifiedAttributesPushed(item) {
      const after_push_status = ["sync_finished", "processing", "sync_error"];
      return item.modified_attributes.length == 0 && after_push_status.includes(item.push_status);
    },
    contentPushProcessingIconToDisplay() {
      return {
        icon: this.push_status_icons["processing"],
        color: this.push_status_colors["processing"],
        title: this.pushStatusMessages["processing"].title,
        text: this.pushStatusMessages["processing"].text,
        color_text: this.pushStatusMessages["processing"].color_text,
        background_color: this.pushStatusMessages["processing"].background_color,
        background_title: this.pushStatusMessages["processing"].background_title,
        status: "processing"
      };
    },
    contentPushIconToDisplay(item, attributeTextStyles) {
      this.current_item = item;
      const prev_push_status = item.push_status;
      const prioritizedTextStyle = {
        "color:#999;font-weight:500": 0,
        //grey
        "color:#4d4d4d;font-weight:500": 1,
        //black
        "color:#2172e1;font-weight:660": 2,
        //blue
        "color:#db2473;font-weight:660": 3,
        //pink
        "color:#ff8933;font-weight:660": 4,
        //orange
        "color:#5a52ae;font-weight:660": 5 //purple
      };
      const attributeStyleArray = Object.keys(attributeTextStyles);
      const pushStatusValue = Math.max(...attributeStyleArray.map(attributeStyle => {
        const style = attributeTextStyles[attributeStyle].style;
        return prioritizedTextStyle[style];
      }));
      console.log("push_status", item.push_status);
      if (item.push_status !== "sync_error" && (item.push_status !== "processing" || this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24)) {
        item.push_status = pushStatusValue == 5 ? "draft" : pushStatusValue == 4 ? "not_accepted" : pushStatusValue == 3 ? "seller_central_not_accepted" : pushStatusValue == 2 || item.push_status == "sync_finished" && this.contentLastPushDate(item).diff <= 1000 * 60 * 60 * 24 ? "sync_finished"
        /*: pushStatusValue == 0
        ? "empty_omny"*/ : pushStatusValue == 1 && item.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 ? "accepted" : "unknown";
      }
      const uiPushStatus = ["accepted", "not_accepted", "seller_central_not_accepted"];
      item.prev_push_status = !uiPushStatus.includes(prev_push_status) && item.push_status != prev_push_status ? prev_push_status : item.prev_push_status;
      const status = item.push_status;
      item.push_status = item.push_status == "" ? "unknown" : item.push_status;
      return item.push_status ? {
        icon: this.push_status_icons[item.push_status],
        color: this.push_status_colors[item.push_status],
        title: this.pushStatusMessages[item.push_status].title,
        text: this.pushStatusMessages[item.push_status].text,
        color_text: this.pushStatusMessages[item.push_status].color_text,
        background_color: this.pushStatusMessages[item.push_status].background_color,
        background_title: this.pushStatusMessages[item.push_status].background_title,
        status: status
      } : {};
    },
    contentIconToDisplay(item, key) {
      return item.omny_version?.[key] == item.amazon_version?.[key] ? "amazon" : item.omny_version?.[key] == item.amazon_seller_central_version?.[key] ? "seller_central" : "none";
    },
    updateModifiedAttributes(item, attribute) {
      let modifiedAttributesSet = new Set(item.modified_attributes || []);
      const uiPushStatus = ["draft", "accepted", "not_accepted", "seller_central_not_accepted"];
      if (!modifiedAttributesSet.has(attribute)) {
        modifiedAttributesSet.add(attribute);
        if (!this.isOmnyVersionSameAsAmazonSellerCentralWithCaseSensitive(item, attribute) && !this.isOmnyVersionSameAsAmazonWithCaseSensitive(item, attribute)) {
          item.prev_push_status = !uiPushStatus.includes(item.push_status) ? item.push_status : item.prev_push_status;
          item.push_status = "draft";
        }
      }
      item.modified_attributes = [...modifiedAttributesSet];
      return item;
    },
    removeHtmlTagsAndSpecialCharacters(rawContent) {
      let processedContent = DOMPurify.sanitize(rawContent, {
        ALLOWED_TAGS: []
      });
      return processedContent.trim();
    },
    isAmazonSellerCentralVersionSameAsAmazon(item, key) {
      return stringsAreTheSame(item?.amazon_seller_central_version[key], item?.amazon_version[key]);
    },
    isAmazonSellerCentralVersionSameAsAmazonWithCaseSensitive(item, key) {
      return stringsAreTheSameWithCaseSensitive(item?.amazon_seller_central_version[key], item?.amazon_version[key]);
    },
    transformProducts(products) {
      return products.map(item => this.transformChildElement(item));
    },
    transformChildElement(item) {
      const rulesOmnyScore = this.statusValidate(item, "omny");
      const rulesAmzScore = this.statusValidate(item, "amazon");
      const country = this.getCountry(item.marketplace_id);
      const imageList = this.filterImageList(item.image_list);
      const productImage = this.getProductImage(item.image_list);
      const attributeTextStyles = {
        product_name_style: this.contentToDisplayTextStyle(item, "product_name"),
        bullet_point_1_style: this.contentToDisplayTextStyle(item, "bullet_point_1"),
        bullet_point_2_style: this.contentToDisplayTextStyle(item, "bullet_point_2"),
        bullet_point_3_style: this.contentToDisplayTextStyle(item, "bullet_point_3"),
        bullet_point_4_style: this.contentToDisplayTextStyle(item, "bullet_point_4"),
        bullet_point_5_style: this.contentToDisplayTextStyle(item, "bullet_point_5"),
        generic_keyword_style: this.contentToDisplayTextStyle(item, "generic_keyword"),
        product_description_style: this.contentToDisplayTextStyle(item, "product_description")
      };
      return {
        listing_status: item.listing_status,
        image_small: productImage?.link,
        image_list: imageList,
        statusResultOmnyScore: rulesOmnyScore,
        statusOmnyScore: this.statusCalculate(rulesOmnyScore),
        statusResultAmzScore: rulesAmzScore,
        statusAmzScore: this.statusCalculate(rulesAmzScore),
        sku: item.sku,
        asin: item.asin,
        content_id: item.content_id,
        amazon_link: item.amazon_link,
        modified_attributes: item.modified_attributes,
        category_name: item.category_name,
        category_id: item.category_id,
        has_a_plus_content: item.has_a_plus_content,
        brand: item.brand,
        product_name: this.contentToDisplay(item, "product_name"),
        product_name_style: attributeTextStyles["product_name_style"],
        product_name_icon: this.contentIconToDisplay(item, "product_name"),
        count_images: item.count_images,
        count_images_style: this.mValidateNumberOfImage(item.count_images),
        bullet_point_1: this.contentToDisplay(item, "bullet_point_1"),
        bullet_point_1_style: attributeTextStyles["bullet_point_1_style"],
        bullet_point_1_icon: this.contentIconToDisplay(item, "bullet_point_1"),
        bullet_point_2: this.contentToDisplay(item, "bullet_point_2"),
        bullet_point_2_style: attributeTextStyles["bullet_point_2_style"],
        bullet_point_2_icon: this.contentIconToDisplay(item, "bullet_point_2"),
        bullet_point_3: this.contentToDisplay(item, "bullet_point_3"),
        bullet_point_3_style: attributeTextStyles["bullet_point_3_style"],
        bullet_point_3_icon: this.contentIconToDisplay(item, "bullet_point_3"),
        bullet_point_4: this.contentToDisplay(item, "bullet_point_4"),
        bullet_point_4_style: attributeTextStyles["bullet_point_4_style"],
        bullet_point_4_icon: this.contentIconToDisplay(item, "bullet_point_4"),
        bullet_point_5: this.contentToDisplay(item, "bullet_point_5"),
        bullet_point_5_style: attributeTextStyles["bullet_point_5_style"],
        bullet_point_5_icon: this.contentIconToDisplay(item, "bullet_point_5"),
        generic_keyword: this.contentToDisplay(item, "generic_keyword"),
        generic_keyword_style: attributeTextStyles["generic_keyword_style"],
        generic_keyword_icon: this.contentIconToDisplay(item, "generic_keyword"),
        product_description: this.contentToDisplay(item, "product_description"),
        product_description_style: attributeTextStyles["product_description_style"],
        product_description_icon: this.contentIconToDisplay(item, "product_description"),
        country,
        flag: this.getCountryFlag(this.getCountryCode(country)),
        isSelected: this.selected_contents.includes(item.asin),
        last_push_date: this.contentLastPushDate(item).text,
        show_omny_validation_status: this.contentShowOmnyValidationStatus(item),
        content_evaluation_status: this.contentEvaluateItem(item),
        statusPush: this.contentPushIconToDisplay(item, attributeTextStyles),
        parent_asin: item.parent_asins,
        amazon_version: item.amazon_version,
        omny_version: item.omny_version,
        amazon_seller_central_version: item.amazon_seller_central_version,
        marketplace_id: item.marketplace_id,
        seller_id: item.seller_id
      };
    }
  }
};
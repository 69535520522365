import { request } from "@/store";
import moment from "moment";
import Filter from "@/store/filter";
var _ = require("lodash");
const ppcSettings = {
  state: {
    currentWeek: moment().startOf('isoWeek'),
    strategies: [],
    weekFilled: [],
    weeksRange: [],
    products: [],
    boost: null,
    bid: null,
    bid_settings: {},
    sellers_filter: new Filter([], [], "id"),
    marketplaces_filter: new Filter([], [], "sales_channel"),
    currencies_filter: new Filter([], [], "currency"),
    connected_profiles_for_seller: [],
    budget_allocation: [],
    product_prioritization: [],
    bid_simulator: {},
    budget_allocation_total_rows: 0,
    product_prioritization_total_rows: 0
  },
  getters: {
    ppcSettingsWeeksGet(state) {
      return () => {
        return state.weeksRange.map(weekData => weekData.week);
      };
    },
    ppcBidSimulatorCampaignGroupNameGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_group_name : "";
      };
    },
    ppcBidSimulatorCampaignGroupImageGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_group_image : "";
      };
    },
    ppcBidSimulatorCampaignGroupCountGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_activated_products_count : "";
      };
    }
  },
  mutations: {
    ppcSettingsProductsSet(state, payload) {
      state.products = payload;
    },
    ppcSettingsStrategiesSet(state, payload) {
      state.strategies = payload;
    },
    ppcSettingsBoostSet(state, payload) {
      state.boost = payload;
    },
    ppcSettingsBidSet(state, payload) {
      state.bid = payload;
    },
    ppcBidSettingsSet(state, payload) {
      state.bid_settings = payload;
    },
    ppcSettingsWeekFilled(state, payload) {
      state.weekFilled = payload;
    },
    ppcSettingsWeeksRangeSet(state, payload) {
      state.weeksRange = payload;
    },
    ppcConnectedAdvertisingProfilesSet(state, payload) {
      state.connected_profiles_for_seller = payload;
    },
    ppcSettingsCurrencySet(state, payload) {
      state.currencies_filter = payload;
    },
    ppcBudgetAllocationSet(state, payload) {
      state.budget_allocation = payload;
    },
    ppcProductPrioritizationSet(state, payload) {
      state.product_prioritization = payload;
    },
    ppcBidSimulatorSet(state, payload) {
      state.bid_simulator = payload;
    },
    ppcProductPrioritizationTotalRowsSet(state, payload) {
      state.product_prioritization_total_rows = payload;
    },
    ppcBudgetStrategySet(state, payload) {
      state.weeksRange = state.weeksRange.map(item => {
        let budget_strategy_items = payload.find(budget_strategy => budget_strategy.week === item.week);
        return {
          ..._.mergeWith({}, item, budget_strategy_items, (o, s) => _.isNull(s) ? o : s)
        };
      });
    }
  },
  actions: {
    async ppcBudgetStrategy({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/budget_strategy/get", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcBudgetStrategySet", result.data);
      }
      return result;
    },
    async ppcBudgetStrategyFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/budget_strategy/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcBudgetStrategySet", result.data);
      }
      return result;
    },
    async ppcProductPrioritizationGetFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/product_prioritization/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcProductPrioritizationSet", result.data);
        commit("ppcProductPrioritizationTotalRowsSet", result.total_rows);
      }
      return result;
    },
    async ppcBidSimulatorGetFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/bid_simulator/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        let result_to_save = Object.groupBy(result.data, ({
          targeting_type
        }) => targeting_type);
        commit("ppcBidSimulatorSet", result_to_save);
      }
      return result;
    },
    async ppcBudgetDelete(_, payload) {
      // eslint-disable-next-line no-unused-vars
      await request("/ppc/settings/budget_strategy/delete", "POST", payload);
    },
    async ppcBoost({
      commit
    }, payload) {
      let result = (await request("/ppc/boost", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsBoostSet", result.data);
      }
      return result;
    },
    async ppcProducts({
      commit
    }, payload) {
      let result = (await request("/ppc/products", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsProductsSet", result.data);
      }
      return result;
    },
    async ppcBidAdjustement({
      commit
    }, payload) {
      let result = (await request("/ppc/bid", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsBidSet", result.data);
      }
      return result;
    },
    async refreshPpcBudgetAllocation({
      commit
    }) {
      const data = await request(`/ppc/budget-allocation`);
      if (data) {
        commit('ppcBudgetAllocationSet', data);
      }
      return data;
    },
    async ppcBudgetAllocationUpdate({
      state,
      commit
    }, payload) {
      // To save the updated value using current state instead default one
      const updatedData = state.budget_allocation.map(item => {
        if (item.campaign_group_id === payload.campaign_group_id) {
          return payload;
        }
        return item;
      });
      commit('ppcBudgetAllocationSet', updatedData);
      const data = await request(`/ppc/budget-allocation`);
      if (data) {
        // commit('ppcBudgetAllocationSet', data)
      }
      return data;
    },
    async ppcStrategiesSave(_, payload) {
      // eslint-disable-next-line no-unused-vars
      let result = (await request("/ppc/settings/budget_strategy/create", "POST", payload)).data;
      return result;
    },
    async ppcBidSettingGet({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/bid_settings/get", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcBidSettingsSet", result.data[0] || {
          profile_id: payload.profile_list[0],
          seller_id: payload.seller_list[0],
          marketplace_id: payload.marketplace_id,
          non_branded_keyword_targeting: 1,
          branded_keyword_product_targeting: 0.4,
          competitor_product_targeting: 1,
          discovery_targeting: 1
        });
      }
      return result;
    },
    async ppcBidSettingSave(_, payload) {
      let result = (await request("/ppc/settings/bid_settings/create", "POST", payload)).data;
      console.log("bid settings: ", result);
      return result;
    },
    ppcSettingsClearStrategies({
      commit
    }) {
      commit("ppcSettingsStrategiesSet", []);
      commit("ppcSettingsWeekFilled", []);
    },
    ppcSettingsInitializeSellers({
      commit
    }, payload) {
      commit("ppcSettingsSellersSet", payload);
    },
    ppcSettingsInitializeMarketplaces({
      commit
    }, payload) {
      commit("ppcSettingsMarketplacesSet", payload);
    }
  }
};
export default ppcSettings;
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59fa7df5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cogs-data-content-search"
};
const _hoisted_2 = {
  class: "cogs-data-content"
};
const _hoisted_3 = {
  class: "cogs-data-content-middle"
};
const _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cogs_data_top_section = _resolveComponent("cogs-data-top-section");
  const _component_app_input = _resolveComponent("app-input");
  const _component_error_occured = _resolveComponent("error-occured");
  const _component_cogs_data_content = _resolveComponent("cogs-data-content");
  const _component_AppPagination = _resolveComponent("AppPagination");
  const _component_UploadProductCogsDataSidebar = _resolveComponent("UploadProductCogsDataSidebar");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0",
    modelValue: $data.openModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.openModal = $event),
    showPagination: true,
    activateSettings: true,
    settingsInfoUpload: "Upload COGS Data",
    settingsInfoDownload: "Download COGS Data",
    onModalShown: $options.handleSideOpened,
    onHandleDownloadCSVClick: $options.downloadCogsCSV,
    onCloseFileUploadModal: $options.closeModal
  }, {
    "topbar-contents": _withCtx(() => [_createVNode(_component_cogs_data_top_section, {
      selected: $options.selectedFilteredSkus
    }, null, 8 /* PROPS */, ["selected"])]),
    pagination: _withCtx(() => [_createVNode(_component_AppPagination, {
      page: $data.page,
      "onUpdate:page": [_cache[1] || (_cache[1] = $event => $data.page = $event), $options.changePage],
      perPage: $data.perPage,
      "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => $data.perPage = $event), $options.changeElements],
      total: _ctx.total_rows
    }, null, 8 /* PROPS */, ["page", "perPage", "total", "onUpdate:page", "onUpdate:perPage"])]),
    "right-strip-modal-file-upload": _withCtx(() => [_createVNode(_component_UploadProductCogsDataSidebar, {
      onCloseUploadFileModal: $options.closeModal,
      onIsSidebarCollapsed: $options.onIsSidebarCollapsed,
      onHandleDownloadCSVClick: $options.downloadCogsCSV
    }, null, 8 /* PROPS */, ["onCloseUploadFileModal", "onIsSidebarCollapsed", "onHandleDownloadCSVClick"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_input, {
      modelValue: $data.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
      placeholder: "Search on this page...",
      v2: "",
      version: "search",
      debounce: "300",
      onChange: $options.searchItem
    }, null, 8 /* PROPS */, ["modelValue", "onChange"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$data.errorOccured ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_error_occured, {
      onRetry: $options.refreshCogsDataPage
    }, null, 8 /* PROPS */, ["onRetry"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
      class: _normalizeClass(["cogs-data-content", {
        blur: $data.dataLoading
      }])
    }, [$options.dfDataInitialized && !$data.errorOccured ? (_openBlock(), _createBlock(_component_cogs_data_content, {
      key: 0,
      items: $options.dfFiltered,
      selected: $data.selected,
      sortField: $data.sortField,
      sortDirection: $data.sortDirection,
      settings: $data.currentSettings,
      accountProvider: $options.accountProvider,
      productSegmentsList: $options.allProductSegmentsList,
      isSideBarCollapsed: $data.isSideBarCollapsed,
      onSelect: $options.onSelect,
      onSort: $options.onSort,
      onUpdate: $options.onUpdate,
      onSaveProductData: $options.onSaveProductData
    }, null, 8 /* PROPS */, ["items", "selected", "sortField", "sortDirection", "settings", "accountProvider", "productSegmentsList", "isSideBarCollapsed", "onSelect", "onSort", "onUpdate", "onSaveProductData"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onModalShown", "onHandleDownloadCSVClick", "onCloseFileUploadModal"]);
}
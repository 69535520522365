import { getAiCategoryBenefits } from "@/components/aiCategory/service";
import CategoryWrapper from "./CategoryWrapper.vue";
export default {
  name: "AiCategoryBenefits",
  emits: ["selectedProductBenefits"],
  components: {
    CategoryWrapper
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    selectedProductData: {
      type: Object,
      default: () => {}
    },
    userInput: {
      type: String,
      default: ""
    }
  },
  async created() {
    try {
      //this is checking whether user has selected any version such as amazon version.
      const useSelectedProductData = this.selectedProductData && this.selectedProductData.title !== null && this.selectedProductData.title !== "Local version";

      //based on the user selected version,return the value associated with the version.
      const getPropertyValue = property => {
        return useSelectedProductData ? this.selectedProductData[property] : this.product[property];
      };
      const params = {
        asin: this.selectedProductData?.asin ?? this.product.asin,
        marketplace_id: this.selectedProductData?.marketplace_id ?? this.product.marketplace_id,
        product_name: getPropertyValue("product_name"),
        product_description: getPropertyValue("product_description"),
        user_text: this.userInput,
        bullet_points: [getPropertyValue("bullet_point_1"), getPropertyValue("bullet_point_2"), getPropertyValue("bullet_point_3"), getPropertyValue("bullet_point_4"), getPropertyValue("bullet_point_5")].filter(Boolean)
      };

      //old implementation
      // const params = {
      //    marketplace_id: this.product.marketplace_id,
      //    asin: this.product.asin,
      // };

      //working version of code
      // const params = {
      //   asin: this.selectedProductData?.asin ?? this.product.asin,
      //   marketplace_id:
      //     this.selectedProductData?.marketplace_id ??
      //     this.product.marketplace_id,

      //   product_name:
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product.product_name
      //       : this.selectedProductData.product_name,
      //   product_description:
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product.product_description
      //       : this.selectedProductData.product_description,
      //   user_text: this.userInput,
      //   bullet_points: [
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product?.bullet_point_1 ?? ""
      //       : this.selectedProductData?.bullet_point_1 ?? "",
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product?.bullet_point_2 ?? ""
      //       : this.selectedProductData?.bullet_point_2 ?? "",
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product?.bullet_point_3 ?? ""
      //       : this.selectedProductData?.bullet_point_3 ?? "",
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product?.bullet_point_4 ?? ""
      //       : this.selectedProductData?.bullet_point_4 ?? "",
      //     !this.selectedProductData ||
      //     this.selectedProductData.title === null ||
      //     this.selectedProductData.title === "Local version"
      //       ? this.product?.bullet_point_5 ?? ""
      //       : this.selectedProductData?.bullet_point_5 ?? "",
      //   ].filter(Boolean),
      // };

      //legacy version where we only had just the local version
      // const params = {
      //   asin: this.selected_product_data.asin,
      //   marketplace_id: this.selected_product_data.marketplace_id,
      //   benefits_list: this.selected_benefits,
      //   use_cases_list: this.selected_usecases,
      //   seller_id: this.selected_product_data.seller_id,
      //   category_id: this.selected_product_data.category_id,
      //   product_name: this.selected_product_data.product_name,
      //   product_description: this.selected_product_data.product_description,
      //   user_text:this.userInput,
      //   bullet_points: [
      //     this.selected_product_data?.bullet_point_1 ?? "",
      //     this.selected_product_data?.bullet_point_2 ?? "",
      //     this.selected_product_data?.bullet_point_3 ?? "",
      //     this.selected_product_data?.bullet_point_4 ?? "",
      //     this.selected_product_data?.bullet_point_5 ?? "",
      //   ].filter(Boolean),
      // };

      console.log("[ai category] mounted in AICategoryBenefits");
      console.log(params);
      const categories = await getAiCategoryBenefits({
        params
      });
      this.categories = categories.map(category => ({
        ...category,
        active: true
      }));
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
    this.$emit("selectedProductBenefits", this.categories);
  },
  data() {
    return {
      categories: [],
      loading: true
    };
  },
  methods: {
    getSelectedBenefits() {
      return this.$refs.categoryWrapper.getSelectedCategories();
    },
    onSelectedProductBenefits(val) {
      this.$emit("selectedProductBenefits", val);
    }
  }
};
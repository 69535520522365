import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b665cab6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "app-switch-container"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return typeof $data.checked == 'boolean' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    type: "checkbox",
    id: $data.id,
    onChange: _cache[0] || (_cache[0] = (...args) => $options.onChange && $options.onChange(...args)),
    class: _normalizeClass([{
      checked: $data.checked
    }])
  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2), _createElementVNode("label", {
    for: $data.id,
    class: "app-switch"
  }, [_createElementVNode("span", null, [!$data.checked ? _renderSlot(_ctx.$slots, "icon-unchecked", {
    key: 0
  }, undefined, true) : _renderSlot(_ctx.$slots, "icon-checked", {
    key: 1
  }, undefined, true)])], 8 /* PROPS */, _hoisted_3)])) : _renderSlot(_ctx.$slots, "intermediate-state", {
    key: 1
  }, undefined, true);
}
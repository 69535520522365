import { DateValue } from '@/components/tableElements';
export default {
  name: 'MultiModeDatePickerQuarterSelectorItem',
  components: {
    DateValue
  },
  emits: ['click'],
  props: {
    quarter: {
      type: Object
    },
    format: {
      type: String,
      default: 'MMM'
    },
    active: {
      type: Boolean,
      default: false
    },
    previous: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6f51a3f7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "demand-forecastig"
};
const _hoisted_2 = {
  class: "demand-forecastig-content"
};
const _hoisted_3 = {
  class: "demand-forecastig-content-top"
};
const _hoisted_4 = {
  class: "demand-forecastig-content-search"
};
const _hoisted_5 = {
  class: "demand-forecastig-content-middle"
};
const _hoisted_6 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_demand_forecasting_top = _resolveComponent("demand-forecasting-top");
  const _component_app_input = _resolveComponent("app-input");
  const _component_error_occured = _resolveComponent("error-occured");
  const _component_demand_forecasting_content = _resolveComponent("demand-forecasting-content");
  const _component_demand_forecasting_sidebar = _resolveComponent("demand-forecasting-sidebar");
  return !$data.dataLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_demand_forecasting_top, {
    selected: $options.selectedFilteredSkus,
    onCreateInventoryOrder: $options.onCreateInventoryOrder,
    onGenerateGoogleSheet: $options.onGenerateGoogleSheet
  }, null, 8 /* PROPS */, ["selected", "onCreateInventoryOrder", "onGenerateGoogleSheet"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_app_input, {
    modelValue: $data.search,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
    placeholder: "Search on this page...",
    v2: "",
    version: "search"
  }, null, 8 /* PROPS */, ["modelValue"])])]), _createElementVNode("div", _hoisted_5, [$data.errorOccured ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_error_occured, {
    onRetry: $options.refreshPage
  }, null, 8 /* PROPS */, ["onRetry"])])) : _createCommentVNode("v-if", true), $options.dfDataInitialized && !$data.errorOccured ? (_openBlock(), _createBlock(_component_demand_forecasting_content, {
    key: 1,
    items: $options.dfFiltered,
    selected: $data.selected,
    sortField: $data.sortField,
    sortDirection: $data.sortDirection,
    settings: $data.currentSettings,
    isSideBarCollapsed: $data.isSideBarCollapsed,
    onSelect: $options.onSelect,
    onSort: $options.onSort,
    onUpdate: $options.onUpdate,
    onSaveProductData: $options.onSaveProductData
  }, null, 8 /* PROPS */, ["items", "selected", "sortField", "sortDirection", "settings", "isSideBarCollapsed", "onSelect", "onSort", "onUpdate", "onSaveProductData"])) : _createCommentVNode("v-if", true)]), _createCommentVNode(" <div class=\"demand-forecastig-content-bottom\">\n         <app-pagination\n          v-model:page=\"page\"\n          v-model:perPage=\"perPage\"\n          :total=\"total_rows\"\n          @update:page=\"changePage($event)\"\n          @update:perPage=\"changeElements($event)\"\n        /> \n      </div> ")]), $options.selectedSellerAccountId ? (_openBlock(), _createBlock(_component_demand_forecasting_sidebar, {
    key: 0,
    account_id: $options.selectedSellerAccountId,
    onIsSidebarCollapsed: $options.onIsSidebarCollapsed
  }, null, 8 /* PROPS */, ["account_id", "onIsSidebarCollapsed"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}
import AppIcon from '@/components/AppIcon';
export default {
  name: 'EditPageContentPreview',
  emits: ['add'],
  components: {
    AppIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    content_type: {
      type: String,
      default: 'amazon'
    },
    show_header: {
      type: Boolean,
      default: false
    },
    header_title: {
      type: String,
      default: "Amazon Version"
    },
    show_option: {
      type: Boolean,
      default: false
    },
    option_number: {
      type: String,
      default: ""
    },
    show_add: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    show_comparison: {
      type: Boolean,
      default: false
    },
    text_with_diffs: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      copied: false
    };
  },
  methods: {
    onCopy() {
      if (!this.copied) {
        navigator.clipboard.writeText(this.removeEndQuote(this.text)).then(this.setCopied);
      }
    },
    removeEndQuote(text) {
      if (text.endsWith('"')) {
        return text.slice(0, -1);
      }
      return text;
    },
    setCopied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000);
    },
    onAdd() {
      this.$emit('add', this.removeEndQuote(this.text));
    }
  }
};
import HelperMixin from '@/mixins/HelperMixin';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'PpcBudgetAllocationWeeklyBudgetInput',
  emits: ['update:modelValue', 'input-update'],
  mixins: [HelperMixin],
  components: {
    AppIcon
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    ai: {
      type: Number,
      default: 0
    },
    sufix: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        let newValue = value.replace(/[^0-9.]/g, '');
        const parts = newValue.split('.');
        if (parts.length > 2) {
          newValue = parts[0] + '.' + parts.slice(1).join('');
        }
        this.$emit('update:modelValue', newValue);
      }
    },
    isCustom() {
      return this.modelValue != this.ai;
    }
  },
  watch: {
    focus: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs.editInput.focus();
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    onReset() {
      if (!this.disabled) {
        this.localModelValue = this.ai.toString();
      }
    },
    onInput(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('');
      }
      this.localModelValue = value;
      this.$emit('input-update', value);
    },
    isNumber(event) {
      const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End', 'Escape'];
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (event.key === '.' && !event.target.value.includes('.')) {
        return;
      }
      if (event.key >= '0' && event.key <= '9') {
        return;
      }
      event.preventDefault();
    }
  }
};
import FlexRow from "@/components/FlexRow.vue";
import TableSkeletonV2 from '@/components/TableSkeletonV2.vue';
import MinimizeIcon from "@/components/icons/MinimizeIcon.vue";
import MaximizeIcon from "@/components/icons/MaximizeIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import ErrorWrapper from "@/components/ErrorWrapper.vue";
export default {
  name: "CompoundTable",
  emits: ['topbar-click', 'retry'],
  components: {
    FlexRow,
    TableSkeletonV2,
    MinimizeIcon,
    MaximizeIcon,
    MinusIcon,
    WrapWithPopover,
    ErrorWrapper
  },
  props: {
    showFullSize: {
      type: Boolean,
      default: false
    },
    fullSize: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    },
    topBgColor: {
      type: String,
      default: "var(--bs-background)"
    },
    innerClass: {
      type: String,
      default: ""
    },
    topLeftGap: {
      type: Number,
      default: 8
    },
    fields: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    hasToolbar: {
      type: Boolean,
      default: false
    },
    errorOccurred: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    toolbarState() {
      return {
        INTERMEDIATE: !this.fullSize && this.expanded,
        FULL_SCREEN: this.fullSize,
        CLOSED: !this.expanded
      };
    }
  }
};
import AppCardCustom from "@/components/AppCardCustom";
import PpcBudgetAllocationHeader from "@/components/PpcBudgetAllocationHeader";
import PpcBudgetAllocationItem from "@/components/PpcBudgetAllocationItem";
export default {
  name: "PpcBudgetAllocation",
  emits: ["item-update"],
  components: {
    AppCardCustom,
    PpcBudgetAllocationHeader,
    PpcBudgetAllocationItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String
    },
    selected_profile_id: {
      type: String
    }
  },
  data() {
    return {
      mode: "acos",
      search: "",
      weeklyBudgetTarget: null
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.campaign_group_name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    onItemUpdate(item) {
      this.$emit("item-update", item);
    },
    onWeeklyBudgetEdit(target) {
      this.weeklyBudgetTarget = target;
    }
  }
};
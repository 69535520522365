import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5f09767c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cog-data-top-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_flex_row, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_seller_filter, {
      sellers: _ctx.sellers_filter,
      onlyMode: true,
      onApply: $options.applySellers,
      onCleared: $options.clearSellers
    }, null, 8 /* PROPS */, ["sellers", "onApply", "onCleared"]), _createVNode(_component_app_button, {
      variant: "text",
      version: "small",
      v2: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_app_icon, {
        icon: "info-circle"
      })]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  })]);
}
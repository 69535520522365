import HelperMixin from '@/mixins/HelperMixin';
import AppButton from '@/components/AppButton';
import AppIcon from '@/components/AppIcon';
import PpcBudgetAllocationWeeklyBudgetInput from '@/components/PpcBudgetAllocationWeeklyBudgetInput';
import PpcBudgetAllocationWeeklyBudgetCampaign from '@/components/PpcBudgetAllocationWeeklyBudgetCampaign';
export default {
  name: 'PpcBudgetAllocationWeeklyBudgetEdit',
  emits: ['cancel', 'apply'],
  mixins: [HelperMixin],
  components: {
    AppButton,
    AppIcon,
    PpcBudgetAllocationWeeklyBudgetInput,
    PpcBudgetAllocationWeeklyBudgetCampaign
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      advanced: false,
      localModel: this.data.budgetCurrent,
      localCampaigns: this.data.campaigns
    };
  },
  computed: {
    isMainInputDisabled() {
      return this.localCampaigns.some(item => item.budgetCurrent != item.budgetCalculated) && this.localModel != this.data.budgetAi;
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.localModel = this.data.budgetCurrent;
        this.localCampaigns = this.data.campaigns;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onApply() {
      this.$emit('apply', {
        type: this.type,
        current: this.localModel,
        campaigns: this.localCampaigns
      });
    },
    onAdvancedToggle() {
      this.advanced = !this.advanced;
    },
    onUpdateCampaign(campaign) {
      this.localCampaigns = this.localCampaigns.map(item => {
        if (item.id === campaign.id) {
          return campaign;
        }
        return item;
      });
      this.localModel = this.numberFormatter(this.localCampaigns.reduce((acc, item) => acc + item.budgetCurrent * 1, 0), 2);
    },
    onInputUpdate(value) {
      this.localCampaigns = this.localCampaigns.map(item => {
        if (this.localModel == this.data.budgetAi) {
          item.budgetCurrent = item.budgetAi;
          item.budgetCalculated = item.budgetAi;
        } else {
          const percent = item.budgetAi / this.data.budgetAi;
          item.budgetCurrent = this.numberFormatter(value * percent, 2);
          item.budgetCalculated = this.numberFormatter(value * percent, 2);
        }
        return item;
      });
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8e62b52e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-data-top-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_product_filter = _resolveComponent("product-filter");
  const _component_app_dropdown = _resolveComponent("app-dropdown");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_flex_row, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_seller_filter, {
      sellers: _ctx.sellers_filter,
      onlyMode: true,
      onApply: $options.applySellers,
      onCleared: $options.clearSellers
    }, null, 8 /* PROPS */, ["sellers", "onApply", "onCleared"]), _createVNode(_component_product_filter, {
      products: _ctx.products_filter,
      sellers: _ctx.sellers_filter,
      select_key: "content_id",
      onApply: $options.applyProducts,
      onCleared: $options.clearProducts,
      onSearch: $options.searchProducts
    }, null, 8 /* PROPS */, ["products", "sellers", "onApply", "onCleared", "onSearch"]), _createVNode(_component_app_dropdown, {
      modelValue: _ctx.item,
      "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => _ctx.item = $event), $options.handleScaleTypeChange],
      options: $data.options,
      icon: "unit-scale"
    }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdate:modelValue"]), _createCommentVNode(" a<app-dropdown v-model=\"item\" :options=\"options\" icon=\"cart\" />"), _createCommentVNode("<app-button version=\"small\" v2><app-icon icon=\"rocket\" :size=\"13\" /> Order {{ selected.length ? 'selected items' : 'all' }}</app-button>\n      <app-button variant=\"text\" version=\"small\" v2><app-icon icon=\"info-circle\" /></app-button> ")]),
    _: 1 /* STABLE */
  })]);
}
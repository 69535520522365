import { required, email, alphaNum, minLength, maxLength, maxValue, minValue } from "@vuelidate/validators";
import { isEmptyString } from "@/utils";
export default {
  name: "ValidateMixin",
  validations() {
    const floatWithTwoDecimals = value => value == '' || /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
    const rules = {
      none: {},
      name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        alphaNum,
        minLength: minLength(8)
      },
      region: {
        required,
        minLength: minLength(2)
      },
      array: {
        required,
        minLength: minLength(1)
      },
      country: {
        required,
        minLength: minLength(2)
      },
      bid: {
        required,
        maxValue: maxValue(999),
        minValue: minValue(1)
      },
      USD_strategies_rule: {
        required,
        maxValue: maxValue(1000000),
        minValue: minValue(0)
      },
      EUR_strategies_rule: {
        required,
        maxValue: maxValue(1000000),
        minValue: minValue(0)
      },
      GBP_strategies_rules: {
        required,
        maxValue: maxValue(1000000),
        minValue: minValue(0)
      },
      JPY_strategies_rule: {
        required,
        maxValue: maxValue(150000000),
        minValue: minValue(0)
      },
      CAD_strategies_rule: {
        required,
        maxValue: maxValue(1500000),
        minValue: minValue(0)
      },
      AUD_strategies_rule: {
        required,
        maxValue: maxValue(2000000),
        minValue: minValue(0)
      },
      strategies_percentage: {
        required,
        maxValue: maxValue(100),
        minValue: minValue(0)
      },
      cogs: {
        floatWithTwoDecimals,
        minValue: minValue(0)
      }
    };
    const validateConfig = {};
    if (this.validate) {
      for (const rule of this.validate) {
        if (rule.rule && rules[rule.rule]) {
          validateConfig[rule.variable] = rules[rule.rule];
        }
      }
    }
    return validateConfig;
  },
  methods: {
    mValidateMessage(field) {
      if (this.v$[field] && this.v$[field].$error) {
        for (const error of this.v$.$errors) {
          if (error.$property == field) {
            return error.$message;
          }
        }
      } else {
        return null;
      }
    },
    async mValidate() {
      console.log("function called");
      const result = await this.v$.$validate();
      return result;
    },
    mValidateTitle(val) {
      if (!val || isEmptyString(val)) {
        return {
          status: "error",
          text: "Title is missing",
          score: 0,
          coefficient: 3
        };
      }
      if (val.length > 130 && val.length <= 200) {
        return {
          status: "success",
          text: "Title length is ideal",
          score: 10,
          coefficient: 3
        };
      } else if (val.length > 80 && val.length <= 130) {
        return {
          status: "warning",
          text: "Title length is too short(<130)",
          score: 5,
          coefficient: 3
        };
      } else {
        return {
          score: 0,
          coefficient: 3,
          status: "error",
          text: `Title length is too ${val.length <= 80 ? "short(<130)" : "long(>200)"}`
        };
      }
    },
    mValidateDescription(val) {
      if (!val || isEmptyString(val)) {
        return {
          status: "error",
          text: "Description is missing",
          score: 0,
          coefficient: 1
        };
      }
      if (val.length <= 100) {
        return {
          status: "error",
          text: "Description is too short (<500)",
          score: 0,
          coefficient: 1
        };
      } else if (val.length > 2000) {
        return {
          status: "error",
          text: "Description is too long (>2000)",
          score: 0,
          coefficient: 1
        };
      } else if (val.length <= 500 && val.length > 100) {
        return {
          status: "warning",
          text: "Description is too short (<500)",
          score: 5,
          coefficient: 1
        };
      } else {
        return {
          status: "success",
          text: "Description length is excellent",
          score: 10,
          coefficient: 1
        };
      }
    },
    mValidateKeyword(val) {
      if (!val || isEmptyString(val)) {
        return {
          status: "error",
          text: "Add search terms to reach at least 200 characters",
          score: 0,
          coefficient: 2
        };
      }

      //const keywords = typeof val === "string" ? val.split(" ") : val;

      if (val.length > 200 && val.length <= 400) {
        return {
          status: "warning",
          text: "Add search terms to reach at least 400 characters",
          score: 5,
          coefficient: 2
        };
      } else if (val.length <= 200) {
        return {
          status: "error",
          text: "Add search terms to reach at least 400 characters",
          score: 0,
          coefficient: 2
        };
      } else if (val.length > 500) {
        return {
          status: "error",
          text: "Search terms schould not exceed 500 characters",
          score: 0,
          coefficient: 2
        };
      } else {
        return {
          status: "success",
          text: "Great use of search terms",
          score: 10,
          coefficient: 2
        };
      }
    },
    mValidateBulletPointsEmpty(val) {
      if (!val || isEmptyString(val)) {
        return {
          status: "error",
          text: "Bullet points are missing",
          score: 0,
          coefficient: 2
        };
      }
      const empty_bullet_points = val.filter(el => el.length != 0);
      if (empty_bullet_points.length > 0 && empty_bullet_points.length <= 2) {
        return {
          status: "error",
          text: "Consider adding more bullet points",
          score: 0,
          coefficient: 2
        };
      } else if (empty_bullet_points.length > 2 && empty_bullet_points.length <= 4) {
        return {
          status: "warning",
          text: "Consider adding more bullet points",
          score: 5,
          coefficient: 2
        };
      } else {
        return {
          status: "success",
          text: "More than 4 bullet points",
          score: 10,
          coefficient: 2
        };
      }
    },
    // mValidateBulletPointsLength(val) {
    //   const bullet_points_lengths = val.map(el => el.length > 0 ? el.length : false)
    //   if (bullet_points_lengths.every(length => length >= 100 && length <= 200)) {
    //     return { status: 'success', text: 'Bullet point length is satisfactory' }
    //   } else {
    //     if (bullet_points_lengths.every(length => length > 250)) {
    //       return { status: 'error', text: 'Bullet points are excessively long' }
    //     } else {
    //       if (bullet_points_lengths.some(length => length > 200)) {
    //         return { status: 'warning', text: 'Some bullet points are too long' }
    //       }

    //       if (bullet_points_lengths.some(length => length < 100)) {
    //         return { status: 'warning', text: 'Some bullet points are too short' }
    //       }
    //     }
    //   }
    // },

    mValidateBulletPointsLength(val, index) {
      if (!val || isEmptyString(val)) {
        return {
          score: 0,
          coefficient: 1,
          status: "error",
          text: "Bullet point %s missing".replace("%s", index)
        };
      }
      if (val.length >= 100 && val.length <= 250) {
        return {
          score: 10,
          coefficient: 1,
          status: "success",
          text: "Bullet point %s length is satisfactory (between 100-250)".replace("%s", index)
        };
      } else if (val.length >= 50 && val.length < 100 || val.length > 250 && val.length <= 300) {
        return {
          score: 5,
          coefficient: 1,
          status: "warning",
          text: `Bullet point ${index} length is too ${val.length < 100 ? "short(<100)" : "long(>250 and <=300)"}`
        };
      } else {
        return {
          score: 0,
          coefficient: 1,
          status: "error",
          text: `Bullet point ${index} length is way too ${val.length < 50 ? "short(<50)" : "long(>300)"}`
        };
      }
    },
    mValidateBulletPoints(val) {
      if (!val || isEmptyString(val)) {
        return {
          status: "error",
          text: "Bullet point is missing",
          score: 0,
          coefficient: 1
        };
      }
      if (val.length >= 100 && val.length <= 250) {
        return {
          status: "success",
          text: "Bullet point length is satisfactory (between 100-250)",
          score: 10,
          coefficient: 1
        };
      } else if (val.length >= 50 && val.length < 100 || val.length > 250 && val.length <= 300) {
        return {
          score: 5,
          coefficient: 1,
          status: "warning",
          text: `Bullet point length is too ${val.length < 100 ? "short(<100)" : "long(>250 and <=300)"}`
        };
      } else {
        return {
          score: 0,
          coefficient: 1,
          status: "error",
          text: `Bullet point length is way too ${val.length < 50 ? "short(<50)" : "long(>300)"}`
        };
      }
    },
    mValidateImage(val) {
      const imageSize = src => {
        return new Promise(resolve => {
          const image = new Image();
          image.onload = function () {
            resolve({
              width: this.width,
              height: this.height
            });
          };
          image.src = src;
        });
      };
      const size = imageSize(val);
      if (size.width < 500 || size.height < 500) {
        return {
          status: "error",
          text: "Main image quality must be improved",
          score: 0,
          coefficient: 3
        };
      } else if (size.width < 2000 || size.height < 2000) {
        return {
          status: "warning",
          text: "Main Image quality could be improved",
          score: 5,
          coefficient: 3
        };
      } else {
        return {
          status: "success",
          text: "Main Image quality is Ok",
          score: 10,
          coefficient: 3
        };
      }
    },
    mValidateNumberOfImage(val) {
      val = parseInt(val, 10);
      if (isNaN(val) || val === null || val < 0) {
        return {
          status: "error",
          text: "No image found",
          score: 0,
          coefficient: 3
        };
      } else if (val >= 1 && val <= 3) {
        return {
          status: "error",
          text: "Consider adding more images",
          score: 0,
          coefficient: 3
        };
      } else if (val >= 4 && val <= 6) {
        return {
          status: "warning",
          text: "Consider adding more images",
          score: 5,
          coefficient: 3
        };
      } else {
        return {
          status: "success",
          text: "Good number of images",
          score: 10,
          coefficient: 3
        };
      }
    },
    mValidateRating(val) {
      val = parseFloat(val);
      if (isNaN(val) || val === null || val < 1.0) {
        return {
          status: "error",
          text: "No ratings found",
          score: 0,
          coefficient: 4
        };
      } else if (val < 3.7) {
        return {
          status: "error",
          text: "Rating must be improved",
          score: 0,
          coefficient: 4
        };
      } else if (val >= 3.7 && val <= 4.3) {
        return {
          status: "warning",
          text: "Rating could be improved",
          score: 5,
          coefficient: 4
        };
      } else {
        return {
          status: "success",
          text: "Rating is excellent",
          score: 10,
          coefficient: 4
        };
      }
    },
    mValidateReview(val) {
      val = parseInt(val, 10);
      if (isNaN(val) || val === null || val < 1) {
        return {
          status: "error",
          text: "No review found",
          score: 0,
          coefficient: 4
        };
      } else if (val >= 1 && val <= 4) {
        return {
          status: "error",
          text: "Need more reviews",
          score: 0,
          coefficient: 4
        };
      } else if (val >= 5 && val < 30) {
        return {
          status: "warning",
          text: "Need more reviews",
          score: 5,
          coefficient: 4
        };
      } else {
        return {
          status: "success",
          text: "Minimum number of reviews",
          score: 10,
          coefficient: 4
        };
      }
    }
  }
};
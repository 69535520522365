import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_popover = _resolveComponent("b-popover");
  return _openBlock(), _createBlock(_component_b_popover, {
    target: $props.target,
    triggers: $props.trigger,
    placement: $props.placement,
    show: $props.show,
    "custom-class": $options.customClass,
    container: $props.container,
    delay: {
      show: 0,
      hide: 0
    },
    onShown: $options.onShown
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["target", "triggers", "placement", "show", "custom-class", "container", "onShown"]);
}
import AppIcon from "@/components/AppIcon";
import EditPageTranslate from "@/components/EditPageTranslate";
import AiGeneration from "@/components/AiGeneration";
import AiAssistance from "@/components/AiAssistance";
import EditPagePreview from "@/components/EditPagePreview";
import EditPageScore from "@/components/EditPageScore";
import EditPageKeywords from "@/components/EditPageKeywords";
export default {
  name: "EditPageSidebar",
  emits: ["translate", "aigenerate", "toggle_menu", "asyncUpdate", "update:is_translation_button_active", "close", "no-keywords"],
  components: {
    AppIcon,
    EditPageTranslate,
    EditPagePreview,
    EditPageScore,
    AiGeneration,
    EditPageKeywords,
    AiAssistance
  },
  expose: ['onToggleMenu'],
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    all_versions_list: {
      type: Object,
      default: () => {}
    },
    validate: {
      type: Array,
      default: () => []
    },
    loading_translation_spinner: {
      type: Boolean,
      default: false
    },
    is_translation_button_active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: null,
      type: "normal"
    };
  },
  computed: {
    editPageSideBarClass() {
      return {
        translate: this.menu == "translate",
        keywords: this.menu == "keywords",
        preview: this.menu == "preview",
        ai_assistance: this.menu == "ai_assistance",
        ai_generation: this.menu == "ai_generation" && this.type == "normal",
        market_definition: this.menu == "ai_generation" && this.type === "market_definition",
        product_data: this.menu == "ai_generation" && this.type === "product_data",
        product_benefits: this.menu == "ai_generation" && this.type === "product_benefits",
        product_usecases: this.menu == "ai_generation" && this.type === "product_usecases"
      };
    }
  },
  methods: {
    onToggleMenu(menu) {
      this.menu = this.menu == menu ? null : menu;
      this.$emit('toggle_menu', menu);
    },
    onClose() {
      this.$emit('close', this.menu);
    },
    onTypeChange(type) {
      this.type = type;
    },
    onTranslate(val) {
      this.$emit("translate", val);
    },
    onAigenerate(val) {
      this.$emit("aigenerate", val);
    },
    getAsyncUpdate(val) {
      this.$emit("asyncUpdate", val);
    },
    getFeatureName() {
      return "keywordsmodulefeature";
    },
    onAiAssistanceOpenNext(selected) {
      this.onToggleMenu(selected);
    },
    updateTranslationButtonActive(val) {
      this.$emit("update:is_translation_button_active", val);
    },
    onNoKeywords() {
      this.$emit('no-keywords');
    }
  }
};
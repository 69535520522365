import { TextValue } from "../tableElements";
import FilterItem from "./FilterItem.vue";
import { truncateString } from "@/utils";
import TargetingFilterBody from "./bodies/TargetingFilterBody.vue";
import FiltersMixin from "@/mixins/FiltersMixin.vue";
export default {
  name: "TargetingFilter",
  components: {
    FilterItem,
    TargetingFilterBody,
    TextValue
  },
  emits: ["apply", "cleared"],
  expose: ["openDropdown"],
  mixins: [FiltersMixin],
  props: {
    targetings: {
      type: Object,
      // Filter Type (@/store/filter.js)
      required: true
    },
    sellers: {
      type: Object,
      // Filter Type (@/store/filter.js)
      required: true
    },
    marketplaces: {
      type: Object,
      // Filter Type (@/store/filter.js)
      required: true
    }
  },
  computed: {
    getTargetingTitle() {
      return this.getTargeting ?? "Targeting";
    },
    getTargeting() {
      return this.targetings.selected?.[0];
    }
  },
  methods: {
    truncateString,
    handleSearch({
      value
    }) {
      if (value.length === 0) {
        this.targetings.searching = false;
      }
      this.targetings.asyncSearch({
        keyword: value,
        args: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        }
      });
    },
    handleSelect({
      keyword_id_list
    }) {
      this.targetings.select({
        keyword_id_list
      }, 'keyword_id_list');
    },
    handleSelectOnly({
      keyword_id_list
    }) {
      this.targetings.selectOnly({
        keyword_id_list
      }, 'keyword_id_list');
    },
    handleDeselect({
      keyword_id_list
    }) {
      this.targetings.deselect({
        keyword_id_list
      }, 'keyword_id_list');
    },
    openDropdown(initializer = async () => {}, callback = () => {}) {
      this.openAsyncFilterDropdown(this.$refs.targetingFilterItem, initializer, callback);
    }
  }
};
import { getFormattedPercentage } from '@/utils';
import ValueElement from './ValueElement.vue';
import FlexRow from '../FlexRow.vue';
import TableElementsMixin from '@/mixins/TableElementsMixin.vue';
export default {
  name: 'PercentageValue',
  mixins: [TableElementsMixin],
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: null
    },
    precision: {
      type: Number,
      required: false,
      default: 0
    },
    limit: {
      type: Number,
      required: false,
      default: Number.NEGATIVE_INFINITY
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({})
    },
    charCount: {
      type: Number
    },
    showSymbol: {
      type: Boolean,
      default: true
    },
    showSign: {
      type: Boolean,
      default: false
    },
    pp: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    type: {
      type: String
    }
  },
  components: {
    ValueElement,
    FlexRow
  },
  data() {
    return {
      minMaxMap: {
        'ba_table': {
          min: {
            value: 0,
            percentage: 0
          },
          max: {
            value: 1,
            percentage: 100
          }
        }
      }
    };
  },
  computed: {
    formattedValue() {
      if (!this.isCorrectValue) {
        return null;
      }
      const min_max = this.minMaxMap[this.type] ?? {
        min: {
          value: -10,
          percentage: -999
        },
        max: {
          value: 10,
          percentage: 999
        }
      };
      if (this.value >= min_max.max.value) {
        /*
            Two options:
            -
        */
        return `&gt;${min_max.max.percentage}`;
      }
      if (this.value <= min_max.min.value) {
        return `&lt;${min_max.min.percentage}`;
      }
      return getFormattedPercentage(this.value, this.precision, this.limit, false, this.showSign).replace(/%/g, '').trim();
    },
    getComputedStyle() {
      let charCount = this.isCorrectValue ? this.charCount : this.charCount + 1;
      let width = this.charCount ? `${charCount}ch` : 'auto';
      return {
        textAlign: 'right',
        width,
        color: this.isCorrectValue ? this.color : '#54595E'
      };
    },
    symbol() {
      if (this.pp) {
        return '\xA0pp';
      }
      return '%';
    }
  }
};
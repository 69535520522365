import AppIcon from './AppIcon.vue';
import FlexRow from './FlexRow.vue';
import WrapWithPopover from './shared/WrapWithPopover.vue';
export default {
  name: 'WrapWithInfo',
  props: {
    info: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    }
  },
  components: {
    FlexRow,
    AppIcon,
    WrapWithPopover
  }
};
export default {
  name: 'PpcGraph',
  props: {
    width: {
      type: Number,
      default: 123
    },
    height: {
      type: Number,
      default: 44
    },
    color: {
      type: String,
      default: '#2F90DE'
    },
    highlight: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    max: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    showForecast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canvas: null,
      ctx: null
    };
  },
  mounted() {
    if (this.data && this.max > 0 && (this.data.current || this.data.current === 0)) {
      this.canvas = this.$refs.graph;
      this.ctx = this.canvas.getContext('2d');
      setTimeout(() => {
        this.draw();
      }, 300);
    }
  },
  methods: {
    draw() {
      const dotX = Math.round(this.width / 2);
      const percentY = this.calculatePercentage(this.data.current);
      const dotY = this.height * (100 - percentY) / 100;
      this.dot(dotX, dotY);
      let isForecast = false;
      if (this.data.prev != null) {
        const prevDotX = dotX - this.width;
        const prevPercentY = this.calculatePercentage(this.data.prev);
        const prevDotY = this.height * (100 - prevPercentY) / 100;
        this.line(prevDotX, prevDotY, dotX, dotY);
        this.gradient(prevDotX, prevDotY, dotX, dotY, 'left');
        if (this.showForecast && this.data.current_forecast != null) {
          const forecastPercentY = this.calculatePercentage(this.data.current_forecast);
          const forecastDotY = this.height * (100 - forecastPercentY) / 100;
          this.line(prevDotX, prevDotY, dotX, forecastDotY, true);
          this.dot(dotX, forecastDotY, true);
          isForecast = true;
          this.text(this.data.current.toLocaleString('en-US'), dotX + 24, dotY + 4);
        }
      }
      if (!isForecast) {
        this.text(this.data.current.toLocaleString('en-US'), dotX, percentY < 50 ? dotY - 6 : dotY + 17);
      }
      if (this.data.next != null) {
        const nextDotX = dotX + this.width;
        const nextPercentY = this.calculatePercentage(this.data.next);
        const nextDotY = this.height * (100 - nextPercentY) / 100;
        this.line(dotX, dotY, nextDotX, nextDotY);
        this.gradient(dotX, dotY, nextDotX, nextDotY, 'right');
        if (this.showForecast && this.data.next_forecast != null) {
          const nextForecastPercentY = this.calculatePercentage(this.data.next_forecast);
          const nextForecastDotY = this.height * (100 - nextForecastPercentY) / 100;
          this.line(dotX, dotY, nextDotX, nextForecastDotY, true);
        }
      }
    },
    dot(x, y, isBorder) {
      this.ctx.beginPath();
      this.ctx.arc(x, y, 3, 0, 2 * Math.PI);
      this.ctx.fillStyle = this.color;
      this.ctx.fill();
      if (isBorder) {
        this.ctx.beginPath();
        this.ctx.arc(x, y, 1.5, 0, 2 * Math.PI);
        this.ctx.fillStyle = '#fff';
        this.ctx.fill();
      }
    },
    line(startX, startY, endX, endY, isDashed = false) {
      if (isDashed) {
        this.ctx.setLineDash([4, 2]);
      }
      this.ctx.beginPath();
      this.ctx.moveTo(startX, startY);
      this.ctx.lineTo(endX, endY);
      this.ctx.strokeStyle = this.color;
      this.ctx.lineWidth = 1;
      this.ctx.stroke();
      this.ctx.setLineDash([]);
    },
    gradient(startX, startY, endX, endY, side) {
      const gradient = this.ctx.createLinearGradient(0, 0, 0, this.height);
      gradient.addColorStop(0, this.hexToRgba(this.color, 0.12));
      gradient.addColorStop(0.5, this.hexToRgba(this.color, 0.12));
      gradient.addColorStop(1, this.hexToRgba(this.color, 0.01));
      this.ctx.beginPath();
      this.ctx.moveTo(startX, startY);
      this.ctx.lineTo(endX, endY);
      this.ctx.lineTo(side == 'left' ? this.width / 2 : this.width, this.height);
      this.ctx.lineTo(side == 'left' ? 0 : this.width / 2, this.height);
      this.ctx.closePath();
      this.ctx.fillStyle = gradient;
      this.ctx.fill();
    },
    text(text, x, y) {
      this.ctx.font = '500 13px Figtree';
      this.ctx.fillStyle = this.color;
      this.ctx.textAlign = 'center';
      this.ctx.fillText(text, x, y);
    },
    hexToRgba(hex, opacity = 1) {
      const bigint = parseInt(hex.replace(/^#/, ''), 16);
      const r = bigint >> 16 & 255;
      const g = bigint >> 8 & 255;
      const b = bigint & 255;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    calculatePercentage(value) {
      const result = (value - this.min) * 100 / (this.max - this.min);
      return result;
    }
  }
};
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-beb73f42"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "image-with-overlay"
};
const _hoisted_2 = {
  key: 1,
  class: "fallback-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), $props.flag ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "country-flag",
    style: _normalizeStyle({
      background: `url(${$props.flag}) lightgray -1.143px -3.429px / 114.286% 160% no-repeat`
    })
  }, null, 4 /* STYLE */)) : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.country), 1 /* TEXT */))]);
}
import { getAggregateTableData, AggregateTable, aggregateTableHeaders } from "@/components/profit-and-loss";
import ProfitAndLossMixin from "@/mixins/ProfitAndLossMixin.vue";
import ProfitAndLossFilters from "@/components/ProfitAndLossFilters";
import { EVENTS, flattenTree } from "../utils";
import AggregateTableGroupBy from '@/components/profit-and-loss/AggregateTableGroupBy.vue';
import CurrencyFilter from "@/components/new-filters/CurrencyFilter.vue";
import { capitalize } from "vue";
import FlexRow from '@/components/FlexRow.vue';
import DownloadIcon from "../components/icons/DownloadIcon.vue";
import { mapActions, mapState } from "vuex";
import TasksMixin from "@/mixins/TasksMixin.vue";
import moment from "moment";
import { PandLAggregate, PL_EXPORT_LEVEL, PL_EXPORT_HEADERS } from "../utils/profit_and_loss_transformers";
export default {
  name: 'ProfitAndLossPage',
  mixins: [ProfitAndLossMixin, TasksMixin],
  async mounted() {
    this.$store.dispatch("profitAndLossSetDefaultCurrency");
    window.$bus.on(EVENTS.PROFIT_AND_LOSS_DATE_PICKER_SUBMIT, async state => await this.handleDatePickerDateChange(state));

    // Setup Filters
    const sellers = await this.getOmnyAccountSellers();
    if (sellers.length === 0) {
      this.announceNoAccounts();
      return;
    }
    // We are enabling only {Sellers, Marketplaces, Products} filters
    const {
      seller_ids,
      sales_channel
    } = await this.initializeFilters({
      sellers
    }, "profitAndLoss", {
      seller: "sellers",
      marketplace: "marketplaces"
    }, "profit_and_loss");
    this.fetchSubFilters({
      sellers: seller_ids,
      sales_channel
    }, "profitAndLoss", {
      product: "products"
    }, "profit_and_loss");
    const datepicker = this.$refs.filters.datePicker;
    if (!datepicker) {
      throw new Error("Datepicker not found");
    }
    const datePickerDate = datepicker.state;
    //console.log('Datepicker Date', datePickerDate)
    // Set default period for Aggregate Table
    this.$store.dispatch('profitAndLossSetAggregatePeriod', datePickerDate);
    await this.setupAggregateTable();
    window.$bus.on(EVENTS.REFRESH_PROFIT_AND_LOSS, () => {
      this.refreshAggregateTable().then(() => {});
    });
    this.aggregateTable.loading = false;
    this.datePickerLoading = false;
  },
  unmounted() {
    clearTimeout(this.exportTimeoutHandler);
    window.$bus.off(EVENTS.REFRESH_PROFIT_AND_LOSS, this.refreshAggregateTable);
  },
  watch: {
    exportLoading: {
      handler(newVal, oldVal) {
        // The idea is to attach a timeout to it when it transitions from `false` to `true`

        if (!oldVal && newVal) {
          this.exportTimeoutHandler = setTimeout(() => {
            // The code failed to stop loader so we force turn it off her with the timeout.
            console.log("Resetting exportLoading to false");
            this.exportLoading = false;
          }, 30 * 1000);
        }
      }
    }
  },
  components: {
    AggregateTable,
    ProfitAndLossFilters,
    AggregateTableGroupBy,
    CurrencyFilter,
    FlexRow,
    DownloadIcon
  },
  data() {
    return {
      PL_EXPORT_LEVEL,
      datePickerLoading: true,
      aggregateTable: {
        loading: true,
        aggregate: {
          items: [],
          minMax: {},
          colGroups: aggregateTableHeaders
        }
      },
      sheetGenerationTaskIdentifier: "",
      exportLoading: false,
      exportTimeoutHandler: undefined
    };
  },
  computed: {
    ...mapState({
      currencies: state => state.profitAndLoss.currencies,
      config: state => state.currency_config.config,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.profitAndLoss.sellers,
      marketplaces_filter: state => state.profitAndLoss.marketplaces
    }),
    profitAndLossFilters() {
      let filters = {
        seller_id: this.$store.getters["profitAndLossGetSellers"](),
        sales_channel: this.$store.getters["profitAndLossGetMarketplaces"](),
        sku_list: this.$store.getters["profitAndLossGetSkus"]() ?? [],
        asin_list: this.$store.getters["profitAndLossGetAsins"]() ?? []
      };
      return filters;
    },
    getAggregateTableItems() {
      if (this.aggregateTable.loading) {
        return new Array(12).fill({});
      }
      return this.aggregateTable[this.getAggregateTableGroupBy.key].items;
    },
    getColGroups() {
      const colGroups = this.aggregateTable[this.getAggregateTableGroupBy.key].colGroups;

      /*
      ColGroups looks like this:
          [
              {key, label, fields: {montly: [], quarterly: [], yearly: []}}
          ]
           We will use the current period to determine which fields to use
      */

      const {
        key
      } = this.aggregatePeriod ?? '';
      const colExport = colGroups?.map(group => {
        return {
          ...group,
          fields: group.fields[key]
        };
      });
      return colExport;
    },
    getExportColGroups() {
      const originalColGroups = this.getColGroups;
      if (!Array.isArray(originalColGroups)) {
        return [];
      }
      if (originalColGroups.length !== 2) {
        return [];
      }
      let output = {
        data_and_evolution: originalColGroups[0]?.fields.filter(el => !['aggregate_level'].includes(el.key)),
        percent_net_revenue: originalColGroups[1]?.fields
      };
      return output;
    },
    getAggregateTableGroupBy() {
      return this.$store.getters.profitAndLossGetAggregateGroupBy();
    },
    aggregatePeriod() {
      return this.$store.getters.profitAndLossGetAggregatePeriod();
    }
  },
  methods: {
    ...mapActions(['profitAndLossGenerateGoogleSheet']),
    async setupAggregateTable() {
      const data = await this.fetchAggregateData();
      if (!data) return;
      const flattened = data.map(item => flattenTree(item, 'pc_net_revenue_evol')).flat().filter(Boolean);
      const absFlattened = flattened.filter(v => !!v).map(v => Math.abs(v));
      const minMax = {
        evolution_pp: {
          min: Math.min(...absFlattened),
          max: Math.max(...absFlattened)
        }
      };
      const groupBy = this.getAggregateTableGroupBy.key;
      this.aggregateTable[groupBy].items = data;
      this.aggregateTable[groupBy].minMax = minMax;
    },
    async fetchAggregateData() {
      //console.log("profitAndLossFilters", this.profitAndLossFilters);
      const params = {
        ...this.profitAndLossFilters,
        period: this.aggregatePeriod,
        ...this.$store.getters.currencyConfigQueryGet()
      };
      //console.log("Params", params)
      return await getAggregateTableData({
        params
      });
    },
    async refreshAggregateTable() {
      this.aggregateTable.loading = true;
      await this.setupAggregateTable();
      this.aggregateTable.loading = false;
    },
    async handleDatePickerDateChange(state) {
      this.$store.dispatch('profitAndLossSetAggregatePeriod', state);
      await this.refreshAggregateTable();
    },
    handleApplyForCurrencyChange() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        this.$store.dispatch("currencyConfigSetCurrency", this.currencies.filterValues[0]);
        this.refreshAggregateTable();
      }
    },
    getExportFileName() {
      const period = this.aggregatePeriod.key;
      const workspace = this.current_workspace.workspace_name;
      const exportDate = moment().format("YYYYMMDD");
      const firstPeriod = moment(this.aggregatePeriod.range.start).format("MMMYYYY");
      const secondPeriod = moment(this.aggregatePeriod.compare.start).format("MMMYYYY");
      return `P&L_${workspace}_${capitalize(period)}_${firstPeriod}-${secondPeriod}_${exportDate}`;
    },
    async handleExport(level) {
      if (this.aggregateTable.loading) {
        window.$bus.trigger('showWarning', {
          message: 'Data not yet available for export',
          visible: true,
          delay: 1
        });
        return;
      }
      ;
      try {
        this.exportLoading = true;
        const {
          row0,
          row1,
          row2
        } = Object.create(PL_EXPORT_HEADERS[level]);
        let header = [row0, row1, row2];
        console.log('header', header);
        /*
                 Exporting the header has to be done in a wholistic manner.
            For now, we ignore that there will be `""` in some of the patterns
                 What we need is 
            [
                ["",        "",        "".        "",                                                  "",                 "",                              "",          "", "% of net revenue", "",                 ""]
                ["",        "",        "",        "Current `[insert period {Month, Quarter, Year}]`",  "Previous ``",      "Evolution",                     "Evolution", "", "Current ``",       "Previous ``",      "Evolution"],
                ["Level 1", "Level 2", "Level 3", "`period as date`",                                  "`period as date`", "in `[insert currency symbol]`", "in %",      "", "`period as date`", "`period as date`", "in pp"]
            ]
             */

        const exportCols = this.getExportColGroups;
        if ("data_and_evolution" in exportCols && "percent_net_revenue" in exportCols) {
          const firstHalf = exportCols.data_and_evolution;
          const secondHalf = exportCols.percent_net_revenue;
          header[1].push(...firstHalf.map(({
            label
          }) => label), "", ...secondHalf.map(({
            label
          }) => label));
          const firstPeriod = moment(this.aggregatePeriod.range.start).format("YYYY-MM");
          const secondPeriod = moment(this.aggregatePeriod.compare.start).format("YYYY-MM");
          // second row
          header[2].push(...[firstPeriod, secondPeriod, `in ${this.config.symbol}`, "in %", "", firstPeriod, secondPeriod, "in pp"]);
          const payload = [...header, ...this.convertAggregateToRow(level)];
          console.log('payload', payload);
          const task = await this.profitAndLossGenerateGoogleSheet({
            sheet_name: this.getExportFileName(),
            task_type: "google_sheet_generation",
            data: payload
          });
          console.log('task', task);
          if (task) {
            this.sheetGenerationTaskIdentifier = task.task_id;
            this.executeTaskStatusHandler(task);
            this.checkTaskStatusPeriodically(this.sheetGenerationTaskIdentifier);
          }
        }
      } catch (err) {
        this.exportLoading = false;
      }
    },
    convertAggregateToRow(level) {
      /*
               Aggregate looks like this:
                  [
                      {
                          "pl_aggregate": string
                          "details": [
                                  {
                                      "pl_detail": string
                                      "events": [
                                          {
                                              "event_type": string
                                          }
                                      ]
                                  }
                              ]
                      },
                      {
                      }
                  ]
              Each aggregate will have a minimum of these items:
              
      */
      const aggregate = this.aggregateTable[this.getAggregateTableGroupBy.key].items;
      return aggregate.map(el => new PandLAggregate(el, this.config.currency, level)).flatMap(e => e.toRow());
    },
    handleRunning() {
      this.checkTaskStatusPeriodically(this.sheetGenerationTaskIdentifier);
    },
    handleFailed(data) {
      this.exportLoading = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showDanger", {
        visible: true,
        message: "Google sheet generation Task failed \n" + data.task_result,
        delay: 60 // Show alert less than 60 secs
      });
      throw new Error("Google sheet generation Task failed");
    },
    async handleFinished(data) {
      const {
        task_result: sheet_link
      } = data;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: "The google sheet has been successfully generated. A link has been sent to your email.",
        delay: 60 // Show alert less than 60 secs
      });
      this.exportLoading = false;
      if (sheet_link) window.open(sheet_link, "_blank");
    }
  }
};
import { addGhostIds } from "@/utils";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import FetchFiltersMixin from "@/mixins/FetchFiltersMixin.vue";
import { isObjectEmpty, tryParse } from "@/utils";
import Filter from "@/store/filter";
import { Currencies } from "../utils/currencies";
export default {
  name: "InitializeFiltersMixin",
  mixins: [AccountsMixin, FetchFiltersMixin],
  methods: {
    async initializeSellersFilters({
      sellers
    }, parent, seller) {
      this.$store.state[parent][seller].initialize({
        list: addGhostIds(sellers),
        selected: [],
        name: "sellers_filter",
        key: "id",
        autoSelect: true
      });
      // Return `id`s array
      return sellers.map(seller => seller.id);
    },
    async initializeFilters({
      sellers
    },
    // Location object
    parent, {
      seller,
      marketplace
    }, rootPath) {
      const initializeStart = performance.now();
      if (!seller) return;
      const seller_ids = await this.initializeSellersFilters({
        sellers
      }, parent, seller, rootPath);
      if (!marketplace) return;
      const sales_channel = await this.initializeMarketplacesFilters({
        sellers: seller_ids
      }, parent, marketplace, rootPath);

      // This task can be done in parallel since the only important filters are the first two
      // Sellers and Marketplaces

      const initializeEnd = performance.now();
      console.log(`[Perf][InitializeFilter.initializeFilters]: ${(initializeEnd - initializeStart) / 1000} s`);
      return {
        sales_channel,
        seller_ids
      };
    },
    async initializeMarketplacesFilters({
      sellers
    }, parent, marketplace, rootPath) {
      //console.log("initializeMarketplaces", new Date().toISOString());
      const path = `/${rootPath}/filters/countries`;
      const marketplaces = await this.getCountriesBasedOnSellers({
        sellers
      }, path);
      this.$store.state[parent][marketplace].initialize({
        list: marketplaces,
        selected: [],
        name: "marketplaces_filter",
        key: "sales_channel",
        autoSelect: true
      });
      return marketplaces.filter(({
        sales_channel
      }) => sales_channel !== "-").map(marketplace => marketplace.sales_channel);
    },
    async initializeProductsFilters({
      sellers,
      sales_channel
    }, parent, product, rootPath) {
      // Get the sales channel from the store
      const path = `/${rootPath}/filters/products`;
      const products = await this.getProductsSuggestionsBasedOnSellers({
        sellers,
        sales_channel
      }, path);
      this.$store.state[parent][product].initialize({
        list: products,
        selected: [],
        key: "id",
        name: "products_filter",
        asyncSearcher: params => this.getProductsSuggestionsBasedOnSellers({
          ...params
        }, path)
      });
    },
    async initializeCampaignsFilters({
      sellers,
      sales_channel
    }, campaignsFilter, rootPath) {
      const path = `/${rootPath}/filters/campaigns`;
      const campaigns = await this.getCampaignsBasedOnSellers({
        sellers,
        sales_channel
      }, path);
      campaignsFilter.initialize({
        list: campaigns?.slice(0, 100),
        selected: [],
        key: "id",
        name: "campaigns_filter",
        asyncSearcher: params => this.getCampaignsBasedOnSellers({
          ...params
        }, path)
      });
    },
    async initializePortfoliosFilters({
      sellers,
      sales_channel
    }, portfoliosFilter, rootPath) {
      const path = `/${rootPath}/filters/portfolios`;
      const portfolios = await this.getPortfoliosBasedOnSellers({
        sellers,
        sales_channel
      }, path);
      portfoliosFilter.initialize({
        list: portfolios?.slice(0, 100),
        selected: [],
        key: "portfolio_name",
        name: "portfolios_filter",
        asyncSearcher: params => this.getPortfoliosBasedOnSellers({
          ...params
        }, path)
      });
    },
    async initializeAdFormatsFilters({
      sellers
    }, adFormatsFilter, rootPath) {
      const path = `/${rootPath}/filters/ad_formats`;
      const ad_formats = await this.getAdFormatsBasedOnSellers({
        sellers
      }, path);
      adFormatsFilter.initialize({
        list: ad_formats,
        selected: [],
        name: "ad_formats_filter"
      });
    },
    async initializeMatchTypesFilters({
      sellers
    }, matchTypesFilter, rootPath) {
      const path = `/${rootPath}/filters/match_types`;
      const match_types = await this.getMatchTypesBasedOnSellers({
        sellers
      }, path);
      matchTypesFilter.initialize({
        list: match_types,
        selected: [],
        key: "id",
        name: "match_types_filter"
      });
    },
    async initializeTargetingsFilters({
      sellers,
      sales_channel
    }, targetingsFilter, rootPath) {
      const path = `/${rootPath}/filters/targetings`;
      const targetings = await this.getTargetingsBasedOnSellers({
        sellers,
        sales_channel
      }, path);
      targetingsFilter.initialize({
        list: targetings?.slice(0, 100),
        selected: [],
        key: "keyword_id_list",
        name: "tagetings_filter",
        asyncSearcher: params => this.getTargetingsBasedOnSellers({
          ...params
        }, path)
      });
    },
    initializeCurrenciesFilters({
      sellers
    }, parent, currency) {
      const currencies = this.getCurrencies(sellers);
      this.$store.state[parent][currency].initialize({
        list: currencies,
        selected: [],
        key: "currency",
        name: "currency_filter"
      });
    },
    fetchSubFilters({
      sellers,
      sales_channel
    }, parent, {
      product,
      targeting,
      match_type,
      campaign,
      portfolio,
      ad_format
    }, rootPath) {
      const promises = [];
      if (product) {
        promises.push(this.initializeProductsFilters({
          sellers,
          sales_channel
        }, parent, product, rootPath));
      }
      if (targeting) {
        promises.push(this.initializeTargetingsFilters({
          sellers,
          sales_channel
        }, parent, targeting, rootPath));
      }
      if (match_type) {
        promises.push(this.initializeMatchTypesFilters({
          sellers
        }, parent, match_type, rootPath));
      }
      if (campaign) {
        promises.push(this.initializeCampaignsFilters({
          sellers,
          sales_channel
        }, parent, campaign, rootPath));
      }
      if (portfolio) {
        promises.push(this.initializePortfoliosFilters({
          sellers,
          sales_channel
        }, parent, portfolio, rootPath));
      }
      if (ad_format) {
        promises.push(this.initializeAdFormatsFilters({
          sellers,
          sales_channel
        }, parent, ad_format, rootPath));
      }
      Promise.all(promises);
    },
    async initializeSellersFilter(commitFilterFunction) {
      let current_workspace = tryParse(localStorage.getItem("current_workspace"));
      let any_saved_seller_filter = localStorage.getItem(current_workspace.workspace_id + "sellers_filter");
      if (!!any_saved_seller_filter && !isObjectEmpty(any_saved_seller_filter) && Filter.deserialize(any_saved_seller_filter)?.selected.length && Filter.deserialize(any_saved_seller_filter)?.selected.length < 2) {
        commitFilterFunction(Filter.deserialize(any_saved_seller_filter));
      } else {
        await this.refreshSellersFilter();
      }
    },
    async refreshSellersFilter() {
      // Setup Filters
      let sellers = await this.getFilterValuesForSellersFilter();
      if (sellers.length > 0) {
        await this.getOmnyAccountProfileIds(sellers);
        this.sellers_filter.initialize({
          list: sellers,
          selected: [],
          name: "sellers_filter",
          key: "id"
        });
      } else {
        this.handleErrorOccured();
      }
    },
    async initializeMarketplacesFilter(commitFilterFunction, check_local_storage) {
      let current_workspace = tryParse(localStorage.getItem("current_workspace"));
      let any_saved_marketplace_filter = localStorage.getItem(current_workspace.workspace_id + "marketplaces_filter");
      if (any_saved_marketplace_filter && !isObjectEmpty(any_saved_marketplace_filter) && Filter.deserialize(any_saved_marketplace_filter)?.selected.length && Filter.deserialize(any_saved_marketplace_filter)?.selected.length < 2 && check_local_storage) {
        commitFilterFunction(Filter.deserialize(any_saved_marketplace_filter));
      } else {
        await this.refreshMarketPlacesFilter();
      }
    },
    async refreshMarketPlacesFilter() {
      // Setup Filters
      let marketplaces = await this.getFilterValuesForMarketPlacesFilter();
      if (marketplaces.length > 0) {
        this.marketplaces_filter.initialize({
          list: marketplaces,
          selected: [],
          name: "marketplaces_filter",
          key: "sales_channel"
        });
      } else {
        this.handleErrorOccured();
      }
    }
  }
};
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-693f1712"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "image-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.overlays, (_, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "overlap",
      key: index,
      style: _normalizeStyle($setup.overlayStyles[index])
    }, [index == 0 ? _renderSlot(_ctx.$slots, "default", {
      key: 0
    }, undefined, true) : _createCommentVNode("v-if", true)], 4 /* STYLE */);
  }), 128 /* KEYED_FRAGMENT */)), $props.count ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "counter",
    style: _normalizeStyle({
      zIndex: $props.overlays - 1
    })
  }, _toDisplayString($props.count), 5 /* TEXT, STYLE */)) : _createCommentVNode("v-if", true)]);
}
import { normalizeToRange } from '@/utils';
import { BAR_CHART_MINIMUM_PERCENTAGE } from '@/utils/constants';
import { normalizeNegativeToRange } from '../../../utils';
export default {
  name: 'ColorfulBigBar',
  props: {
    value: {
      type: Number,
      required: true,
      default: 3301
    },
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    background: {
      type: String,
      default: "#F2F2F2"
    },
    borderColor: {
      type: String,
      default: "#F2F2F2"
    },
    height: {
      type: String,
      default: "18px"
    },
    chartMinimum: {
      type: Number,
      default: BAR_CHART_MINIMUM_PERCENTAGE
    },
    negative: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedStyle() {
      let width;
      width = normalizeToRange(this.value, this.chartMinimum * this.min, this.max);
      console.log("Width", width, this.value, this.min, this.max);
      return {
        height: this.height,
        backgroundColor: this.background,
        borderRadius: '2px',
        width: `calc(var(--container-width ) * 0.5 * ${width / 100})`
      };
    }
  }
};
import AppIcon from "@/components/AppIcon";
import AppTabs from "@/components/AppTabs";
import AppVersion from "@/components/AppVersion";
import EditPagePreviewItem from "@/components/EditPagePreviewItem";
import EditPagePreviewPage from "@/components/EditPagePreviewPage";
export default {
  name: "EditPagePreview",
  emits: ["close", "type-change"],
  components: {
    AppIcon,
    AppTabs,
    AppVersion,
    EditPagePreviewItem,
    EditPagePreviewPage
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  expose: ['getCurrentType'],
  data() {
    return {
      tabs: [{
        value: "item",
        text: "List item",
        canSwitchTab: this.canSwitchTab
      }, {
        value: "page",
        text: "Product page",
        canSwitchTab: this.canSwitchTab
      }],
      tab: "item",
      selected_version: {}
    };
  },
  watch: {
    tab(val) {
      this.$emit("type-change", val == "item" ? "normal" : "expand");
    }
  },
  computed: {
    updatedProduct() {
      const {
        title,
        id,
        ...rest
      } = {
        ...this.product
      };
      const updatedProduct = {
        ...rest,
        title: title ?? "Local Version",
        id: id ?? this.product.content_id
      };
      return updatedProduct;
    },
    versionList() {
      return [this.updatedProduct];
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    canSwitchTab() {
      return true;
    },
    onSelectVersion(version) {
      this.selected_version = version;
    },
    getCurrentType() {
      console.log("Preview Tab: ", this.tab);
      return this.tab == 'item' ? 'normal' : 'expand';
    }
  }
};
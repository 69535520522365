import moment from "moment";
import DOMPurify from "dompurify";
import ValidateMixin from "./ValidateMixin.vue";
import ContentMixin from "./ContentMixin.vue";
import { stringsAreTheSame, isEmptyString } from "@/utils";
export default {
  name: "ContentDisplayRulesMixin",
  mixins: [ValidateMixin, ContentMixin],
  computed: {
    pushStatusMessages() {
      return {
        sync_error: {
          text: `<span style="font-weight: 600">Sync error</span><br /><span style="font-weight: 400">Synchronization with Seller/Vendor Central failed. Please review the error message below, correct the issue, and attempt to re-sync your content.<br />
          <b>Issues:</b> <br /></span>` + this.current_item?.sync_errors?.join("<br />") ?? "",
          color_text: "#db333b",
          background_color: "#fff0f1"
        },
        draft: {
          text: `<span style="font-weight: 600">Draft</span><br /><span style="font-weight: 400"> An updated listing has been created in the platform</span>`,
          color_text: "#ffffff",
          background_color: "#736bc9"
        },
        processing: {
          text: `<span style="font-weight: 600">Processing</span><br /><span style="font-weight: 400">An updated listing is currently being pushed to Amazon Seller Central</span>`,
          color_text: "#595959",
          background_color: "#f4f4f4"
        },
        sync_finished: {
          text: `<span style="font-weight: 600">Sync with Seller Central</span><br /><span style="font-weight: 400">The last modifications have been synced with Seller Central</span>`,
          color_text: "#2172e1",
          background_color: "#e0ecff"
        },
        accepted: {
          text: `<span style="font-weight: 600">Synced with Amazon</span><br /><span style="font-weight: 400">The last modifications have been published on Amazon</span>`,
          color_text: "#22863d",
          background_color: "#f1fdf5"
        },
        not_accepted: {
          text: `<span style="font-weight: 600">Contribution not accepted</span>`,
          color_text: "#ffffff",
          background_color: "#e7747a"
        },
        seller_central_not_accepted: {
          text: `<span style="font-weight: 600">Contribution not accepted by Amazon Seller Central</span>`,
          color_text: "#ffffff",
          background_color: "#e7747a"
        },
        unknown: ""
      };
      // return {
      //   sync_error:
      //     "<b>Sync Error:</b> Synchronization with Seller/Vendor Central failed. Please review the error message below, correct the issue, and attempt to re-sync your content.<br />" +
      //     "<b>Issues:</b> <br />"+
      //       this.current_item?.sync_errors?.join("<br />") ?? "",
      //   draft: "<b>Draft:</b> An updated listing has been created in the platform",
      //   processing:
      //     "<b>Processing:</b> An updated listing is currently being pushed to Amazon Seller Central",
      //   sync_finished:
      //     "<b>Synced with Seller Central:</b> Synced with Seller/Vendor Central successful. Daily sync with Amazon will confirm publication on the Amazon website by tomorrow.",
      //   accepted:
      //     "<b>Synced with Amazon:</b> The last modifications has been published on Amazon",
      //   not_accepted: "The last modification has been rejected by Amazon",
      //   unknown: "",
      // };
    }
  },
  data() {
    return {
      current_item: {},
      push_status_colors: {
        sync_error: "#fff",
        draft: "#f3eeff",
        processing: "#fff",
        sync_finished: "#edf5ff",
        accepted: "#defbe6",
        not_accepted: "#ffe5e7",
        seller_central_not_accepted: "#ffe5e7",
        unknown: ""
      },
      push_status_icons: {
        sync_error: "sync-error-status",
        draft: "draft-status",
        processing: "processing-status",
        sync_finished: "seller-central-sync-status",
        accepted: "amazon-sync-status",
        not_accepted: "not-accepted-status",
        seller_central_not_accepted: "seller-central-not-accepted-status",
        unknown: ""
      }
    };
  },
  methods: {
    isPropertyAbsent(obj, prop) {
      return obj[prop] === undefined || obj[prop] === null || obj[prop] === "";
    },
    allModifiedAttributesAccepted(item) {
      let previously_modified_attribute = Object.keys(item.amazon_version).filter(attribute => !this.isOmnyVersionEmptyString(item, attribute));
      return previously_modified_attribute.length > 0 && !!item?.last_push_to_amazon_time && !isEmptyString(item?.last_push_to_amazon_time) && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 ? previously_modified_attribute.every(attribute => this.isOmnyVersionSameAsAmazon(item, attribute)) : false;
    },
    allAttributeOmnyVersionEqualAmazon(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazon(item, attribute));
    },
    allAttributeOmnyVersionEqualAmazonSellerCentral(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionSameAsAmazonSellerCentral(item, attribute));
    },
    allAttributeAmazonIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonVersionEmptyString(item, attribute));
    },
    allAttributeAmazonVersionEqualAmazonSellerCentral(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionSameAsAmazon(item, attribute));
    },
    allAttributeAmazonSellerCentralIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isAmazonSellerCentralVersionEmptyString(item, attribute));
    },
    allAttributeOmnyIsEmptyString(item) {
      let all_attributes = Object.keys(item.amazon_version);
      return all_attributes.length > 0 && all_attributes.every(attribute => this.isOmnyVersionEmptyString(item, attribute));
    },
    noContentOnSCAndOnOmny(item, content_evaluation) {
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_sc_and_on_omny");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCEqualAmazon(item, content_evaluation) {
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_equal_amazon");
      }
      return content_evaluation;
    },
    NoContentOnOmnyAndSCDifferenceFromAmazon(item, content_evaluation) {
      let evaluation = this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("no_content_on_omny_and_sc_difference_from_amazon");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndDifferenceSCWithoutPush(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_difference_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndDifferenceSCWithPush(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_difference_sc_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndSCWithoutPush(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && !this.allModifiedAttributesPushed(item) && !item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndSCWithSyncError(item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !!item?.last_push_to_amazon_time && item.push_status == "sync_error" && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_sc_with_sync_error");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndSCWithRecentPush(item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff < 1000 * 60 * 60 * 24 && item.push_status == "sync_finished" && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_sc_with_recent_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndSCWithOldPushContributionModified(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && item.push_status == "sync_finished" && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_sc_with_old_push_contribution_modified");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndSCWithOldPushDirectModification(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 && item.push_status == "sync_finished" && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_sc_with_old_push_direct_modification");
      }
      return content_evaluation;
    },
    ContentOnOmnyDifferenceFromAmazonAndSCWithoutPush(item, content_evaluation) {
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !this.allModifiedAttributesPushed(item) && !item?.last_push_to_amazon_time && item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_difference_from_amazon_and_sc_without_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualSCAndDifferenceFromAmazonWithPush(item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 36 && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_sc_and_difference_from_amazon_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyDiffrenceFromSCAndAmazonWithPush(item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 36 && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_diffrence_from_sc_and_amazon_with_push");
      }
      return content_evaluation;
    },
    ContentOnOmnyEqualAmazonAndSCWithPush(item, content_evaluation) {
      let all_attributes = Object.keys(item.amazon_version);
      let evaluation = !this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && this.allAttributeOmnyVersionEqualAmazon(item) && this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && this.allModifiedAttributesPushed(item) && !!item?.last_push_to_amazon_time && !item?.modified_attributes.length;
      if (evaluation) {
        content_evaluation.push("content_on_omny_equal_amazon_and_sc_with_push");
      }
      return content_evaluation;
    },
    contentEvaluateItem(item) {
      let content_evaluation = [];
      this.noContentOnSCAndOnOmny(item, content_evaluation);
      this.NoContentOnOmnyAndSCEqualAmazon(item, content_evaluation);
      this.NoContentOnOmnyAndSCDifferenceFromAmazon(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndDifferenceSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndDifferenceSCWithPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndSCWithoutPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndSCWithSyncError(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndSCWithRecentPush(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndSCWithOldPushContributionModified(item, content_evaluation);
      this.ContentOnOmnyDifferenceFromAmazonAndSCWithOldPushDirectModification(item, content_evaluation);
      this.ContentOnOmnyEqualSCAndDifferenceFromAmazonWithPush(item, content_evaluation);
      this.ContentOnOmnyDiffrenceFromSCAndAmazonWithPush(item, content_evaluation);
      this.ContentOnOmnyEqualAmazonAndSCWithPush(item, content_evaluation);
      return content_evaluation;
    },
    contentToDisplayTextStyle(item, key) {
      // item.push_status = !this.isOmnyVersionSameAsAmazonAndAmazonSellerCentral(item, key)
      //   ? "draft"
      //   : item.push_status;
      const statusValidationMap = {
        product_name: this.mValidateTitle,
        product_description: this.mValidateDescription,
        bullet_point_1: this.mValidateBulletPoints,
        bullet_point_2: this.mValidateBulletPoints,
        bullet_point_3: this.mValidateBulletPoints,
        bullet_point_4: this.mValidateBulletPoints,
        bullet_point_5: this.mValidateBulletPoints,
        generic_keyword: this.mValidateKeyword,
        count_images: this.mValidateNumborOfImage
      };
      let validationStatus;
      if (statusValidationMap[key]) {
        validationStatus = statusValidationMap[key].call(this, this.contentToDisplay(item, key));
      }
      let style = "grey";
      style = this.contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonStatus(item, key) ? "color:#db333b;font-weight:660" : this.contentNotAcceptedAmazonSellerCentralAndNotAcceptedOnAmazonStatus(item, key) ? "color:#db333b;font-weight:660" : this.contentNotAcceptedAmazonSellerCentralWithSuccessOldPushStatus(item, key) ? "color:#db333b;font-weight:660" : this.noContentOnOmnyAndSCDifferenceFromAmazonStatus(item, key) ? "color:#db333b;font-weight:660" : this.contentNotAcceptedAmazonSellerCentralWithSuccessPushStatus(item, key) ? "color:#2172e1;font-weight:660" : this.contentNotAcceptedAmazonSellerCentralOrDraftStatus(item, key) ? "color:#5a52ae;font-weight:660" : !this.isOmnyVersionEmptyString(item, key) ? "color:#4d4d4d;font-weight:500" : "color:#999;font-weight:500";
      let isDraft = style == "color:#5a52ae;font-weight:660";
      let isNotAccepted = style == "color:#db333b;font-weight:660";
      return {
        style,
        isNotAccepted,
        isDraft,
        validationStatus
      };
    },
    contentShowOmnyValidationStatus(item) {
      return Object.keys(item.amazon_version).some(attribute => this.contentToDisplayTextStyle(item, attribute).style == "color:#5a52ae;font-weight:660");
    },
    contentAcceptedAmazonSellerCentralNotAcceptedOnAmazonStatus(item, key) {
      return (
        //Why we need to check omny version here?
        !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazon(item, key) && this.isOmnyVersionSameAsAmazonSellerCentral(item, key)
      );
    },
    contentNotAcceptedAmazonSellerCentralAndNotAcceptedOnAmazonStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazon(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentral(item, key);
    },
    contentNotAcceptedAmazonSellerCentralOrDraftStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazon(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentral(item, key);
    },
    noContentOnOmnyAndSCDifferenceFromAmazonStatus(item, key) {
      return this.isOmnyVersionEmptyString(item, key) && !this.isAmazonSellerCentralVersionEmptyString(item, key) && !this.isAmazonVersionEmptyString(item, key) && !this.isAmazonSellerCentralVersionSameAsAmazon(item, key);
    },
    contentNotAcceptedAmazonSellerCentralWithSuccessPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazon(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentral(item, key) && this.isAmazonSellerCentralVersionSameAsAmazon(item, key) && item.push_status == "sync_finished" && !!item?.last_push_to_amazon_time && this.contentLastPushDate(item).diff < 1000 * 60 * 60 * 24 && item?.modified_attributes.length;
    },
    contentNotAcceptedAmazonSellerCentralWithSuccessOldPushStatus(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && !this.isOmnyVersionSameAsAmazon(item, key) && !this.isOmnyVersionSameAsAmazonSellerCentral(item, key) && this.isAmazonSellerCentralVersionSameAsAmazon(item, key) && item.push_status == "sync_finished" && item.last_push_to_amazon_time && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24;
    },
    isOmnyVersionSameAsAmazon(item, key) {
      return stringsAreTheSame(item?.omny_version[key], item?.amazon_version[key]);
    },
    isOmnyVersionEmptyString(item, key) {
      return isEmptyString(item?.omny_version[key]);
    },
    isAmazonSellerCentralVersionEmptyString(item, key) {
      return isEmptyString(item?.amazon_seller_central_version[key]);
    },
    isAmazonVersionEmptyString(item, key) {
      return isEmptyString(item?.amazon_version[key]);
    },
    isOmnyVersionSameAsAmazonAndAmazonSellerCentral(item, key) {
      return !this.isOmnyVersionEmptyString(item, key) && this.isOmnyVersionSameAsAmazon(item, key) && this.isOmnyVersionSameAsAmazonSellerCentral(item, key);
    },
    isOmnyVersionSameAsAmazonSellerCentral(item, key) {
      return stringsAreTheSame(item?.omny_version[key], item?.amazon_seller_central_version[key]);
    },
    contentToDisplay(item, key, displayHTMLTags = false) {
      const rawContent = item.omny_version?.[key] || item.amazon_seller_central_version?.[key] || item.amazon_version?.[key] || "";
      const processedContent = DOMPurify.sanitize(rawContent, {
        ALLOWED_TAGS: []
      });
      // return (
      //   item.omny_version?.[key] ||
      //   item.amazon_version?.[key] ||
      //   item.amazon_seller_central_version?.[key] ||
      //   ""
      // );
      return displayHTMLTags ? rawContent : processedContent;
    },
    contentLastPushDate(item) {
      let last_push_to_amazon_time = item?.last_push_to_amazon_time ? moment(item?.last_push_to_amazon_time) : moment().add(1, "week");
      let diffInMilliseconds = moment().diff(last_push_to_amazon_time);
      if (diffInMilliseconds < 0) {
        return {
          text: "",
          diff: 0
        };
      }
      let last_push_date = diffInMilliseconds < 1000 * 60 * 60 ? Math.floor(diffInMilliseconds / (1000 * 60)) + "m" : diffInMilliseconds < 1000 * 60 * 60 * 24 ? Math.floor(diffInMilliseconds / (1000 * 60 * 60)) + "h" : Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + "d";
      return {
        text: last_push_date,
        diff: diffInMilliseconds
      };
    },
    allModifiedAttributesPushed(item) {
      const after_push_status = ["sync_finished", "processing", "sync_error"];
      return item.modified_attributes.length == 0 && after_push_status.includes(item.push_status);
    },
    contentPushProcessingIconToDisplay() {
      return {
        icon: this.push_status_icons["processing"],
        color: this.push_status_colors["processing"],
        text: this.pushStatusMessages["processing"].text,
        color_text: this.pushStatusMessages["processing"].color_text,
        background_color: this.pushStatusMessages["processing"].background_color,
        status: "processing"
      };
    },
    contentPushIconToDisplay(item) {
      console.log("items status: ", this.allModifiedAttributesAccepted(item));
      item.push_status = this.allModifiedAttributesAccepted(item) ? "accepted" : this.allModifiedAttributesPushed(item) && !this.allModifiedAttributesAccepted(item) && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 36 ? "not_accepted" : this.allAttributeOmnyIsEmptyString(item) && !this.allAttributeAmazonSellerCentralIsEmptyString(item) && !this.allAttributeAmazonIsEmptyString(item) && !this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) ? "not_accepted" : !this.allAttributeOmnyVersionEqualAmazon(item) && !this.allAttributeOmnyVersionEqualAmazonSellerCentral(item) && this.allAttributeAmazonVersionEqualAmazonSellerCentral(item) && !this.allModifiedAttributesAccepted(item) && this.contentLastPushDate(item).diff > 1000 * 60 * 60 * 24 ? "seller_central_not_accepted" : item.push_status;
      const status = item.push_status;
      return item.push_status ? {
        icon: this.push_status_icons[item.push_status],
        color: this.push_status_colors[item.push_status],
        text: this.pushStatusMessages[item.push_status].text,
        color_text: this.pushStatusMessages[item.push_status].color_text,
        background_color: this.pushStatusMessages[item.push_status].background_color,
        status: status
      } : {};
    },
    contentIconToDisplay(item, key) {
      return item.omny_version?.[key] == item.amazon_version?.[key] ? "amazon" : item.omny_version?.[key] == item.amazon_seller_central_version?.[key] ? "seller_central" : "none";
    },
    updateModifiedAttributes(item, attribute) {
      let modifiedAttributesSet = new Set(item.modified_attributes || []);
      if (!(modifiedAttributesSet.has(attribute) && item.push_status == "draft")) {
        modifiedAttributesSet.add(attribute);
        item.push_status = "draft";
      }
      item.modified_attributes = [...modifiedAttributesSet];
      return item;
    },
    removeHtmlTagsAndSpecialCharacters(rawContent) {
      let processedContent = DOMPurify.sanitize(rawContent, {
        ALLOWED_TAGS: []
      });
      return processedContent.trim();
    },
    isAmazonSellerCentralVersionSameAsAmazon(item, key) {
      return stringsAreTheSame(item?.amazon_seller_central_version[key], item?.amazon_version[key]);
    }
  }
};
import { request } from "./index";
import { country_codes_with_flags_and_marketplace_ids } from "../utils/countries";
const account = {
  state: {
    list: [],
    profiles: []
  },
  getters: {
    accountByProviderGet(state) {
      return provider => {
        let result = [];
        for (const account of state.list) {
          if (account.account_provider === provider) result.push(account);
        }
        return result;
      };
    },
    accountByWorkspaceIdGet(state) {
      return workspace_id => {
        return state.list.filter(account => account.workspace_id === workspace_id);
      };
    },
    accountAdvertisingGet(state) {
      return () => {
        const accounts = state?.list?.filter(account => account.account_provider === "Amazon Advertising");
        let advertisingAccounts = new Map();
        if (!accounts) return advertisingAccounts;
        accounts.forEach(account => {
          // Get the account list, if it exists, if not add an new list
          const key = account?.accountInfo?.id;
          const value = advertisingAccounts.get(key) ?? [];
          key && advertisingAccounts.set(key, [...value, account]);
        });
        return advertisingAccounts;
      };
    },
    accountVendorCentralGet(state) {
      return () => {
        const accounts = state?.list?.filter(account => account.account_provider === "Amazon Vendor Central");
        let vendorAccounts = new Map();
        if (!accounts) return vendorAccounts;
        accounts.forEach(account => {
          // Get the account list, if it exists, if not add an new list
          const key = account?.accountInfo?.id;
          const value = vendorAccounts.get(key) ?? [];
          key && vendorAccounts.set(key, [...value, account]);
        });
        return vendorAccounts;
      };
    },
    accountAdvertisingBySellerIdGet(state) {
      return seller_id => {
        let result = [];
        for (const account of state.list) {
          if (account.account_provider === "Amazon Advertising" && account.amz_sp_api_seller_id && account.amz_sp_api_seller_id === seller_id) result.push(account);
        }
        return result;
      };
    },
    accountProfileGet(state) {
      return (seller_id, marketplace_id) => {
        const profile = state.profiles.find(profile => profile.marketplace_id === marketplace_id && profile.seller_id === seller_id);
        if (!profile) return null;
        return profile;
      };
    },
    accountProfileSalesChannelGet(_, getters) {
      return (seller_id, sales_channel) => {
        // Get the marketplace id from the sales channel
        const marketplace_id = country_codes_with_flags_and_marketplace_ids.find(marketplace => marketplace?.name?.toLowerCase() === sales_channel?.toLowerCase())?.marketplace_id;
        if (!marketplace_id) return null;
        const profile = getters.accountProfileGet(seller_id, marketplace_id);
        return profile;
      };
    },
    accountProfileIdGet(_, getters) {
      return ({
        seller_id,
        sales_channel
      }) => {
        return getters.accountProfileSalesChannelGet(seller_id, sales_channel)?.profile_id;
      };
    },
    accountProfileCurrencyGet(_, getters) {
      return ({
        seller_id,
        sales_channel
      }) => {
        const profile = getters.accountProfileSalesChannelGet(seller_id, sales_channel);
        return profile?.currency_code;
      };
    },
    isInventoryDisabledGet(_, getters) {
      return () => {
        const seller_accounts = getters.accountByProviderGet("Amazon Seller Central");
        return !seller_accounts.length;
      };
    }
  },
  mutations: {
    accountListSet(state, payload) {
      state.list = payload;
    },
    accountProfileIdsSet(state, payload) {
      state.profiles = payload;
    }
  },
  actions: {
    async accountList({
      commit
    }, payload) {
      const data = await request("/user/accounts", "POST", payload);
      if (data) {
        commit("accountListSet", data?.accounts || []);
      }
      return data;
    },
    async accountProfileList({
      commit
    }, payload) {
      const profiles = (await request("/ppc/seller_profiles/get", "POST", payload)) ?? [];
      if (profiles) {
        commit("accountProfileIdsSet", profiles);
      }
      return profiles;
    },
    async accountRemove(_, payload) {
      const data = await request("/accounts/delete", "POST", payload);
      if (data) {
        window.$bus.trigger("refreshAccountList", this.refreshAccountList);
      }
      return data;
    },
    async accountCreate(_, payload) {
      const data = await request(` / accounts / authorize `, "POST", payload);
      return data;
    },
    async accountAuthorize(_, payload) {
      console.log("Authorize button pressed");
      var API_URL = window.location.origin;
      // this.result = await fetch(API_URL + '/authorize', {
      //   method: 'POST',
      //   credentials: 'include',
      //   headers: {
      //     Accept: 'application/json',
      //   },
      //   body: JSON.stringify(payload),
      // })
      //   .then(async (res) => {
      //     console.log('token');
      //     const json_data = await res.json();
      //     if (res.status >= 400 && res.status < 600) {
      //       //throw new Error("Bad response from server");
      //       // create error object and reject if not a 2xx response code
      //       let err = new Error('HTTP status code: ' + res.status);
      //       err.response = json_data;
      //       err.status = res.status;
      //       throw err;
      //     }
      const json_data = await request("/authorize", "POST", payload, true, true);
      // console.log(json_data.authorization_url)
      const authorize_window = window.open(json_data.authorization_url, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
      // const authorize_window = window.open(
      //   json_data.authorization_url,
      //   "OmnyAuthorizeTab"
      // );
      authorize_window.addEventListener("beforeunload", () => {
        // do stuff
        window.$bus.trigger("refreshAccountList");
      });
      //   return;
      // })
      // .catch(function (error) {
      //   console.log('Error in authorize:');
      //   console.error(error);
      //   let message = error.response;
      //   window.$bus.trigger('showDanger', {
      //     visible: true,
      //     message: 'Error at server:   ' + message.status,
      //   });
      // });
    },
    async accountAuthorizeResultList(_, payload) {
      // eslint-disable-next-line no-unused-vars
      const data = await request("/authorize/result_list", "POST", payload, true, true);
      return data?.accounts || []; //always return data
    },
    async accountAuthorizeResultSelectedList(_, payload) {
      // eslint-disable-next-line no-unused-vars
      const data = await request("/authorize/result_selected_list", "POST", payload, true, true);
      return data;
    },
    async accountAuthorizeRefreshCheck(_, payload) {
      const data = await request("/authorize/refresh/check", "POST", payload);
      return data;
    }
  }
};
export default account;
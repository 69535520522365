import TableGraph from '@/components/TableGraph.vue';
import DashboardMixin from '@/mixins/DashboardMixin';
import { computeMinMax, EVENTS, computeTableWidths } from '@/utils';
import { getGraphHeaderByTabView, getGraphTitles } from '@/components/shared/chartHelpers';
import { defineComputedPeriod, getGraphDefaultSortBy } from '@/components/shared/chartHelpers';
import { mapState } from 'vuex';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
export default {
  name: 'ProductAdvertisingPage',
  mixins: [DashboardMixin],
  data() {
    return {
      sellerIds: [],
      positions: [],
      totalPositions: [],
      totalPositionsCount: 0,
      totalWidths: [],
      graphLoading: false,
      totalLoading: false,
      showTotal: false,
      currentPage: 1,
      pageSize: 200,
      minMax: {},
      graphHeaders: [],
      graphDescription: [],
      sortBy: "",
      sortDirection: "desc",
      tab: "Product",
      view: "Advertising",
      currentViewPeriodOptions: {},
      pageRenderedLoading: false,
      showError: false,
      graphId: 'product_advertising'
    };
  },
  unmounted() {
    window.$bus.off(EVENTS.PRODUCT_ADVERTISING);
    window.$bus.off(EVENTS.SIDEBAR_CHANGED);
  },
  async mounted() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Advertising');

    // Update Graph Headers
    this.graphHeaders = getGraphHeaderByTabView(this.tab, this.view, this.period);
    this.graphDescription = this.headerDescription(this.tab, this.view);

    // Set the default sortBy
    this.sortBy = getGraphDefaultSortBy(this.tab, this.view);
    this.graphLoading = false;
    await this.updateComponent({
      period: this.period
    });
    this.$nextTick(() => {
      this.totalWidths = computeTableWidths(this.graphId);
    });
    window.$bus.on(EVENTS.PRODUCT_ADVERTISING, () => {
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    });
    window.$bus.on(EVENTS.SIDEBAR_CHANGED, () => {
      this.totalWidths = computeTableWidths(this.graphId);
      console.log("Sidebar changed: ", this.totalWidths);
    });
  },
  computed: {
    ...mapState({
      graph: state => state.dashboard.pageReports['product_advertising'].graph,
      period: state => state.period.datePickerPeriod
    }),
    graphTitle() {
      return `${getGraphTitles()[this.tab][this.view]} ${defineComputedPeriod(this.period)}` || "";
    },
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    }
  },
  methods: {
    retry() {
      this.showError = false;
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    },
    async innerFetch({
      period
    }) {
      return await this.fetchKpis({
        path: '/product/advertising',
        filters: this.filters,
        period,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        page: this.currentPage,
        pageSize: this.pageSize
      });
    },
    async updateComponent({
      period
    }) {
      this.graphLoading = true;
      const {
        graph,
        total,
        totalCount,
        error
      } = await this.setupComponent({
        period
      });
      if (error) {
        this.showError = true;
        this.graphLoading = false;
        return;
      }
      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        tabView: 'product_advertising',
        graph
      });
      this.totalPositions = total[0];
      this.totalPositionsCount = totalCount;
      this.graphLoading = false;
    },
    async setupComponent({
      period
    }) {
      await this.getOmnyAccountSellers();
      return await this.innerFetch({
        period
      });
    },
    async sortTable(sortBy) {
      this.pageRenderedLoading = true;
      if (this.sortBy !== sortBy) this.sortDirection = 'desc';else {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortBy = sortBy;
      const {
        graph
      } = await this.setupComponent({
        period: this.period
      });

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        tabView: 'product_advertising',
        graph
      });
      this.pageRenderedLoading = false;
    },
    handlePage(page) {
      this.pageRenderedLoading = true;
      this.currentPage = page;
      this.innerFetch({
        period: this.period
      }).then(({
        graph
      }) => {
        this.setGraph({
          tabView: 'product_advertising',
          graph
        });
        this.pageRenderedLoading = false;
      }).catch(() => {
        this.pageRenderedLoading = false;
      });
    }
  },
  components: {
    TableGraph,
    ErrorWrapper
  }
};
import FloatingCompoundTable from "@/components/FloatingCompoundTable.vue";
import { EVENTS, TREND_DIRECTION_TYPE, KPI_DIRECTION_TYPE, DAYS_INVENTORY_HEATMAP_COLORS, ORGANIC_CVR_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, COLORS, ANALYTICS_CSV_PAGE_SIZE, CPC_DECILE_COLORS, CVR_HEATMAP_COLORS, PROFIT_DECILE_COLORS, PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS, FLOATING_COMPOUND_TABLE_STATES } from "@/utils/constants";
import { generateColorBasedOnState, getColorForDecile, getColorsBasedOnRange, getDescriptionBasedOnKpiKey, getCampaignsStateColor, getProfitPercentageDecileColor } from "@/utils/transformers";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import PerformanceProductTableGroupBy from "@/components/dashboard_v2/PerformanceProductTableGroupBy.vue";
import { mapState } from "vuex";
import { REVENUE_EXCL_VAT_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, PPC_SALES_FIELD, TACOS_FIELD, RATING_FIELD, BUYBOX_FIELD, RATING_TOTAL_FIELD, REVENUE_SHARE_FIELD, TOTAL_QUANTITY_FIELD, PAGE_VIEWS_FIELD, RETURN_RATE_FIELD, MARKETPLACE_FIELD, PRODUCT_FIELD, SELLER_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, CVR_FIELD, SPEND_FIELD, SPONSORED_ASIN_FIELD, SPONSORED_SALES_FIELD, UNITS_SOLD_FIELD, AVERAGE_UNIT_PRICE_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD } from "@/utils/table_fields";
import FlexRow from "@/components/FlexRow.vue";
import { NumberValue, BoxedValue, BarWithValue, PercentageValue, RatingValue, DottedValue, OverlapBarValue, RateChangeValue, CurrencyValue, ValueElement, HugValue, MarketplaceValue, ProductValue, ParentProductValue, SignedBarWithValue } from "@/components/tableElements";
import WrapWithArrow from "@/components/tableElements/WrapWithArrow.vue";
import PerformanceProductTableTotal from "@/components/dashboard_v2/PerformanceProductTableTotal.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import AppIconGroup from "@/components/AppIconGroup";
import FilterDivider from "@/components/new-filters/FilterDivider.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import { capitalize } from "vue";
import { processDate } from "@/utils";
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
import BlockBarWithValue from '@/components/tableElements/BlockBarWithValue.vue';
import BoxAndBarWithValue from '@/components/tableElements/BoxAndBarWithValue.vue';
import BlockStyledBar from '@/components/tableElements/BlockStyledBar.vue';
import WrapWithAlertIcon from "@/components/WrapWithAlertIcon.vue";
export default {
  name: "PerformanceProductTable",
  components: {
    FloatingCompoundTable,
    WrapWithPopover,
    PerformanceProductTableGroupBy,
    FlexRow,
    NumberValue,
    BoxedValue,
    RatingValue,
    BarWithValue,
    PercentageValue,
    DottedValue,
    OverlapBarValue,
    WrapWithArrow,
    RateChangeValue,
    CurrencyValue,
    ValueElement,
    HugValue,
    PerformanceProductTableTotal,
    MarketplaceValue,
    ProductValue,
    AppIconGroup,
    FilterDivider,
    ParentProductValue,
    SlotWithEvolutionValue,
    EvolutionValue,
    BlockStyledBar,
    BlockBarWithValue,
    BoxAndBarWithValue,
    SignedBarWithValue,
    WrapWithAlertIcon
  },
  emits: ["refresh", "changeDate", "refreshOtherKpis"],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [LiteTableMixin, DownloadMixin],
  data() {
    return {
      EVENTS,
      TREND_DIRECTION_TYPE,
      KPI_DIRECTION_TYPE,
      DAYS_INVENTORY_HEATMAP_COLORS,
      ORGANIC_CVR_HEATMAP_COLORS,
      BUYBOX_HEATMAP_COLORS,
      RATING_TOTAL_FIELD,
      COLORS,
      CPC_DECILE_COLORS,
      CVR_HEATMAP_COLORS,
      CVR_FIELD,
      PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS,
      standardized_parents: {},
      evolCategory: {
        prev_period: "",
        same_period_ly: "last_year_"
      },
      fieldsSources: {
        performance: {
          seller: [SELLER_FIELD, REVENUE_EXCL_VAT_FIELD, REVENUE_SHARE_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD],
          marketplace: [MARKETPLACE_FIELD, REVENUE_EXCL_VAT_FIELD, REVENUE_SHARE_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD],
          parent_asin: [PRODUCT_FIELD, REVENUE_EXCL_VAT_FIELD, REVENUE_SHARE_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD],
          asin: [PRODUCT_FIELD, REVENUE_EXCL_VAT_FIELD, REVENUE_SHARE_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD],
          product: [PRODUCT_FIELD, REVENUE_EXCL_VAT_FIELD, REVENUE_SHARE_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD]
        },
        listing: {
          seller: [SELLER_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD],
          marketplace: [MARKETPLACE_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD],
          parent_asin: [PRODUCT_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD],
          asin: [PRODUCT_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD],
          product: [PRODUCT_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD]
        },
        advertising: {
          seller: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
          marketplace: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
          parent_asin: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
          asin: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
          product: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD]
        },
        profit: {
          seller: [SELLER_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD],
          marketplace: [MARKETPLACE_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD],
          parent_asin: [PRODUCT_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD],
          asin: [PRODUCT_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD],
          product: [PRODUCT_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD]
        }
      },
      csvSources: {
        performance: {
          seller: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
            ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
              hasCurrencyEvolution: true
            }),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
            [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
            [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
            [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key
          },
          marketplace: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
            ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
              hasCurrencyEvolution: true
            }),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
            [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
            [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
            [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key
          },
          parent_asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
            ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
              hasCurrencyEvolution: true
            }),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
            [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
            [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
            [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key
          },
          asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Asin: "asin",
            [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
            ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
              hasCurrencyEvolution: true
            }),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
            [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
            [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
            [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key
          },
          product: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Sku: "sku",
            Asin: "asin",
            [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
            ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
              hasCurrencyEvolution: true
            }),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
            [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
            [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
            [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
            [TACOS_FIELD.label]: TACOS_FIELD.key
          }
        },
        listing: {
          seller: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
            ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [RATING_FIELD.label]: RATING_FIELD.key,
            [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
          },
          marketplace: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
            ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [RATING_FIELD.label]: RATING_FIELD.key,
            [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
          },
          parent_asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
            ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [RATING_FIELD.label]: RATING_FIELD.key,
            [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
          },
          asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Asin: "asin",
            [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
            ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [RATING_FIELD.label]: RATING_FIELD.key,
            [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
          },
          product: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Sku: "sku",
            Asin: "asin",
            [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
            ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
            [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
            ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
            [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
            [RATING_FIELD.label]: RATING_FIELD.key,
            [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
            [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
            ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
            [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
            [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
          }
        },
        advertising: {
          seller: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            ...this.getEvolutionsForField(SPEND_FIELD),
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
            [CVR_FIELD.label]: CVR_FIELD.key,
            [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
            "Sponsored Asin": SPONSORED_ASIN_FIELD.key
          },
          marketplace: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            ...this.getEvolutionsForField(SPEND_FIELD),
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
            [CVR_FIELD.label]: CVR_FIELD.key,
            [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
            "Sponsored Asin": SPONSORED_ASIN_FIELD.key
          },
          parent_asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            ...this.getEvolutionsForField(SPEND_FIELD),
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
            [CVR_FIELD.label]: CVR_FIELD.key,
            [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
            "Sponsored Asin": SPONSORED_ASIN_FIELD.key
          },
          asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Asin: "asin",
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            ...this.getEvolutionsForField(SPEND_FIELD),
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
            [CVR_FIELD.label]: CVR_FIELD.key,
            [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
            "Sponsored Asin": SPONSORED_ASIN_FIELD.key
          },
          product: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
            ...this.getEvolutionsForField(SPONSORED_SALES_FIELD),
            [SPEND_FIELD.label]: SPEND_FIELD.key,
            ...this.getEvolutionsForField(SPEND_FIELD),
            [TACOS_FIELD.label]: TACOS_FIELD.key,
            [CLICKS_FIELD.label]: CLICKS_FIELD.key,
            [CPC_FIELD.label]: CPC_FIELD.key,
            [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
            ...this.getEvolutionsForField(UNITS_SOLD_FIELD),
            [CVR_FIELD.label]: CVR_FIELD.key,
            [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
            "Sponsored Asin": SPONSORED_ASIN_FIELD.key
          }
        },
        profit: {
          seller: {
            [SELLER_FIELD.label]: SELLER_FIELD.key,
            [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
            [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
            [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
            [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
            [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
            [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
            [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
            [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
            [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
            [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
            ['% of Advertising costs']: 'pl_advertising_costs_pc',
            [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
          },
          marketplace: {
            [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
            [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
            [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
            [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
            [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
            [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
            [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
            [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
            [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
            [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
            [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
            ['% of Advertising costs']: 'pl_advertising_costs_pc',
            [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
          },
          parent_asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
            [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
            [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
            [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
            [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
            [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
            [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
            [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
            [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
            [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
            ['% of Advertising costs']: 'pl_advertising_costs_pc',
            [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
          },
          asin: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Asin: "asin",
            [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
            [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
            [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
            [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
            [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
            [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
            [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
            [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
            [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
            [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
            ['% of Advertising costs']: 'pl_advertising_costs_pc',
            [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
          },
          product: {
            [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
            Sku: "sku",
            Asin: "asin",
            [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
            [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
            [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
            ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
            [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
            [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
            [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
            [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
            [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
            [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
            [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
            ['% of Advertising costs']: 'pl_advertising_costs_pc',
            [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
          }
        }
      },
      CLICK_TO_FILTER_OPTIONS: {
        seller: this.sellersClickToFilter,
        marketplace: this.marketplacesClickToFilter,
        parent_asin: this.parentAsinClickToFilter,
        product: this.productsClickToFilter,
        asin: this.asinsClickToFilter
      },
      CLICK_TO_CLEAR_FILTER_OPTIONS: {
        seller: this.sellersClickToClearFilter,
        marketplace: this.marketplacesClickToClearFilter,
        parent_asin: this.parentAsinClickToClearFilter,
        product: this.productsClickToClearFilter,
        asin: this.asinsClickToClearFilter
      }
    };
  },
  computed: {
    ...mapState({
      groupBy: state => state.dashboard_v2.product_table.group_by_text,
      productTable: state => state.dashboard_v2.product_table,
      currentTabValue: state => state.dashboard_v2.currentTabValue,
      periodCategory: state => state.dashboard_v2.periodCategory,
      currencyConfig: state => state.dashboard_v2.currencyConfig,
      sellers_filter: state => state.dashboard_v2.sellers_filter,
      marketplaces_filter: state => state.dashboard_v2.marketplaces_filter,
      products: state => state.dashboard_v2.products
    }),
    filteredItems() {
      const rows = this.productTable[this.currentTabValue].data[this.groupBy]?.items;
      if (this.groupBy === 'parent_asin') {
        // Precompute active rows using Set for O(1) lookup
        const activeRows = new Set(Object.keys(this.standardized_parents).filter(key => this.standardized_parents[key]));
        return rows.filter(item => {
          // Check if the element is a parent (with children), a child of an active parent, or a parent without children
          return item.child_asin?.length > 0 // Parent with children
          || item.is_child_asin && activeRows.has(item.standardized_parent_asin) // Child of an active parent
          || item.standardized_parent_asin === null; // Parent without children
        });
      }
      return rows;
    },
    total() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.total;
    },
    minMax() {
      //console.log('minMax', this.productTable[this.currentTabValue].data[this.groupBy]?.minMax);
      return this.productTable[this.currentTabValue].data[this.groupBy]?.minMax;
    },
    sort() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.sort;
    },
    maxCharCount() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.maxCharCount;
    },
    fields() {
      return this.fieldsSources[this.currentTabValue][this.groupBy];
    },
    datePickerDate() {
      return this.productTable[this.currentTabValue].period;
    },
    page: {
      set(val) {
        this.$store.dispatch("dashboardV2SetProductTablePage", val);
        this.$emit("refresh");
      },
      get() {
        return this.$store.getters.dashboardV2GetProductTablePage();
      }
    },
    perPage: {
      set(val) {
        this.$store.dispatch("dashboardV2SetProductTablePerPage", val);
        this.$emit("refresh");
      },
      get() {
        return this.$store.getters.dashboardV2GetProductTablePerPage();
      }
    },
    totalPages: {
      set(val) {
        this.$store.dispatch("dashboardV2SetProductTableTotalPages", val);
      },
      get() {
        return this.$store.getters.dashboardV2GetProductTableTotalPages();
      }
    },
    groupByFetcher() {
      return this.productTable[this.currentTabValue].data[this.groupBy].fetch;
    },
    csvFileName() {
      const dateRange = `${processDate(this.datePickerDate?.range.start, 'DDMMM')}-${processDate(this.datePickerDate?.range.end, 'DDMMMYYYY')}`;
      return `Dashboard_${capitalize(this.currentTabValue)}_${capitalize(this.groupBy)}_${dateRange}.csv`;
    },
    getTbodyTrClass() {
      if (this.groupBy === 'parent_asin') {
        return item => `item-row 
                                ${item?.standardized_parent_asin || ''} 
                                ${item?.is_child_asin ? `is-child ${this.handleRowClass(item, this.filteredItems)}` : ''}
                                ${item?.standardized_parent_asin === null ? this.handleRowClass(item, this.filteredItems) : ''} 
                                ${item?.is_child_asin && item.last_child ? `last-child ${this.handleRowClass(item, this.filteredItems)}` : ''} 
                                ${this.standardized_parents[item?.standardized_parent_asin] ? 'visible' : 'hidden'}
                            `;
      }
      return item => this.handleRowClass(item, this.filteredItems);
    }
  },
  methods: {
    generateColorBasedOnState,
    getColorForDecile,
    getColorsBasedOnRange,
    getDescriptionBasedOnKpiKey,
    getCampaignsStateColor,
    getProfitPercentageDecileColor,
    handleDatePickerSubmit(date) {
      this.$emit("changeDate", date);
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    handleTableState(state) {
      this.$store.dispatch("dashboardV2SetProductTableState", state);
    },
    handleGroupByRefresh() {
      this.$emit('refresh');
    },
    handleClicked() {
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    handleHeadClick(fieldKey) {
      //console.log('fieldKey', fieldKey)
      const directionFlip = {
        "asc": "desc",
        "desc": "asc"
      };
      if (this.loading) return;
      const direction = directionFlip[this.sort[fieldKey]] ?? "desc";
      this.handleSort({
        sort: fieldKey,
        direction
      });
    },
    async sellersClickToFilter(item) {
      // Set the `seller` filter
      this.sellers_filter.selectOnly({
        id: item.seller_id
      }, 'id');

      // Set the current group by to `marketplace`
      this.sellers_filter.apply();
      await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS);
      this.$emit('refreshOtherKpis');
    },
    async sellersClickToClearFilter() {
      // Set the `seller` filter
      this.sellers_filter.clear();
      this.sellers_filter.apply();
      await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS);
      await this.$emit('refreshOtherKpis');
    },
    async marketplacesClickToFilter(item) {
      this.marketplaces_filter.selectOnly({
        sales_channel: item.sales_channel
      }, 'sales_channel');

      // Set the current group by to `product`
      this.marketplaces_filter.apply();
      await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS);
      await this.$emit('refreshOtherKpis');
    },
    async marketplacesClickToClearFilter() {
      // Set the `marketplace` filter
      this.marketplaces_filter.clear();
      this.marketplaces_filter.apply();
      await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS);
      await this.$emit('refreshOtherKpis');
    },
    async parentAsinClickToFilter(item) {
      // Toggle the `ASIN` in the `standardized_parents` Object<String, Boolean>
      if (item.is_child_asin || item.standardized_parent_asin === null) {
        const keyword = item.asin ? [item.asin] : item.asin_list;
        await this.productsSearchAndSelect(keyword);
      }
    },
    async parentAsinClickToClearFilter() {
      await this.productsReset();
    },
    async productsClickToFilter(item) {
      await this.productsSearchAndSelect([item.asin]);
    },
    async productsClickToClearFilter() {
      await this.productsReset();
    },
    async asinsClickToFilter(item) {
      await this.productsSearchAndSelect([item.asin]);
    },
    async asinsClickToClearFilter() {
      await this.productsReset();
    },
    async productsSearchAndSelect(keyword) {
      this.products.clear();
      this.products.apply();
      let res = await this.products.asyncSearchAndSelect({
        keyword,
        args: {
          sellers: this.sellers_filter.filterValues,
          sales_channel: this.marketplaces_filter.filterValues
        }
      });
      if (!res) {
        return;
      }
      this.products.apply();
      this.$emit('refreshOtherKpis');
    },
    async productsReset() {
      await this.products.clearAfterAsyncSearch({
        args: {
          sellers: this.sellers_filter.filterValues,
          sales_channel: this.marketplaces_filter.filterValues
        }
      });
      await this.$emit('refreshOtherKpis');
    },
    async handleBreakdownReset() {
      const clickToClearFilter = this.CLICK_TO_CLEAR_FILTER_OPTIONS[this.groupBy];
      await clickToClearFilter();
    },
    async handleBreakdownSelect(item) {
      const clickToFilter = this.CLICK_TO_FILTER_OPTIONS[this.groupBy];
      await clickToFilter(item);
    },
    async handleRowClick(item) {
      const performanceFloatingCompoundTable = this.$refs.performanceFloatingCompoundTable;
      if (performanceFloatingCompoundTable) {
        performanceFloatingCompoundTable.handleNormalize();
      }

      // If we are in `parent_asin` group by and the click came from a parent?
      if (this.groupBy === 'parent_asin' && 'child_asin' in item && item.child_asin.length > 0) {
        this.handleParentAsinToggle(item);
      } else {
        this.handleBreakdownRowClicked(item, this.handleBreakdownReset, this.handleBreakdownSelect, this.filteredItems);
      }
    },
    async downloadDashboardV2CSV() {
      const csv_headers = this.csvSources[this.currentTabValue][this.groupBy];
      const csvFileName = this.csvFileName;
      const download = async page => await this.downloader(page, ANALYTICS_CSV_PAGE_SIZE, csv_headers, this.groupByFetcher);
      const str = await this.generateCsvStringWithPagination(csv_headers, Math.ceil(this.totalPages / ANALYTICS_CSV_PAGE_SIZE), download, '-');
      await this.downloadCsv(str, csvFileName);
    },
    collapseAllParents() {
      this.standardized_parents = {};
    },
    async handleParentAsinToggle(item) {
      if (this.standardized_parents?.[item.standardized_parent_asin]) {
        this.standardized_parents[item.standardized_parent_asin] = false;
        await this.productsClickToClearFilter();
      } else {
        this.collapseAllParents();
        this.standardized_parents[item.standardized_parent_asin] = true;
        await this.productsSearchAndSelect(item.asin_list);
      }
    }
  }
};
import { mapState, mapGetters } from "vuex";
import FlexRow from "@/components/FlexRow.vue";
import { SellerFilter, MarketplaceFilter, ProductFilter, FilterDivider, FilterItemSelector } from "@/components/new-filters";
import { EVENTS } from "../utils/constants";
import WrapWithPopover from "./shared/WrapWithPopover.vue";
export default {
  name: "PPCTopSection",
  components: {
    FlexRow,
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    FilterDivider,
    FilterItemSelector,
    WrapWithPopover
  },
  emits: ['more-filters'],
  props: {
    moreFilters: {
      type: Boolean,
      default: false
    },
    hasAnySelection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      sellers: state => state.ppc.sellers,
      marketplaces: state => state.ppc.marketplaces,
      products: state => state.ppc.products,
      match_types: state => state.ppc.match_types
    }),
    ...mapGetters(["ppcGetProductTableGroupBy"]),
    getSellers() {
      return this.sellers.filterState.selected.map(({
        name
      }) => name)[0];
    },
    getMarketplaces() {
      return this.marketplaces.filterState.selected[0];
    },
    showMatchTypeFilter() {
      console.log("Top Section", this.ppcGetProductTableGroupByppcGetProductTableGroupBy());
      return this.ppcGetProductTableGroupBy().key === 'targeting';
    }
  },
  mounted() {
    window.$bus.on(EVENTS.REFRESH_PPC_SELLER_FILTER, this.applySellers);
    window.$bus.on(EVENTS.REFRESH_PPC_MARKETPLACE_FILTER, this.applyMarketplaces);
    window.$bus.on(EVENTS.REFRESH_PPC_PRODUCT_FILTER, this.applyProducts);
    window.$bus.on(EVENTS.REFRESH_PPC_MATCH_TYPE_FILTER, this.applyMatchTypes);
  },
  beforeUnmount() {
    window.$bus.off(EVENTS.REFRESH_PPC_SELLER_FILTER, this.applySellers);
    window.$bus.off(EVENTS.REFRESH_PPC_MARKETPLACE_FILTER, this.applyMarketplaces);
    window.$bus.off(EVENTS.REFRESH_PPC_PRODUCT_FILTER, this.applyProducts);
    window.$bus.off(EVENTS.REFRESH_PPC_MATCH_TYPE_FILTER, this.applyMatchTypes);
  },
  methods: {
    async applySellers() {
      if (this.sellers.isModified) {
        this.sellers.apply();
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for sellers...  ',
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_SELLERS_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces.isModified) {
        this.marketplaces.apply();
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for marketplace...  ',
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyProducts() {
      if (this.products.isModified) {
        this.products.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCurrencies() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    handleSellerCleared() {
      window.$bus.trigger(EVENTS.ADD_SELLER_GROUPBY);
    },
    handleMarketplaceCleared() {
      window.$bus.trigger(EVENTS.ADD_MARKETPLACE_GROUPBY);
    },
    handleClick() {
      if (this.hasAnySelection && this.moreFilters) {
        return;
      }
      this.$emit('more-filters');
    }
  }
};
import TableWithChart from '@/components/TableWithChart.vue';
import DashboardMixin from '@/mixins/DashboardMixin';
import { computeMinMax, EVENTS } from '@/utils';
import { getGraphHeaderByTabView, getGraphTitles } from '@/components/shared/chartHelpers';
import { defineComputedPeriod, getGraphDefaultSortBy } from '@/components/shared/chartHelpers';
import { mapState } from 'vuex';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
import DatePickerMixin from '../../mixins/DatePickerMixin.vue';
import { getDatePickerUIObjectFromDateRange, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES } from '@/utils/datepicker';
export default {
  name: 'ProductInventoryPage',
  mixins: [DashboardMixin, DatePickerMixin],
  data() {
    return {
      sellerIds: [],
      graphPositions: [],
      chartPositions: [],
      totalPositions: [],
      totalPositionsCount: 0,
      totalWidths: [],
      graphLoading: false,
      totalLoading: false,
      showTotal: false,
      currentPage: 1,
      pageSize: 200,
      minMax: {},
      graphHeaders: [],
      graphDescription: [],
      sortBy: "",
      sortDirection: "desc",
      tab: "Product",
      view: "Inventory Coverage",
      pageRenderedLoading: false,
      showError: false,
      graphId: 'product_inventory_coverage'
    };
  },
  async mounted() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Inventory Coverage');
    // Update Graph Headers
    this.graphHeaders = getGraphHeaderByTabView(this.tab, this.view, this.period);
    this.graphDescription = this.headerDescription(this.tab, this.view);

    // Set the default sortBy
    this.sortBy = getGraphDefaultSortBy(this.tab, this.view);
    await this.updateComponent({
      period: this.period
    });
    window.$bus.on(EVENTS.PRODUCT_INVENTORY_COVERAGE, () => {
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    });
  },
  computed: {
    ...mapState({
      graph: state => state.dashboard.positionReport.graph,
      chart: state => state.dashboard.positionReport.chart
    }),
    period() {
      return getDatePickerUIObjectFromDateRange({
        ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, DATE_PICKER_RANGE_TITLES.LAST_28_DAYS),
        rangeTitle: DATE_PICKER_RANGE_TITLES.LAST_28_DAYS,
        compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
      });
    },
    chartTitle() {
      return `Inventory coverage evolution in the last 28 days` || "";
    },
    graphTitle() {
      return `${getGraphTitles()[this.tab][this.view]} 28 days` || "";
    },
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    }
  },
  methods: {
    retry() {
      this.showError = false;
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    },
    async innerFetch({
      period
    }) {
      return await this.fetchKpis({
        path: '/product/inventory_coverage',
        filters: this.filters,
        period,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      });
    },
    async updateComponent({
      period
    }) {
      this.graphLoading = true;
      const {
        graph,
        chart,
        error
      } = await this.setupComponent({
        period
      });
      if (error) {
        this.showError = true;
        this.graphLoading = false;
        return;
      }
      this.setGraph({
        tabView: 'product_inventory_coverage',
        graph,
        chart
      });
      this.minMax = computeMinMax(graph);
      this.graphPositions = graph;
      this.chartPositions = chart;
      this.graphLoading = false;
    },
    async setupComponent({
      period
    }) {
      await this.getOmnyAccountSellers();
      return await this.innerFetch({
        period
      });
    },
    async sortTable(sortBy) {
      this.pageRenderedLoading = true;
      if (this.sortBy !== sortBy) this.sortDirection = 'desc';else {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortBy = sortBy;
      const {
        graph
      } = await this.setupComponent({
        period: this.period
      });

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        tabView: 'product_inventory_coverage',
        graph
      });
      this.pageRenderedLoading = false;
    }
  },
  components: {
    TableWithChart,
    ErrorWrapper
  }
};
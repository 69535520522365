import { KPIS_TABS, KPIS_VIEWS } from "./constants";
import { periodsSwitches } from "../components/shared/chartHelpers";
import { EVENTS } from "./constants";
import { generateMonthsBetweenYears, generateDaysForMonthYear, getWeekFromDate, getWeeksForMonthYear, getWeekEnd, getNumberOfMonthsAbsolute } from "./datepicker";
import moment from "moment";
import { country_codes_with_flags_and_marketplace_ids } from "./countries";
import { CURRENCY_POST_PROCESSORS } from "@/utils/transformers";
import DiffMatchPatch from "diff-match-patch";
const fakeDataGenerator = rows => {
  return [rows].map(item => {
    let res = [];
    for (let i = 0; i < item; i++) {
      res.push(i);
    }
    return res;
  }).pop();
};
const hasNoDecimals = value => value === Math.round(value);
const truncateString = (str, num) => {
  if (!str) return "";
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};
const isSubset = (subset, superset) => {
  for (const element of subset) {
    if (!superset.has(element)) {
      return false;
    }
  }
  return true;
};
const getCountriesMapping = async () => {
  // Use `fetch` to fetch the countries from the `assets` folder
  const countries = country_codes_with_flags_and_marketplace_ids;
  countries.map(country => {
    // Fetch the flag from the `assets` folder
    country.icon = "/assets/flags/" + country.code + ".png";
  });
  return countries;
};
const filterOut = (incoming, stored, left_key, right_key) => {
  if (!Array.isArray(left_key)) left_key = [left_key];
  if (!Array.isArray(right_key)) right_key = [right_key];
  if (incoming === undefined || incoming === null) return [];
  if (!stored) return [];
  if (!stored.length) return incoming;
  if (!incoming) return [];
  return stored.flatMap(element => {
    const res = incoming.filter(a => {
      const res = left_key.map((v, index) => {
        return element[v] === a[right_key[index]];
      });
      return !res.reduce((acc, cur) => acc && cur, true);
    });
    return res;
  });
};
const sortData = (data, key = "name") => {
  if (!data) return [];
  data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
  return data;
};
const arraysAreTheSame = (array1, array2) => {
  return array1.length === array2.length && array1.every((element, index) => element === array2[index]);
};
const isEmptyString = val => {
  return val == false;
};
const stringsAreTheSame = (string1, string2) => {
  return string1.normalize().toLowerCase().replace(/[ \t\r\f]/g, "") === string2.normalize().toLowerCase().replace(/[ \t\r\f]/g, "");
};
const stringsAreTheSameWithCaseSensitive = (string1, string2) => {
  return string1 == string2;
};
const getFormattedCurrency = (amount, full = false, currency = "EUR", precision = 0, showCurrencySign = true, showSign = false, limit = 10000, locale, zeroInitials, allowPrecisionModification = true, fullSymbol = false) => {
  if (Math.abs(amount) > 9_999 && Math.abs(amount) < 99_999 && allowPrecisionModification) {
    precision = 1;
  }
  if (Math.abs(amount) > 1_000_000 && allowPrecisionModification) {
    precision = 2;
  }
  return CURRENCY_POST_PROCESSORS[currency](amount, full, precision, showCurrencySign, showSign, limit, locale, zeroInitials, fullSymbol)?.trim();
};
export const getLongestCurrency = (data, full = false, currency = "EUR", precision = 0, showCurrencySign = true, showSign = false, limit = 10000, locale) => {
  // Data contains values that are not yet formatted as currencies
  // Format them as currencies and we get the maximum length
  if (!data) return 0;
  data = data.map(item => {
    return getFormattedCurrency(item, full, currency, precision, showCurrencySign, showSign, limit, locale).trim();
  }).map(l => {
    let total = 0;
    l = l.split('');
    for (let i = 0; i < l.length; i++) {
      if (['.', ',', '\xA0'].includes(l[i])) {
        total += 0.29;
      } else total += 1;
    }
    return total;
  });
  return Math.max(...data);
};
export const getLongestNumber = (data, precision) => {
  if (!data) return 0;
  data = data.map(item => getFormattedDecimal(item, precision));
  const longest = data.reduce((a, b) => {
    return a.length > b.length ? a : b;
  }, "");
  return longest;
};
export const getLongestPercentage = (data, precision = 0, limit = Number.NEGATIVE_INFINITY, showSymbol = true, showSign = false) => {
  if (!data) return 0;
  data = data.map(item => getFormattedPercentage(item, precision, limit, showSymbol, showSign));
  const longest = data.filter(a => a !== null).reduce((a, b) => {
    return a?.length > b?.length ? a : b;
  }, "");
  return longest;
};
export const getNumberMaxCharCount = (data, key, precision) => {
  if (!data) return 0;
  if (data.length === 0) return 0;

  // Collect the keys in data in []
  const dataKeys = data.map(item => item[key]);

  // Transform with currencies
  const longest = getLongestNumber(dataKeys, precision);
  return longest.length;
};
export const getPercentageMaxCharCount = (data, key, precision, limit, showSymbol, showSign) => {
  if (!data) return 0;
  if (data.length === 0) return 0;

  // Collect the keys in data in []
  const dataKeys = data.map(item => item[key]);

  // Transform with currencies
  const longest = getLongestPercentage(dataKeys, precision, limit, showSymbol, showSign);
  return longest?.length;
};
export const getCurrencyMaxCharCount = (data, key, full = false, currency = "EUR", precision = 0, showCurrencySign = true, showSign = false, limit = 10_000, locale) => {
  if (!data) return 0;
  if (data.length === 0) return 0;

  // Collect the keys in data in []
  const dataKeys = data.map(item => item[key]);

  // Transform with currencies
  const longest = getLongestCurrency(dataKeys, full, currency, precision, showCurrencySign, showSign, limit, locale);
  return longest;
};
const getFormattedDecimal = (amount, precision, showSign = false) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "decimal",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    signDisplay: showSign ? "exceptZero" : undefined
  });
  return formatter.format(parseFloat(amount)).replace(",", ".");
};
const getFormattedPercentage = (amount, precision = 0, limit = 0.1, showSymbol = true, showSign = false) => {
  if (precision === 0) {
    // Figure out precision based on amount
    if (amount < limit) {
      precision = 1;
    } else {
      precision = 0;
    }
  }
  // If the amount itself, is negative, then always show the sign
  if (amount < 0) showSign = true;
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "percent",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    signDisplay: showSign ? "exceptZero" : "never"
  });
  const formatted = formatter.format(parseFloat(amount)).replace(",", ".");
  if (showSymbol) return formatted;
  return formatted.replace(/\xA0/g, "").replace("%", "");
};
const computeMinMax = (data, notALlowedFields) => {
  if (!data) return {};
  if (!data.length) return {};
  const keys = Object.keys(data[0] || {});
  if (!keys) return {};
  if (keys.length === 0) return {};

  // Check if not allowed fields are in keys
  if (!notALlowedFields) notALlowedFields = [];
  let minMax = {};
  keys.forEach(key => {
    if (typeof data[0][key] === "string") return;
    if (!notALlowedFields.includes(key)) {
      // Filter out `null's`
      const keyDataSorted = data.map(item => item[key]).filter(d => !isNaN(d));

      // Transform values into absolute versions.
      const positiveKeyDataSorted = keyDataSorted.map(d => Math.abs(d));

      // Get the min value
      const min = Math.min(...positiveKeyDataSorted);
      const negativeMin = Math.min(...keyDataSorted);

      // Get the max value
      const max = Math.max(...positiveKeyDataSorted);
      minMax[key] = {
        min,
        max,
        negativeMin: negativeMin < 0 ? negativeMin : 0
      };
    }
  });
  return minMax;
};
const transformWithCountries = async data => {
  // Check if data has the `sales_channel` field
  if (!data) return [];
  if (!data.length) return [];
  if (!data[0].sales_channel) return data;
  const countries = await getCountriesMapping();
  const transformed = data.map(item => {
    const country = countries.find(country => country.name === item.sales_channel || country.code === item.sales_channel);
    return {
      ...item,
      flag: country?.icon,
      countryName: country?.countryName
    };
  });
  return transformed;
};
const addGhostIds = data => {
  // Enforce Array<Object> structure
  if (!Array.isArray(data)) return [];
  if (!data.length) return [];
  if (typeof data[0] !== "object") return [];

  // Add ordered ids to items in data
  return data.map((item, index) => {
    item["__id"] = index;
    return item;
  });
};
const hasKey = (obj, key) => {
  if (!key) return false;
  if (!obj) return false;
  const keys = Object.keys(obj);
  return keys.includes(key);
};
const validateKpisTab = tab => {
  if (!tab) return false;
  if (!KPIS_TABS.includes(tab)) return false;
  return true;
};
const validateKpisView = view => {
  if (!view) return false;
  if (!KPIS_VIEWS.includes(view)) return false;
  return true;
};
const isObjectEmpty = obj => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
const convertArrayObjToDictObj = (arr, key) => {
  return Object.assign({}, ...arr.map(item => ({
    [item[key]]: item
  })));
};
const computeTableWidthsFromRow = (row, tableWidth) => {
  /*
    This method will compute the width of each column and return an array of widths.
  */

  // Get the row's cells
  const cells = row.querySelectorAll("th");

  // Check if cells exist
  if (!cells) return [];

  // Get the widths of each cell
  let widths = Array.from(cells).map(cell => {
    return cell.offsetWidth;
  });

  // Compute the width of each cell as a percentage of the table's width
  widths = widths.map(width => {
    return width / tableWidth * 100;
  });

  // Return the widths
  return widths;
};
const computeTableWidths = (tableId, mmScale = false) => {
  /*
    This method will find the table in the DOM using it's id and then compute
    the width of each column and return an array of widths.
  */
  // Get the table
  const tableOrWrapper = document.getElementById(tableId);
  if (!tableOrWrapper) return [];

  // Table might be wrapped in a div
  let table = tableOrWrapper.querySelector("table");

  // If table is not wrapped in a div, use the table itself
  if (!table) table = tableOrWrapper;

  // Check if table exists
  if (!table) return [];

  // Get the table's header
  const header = table.querySelector("thead");

  // Check if header exists
  if (!header) return [];

  // Get the header's row
  const row = header.querySelector("tr");

  // Check if row exists
  if (!row) return [];

  // Get the width of the entire table
  const tableWidth = table.offsetWidth;

  // Compute the width of each cell as a percentage of the table's width
  const widths = computeTableWidthsFromRow(row, tableWidth, mmScale);

  // Return the widths
  return widths;
};
const resurrectRouteFromQuery = ({
  query
}) => {
  // Check if query is empty
  if (!query) return;
  if (isObjectEmpty(query)) return;
  if (hasKey(query, "tab") && hasKey(query, "view")) {
    const {
      tab,
      view,
      period
    } = query;
    let periodObject = null;
    if (period) {
      // Fetch period object from chartHelpers
      // Look through all the objects in periodsSwitches and find the one with the same period

      periodObject = Object.values(periodsSwitches).flat().find(item => item.key === period);
    }
    if (validateKpisTab(tab) && validateKpisView(view)) {
      return {
        tab,
        view,
        period: periodObject
      };
    }
  }
  return;
};
const updateRouteWithQuery = ({
  router,
  base,
  ...rest
}) => {
  // Check if router is empty
  if (!router) return;
  if (!router.push) return;

  // Check if base is empty
  if (!base) {
    // This means we are pushing on the current route
    router.push({
      query: {
        ...rest
      }
    });
  } else {
    // This means we are pushing on a different route
    router.push({
      path: base,
      query: {
        ...rest
      }
    });
  }
};
const updatedField = (list, selected, key = "id") => {
  const used = selected.length ? selected : list;
  if (used.length === 0) return [];
  return used.map(item => item?.[key]);
};
const processDate = (date, format, locale = null) => {
  if (!date) return null;
  if (!format) return null;
  if (locale) {
    return moment(date).locale(locale).format(format);
  }
  return moment(date).format(format);
};
const flipDictionary = data => Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
const makeArrayUnique = data => {
  let jsonObject = data.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  return uniqueArray;
};
const normalizeToRange = (value, min = 0, max = 100) => {
  if (min === 0 && max === 0) return 0;
  const normalized_value = 100 * (value - min) / (max - min);
  return Math.max(0, Math.min(100, normalized_value));
};
const getCurrencySymbol = currencyCode => {
  if (!currencyCode) return null;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode
  });
  if (!currencyFormatter) return null;
  const currencySymbol = currencyFormatter.formatToParts(1).find(part => part.type === "currency").value;
  if (!currencySymbol) return null;
  return currencySymbol;
};
export const flattenTree = (tree, field) => {
  let values = [];
  for (const key in tree) {
    if (tree.hasOwnProperty(key)) {
      const value = tree[key];
      if (typeof value === "object" && value !== null) {
        values = values.concat(flattenTree(value, field));
      } else if (key === field) {
        values.push(value);
      }
    }
  }
  return values;
};
export const asyncWait = async n => {
  return new Promise(resolve => {
    setTimeout(resolve, n);
  });
};
export const computeLength = (content_type, text) => {
  var regex = /[a-zA-Z0-9]/g;
  return content_type === 'generic_keyword' ? text?.match(regex)?.length ?? 0 : text?.trim()?.length;
};
export const generateHtmlDiff = (diffs, textType) => {
  return diffs.map(([op, text]) => {
    const formattedText = text.replace(/\n/g, "<br>");
    if (textType === "text1" && op === DiffMatchPatch.DIFF_DELETE) {
      return `<span style="background: #FAA5A5; padding: Opx 2px;">${formattedText}</span>`;
    } else if (textType === "text2" && op === DiffMatchPatch.DIFF_INSERT) {
      return `<span style="background: #89E3C5; padding: Opx 2px;">${formattedText}</span>`;
    } else if (op === DiffMatchPatch.DIFF_EQUAL) {
      return `${text}`;
    }
    return "";
  }).join("");
};
export const tryParse = value => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return {};
    //console.error(`Error parsing localStorage item "${value}":`, error);
  }
};
export { arraysAreTheSame, stringsAreTheSame, stringsAreTheSameWithCaseSensitive, fakeDataGenerator, getCountriesMapping, truncateString, filterOut, isSubset, hasNoDecimals, sortData, getFormattedCurrency, getFormattedDecimal, getFormattedPercentage, computeMinMax, transformWithCountries, addGhostIds, hasKey, validateKpisTab, validateKpisView, isObjectEmpty, convertArrayObjToDictObj, computeTableWidths, resurrectRouteFromQuery, updateRouteWithQuery, updatedField, processDate, flipDictionary, makeArrayUnique, EVENTS, normalizeToRange, getNumberOfMonthsAbsolute, generateMonthsBetweenYears, getCurrencySymbol, generateDaysForMonthYear, getWeekFromDate, getWeeksForMonthYear, getWeekEnd, isEmptyString };
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c55d9164"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "brand-cell-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "border-table-row brand-cell"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.item.brand), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  });
}
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { getDescriptionBasedOnKpiKey, getColorsBasedOnRange, getColorForDecile, getTargetingTypeSplitDecileColor } from "@/utils/transformers";
import CompoundTable from "@/components/CompoundTable";
import FlexRow from "@/components/FlexRow";
import AppIcon from "@/components/AppIcon";
import AppIconGroup from "@/components/AppIconGroup";
import FilterDivider from "@/components/new-filters/FilterDivider.vue";
import LiteTableMixin from '@/mixins/LiteTableMixin.vue';
import { PERIOD_COLUMN_TITLES, PPC_V2_DATE_FORMATS, COLORS, TARGETING_TYPE_SPLIT, TARGETING_TYPE_TYPES, TARGETING_TYPE_SPLIT_KEYMAP } from '@/utils/constants';
import CompositeColorfulBar from "@/components/tableElements/CompositeColorfulBar.vue";
import { SELECTOR, PPC_V2_PERIOD_FIELD, PPC_SALES_FIELD, TACOS_FIELD, CANNIBALIZATION_FIELD, BIG_ROAS_FIELD, BIG_CPC_FIELD, TOTAL_ROAS_FIELD, TIME_IN_BUDGET_FIELD, BIG_SPEND_FIELD, SHARE_OF_VOICE_FIELD } from "@/utils/table_fields";
import StrokedSafeIcon from "@/components/icons/StrokedSafeIcon.vue";
import ToggleNumberIcon from "@/components/icons/ToggleNumberIcon.vue";
import ToggleNumberCheckedIcon from "@/components/icons/ToggleNumberCheckedIcon.vue";
import CoinsStackedIcon from "@/components/icons/CoinsStackedIcon.vue";
import PercentIcon from "@/components/icons/PercentIcon.vue";
import { HugValue, DateValue, CurrencyValue, NumberValue, BoxedValue, BarWithValue, PercentageValue, DottedValue, OverlapBarValue, DialValue, DotElement } from "@/components/tableElements";
import VariableStyledBar from "@/components/tableElements/VariableStyledBar.vue";
import LayerBars from "@/components/tableElements/LayerBars.vue";
import { mapState, mapGetters } from "vuex";
import BarSlotWithValue from "@/components/tableElements/BarSlotWithValue.vue";
import SlotWithValue from "@/components/tableElements/SlotWithValue.vue";
import moment from 'moment';
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import { capitalize } from "vue";
import WrapWithInfo from "@/components/WrapWithInfo.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import AppSwitch from "@/components/AppSwitch.vue";
import { BIG_SPONSORED_SALES_FIELD } from "../../utils/table_fields";
export default {
  name: "StrategicPeriodTable",
  components: {
    CompoundTable,
    FlexRow,
    DottedValue,
    DotElement,
    AppIcon,
    AppIconGroup,
    FilterDivider,
    CompositeColorfulBar,
    DateValue,
    HugValue,
    StrokedSafeIcon,
    ToggleNumberIcon,
    ToggleNumberCheckedIcon,
    CoinsStackedIcon,
    PercentageValue,
    BarWithValue,
    OverlapBarValue,
    NumberValue,
    BoxedValue,
    VariableStyledBar,
    LayerBars,
    BarSlotWithValue,
    CurrencyValue,
    WrapWithPopover,
    DialValue,
    SlotWithValue,
    WrapWithInfo,
    FlexColumn,
    AppSwitch,
    PercentIcon
  },
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      typ: Boolean,
      required: true
    }
  },
  emits: ["setDateRange", "refresh"],
  data() {
    return {
      COLORS,
      BIG_ROAS_FIELD,
      BIG_CPC_FIELD,
      TARGETING_TYPE_SPLIT,
      TARGETING_TYPE_TYPES,
      fieldsSources: {
        DEFAULT_FIELDS: [SELECTOR, PPC_V2_PERIOD_FIELD, BIG_SPEND_FIELD, BIG_ROAS_FIELD, TOTAL_ROAS_FIELD, BIG_CPC_FIELD, SHARE_OF_VOICE_FIELD, CANNIBALIZATION_FIELD, PPC_SALES_FIELD, TACOS_FIELD, TIME_IN_BUDGET_FIELD],
        TARGETING_FIELDS: [SELECTOR, PPC_V2_PERIOD_FIELD, BIG_SPEND_FIELD, BIG_ROAS_FIELD, TOTAL_ROAS_FIELD, BIG_CPC_FIELD, SHARE_OF_VOICE_FIELD, TIME_IN_BUDGET_FIELD]
      },
      csvSources: period => ({
        DEFAULT_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PPC_V2_PERIOD_FIELD.key,
          [BIG_SPEND_FIELD.label]: BIG_SPEND_FIELD.key,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          'Total ROAS': 'total_ROAS',
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [SHARE_OF_VOICE_FIELD.label]: SHARE_OF_VOICE_FIELD.key,
          Cannibalization: CANNIBALIZATION_FIELD.key,
          [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
          [TACOS_FIELD.label]: TACOS_FIELD.key,
          [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
        },
        TARGETING_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PPC_V2_PERIOD_FIELD.key,
          [BIG_SPEND_FIELD.label]: BIG_SPEND_FIELD.key,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`Spend - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_spend_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`Sponsored Sales - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_sponsored_sales_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`ROAS - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_ROAS_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          'Total ROAS': 'total_ROAS',
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.NON_BRANDED_KWS].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.NON_BRANDED_KWS}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.BRANDED].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.BRANDED}`,
          [`CPC - ${TARGETING_TYPE_SPLIT_KEYMAP[TARGETING_TYPE_TYPES.DISCOVERY].name}`]: `targeting_type_CPC_${TARGETING_TYPE_TYPES.DISCOVERY}`,
          [SHARE_OF_VOICE_FIELD.label]: SHARE_OF_VOICE_FIELD.key,
          [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
        }
      }),
      spendSales: false,
      BIG_SPEND_FIELD,
      BIG_SPONSORED_SALES_FIELD
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.ppc_v2.periodTable,
      tab: state => state.ppc_v2.currentTabValue,
      period: state => state.ppc_v2.period
    }),
    ...mapGetters(['ppcV2SecondaryFiltersEnabled', 'ppcV2SecondaryFields']),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    periodTitle() {
      return this.periodTable[this.tab].title[this.period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return PPC_V2_DATE_FORMATS[period];
    },
    fields() {
      return this.fieldsSources[this.ppcV2SecondaryFields()];
    },
    csvFileName() {
      return `PPC_V2_Strategic_Evolution_${capitalize(this.period)}.csv`;
    }
  },
  methods: {
    getDescriptionBasedOnKpiKey,
    getColorsBasedOnRange,
    getColorForDecile,
    getTargetingTypeSplitDecileColor,
    handleShowComposites(index) {
      const spendComposite = this.$refs[`spendComposite_${index}`];
      if (spendComposite) {
        spendComposite.setShowCurrencies();
      }
    },
    handleHideComposites(index) {
      const spendComposite = this.$refs[`spendComposite_${index}`];
      if (spendComposite) {
        spendComposite.setHideCurrencies();
      }
    },
    handleDateClick(period) {
      period = moment(period);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    findTargetingTypeValue(key, arr = []) {
      return arr.find(item => item.key === key)?.value ?? null;
    },
    findTargetingTypePercentage(key, arr = [], divisor) {
      const value = this.findTargetingTypeValue(key, arr);
      if (value === null) return null;
      return value / divisor;
    },
    findTargetingTypeMinMax(key, arr = []) {
      return arr.find(item => item.key === key)?.minMax ?? {
        min: 0,
        max: 0
      };
    },
    async downloadStrategicPeriodCSV() {
      const csv_headers = this.csvSources(this.period)[this.ppcV2SecondaryFields()];
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    },
    switchSpendSales(_) {
      /*
           When the user toggles, we need to re-run the `calculatePositionForCurrencies` for all composites
          to make sure the percentages are at the right places.
           Here, since the refs are in the form of `spendComposite_{0,N}`, we will have to filter for the refs
          containing this substring to be efficient.
           $Refs -> 
              Object.keys(refs) -> 
              filter (substring `spendComposite_`) -> 
              map (get referencedEl ) ->
              filter (clean nulls) -> 
              forEach apply calculateFxn
      */

      Object.keys(this.$refs).filter(el => el.includes("spendComposite_")).map(e => this.$refs[e]).filter(e => Boolean(e)).forEach(e => {
        'calculatePositionForCurrencies' in e && e.calculatePositionForCurrencies();
      });
    },
    spendSalesValues(row) {
      const rowField = this.spendSales ? row.item?.targeting_type_sponsored_sales : row.item?.targeting_type_spend;
      return [this.findTargetingTypeValue(TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, rowField), this.findTargetingTypeValue(TARGETING_TYPE_TYPES.NON_BRANDED_KWS, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, rowField), this.findTargetingTypeValue(TARGETING_TYPE_TYPES.BRANDED, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.NON_BRANDED_KWS, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, rowField), this.findTargetingTypeValue(TARGETING_TYPE_TYPES.BRANDED, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.NON_BRANDED_KWS, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, rowField) + this.findTargetingTypeValue(TARGETING_TYPE_TYPES.DISCOVERY, rowField)];
    }
  }
};
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { arraysAreTheSame, getCountriesMapping, isObjectEmpty } from "@/utils";
export default {
  name: "ContentMixin",
  computed: {
    getDefaultFilters() {
      const defaultFilters = [{
        title: "Sellers",
        titleName: "Sellers",
        showSearch: true,
        showIcon: false,
        searchPlaceholder: "Search Seller",
        values: this.sellersList,
        selected: this.currentSellers,
        handleSelect: this.handleSellerSelect,
        handleDeselect: this.handleSellerDeselect,
        handleSearch: this.handleSellerSearch,
        handleClear: this.clearSellerFilter,
        refreshTrigger: this.refreshContentsPage
      }, {
        title: "Country",
        titleName: "Country",
        values: this.countriesList,
        showSearch: false,
        showIcon: true,
        searchPlaceholder: "Search Country",
        selected: this.currentCountries,
        handleSelect: this.handleCountrySelect,
        handleDeselect: this.handleCountryDeselect,
        handleSearch: () => {},
        handleClear: this.clearCountryFilter,
        refreshTrigger: this.refreshContentsPage
      }, {
        title: "Product",
        titleName: "Product &bull; Sku &bull; Asin",
        values: this.contentsList,
        showSearch: true,
        showIcon: false,
        searchPlaceholder: "Search Product, Sku, Asin",
        selected: this.currentContents,
        handleSelect: this.handleContentSelect,
        handleDeselect: this.handleContentDeselect,
        handleSearch: this.handleContentSearch,
        handleClear: this.clearContentFilter,
        refreshTrigger: this.refreshContentsPage
      }];
      return defaultFilters;
    },
    filterMapping() {
      return {
        Sellers: {
          list: this.sellersList,
          setList: this.sellersListSet,
          selected: this.currentSellers,
          setSelected: this.sellersSelectedSet,
          refreshList: this.refreshSellers
        },
        Country: {
          list: this.countriesList,
          setList: this.countriesListSet,
          selected: this.currentCountries,
          setSelected: this.countriesSelectedSet,
          refreshList: this.refreshCountries
        },
        Products: {
          list: this.contentsList,
          setList: this.contentSuggestionsListSet,
          selected: this.currentContents,
          setSelected: this.contentsSelectedSet,
          refreshList: this.refreshContents
        }
      };
    },
    getTabFilters() {
      const defaultFilters = this.getDefaultFilters;
      const tabFilters = [...defaultFilters];
      return tabFilters;
    },
    contentFilterData() {
      return {
        default: this.fetchContentFilterData,
        push_mode: this.fetchSpecifiedContentFilterData,
        parent_view_mode: this.fetchContentFilterDataForParents
      };
    },
    contentFilterSuggestionData() {
      return {
        default: this.fetchContentFilterSuggestionsData,
        push_mode: this.fetchSpecifiedContentFilterSuggestionsData,
        parent_view_mode: this.fetchContentFilterSuggestionsData
      };
    },
    ...mapGetters(["workspaceCurrentOld", "getCountry", "getCountryFlag", "getCountryCode", "getCountry", "contentSellersListGet", "contentSellersSelectedGet", "contentCountriesListGet", "contentCountriesSelectedGet", "contentSuggestionsListGet", "contentSuggestionsSelectedGet", "contentFiltersSelectedGet", "modifiedContentsSelectedGet", "totalCountGet", "contentKeyWordsGet", "contentFiltersSuggestionsGet", "contentGetAllChildAsins", "contentGetParentMarketplaces"]),
    ...mapState({
      products: state => state.content.list
    })
  },
  methods: {
    ...mapMutations(["contentUpdateAfterPush", "contentFieldSet", "contentUpdateProcessingAmazonPushStatus", "contentSellersListSet", "contentSellersSelectedSet", "contentCountriesListSet", "contentCountriesSelectedSet", "contentSuggestionsListSet", "contentSuggestionsSelectedSet", "contentsListSet", "contentTotalCountSet", "contentsModifiedListSet", "contentsKeyWordListSet", "contentSetAllChildAsins", "contentsSellersFilterSet", "contentsMarketplacesFilterSet", "contentParentsMapSet"]),
    ...mapActions(["contentGetByContentId", "contentUpdate", "contentGetByFilters", "contentGetCountries", "authStatus", "productList", "getCountries", "contentPush", "contentModifiedList", "contentGetParents"]),
    handleFilterSelect(filterName, {
      item,
      only
    }) {
      const filter = this.filterMapping[filterName];
      if (!Array.isArray(item)) {
        item = [item];
      }
      const definedList = only ? (() => {
        this.filterMapping[filterName];
        filter.setList([...filter.list, ...filter.selected]);
        filter.setSelected([]);
        return [...filter.list];
      })() : filter.list;
      const definedListFiltered = definedList.filter(element => !item.some(current_item => element.id == current_item.id));
      filter.setList(definedListFiltered);
      filter.setSelected([...filter.selected, ...item]);
    },
    handleFilterDeselect(filterName, item) {
      const filter = this.filterMapping[filterName];
      let removedItems = [];
      if (item) {
        if (!Array.isArray(item)) {
          item = [item];
        }
        removedItems = filter.selected;
        item.forEach(innerItem => {
          const filteredSelected = filter.selected.filter(el => el.id !== innerItem.id);
          filter.setSelected(filteredSelected);
          filter.setList([...filter.list, innerItem]);
        });
      } else {
        removedItems = filter.selected;
        filter.setList([...filter.list, ...filter.selected]);
        filter.setSelected([]);
      }
      return removedItems;
    },
    resetPagingValues() {
      this.$emit("reset-paging-values");
    },
    refreshSellerDropDown(data) {
      let sellerListFilter = this.createSellerSuggestions(data);
      //sellerListFilter["A2AH6MERLA01FY"].name = "GetOmny";
      //this.contentSellersListSet(Object.values(sellerListFilter));
      this.sellersListSet(sellerListFilter.filter(seller => !this.currentSellers.some(current_seller => seller.seller_id == current_seller.seller_id)));
    },
    createSellerSuggestions(data) {
      let sellerListFilter = [];
      data.forEach(seller => {
        let found_seller_account = this.sellerAccounts.find(seller_account => seller_account.account_id === seller.seller_id || seller_account.account_id === seller.account_id);
        let singleContent = {
          id: found_seller_account.account_id,
          region: found_seller_account.region,
          name: found_seller_account.account_name || this.workspaceNameById(found_seller_account.workspace_id),
          seller_id: found_seller_account.account_id
        };
        sellerListFilter.push(singleContent);
      });
      return sellerListFilter;
    },
    refreshCountriesDropDown(data) {
      let filteredCountries = this.createCountrySuggestions(data);
      this.countriesListSet(filteredCountries.filter(country => !this.currentCountries.some(current_country => country.marketplace_id == current_country.marketplace_id)));
    },
    createCountrySuggestions(data) {
      let filteredCountries = this.countriesMapping.filter(countriesMapping => data.some(someData => someData.country_code === countriesMapping.code || someData.marketplace_id === countriesMapping.marketplace_id));
      return Object.values(filteredCountries);
    },
    refreshContentDropDown(data) {
      let contentListFilter = this.createContentSuggestions(data);
      this.contentSuggestionsListSet(contentListFilter.filter(content => !this.currentContents.some(current_content => content.content_id == current_content.content_id)));
    },
    createContentSuggestions(data) {
      let contentListFilter = [];
      data.forEach(content => {
        let singleContent = {
          id: `${content.standardized_product_name}-${content.sku}-${content.asin}`,
          product_name: content.standardized_product_name,
          seller_id: content.seller_id,
          sku: content.sku,
          asin: content.asin,
          marketplace_id: content.marketplace_id,
          main_image_link: content.standardized_main_image_link,
          content_id: content.content_id
        };
        contentListFilter.push(singleContent);
      });
      return contentListFilter;
    },
    async sellerSearch({
      value,
      refresh
    }) {
      let filter = this.filterMapping.Sellers;
      if (arraysAreTheSame(value, [])) {
        if (refresh) {
          this.resetPagingValues();
          filter.refreshList();
        }
        return;
      }
      filter.setList(this.filterMapping.Sellers.list.filter(item => value.some(aValue => item.name.toLowerCase().includes(aValue.toLowerCase()) || item.id.toLowerCase().includes(aValue.toLowerCase()))));
    },
    async contentSearch({
      value,
      refresh
    }) {
      //this.keyword_list = value;
      this.contentsKeyWordListSet(value);
      let filter = this.filterMapping.Products;
      this.resetPagingValues();
      if (arraysAreTheSame(value, [])) {
        if (refresh) {
          filter.refreshList();
        }
        return;
      }
      this.refreshContents();
    },
    async fetchContentFilterDataForParents() {
      let {
        mappedParents,
        total
      } = await this.contentGetParents({
        ...{
          page_limit: this.perPage,
          page_offset: this.page
        },
        ...this.contentFiltersSelectedGet()
      });
      mappedParents = mappedParents.map(([parent, child]) => {
        if (!parent?.is_parent_asin) parent = this.transformChildElement(parent);
        return [parent, child];
      });
      this.contentParentsMapSet(new Map(mappedParents));
      const pagingPayload = {
        ...{
          page_limit: 2000,
          page_offset: 1
        },
        ...this.contentFiltersSelectedGet(),
        ...this.contentGetAllChildAsins(),
        ...this.contentGetParentMarketplaces(),
        only_children: true
      };
      const result = await this.contentGetByFilters(pagingPayload);
      console.log("result", result);
      const data = this.transformProducts(result?.data ?? []);
      if (data.length >= 1) {
        data[0] = {
          ...data[0],
          total_count: total
        };
      }
      return {
        data,
        suggestions: result?.suggestions ?? []
      };
    },
    async fetchContentFilterData() {
      const pagingPayload = {
        ...{
          page_limit: this.perPage,
          page_offset: this.page
        },
        ...this.contentFiltersSelectedGet()
      };
      const result = await this.contentGetByFilters(pagingPayload);
      return result;
    },
    async fetchContentFilterDataToDownload(pageOffset, perPage) {
      const pagingPayload = {
        ...{
          page_limit: perPage,
          page_offset: pageOffset
        },
        ...this.contentFiltersSelectedGet()
      };
      const result = await this.contentGetByFilters(pagingPayload);
      return result.data;
    },
    async fetchContentFilterSuggestionsData() {
      const pagingPayload = {
        ...{
          page_limit: this.perPage,
          page_offset: this.page
        },
        ...this.contentFiltersSuggestionsGet()
      };
      const result = await this.contentGetByFilters(pagingPayload);
      return result;
    },
    async fetchSpecifiedContentFilterSuggestionsData() {
      const pagingPayload = {
        ...{
          page_limit: this.perPage,
          page_offset: this.page
        },
        ...{
          content_id_list: this.filtersSelectedForSpecificSearch()
        },
        ...{
          keyword_list: this.contentKeyWordsGet()
        }
      };
      const result = await this.contentGetByContentId(pagingPayload);
      return result;
    },
    async fetchSpecifiedContentFilterData() {
      const pagingPayload = {
        ...{
          page_limit: this.perPage,
          page_offset: this.page
        },
        ...{
          content_id_list: this.filtersSelectedForSpecificSearch()
        },
        ...{
          keyword_list: []
        }
      };
      const result = await this.contentGetByContentId(pagingPayload);
      return result;
    },
    async refreshSellers() {
      await this.initializeSellersFilter(this.contentsSellersFilterSet);
      await this.refreshCountries();
      await this.refreshContentsAndSuggestions();
    },
    async getFilterValuesForSellersFilter() {
      this.sellerAccounts = await this.getOmnyAccountSellers();
      if (this.sellerAccounts.length > 0) {
        return this.sellerAccounts;
      } else {
        this.errorOccured = true;
      }
    },
    async refreshCountries() {
      await this.initializeMarketplacesFilter(this.contentsMarketplacesFilterSet, true);
      this.marketplaces_filter._key = "marketplace_id";
    },
    async getFilterValuesForMarketPlacesFilter() {
      const countries_data = await this.contentGetCountries();
      this.countriesMapping = await getCountriesMapping();
      let filteredCountries = [];
      if (countries_data) {
        filteredCountries = this.createCountrySuggestions(countries_data);
      } else {
        this.errorOccured = true;
      }
      return filteredCountries;
    },
    async refreshContentsAndSuggestions() {
      this.$emit("data-loading", true);
      const content_data = await this.contentFilterData[this.$store.state.content.current_mode]();
      if (content_data) {
        let contentSuggestions = this.createContentSuggestions(content_data.suggestions);
        this.products_filter.initialize({
          list: contentSuggestions,
          selected: [],
          name: "seo_content_suggestions_filter",
          key: "content_id"
        });
        this.content_list = content_data.data;
        this.contentsListSet(this.content_list);
        this.contentTotalCountSet(this.content_list);
      } else {
        this.errorOccured = true;
      }
      this.$emit("data-loading", false);
    },
    async refreshSuggestions() {
      const content_data = await this.contentFilterSuggestionData[this.$store.state.content.current_mode]();
      if (content_data) {
        let contentSuggestions = this.createContentSuggestions(content_data.suggestions);
        this.products_filter.list = contentSuggestions;
      } else {
        this.errorOccured = true;
      }
    },
    async refreshContents() {
      this.$emit("data-loading", true);
      const content_data = await this.contentFilterData[this.$store.state.content.current_mode]();
      if (content_data) {
        this.content_list = content_data.data;
        this.contentsListSet(this.content_list);
        this.contentTotalCountSet(this.content_list);
      } else {
        this.errorOccured = true;
      }
      this.$emit("data-loading", false);
    },
    async refreshContentsPage() {
      await this.refreshCountries();
    },
    handleSellerSelect({
      item,
      only
    }) {
      this.handleFilterSelect("Sellers", {
        item,
        only
      });
    },
    async handleSellerSearch({
      value,
      refresh
    }) {
      await this.sellerSearch({
        value,
        refresh
      });
    },
    async handleSellerDeselect(value) {
      const sellers = this.handleFilterDeselect("Sellers", value);
      this.resetPagingValues();
      await this.filterMapping.Sellers.refreshList();
      this.refreshSelectedSellers(sellers);
    },
    async clearSellerFilter() {
      await this.handleSellerDeselect(null);
    },
    handleCountrySelect({
      item,
      only
    }) {
      this.handleFilterSelect("Country", {
        item,
        only
      });
    },
    handleCountryDeselect(value) {
      this.handleFilterDeselect("Country", value);
      this.resetPagingValues();
      //this.filterMapping.Products.refreshList();
    },
    clearCountryFilter() {
      this.handleCountryDeselect(null);
    },
    handleContentSelect({
      item,
      only
    }) {
      this.handleFilterSelect("Products", {
        item,
        only
      });
    },
    handleContentDeselect(value) {
      this.handleFilterDeselect("Products", value);
      this.resetPagingValues();
    },
    async handleContentSearch({
      value,
      refresh
    }) {
      await this.contentSearch({
        value,
        refresh
      });
    },
    clearContentFilter() {
      this.handleContentDeselect(null);
    },
    async clearAllFilters() {
      //this.toggleSelectAll(false);
      this.products_filter.clear();
      this.marketplaces_filter.clear();
      this.sellers_filter.clear();
      this.contentsKeyWordListSet([]);
    },
    refreshSelectedSellers(sellers) {
      sellers = sellers.map(seller => seller.id);
      const removeSellerFromSelected = (sellers, selected) => {
        return selected.filter(item => {
          return !sellers.includes(item.seller_id);
        });
      };
      this.contentsSelectedSet(removeSellerFromSelected(sellers, this.currentContents));
    },
    async onAmazonPush() {
      this.$emit("data-loading", true);
      await this.contentModifiedList({
        seller_list: this.sellersList
      });
      if (this.modified_product_contents?.length > 0) {
        this.contentCurrentModeSet("push_mode");
        await this.clearAllFilters();
        await this.refreshContents();
        this.contentPushModalStatusSet(true);
        this.$emit("data-loading", false);
        return;
      }
      window.$bus.trigger("showWarning", {
        visible: true,
        message: "There is no Modified content to push"
      });
      this.$emit("data-loading", false);
    },
    async onPushModalSubmit(content_to_push) {
      this.$emit("data-loading", true);
      const pushed_content_list = content_to_push.map(item => item.content_id);
      let payload = {
        content_list: pushed_content_list,
        task_type: "content_push",
        workspace_id: this.current_workspace.workspace_id
      };
      pushed_content_list.forEach(async content_id => {
        const item = this.filteredItems.find(item => item.content_id === content_id);
        const originalItem = this.products.find(el => el.content_id == item.content_id);
        originalItem.push_status = "processing";
        originalItem.last_push_to_amazon_time = new Date().toISOString();
        await this.contentUpdate({
          content_id: item.content_id
        });
      });
      this.refreshContents();
      const result = await this.contentPush(payload);
      if (result) {
        this.onPushModalCancel();
        this.executeTaskStatusHandler(result);
        this.checkTaskStatusPeriodically(this.pushTaskIdentifier);
      }
      this.pushModalActiveButton = false;
      this.pushModalSpinButton = true;
      this.$emit("data-loading", false);
    },
    async onPushModalCancel() {
      this.$emit("data-loading", true);
      this.contentCurrentModeSet("default");
      this.contentPushModalStatusSet(false);
      this.singleContentToPush = {};
      this.confirmPushSingleContent = false;
      this.clearAllFilters();
      await this.refreshContents();
      this.$emit("data-loading", false);
    },
    onConfirmPushSingleContent(item) {
      this.singleContentToPush = item;
      this.confirmPushSingleContent = true;
    },
    statusValidate(item, version) {
      const result = [];
      const versionData = key => {
        return version === "amazon" ? item.amazon_version[key] : this.contentToDisplay(item, key);
      };
      const title = versionData("product_name");
      result.push(this.mValidateTitle(title));
      const description = versionData("product_description");
      result.push(this.mValidateDescription(description));
      const keywords = versionData("generic_keyword");
      result.push(this.mValidateKeyword(keywords));
      const bullet_points = [versionData("bullet_point_1"), versionData("bullet_point_2"), versionData("bullet_point_3"), versionData("bullet_point_4"), versionData("bullet_point_5")];
      const result_bullet_points = this.statusValidateBulletPoints(bullet_points);
      const image = item.image_large;
      result.push(this.mValidateImage(image));
      const numberOfImages = item.count_images;
      result.push(this.mValidateNumberOfImage(numberOfImages));
      return [...result, ...result_bullet_points];
    },
    statusValidateBulletPoints(bullet_points) {
      const result = [];
      result.push(this.mValidateBulletPointsEmpty(bullet_points));
      bullet_points.forEach((bullet_point, index) => {
        result.push(this.mValidateBulletPointsLength(bullet_point, index + 1));
      });
      return result;
    },
    getVersionKeys() {
      //const versionKeys = ['amazon_version', 'amazon_seller_central_version','omny_version'];
      const versionKeys = ["amazon_version"];
      return versionKeys;
    },
    getVersionName(key) {
      const versionDict = {
        amazon_version: "Amazon version",
        amazon_seller_central_version: "Amazon seller central version",
        omny_version: "Omny version"
      };
      return versionDict[key];
    },
    getHeadersforMappingContentDataCSV() {
      let headersMapping = {
        seller_id: "seller_id",
        seller_name: "seller_name",
        marketplace_id: "marketplace_id",
        country_code: "country_code",
        sku: "sku",
        asin: "asin",
        image_small: "image_small",
        listing_status: "listing_status",
        count_images: "count_images",
        brand: "brand",
        category_id: "category_id",
        category_name: "category_name",
        product_type: "product_type",
        product_name: "product_name",
        generic_keyword: "generic_keyword",
        product_description: "product_description",
        bullet_point_1: "bullet_point_1",
        bullet_point_2: "bullet_point_2",
        bullet_point_3: "bullet_point_3",
        bullet_point_4: "bullet_point_4",
        bullet_point_5: "bullet_point_5",
        push_status: "push_status",
        last_push_date: "last_push_date"
      };
      return this.extractHeadersContentDataCSV(headersMapping);
    },
    getContentAttributesCSV() {
      let contentAttributes = {
        product_name: "product_name",
        generic_keyword: "generic_keyword",
        product_description: "product_description",
        bullet_point_1: "bullet_point_1",
        bullet_point_2: "bullet_point_2",
        bullet_point_3: "bullet_point_3",
        bullet_point_4: "bullet_point_4",
        bullet_point_5: "bullet_point_5"
      };
      return contentAttributes;
    },
    extractHeadersContentDataCSV(headersMapping) {
      // Ensure data is valid and not empty
      return Object.fromEntries(new Map(
      //Object.entries(headersMapping).map((fieldName) => [fieldName, fieldName])
      Object.entries(headersMapping).map(([key, value]) => [key, value])));
    },
    throwIfError(result) {
      if (result?.status == "error" || isObjectEmpty(result) || !result) {
        throw result;
      }
    },
    generateFileName() {
      let filters = this.contentFiltersSelectedGet();
      let flattenedValues = Object.keys(filters).reduce((acc, key) => {
        if (filters[key].length > 0) {
          let valuesString = filters[key].join("_");
          acc.push(valuesString);
        }
        return acc;
      }, []).join("_");

      //keep it because if wee see longerfile name issue, we should activate this condition.
      // if (flattenedValues.length > 100) {
      //   const hash = crypto.createHash('sha256');
      //   hash.update(flattenedValues);
      //   flattenedValues = hash.digest('hex').substr(0, 20); // Shorten the hash to 20 characters
      // }
      return flattenedValues;
    }
  }
};
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "edit-text-modal-title"
};
const _hoisted_2 = {
  class: "edit-text-modal-top"
};
const _hoisted_3 = {
  class: "image-box"
};
const _hoisted_4 = {
  class: "text-box"
};
const _hoisted_5 = {
  class: "sku-and-asin"
};
const _hoisted_6 = {
  class: "product-name"
};
const _hoisted_7 = {
  class: "button-box"
};
const _hoisted_8 = {
  class: "edit-text-modal-body"
};
const _hoisted_9 = {
  class: "edit-text-modal-bottom"
};
const _hoisted_10 = {
  class: "button-box"
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_img = _resolveComponent("b-img");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_edit_text_modal_content = _resolveComponent("edit-text-modal-content");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createBlock(_component_b_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $options.modal = $event),
    "hide-footer": "",
    "hide-header": "",
    "no-close-on-backdrop": "",
    "no-fade": "",
    class: "edit-text-modal"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_img, {
      src: $props.image,
      alt: "Image",
      class: "custom-img"
    }, null, 8 /* PROPS */, ["src"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($props.asin) + " - " + _toDisplayString($props.sku), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, _toDisplayString($props.name), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
      class: "cancel-btn",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.onCancel && $options.onCancel(...args))
    }, "Cancel"), _createElementVNode("div", {
      class: "save-btn",
      onClick: _cache[1] || (_cache[1] = (...args) => $options.onSubmit && $options.onSubmit(...args))
    }, [_createVNode(_component_app_icon, {
      icon: "save"
    }), _createTextVNode(" Save ")])])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_edit_text_modal_content, {
      modelValue: $options.valueModel,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.valueModel = $event),
      type: "omny",
      content_type: $props.type,
      allowHtmlInputAndSpecialCharacters: $props.allowHtmlInputAndSpecialCharacters,
      character_range: $options.characterRangePerContentType,
      translation: $data.omny_translation,
      show_translation: $data.show_translation,
      show_comparism: $data.show_comparism,
      comparism: $data.omny_text_with_diffs,
      apply_keywords_loading: $data.apply_keywords_loading,
      onHide_comparism: _cache[3] || (_cache[3] = $event => $data.show_comparism = false),
      onApplyKeywords: $options.onApplyKeywords
    }, null, 8 /* PROPS */, ["modelValue", "content_type", "allowHtmlInputAndSpecialCharacters", "character_range", "translation", "show_translation", "show_comparism", "comparism", "apply_keywords_loading", "onApplyKeywords"]), _createVNode(_component_edit_text_modal_content, {
      type: "amazon",
      content_type: $props.type,
      original: $props.original,
      allowHtmlInputAndSpecialCharacters: $props.allowHtmlInputAndSpecialCharacters,
      character_range: $options.characterRangePerContentType,
      translation: $data.amazon_translation,
      show_translation: $data.show_translation,
      show_comparism: $data.show_comparism,
      comparism: $data.amazon_text_with_diffs
    }, null, 8 /* PROPS */, ["content_type", "original", "allowHtmlInputAndSpecialCharacters", "character_range", "translation", "show_translation", "show_comparism", "comparism"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
      class: _normalizeClass(['compare-btn', {
        show_comparism: $data.show_comparism
      }, {
        comparison_loading: $data.comparison_loading
      }]),
      onClick: _cache[4] || (_cache[4] = $event => $options.computeComparism())
    }, [!$data.comparison_loading ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString($data.show_comparism ? 'Hide Comparison' : 'Show Comparison'), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.comparison_loading ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 1,
      icon: "loading",
      size: "16",
      color: "#736BC9",
      class: "icon-spin"
    })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(['translate-btn', {
        show_translation: $data.show_translation
      }, {
        translation_loading: $data.translation_loading
      }]),
      onClick: _cache[5] || (_cache[5] = $event => $options.computeTranslations())
    }, [!$data.translation_loading ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($data.show_translation ? 'Hide Translation' : 'Show Translation'), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.translation_loading ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 1,
      icon: "loading",
      size: "16",
      color: "#736BC9",
      class: "icon-spin"
    })) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]);
}
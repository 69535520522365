import AppTextarea from "@/components/AppTextarea";
export default {
  name: "EditTextField",
  emits: ["update:modelValue", "change"],
  components: {
    AppTextarea
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    minimun_characters: {
      type: Number,
      default: 0
    },
    maximum_characters: {
      type: Number,
      default: 0
    },
    bar: {
      type: String,
      default: "top"
    },
    type: {
      type: String,
      default: 0
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.$emit("change", val);
      }
    },
    characterCountInfo() {
      return this.getCharacterCountInfo();
    }
  },
  methods: {
    getCharacterCountInfo() {
      const valueLength = this.value.length;
      const isProductType = this.type === 'product_name' || this.type === 'generic_keyword';
      const maxCharacters = isProductType ? ` / ${this.maximum_characters}` : '';
      const plural = valueLength > 2 ? 's' : '';
      return `${valueLength}${maxCharacters} character${plural}`;
    }
  }
};
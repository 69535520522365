import { request } from "@/store";
export const getAggregateTableData = async ({
  params
} = {}) => {
  try {
    // Check if `seller_id` is provided
    if (!('seller_id' in params)) throw new Error("Sellers are required. No key `seller_id` found in the request.");
    if (!params?.seller_id.length) throw new Error("Sellers are required. No value found for `seller_id` in the request.");
    let response = await request("/profit_and_loss/aggregate", "POST", params, false, true, "cors");
    if (!response) {
      return [];
    }

    // Find `Quantity` and `Net Revenue` in the response
    const quantityIndex = response.findIndex(item => item.pl_aggregate === 'Quantity');
    const netRevenueIndex = response.findIndex(item => item.pl_aggregate === 'Net Revenue');

    // Check if both `Quantity` and `Net Revenue` exist in the response
    if (quantityIndex === -1 || netRevenueIndex === -1) {
      return response;
    }

    // Extract `Quantity` and `Net Revenue` objects from the response
    const quantity = response[quantityIndex];
    const netRevenue = response[netRevenueIndex];

    // Remove the `details` key from `Quantity` to avoid duplication
    delete quantity.details;

    // Create a new detail object for `Net Revenue` using `Quantity`
    const netRevenueDetail = {
      ...quantity,
      pl_aggregate: 'Net Revenue',
      pl_detail: 'Quantity',
      events: []
    };

    // Update `Net Revenue` with the new detail
    netRevenue.details = [netRevenueDetail];

    // Replace `Net Revenue` in the original response and remove `Quantity`
    response[netRevenueIndex] = netRevenue;
    response.splice(quantityIndex, 1);
    return response;
  } catch (err) {
    console.log(err);
  }
};
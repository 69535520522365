import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5667002b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ba-page"
};
const _hoisted_2 = {
  class: "ba-pagination-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_pagination = _resolveComponent("app-pagination");
  const _component_ppc_budget_allocation = _resolveComponent("ppc-budget-allocation");
  const _component_app_card_custom = _resolveComponent("app-card-custom");
  return _openBlock(), _createBlock(_component_app_card_custom, {
    "no-padding": ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createCommentVNode("div class=\"ba-section\">\n        <app-icon icon=\"building\" />\n\n        <div class=\"ba-section-text\">\n          <div class=\"ba-section-title\">\n            Latest Budget Allocation per Product\n          </div>\n\n          <div class=\"ba-section-subtitle\">\n            Review the latest budget allocation results below and adjust as\n            needed.\n          </div>\n        </div>\n      </div"), _createElementVNode("div", _hoisted_2, [_createVNode(_component_ppc_budget_allocation, {
      items: _ctx.product_prioritization,
      onItemUpdate: $options.onItemUpdate
    }, {
      pagination: _withCtx(() => [_createVNode(_component_app_pagination, {
        page: $data.page,
        "onUpdate:page": [_cache[0] || (_cache[0] = $event => $data.page = $event), _cache[2] || (_cache[2] = $event => $options.changePage($event))],
        perPage: $data.perPage,
        "onUpdate:perPage": [_cache[1] || (_cache[1] = $event => $data.perPage = $event), _cache[3] || (_cache[3] = $event => $options.changeElements($event))],
        total: _ctx.total_rows,
        style: {
          "padding": "8px 12px"
        }
      }, null, 8 /* PROPS */, ["page", "perPage", "total"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["items", "onItemUpdate"])])])]),
    _: 1 /* STABLE */
  });
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 12 11`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M9.2854 9.5C9.9164 9.5 10.4284 8.9885 10.4284 8.357V6.0715L10.9999 5.5L10.4284 4.9285V2.643C10.4284 \n          2.0115 9.9169 1.5 9.2854 1.5M2.7145 1.5C2.083 1.5 1.5715 2.0115 1.5715 2.643V4.9285L1 5.5L1.5715 6.0715V8.357C1.5715 \n          8.9885 2.083 9.5 2.7145 9.5M6 3.5V7.5M4 5.5H8",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}
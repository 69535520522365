import LoadingElement from "@/components/shared/LoadingElement.vue";
export default {
  name: "GroupBy",
  components: {
    LoadingElement
  },
  emits: ["update:modelValue", "clicked"],
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    type: {
      type: String
    },
    tabBusy: {
      type: Boolean
    },
    disabledTabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: true,
      setup: false
    };
  },
  mounted() {
    // If it's remounted there is `tabs` already in, then switch the loader to false.
    if (this.tabs.length > 0) {
      this.loading = false;
    }
  },
  computed: {
    groupBy: {
      get() {
        return this.modelValue;
      },
      set(val) {
        if (this.tabBusy) {
          return;
        }
        console.log('[perf] set - GroupBy', 'called', val);
        if (this.setup) {
          this.$emit("update:modelValue", val);
        } else {
          this.setup = true;
        }
      }
    }
  },
  watch: {
    tabs(newVal) {
      //console.log("newVal", newVal);
      if (newVal.length > 0) {
        this.loading = false;
      }
    }
  },
  methods: {
    handleClick(e) {
      if (this.tabBusy) {
        return;
      }
      console.log('[perf] handleClick - GroupBy', 'called');
      this.$emit('clicked', e);
    }
  }
};
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createBlock(_component_b_button, {
    type: $props.type,
    variant: $props.variant,
    class: _normalizeClass(['button', {
      withMinWidth: !$props.noMinWidth,
      v2: $props.v2,
      small: $props.small,
      active: $props.active
    }, $props.version]),
    disabled: $props.disabled
  }, {
    default: _withCtx(() => [$props.loading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      small: ""
    })) : _renderSlot(_ctx.$slots, "default", {
      key: 1
    }, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["type", "variant", "class", "disabled"]);
}
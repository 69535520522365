import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seo_parent_product_cell = _resolveComponent("seo-parent-product-cell");
  const _component_b_td = _resolveComponent("b-td");
  const _component_b_tr = _resolveComponent("b-tr");
  return _openBlock(), _createBlock(_component_b_tr, {
    class: "parent-seo-row seo-row"
  }, {
    default: _withCtx(() => [_createVNode(_component_seo_parent_product_cell, {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('handleToggle')),
      parent: $props.parent,
      collapsed: $props.collapsed,
      childrenSize: $props.childrenSize
    }, null, 8 /* PROPS */, ["parent", "collapsed", "childrenSize"]), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, i => {
      return _createVNode(_component_b_td, {
        key: i
      });
    }), 64 /* STABLE_FRAGMENT */)), _createVNode(_component_b_td, {
      class: "b-table-sticky-column table-b-table-default custom-td border-table-row stick-to-right",
      style: {
        right: '147px'
      }
    }), _createVNode(_component_b_td, {
      class: "b-table-sticky-column table-b-table-default custom-td border-table-row stick-to-right",
      style: {
        right: '115px'
      }
    }), _createVNode(_component_b_td, {
      class: "b-table-sticky-column table-b-table-default custom-td border-table-row stick-to-right",
      style: {
        right: '36px'
      }
    }), _createVNode(_component_b_td, {
      class: "b-table-sticky-column table-b-table-default custom-td border-table-row stick-to-right",
      style: {
        width: '36px'
      }
    })]),
    _: 1 /* STABLE */
  });
}
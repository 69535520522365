import { mapActions, mapState, mapMutations } from "vuex";
import { EVENTS } from "@/utils";
import AppDropdown from "@/components/AppDropdown";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import FlexRow from "@/components/FlexRow.vue";
import SellerFilter from "@/components/new-filters/SellerFilter.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import SheetIcon from "@/components/icons/SheetIcon.vue";
import CSVIcon from "@/components/icons/CSVIcon.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
export default {
  name: "DemandForecastingTop",
  mixins: [AccountsMixin],
  emits: ["create-inventory-order", "generate-google-sheet"],
  components: {
    AppDropdown,
    AppButton,
    AppIcon,
    FlexRow,
    SellerFilter,
    SheetIcon,
    CSVIcon,
    AppDropdownItem
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: "omny",
      options: [{
        value: "omny",
        text: "Omny"
      }],
      sellers: [],
      options: [{
        value: "imperial",
        text: "Imperial"
      }, {
        value: "metric",
        text: "Metric"
      }],
      order_options: [{
        value: "csv",
        text: "Raw CSV",
        sub_category: 'unprocessed',
        icon: "CSVIcon"
      }, {
        value: "box_level",
        text: "Box level fulfillment template",
        sub_category: 'intermediate',
        icon: "SheetIcon"
      }, {
        value: "pick_and_pack",
        text: "Pick and Pack fulfillment template",
        sub_category: 'intermediate',
        icon: "SheetIcon"
      }
      // { value: "csv", text: "Amazon template", sub_category: 'processed', icon: "CSVIcon"}
      ],
      order_options_categories: ["unprocessed", "intermediate"]
    };
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.demand_forecasting.sellers_filter,
      itemScaleType: state => state.workspace.scaleType
    })
  },
  methods: {
    ...mapMutations(["demandForecastingSellersFilterSet"]),
    ...mapActions(["inventoryCreateOrderCsv"]),
    async applySellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_PAGE);
      }
    },
    async clearSellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.clear();
        this.sellers_filter.selectOnly(this.sellers_filter.filterItems.slice(-1).pop(), "id");
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_PAGE);
      }
    },
    async onCreateInventoryOrder() {
      window.$bus.trigger("showSuccess", {
        message: "Creating Order csv for download ...  ",
        visible: true,
        delay: 10
      });
      this.$emit("create-inventory-order");
    },
    async handleScaleTypeChange(newScaleType) {
      console.log("[selected scale option]:", newScaleType);
      await window.$bus.trigger(EVENTS.CHANGE_PRODUCT_DATA_SCALE, newScaleType);
    },
    async handleOrder(order_option) {
      if (order_option == "csv") {
        this.onCreateInventoryOrder();
        return;
      }
      window.$bus.trigger("showSuccess", {
        message: "Generating Google Sheet ...  ",
        visible: true,
        delay: 10
      });
      this.$emit("generate-google-sheet", order_option);
    }
  }
};
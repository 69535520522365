import { CampaignFilter, MatchTypeFilter, TargetingFilter } from '@/components/new-filters';
import FlexRow from '@/components/FlexRow.vue';
import { EVENTS } from '@/utils';
import { mapState } from "vuex";
export default {
  name: 'PpcV2MoreFilters',
  components: {
    CampaignFilter,
    MatchTypeFilter,
    TargetingFilter,
    FlexRow
  },
  expose: ['hasAnySelection'],
  computed: {
    ...mapState({
      sellers_filter: state => state.ppc_v2.sellers_filter,
      marketplaces_filter: state => state.ppc_v2.marketplaces_filter,
      campaigns: state => state.ppc_v2.campaigns,
      match_types: state => state.ppc_v2.match_types,
      targetings: state => state.ppc_v2.targetings
    })
  },
  methods: {
    applyMatchTypes() {
      if (this.match_types.isModified) {
        this.match_types.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCampaigns() {
      if (this.campaigns.isModified) {
        this.campaigns.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyTargetings() {
      if (this.targetings.isModified) {
        this.targetings.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    }
  }
};
import FilterItem from "./FilterItem.vue";
import CurrencyFilterBody from "./bodies/CurrencyFilterBody.vue";
import { truncateString } from "@/utils";
export default {
  name: "CurrencyFilter",
  components: {
    FilterItem,
    CurrencyFilterBody
  },
  emits: ["apply", "cleared"],
  props: {
    currencies: {
      type: Object,
      required: true,
      default: () => ({
        filterState: {
          selected: [],
          list: []
        },
        isModified: false,
        select: () => {},
        deselect: () => {},
        clear: () => {},
        apply: () => {},
        listSearch: () => {}
      })
    },
    onlyMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCurrencyTitle() {
      let joinedCurrencies = this.currencies.selected?.map(({
        currency
      }) => currency).join(', ');
      joinedCurrencies = joinedCurrencies ? truncateString(joinedCurrencies, 20) : 'Currency';
      return joinedCurrencies;
    }
  }
};
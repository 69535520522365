import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seo_check_box_cell = _resolveComponent("seo-check-box-cell");
  const _component_seo_product_cell = _resolveComponent("seo-product-cell");
  const _component_seo_images_count_cell = _resolveComponent("seo-images-count-cell");
  const _component_seo_bullet_point_cell = _resolveComponent("seo-bullet-point-cell");
  const _component_seo_search_terms_cell = _resolveComponent("seo-search-terms-cell");
  const _component_seo_description_cell = _resolveComponent("seo-description-cell");
  const _component_seo_image_cell = _resolveComponent("seo-image-cell");
  const _component_seo_category_cell = _resolveComponent("seo-category-cell");
  const _component_seo_a_plus_cell = _resolveComponent("seo-a-plus-cell");
  const _component_seo_brand_cell = _resolveComponent("seo-brand-cell");
  const _component_seo_amazon_score_cell = _resolveComponent("seo-amazon-score-cell");
  const _component_seo_omny_score_cell = _resolveComponent("seo-omny-score-cell");
  const _component_seo_push_status_cell = _resolveComponent("seo-push-status-cell");
  const _component_seo_pencil_edit_cell = _resolveComponent("seo-pencil-edit-cell");
  const _component_b_tr = _resolveComponent("b-tr");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tableItems, (item, index) => {
    return _openBlock(), _createBlock(_component_b_tr, {
      key: index,
      class: "seo-row"
    }, {
      default: _withCtx(() => [$props.pushModal ? (_openBlock(), _createBlock(_component_seo_check_box_cell, {
        key: 0,
        item: item,
        onSelectItem: _cache[0] || (_cache[0] = val => _ctx.$emit('selectItem', val)),
        onIsSelectedItem: _cache[1] || (_cache[1] = val => _ctx.$emit('isSelectedItem', val))
      }, null, 8 /* PROPS */, ["item"])) : _createCommentVNode("v-if", true), _createVNode(_component_seo_product_cell, {
        onOnEdit: _cache[2] || (_cache[2] = val => _ctx.$emit('onEdit', val)),
        item: item,
        pushModal: $props.pushModal,
        showPopover: $props.showPopover,
        onOnCopyingHover: _cache[3] || (_cache[3] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[4] || (_cache[4] = val => _ctx.$emit('onCopyingHoverLeave', val))
      }, null, 8 /* PROPS */, ["item", "pushModal", "showPopover"]), _createVNode(_component_seo_images_count_cell, {
        item: item
      }, null, 8 /* PROPS */, ["item"]), _createVNode(_component_seo_bullet_point_cell, {
        bullet_point: {
          text: item.bullet_point_1,
          ui_style: item.bullet_point_1_style,
          icon: item.bullet_point_1_icon,
          id: `${item.content_id}-bullet-point-1`,
          type: 'bullet_point_1',
          title: 'Bullet Point #1'
        },
        item: item,
        onOnEdit: _cache[5] || (_cache[5] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[6] || (_cache[6] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[7] || (_cache[7] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[8] || (_cache[8] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying,
        onOnPaste: _cache[9] || (_cache[9] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["bullet_point", "item", "isCopying", "showPopover"]), _createVNode(_component_seo_bullet_point_cell, {
        bullet_point: {
          text: item.bullet_point_2,
          ui_style: item.bullet_point_2_style,
          icon: item.bullet_point_2_icon,
          id: `${item.content_id}-bullet-point-2`,
          type: 'bullet_point_2',
          title: 'Bullet Point #2'
        },
        item: item,
        onOnEdit: _cache[10] || (_cache[10] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[11] || (_cache[11] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[12] || (_cache[12] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[13] || (_cache[13] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying,
        onOnPaste: _cache[14] || (_cache[14] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["bullet_point", "item", "isCopying", "showPopover"]), _createVNode(_component_seo_bullet_point_cell, {
        bullet_point: {
          text: item.bullet_point_3,
          ui_style: item.bullet_point_3_style,
          icon: item.bullet_point_3_icon,
          id: `${item.content_id}-bullet-point-3`,
          type: 'bullet_point_3',
          title: 'Bullet Point #3'
        },
        item: item,
        onOnEdit: _cache[15] || (_cache[15] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[16] || (_cache[16] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[17] || (_cache[17] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[18] || (_cache[18] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying,
        onOnPaste: _cache[19] || (_cache[19] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["bullet_point", "item", "isCopying", "showPopover"]), _createVNode(_component_seo_bullet_point_cell, {
        bullet_point: {
          text: item.bullet_point_4,
          ui_style: item.bullet_point_4_style,
          icon: item.bullet_point_4_icon,
          id: `${item.content_id}-bullet-point-4`,
          type: 'bullet_point_4',
          title: 'Bullet Point #4'
        },
        item: item,
        onOnEdit: _cache[20] || (_cache[20] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[21] || (_cache[21] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[22] || (_cache[22] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[23] || (_cache[23] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying,
        onOnPaste: _cache[24] || (_cache[24] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["bullet_point", "item", "isCopying", "showPopover"]), _createVNode(_component_seo_bullet_point_cell, {
        bullet_point: {
          text: item.bullet_point_5,
          ui_style: item.bullet_point_5_style,
          icon: item.bullet_point_5_icon,
          id: `${item.content_id}-bullet-point-5`,
          type: 'bullet_point_5',
          title: 'Bullet Point #5'
        },
        item: item,
        onOnEdit: _cache[25] || (_cache[25] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[26] || (_cache[26] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[27] || (_cache[27] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[28] || (_cache[28] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying,
        onOnPaste: _cache[29] || (_cache[29] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["bullet_point", "item", "isCopying", "showPopover"]), _createVNode(_component_seo_search_terms_cell, {
        item: item,
        isCopying: $props.isCopying,
        onOnEdit: _cache[30] || (_cache[30] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[31] || (_cache[31] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[32] || (_cache[32] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[33] || (_cache[33] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        onOnPaste: _cache[34] || (_cache[34] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["item", "isCopying", "showPopover"]), _createVNode(_component_seo_description_cell, {
        item: item,
        isCopying: $props.isCopying,
        onOnEdit: _cache[35] || (_cache[35] = val => _ctx.$emit('onEdit', val)),
        onIsCopied: _cache[36] || (_cache[36] = val => _ctx.$emit('isCopied', val)),
        onOnCopyingHover: _cache[37] || (_cache[37] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[38] || (_cache[38] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        onOnPaste: _cache[39] || (_cache[39] = val => _ctx.$emit('onPaste', val)),
        showPopover: $props.showPopover
      }, null, 8 /* PROPS */, ["item", "isCopying", "showPopover"]), _createVNode(_component_seo_image_cell, {
        imagesList: item.image_list,
        isCollapsed: $props.isImageCellCollapsed,
        pushModal: $props.pushModal,
        onExpandImageList: _cache[40] || (_cache[40] = $event => _ctx.$emit('expandImageList')),
        onOnCopyingHoverLeave: _cache[41] || (_cache[41] = val => _ctx.$emit('onCopyingHoverLeave', val)),
        isCopying: $props.isCopying
      }, null, 8 /* PROPS */, ["imagesList", "isCollapsed", "pushModal", "isCopying"]), _createVNode(_component_seo_category_cell, {
        item: item
      }, null, 8 /* PROPS */, ["item"]), _createVNode(_component_seo_a_plus_cell, {
        item: item
      }, null, 8 /* PROPS */, ["item"]), _createVNode(_component_seo_brand_cell, {
        item: item
      }, null, 8 /* PROPS */, ["item"]), _createVNode(_component_seo_amazon_score_cell, {
        item: item,
        pushModal: $props.pushModal
      }, null, 8 /* PROPS */, ["item", "pushModal"]), _createVNode(_component_seo_omny_score_cell, {
        item: item,
        pushModal: $props.pushModal
      }, null, 8 /* PROPS */, ["item", "pushModal"]), _createVNode(_component_seo_push_status_cell, {
        item: item,
        pushModal: $props.pushModal,
        onOnConfirmPushSingleContent: _cache[42] || (_cache[42] = val => _ctx.$emit('onConfirmPushSingleContent', val)),
        showPopover: $props.showPopover,
        onOnCopyingHover: _cache[43] || (_cache[43] = val => _ctx.$emit('onCopyingHover', val)),
        onOnCopyingHoverLeave: _cache[44] || (_cache[44] = val => _ctx.$emit('onCopyingHoverLeave', val))
      }, null, 8 /* PROPS */, ["item", "pushModal", "showPopover"]), !$props.pushModal ? (_openBlock(), _createBlock(_component_seo_pencil_edit_cell, {
        key: 1,
        item: item,
        onOnEditSingleItem: _cache[45] || (_cache[45] = val => _ctx.$emit('onEditSingleItem', val))
      }, null, 8 /* PROPS */, ["item"])) : _createCommentVNode("v-if", true)]),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */);
  }), 128 /* KEYED_FRAGMENT */);
}
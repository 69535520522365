import AppTd from "@/components/AppTd";
import SeoParentProductCell from "@/components/seo/SeoParentProductCell.vue";
export default {
  name: 'SeoRow',
  components: {
    AppTd,
    SeoParentProductCell
  },
  emits: ['handleToggle'],
  props: {
    parent: Object,
    collapsed: Boolean,
    childrenSize: Number
  }
};
import { DottedValue, DateValue, CurrencyValue, PercentageValue, BoxedValue, BarWithValue, ValueElement, NumberValue, PeriodTableGroupBy, WrapWithArrow } from "@/components/ppc";
import CompoundTable from "@/components/CompoundTable.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { DEFAULT_DATE_FORMATS, PERIOD_COLUMN_TITLES } from "@/utils/constants";
import LoadingElement from "@/components/shared/LoadingElement.vue";
import { OverlapBarValue } from "@/components/tableElements";
import { HugValue } from ".";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import AppIcon from "@/components/AppIcon.vue";
import { EVENTS, KPI_DIRECTION_TYPE } from "@/utils/constants";
import { generateColorBasedOnState, getColorsBasedOnRange, getColorForDecile, getDescriptionBasedOnKpiKey } from "@/utils/transformers";
import { mapState, mapGetters } from "vuex";
import FlexRow from '@/components/FlexRow.vue';
import AppIconGroup from "@/components/AppIconGroup";
import DownloadMixin from "@/mixins/DownloadMixin";
import { PERIOD_FIELD, TACOS_FIELD, SPONSORED_ASIN_FIELD, SPEND_FIELD, SPONSORED_SALES_FIELD, CLICKS_FIELD, CPC_FIELD, BAR_ROAS_FIELD, UNITS_SOLD_FIELD, CVR_FIELD } from "@/utils/table_fields";
import { capitalize } from "vue";
export default {
  name: "PeriodTable",
  components: {
    CompoundTable,
    DottedValue,
    DateValue,
    CurrencyValue,
    PercentageValue,
    BoxedValue,
    ValueElement,
    NumberValue,
    BarWithValue,
    WrapWithPopover,
    WrapWithArrow,
    PeriodTableGroupBy,
    LoadingElement,
    OverlapBarValue,
    HugValue,
    AppIcon,
    FlexRow,
    AppIconGroup
  },
  emits: ["refresh", "setDateRange"],
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    periodTitle: {
      type: String
    },
    items: {
      type: Array
    },
    minMax: {
      type: Object
    },
    thresholds: {
      type: Function
    },
    fields: {
      type: Array
    },
    fields_heatmap: {
      type: Function
    },
    loading: {
      type: Boolean
    },
    maxCharCount: {
      type: Object
    },
    currencyConfig: {
      type: Object
    }
  },
  data() {
    return {
      KPI_DIRECTION_TYPE,
      showShadow: false,
      csvSources: period => ({
        DEFAULT_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
          [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
          [SPEND_FIELD.label]: SPEND_FIELD.key,
          [TACOS_FIELD.label]: TACOS_FIELD.key,
          [CLICKS_FIELD.label]: CLICKS_FIELD.key,
          [CPC_FIELD.label]: CPC_FIELD.key,
          [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
          [CVR_FIELD.label]: CVR_FIELD.key,
          [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
          "Sponsored Asin": SPONSORED_ASIN_FIELD.key
        },
        TARGETING_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
          [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
          [SPEND_FIELD.label]: SPEND_FIELD.key,
          [CLICKS_FIELD.label]: CLICKS_FIELD.key,
          [CPC_FIELD.label]: CPC_FIELD.key,
          [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
          [CVR_FIELD.label]: CVR_FIELD.key,
          [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key
        }
      })
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.ppc.periodTable,
      period: state => state.ppc.periodTable.period
    }),
    ...mapGetters(['ppcTargetingFiltersEnabled']),
    getPeriodColumnTitle() {
      return this.periodTable.periodTableElements?.[this.period]?.name;
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.getPeriodColumnTitle?.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    csvFileName() {
      console.log('this.period', this.period);
      return `PPC_Evolution_${capitalize(this.getPeriodColumnTitle)}.csv`;
    },
    targetingEnabled() {
      return this.ppcTargetingFiltersEnabled() ? 'TARGETING_FIELDS' : 'DEFAULT_FIELDS';
    }
  },
  methods: {
    generateColorBasedOnState,
    getColorsBasedOnRange,
    getColorForDecile,
    getDescriptionBasedOnKpiKey,
    getMinForColumn(column) {
      return this.minMax[column]?.min;
    },
    getMaxForColumn(column) {
      return this.minMax[column]?.max;
    },
    getHeatMapRanges(column) {
      return this.fields_heatmap(column);
    },
    handleDateClick(period) {
      window.$bus.trigger(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    activateDateValueStyles(row) {
      return row.item.selected ? {
        color: "#312783",
        fontWeight: "600"
      } : {};
    },
    handleRefresh() {
      this.$emit('refresh');
    },
    handleRefreshDatePicker() {
      this.$emit('refresh:datePicker');
    },
    async downloadPeriodTableCSV() {
      const csv_headers = this.csvSources(this.periodTable.periodText)[this.targetingEnabled];
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};
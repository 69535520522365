import AppIcon from "@/components/AppIcon.vue";
import AppModal from "@/components/AppModal.vue";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd.vue";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
import AppPopover from "@/components/AppPopover";
import { EVENTS } from "@/utils";
export default {
  name: "SeoImageCell",
  components: {
    AppIcon,
    AppModal,
    Popper,
    AppTd,
    AppPopover,
    WrapWithStatusIcon
  },
  props: {
    imagesList: {
      type: Array,
      default: () => []
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    pushModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoverImage: {},
      index: 0
    };
  },
  computed: {
    renderedImageList() {
      if (this.imagesList.length <= 0) {
        return [];
      }
      return this.isCollapsed ? [this.imagesList[0]] : this.imagesList;
    },
    imageListSize() {
      let size = 9;
      this.imagesList.forEach(image => {
        size = image.link == "" ? size - 1 : size;
      });
      return size;
    },
    computedStatus() {
      return image => {
        const status = image.link === '' ? 'error' : image.hover_img_size == 'image_large' ? 'success' : 'warning';
        if (status == 'error') {
          return {
            status: "error",
            text: "Image is missing"
          };
        }
        if (status == 'warning') {
          return {
            status: "warning",
            text: "image with too low size (<1000px)"
          };
        }
        if (status == 'success') {
          return {
            status: "success",
            text: "Image with good size (>1000px)"
          };
        }
      };
    }
  },
  mounted() {
    this.imagesList.forEach((image, i) => {
      this.hoverImage[`${image.link}/${i}`] = false;
    });
  },
  methods: {
    expandColumn() {
      this.$emit("expandImageList");
    },
    onClickImage(refName, index) {
      if (!refName) {
        return;
      }
      this.hoverImage[refName] = true;
      this.index = index;
    },
    onLeaveImage(refName) {
      this.hoverImage[refName] = false;
    }
  }
};
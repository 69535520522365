import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import HelperMixin from "@/mixins/HelperMixin";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import PercentageValue from "@/components/tableElements/PercentageValue";
import { CurrencyValue } from "@/components/tableElements";
import PpcBudgetAllocationCircle from "@/components/PpcBudgetAllocationCircle";
import PpcBudgetAllocationWeeklyBudgetEdit from "@/components/PpcBudgetAllocationWeeklyBudgetEdit";
import TasksMixin from "@/mixins/TasksMixin.vue";
import AppModal from "@/components/AppModal";
import StateSwitch from "@/components/StateSwitch";
import Alert from "@/components/icons/Alert.vue";
import AlertOctagon from "@/components/icons/AlertOctagon.vue";
import PlayIcon from "@/components/icons/PlayIcon.vue";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import TargetIcon from "@/components/icons/TargetIcon.vue";
import CalendarWithArrowLeftIcon from "@/components/icons/CalendarWithArrowLeftIcon.vue";
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "PpcBudgetAllocationItem",
  emits: ["item-update", "weekly-budget-edit"],
  mixins: [HelperMixin, TasksMixin],
  components: {
    AppPopover,
    AppIcon,
    AppModal,
    PpcBudgetAllocationCircle,
    PpcBudgetAllocationWeeklyBudgetEdit,
    PercentageValue,
    CurrencyValue,
    StateSwitch,
    Alert,
    PauseIcon,
    PlayIcon,
    AlertOctagon,
    TargetIcon,
    CalendarWithArrowLeftIcon,
    FlexRow
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: ""
    },
    weeklyBudgetTarget: {
      type: String,
      default: null
    },
    last: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String
    },
    selected_profile_id: {
      type: String
    }
  },
  data() {
    return {
      blockIconOver: false,
      currency_precision: 0,
      isBoostEdit: false,
      localBoost: "1",
      weeklyBudgetShown: null,
      calculatedCapForMode: {
        acos: this.aCOSCap,
        roas: this.rOASCap
      },
      badgeTextMapping: {
        activated: "Newly activated",
        paused: "Paused",
        inactive: "Inactive",
        updated: ""
      },
      campaignEditTaskIdentifier: "",
      edit_campaign_loading: false,
      edit_campaign_type: "",
      updated_budget: {},
      confirmBlockModal: false,
      updated_campaign_group_state: "",
      confirm_block_modal_text: "",
      confirm_block_modal_type: "",
      confirmBlockModalOkMap: {
        block: this.onBlock,
        state: this.onApplyStateConfirm
      },
      loadingMap: {
        block_loading: false,
        budget_discovery_loading: false,
        budget_perf_loading: false
      }
    };
  },
  computed: {
    ...mapState({
      bid_settings: state => state.ppc_settings.bid_settings,
      current_workspace: state => state.workspace.current_workspace
    }),
    ...mapGetters(["getCountryFlag"]),
    boost: {
      get() {
        return this.localBoost;
      },
      set(value) {
        let newValue = value.replace(/[^0-9.]/g, "");
        const parts = newValue.split(".");
        if (parts.length > 2) {
          newValue = parts[0] + "." + parts.slice(1).join("");
        }
        this.localBoost = newValue;
      }
    },
    campaignGroupState: {
      get() {
        const campaignGroupStateMap = {
          0: false,
          1: true,
          2: "inconsistent"
        };
        return campaignGroupStateMap[this.item.is_enabled ?? 1];
      },
      set(value) {
        this.onApplyState(value ? "ENABLED" : "PAUSED");
      }
    },
    mounted() {
      this.campaignEditTaskIdentifier = this.current_workspace.workspace_id + "_" + this.item.campaign_group_id + "_" + "ppc_edit_campaign";
    },
    processedItem() {
      console.log(this.item);
      console.log(this.getCountryFlag(this.item.country_code));
      return {
        id: this.item.campaign_group_id,
        isBlocked: this.item.is_blocked && this.item.is_blocked !== "false",
        name: this.item.campaign_group_name,
        image: this.item.campaign_group_image_link,
        flag: this.getCountryFlag(this.item.country_code),
        result: this.item.result,
        countryCode: this.item.country_code,
        subDetails: this.item.asin_count,
        daysInventory: this.item.days_inventory * 1,
        adsNetProfit4W: this.item.sponsored_net_profit,
        revShare4W: this.item.revenue_4w_share,
        productsMargin: this.item.asin_group_margin,
        boost: this.item?.boost ?? 1,
        cap: this.calculatedCapForMode[this.mode](this.item),
        //45, // TO-DO Need to calculate it
        acos4W: this.item.asin_group_4w_acos,
        roas4W: this.item.asin_group_4w_roas,
        perfomance: {
          budgetAi: this.item.perf_allocated_budget_ai,
          budgetCurrent: this.item.perf_allocated_budget_current,
          budgetPotential: this.item.perf_potential_budget,
          isCustom: this.numberFormatter(this.item.perf_allocated_budget_current) !== this.numberFormatter(this.item.perf_allocated_budget_ai),
          potential: this.item?.perf_allocated_budget_current / (this.item?.perf_potential_budget ?? 1),
          previous: this.item.perf_allocated_budget_current - this.item.perf_previous_week_spend,
          campaigns: this.item.campaign_details.filter(item => item.campaign_theme === "PERF").map(item => ({
            id: item.campaign_id,
            budgetAi: item?.allocated_budget_ai,
            budgetCurrent: item?.allocated_budget_current,
            budgetCalculated: item?.allocated_budget_current,
            evolution: item?.budget_evolution,
            potential: item?.potential_budget,
            roas: item?.campaign_roas,
            maxRoas: this.item?.asin_group_4w_roas,
            maxAcos: this.item?.asin_group_4w_acos,
            name: item?.campaign_name,
            type: item?.campaign_type
          }))
        },
        discovery: {
          budgetAi: this.item?.discovery_allocated_budget_ai,
          budgetCurrent: this.item?.discovery_allocated_budget_current,
          budgetCalculated: this.item?.discovery_allocated_budget_ai,
          budgetPotential: this.item?.discovery_potential_budget,
          isCustom: this.numberFormatter(this.item?.discovery_allocated_budget_current) !== this.numberFormatter(this.item?.discovery_allocated_budget_ai),
          potential: this.item?.discovery_allocated_budget_current / (this.item?.discovery_potential_budget ?? 1),
          previous: (this.item?.discovery_allocated_budget_current ?? this.item?.discovery_allocated_budget_ai) - this.item?.discovery_previous_week_spend,
          campaigns: this.item.campaign_details.filter(item => item.campaign_theme === "DISCOVERY").map(item => ({
            id: item.campaign_id,
            budgetAi: item.allocated_budget_ai,
            budgetCurrent: item.allocated_budget_current,
            evolution: item.budget_evolution,
            potential: item.potential_budget,
            roas: item.campaign_roas,
            maxRoas: this.item.asin_group_4w_roas,
            maxAcos: this.item.asin_group_4w_acos,
            name: item.campaign_name,
            type: item.campaign_type
          }))
        }
      };
    },
    getRowClass() {
      const item = this.processedItem;
      const result = [];
      const classMap = {
        true: "ba-row-regular",
        false: "ba-row-paused",
        inconsistent: "ba-row-inconsistent"
      };
      result.push(classMap[this.campaignGroupState]);
      if (item.isBlocked) {
        result.push("ba-row-blocked");
      }
      return result;
    },
    getBadgeText() {
      const item = this.processedItem;
      let result = "";
      console.log(item);
      if (item.isBlocked) {
        result = "Blocked";
      } else {
        result = this.badgeTextMapping[item.result] || "";
      }
      return result;
    },
    shouldDisplayBadge() {
      return !!this.getBadgeText;
    },
    getBlockIconColor() {
      return this.processedItem.isBlocked ? !!this.campaignGroupState ? "#FF8F94" : "#DB333B" : this.blockIconOver ? "#F07A80" : "#C9CFD4";
    },
    getCap4W() {
      return this.mode == "acos" ? this.processedItem.acos4W : this.processedItem.roas4W;
    },
    getCapColor() {
      const cap = this.processedItem.cap * 1;
      let result = "";
      if (this.mode == "acos") {
        const value = this.processedItem.acos4W * 1;
        if (value <= cap) {
          result = "ba-text-green";
        } else if (value > 2 * cap) {
          result = "ba-text-red";
        } else if (value > 1.25) {
          result = "ba-text-yellow";
        }
      }
      if (this.mode == "roas") {
        const value = this.processedItem.roas4W * 1;
        if (value >= cap) {
          result = "ba-text-green";
        } else if (value < 0.5 * cap) {
          result = "ba-text-red";
        } else if (value < 0.8 * cap) {
          result = "ba-text-yellow";
        }
      }
      return result;
    },
    getPotentialColor() {
      return value => {
        if (value * 100 >= 90) return "color-green";
        if (value * 100 >= 60) return "color-yellow";
        return "color-red";
      };
    },
    getPotentialWidth() {
      return value => {
        return value * 100 > 100 ? 100 : value * 100;
      };
    }
  },
  methods: {
    ...mapActions(["ppcEditCampaign", "ppcProductPrioritizationCampaignGroupUpdate", "ppcProductPrioritizationCampaignEditUpdate"]),
    ...mapMutations(["ppcCampaignUpdateSet"]),
    onBlockIconOverToggle(value) {
      if (this.edit_campaign_loading) {
        return;
      }
      this.blockIconOver = value;
    },
    onBlockToggle() {
      this.confirm_block_modal_text = `Are you sure you want to ${this.processedItem.isBlocked ? "UNBLOCK" : "BLOCK"} this campaign group?`;
      this.confirm_block_modal_type = "block";
      this.confirmBlockModal = true;
    },
    async onBlock() {
      this.confirmBlockModal = false;
      const item = {
        ...this.item
      };
      item.is_blocked = !item.is_blocked;
      this.onItemUpdate(item);
      await this.updateCampaignGroup(item.boost ?? 1, item.is_blocked);
    },
    onApplyState(value) {
      if (this.edit_campaign_loading) {
        return;
      }
      this.updated_campaign_group_state = value;
      this.confirm_block_modal_text = `Are you sure you want to change the statuses of all campaigns in this group to ${value}?`;
      this.confirm_block_modal_type = "state";
      this.confirmBlockModal = true;
    },
    onApplyStateConfirm() {
      this.confirmBlockModal = false;
      this.edit_campaign_type = "block";
      this.loadingMap[`${this.edit_campaign_type}_loading`] = true;
      this.editCampaign();
    },
    campaignListToUpdateState() {
      let campaign_list = [];
      this.item.campaign_id_list.forEach(campaign_id => {
        campaign_list.push({
          campaign_id: campaign_id,
          profile_id: this.selected_profile_id,
          country_code: this.item.country_code,
          update_dict: {
            state: this.updated_campaign_group_state
          }
        });
      });
      return campaign_list;
    },
    campaignListToUpdateBudget() {
      let campaign_list = [];
      const campaigns = this.updated_budget.campaigns;
      campaigns.forEach(campaign => {
        campaign_list.push({
          campaign_id: campaign.id,
          profile_id: this.selected_profile_id,
          country_code: this.item.country_code,
          update_dict: {
            budget: {
              budgetType: "DAILY",
              budget: Math.round(campaign.budgetCurrent / 7 * 100) / 100
            }
          }
        });
      });
      return campaign_list;
    },
    async editCampaign() {
      const campaignListMap = {
        block: this.campaignListToUpdateState,
        budget: this.campaignListToUpdateBudget
      };
      const payload = {
        workspace_id: this.current_workspace.workspace_id,
        campaign_group_id: this.item.campaign_group_id,
        campaign_list: campaignListMap[this.edit_campaign_type](),
        task_type: "ppc_edit_campaign"
      };
      const data = await this.ppcEditCampaign(payload);
      if (data) {
        if (data.task_id === `${this.current_workspace.workspace_id}_${this.item.campaign_group_id}_ppc_edit_campaign`) {
          this.campaignEditTaskIdentifier = `${this.current_workspace.workspace_id}_${this.item.campaign_group_id}_ppc_edit_campaign`;
          this.executeTaskStatusHandler(data);
          this.checkTaskStatusPeriodically(this.campaignEditTaskIdentifier);
        }
      }
    },
    onItemUpdate(newItem) {
      this.$emit("item-update", newItem);
    },
    onBoostEdit() {
      this.localBoost = this.convertBoost(this.processedItem.boost);
      this.isBoostEdit = true;
      this.$nextTick(() => {
        this.$refs.boost.focus();
      });
    },
    onBoostInput(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "");
      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts.slice(1).join("");
      }
      this.boost = value;
    },
    onBoostBlur() {
      this.validateBoost();
      this.isBoostEdit = false;
    },
    onBoostKeydown(event) {
      this.isNumber(event);
      if (event.key === "Enter") {
        this.validateBoost();
        this.isBoostEdit = false;
      } else if (event.key === "Escape") {
        this.localBoost = this.processedItem.boost;
        this.isBoostEdit = false;
      }
    },
    isNumber(event) {
      const allowedKeys = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", "Escape"];
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (event.key === "." && !event.target.value.includes(".")) {
        return;
      }
      if (event.key >= "0" && event.key <= "9") {
        return;
      }
      event.preventDefault();
    },
    async validateBoost() {
      let value = parseFloat(this.boost);
      if (isNaN(value) || value <= 0) {
        value = 1;
      }
      const item = {
        ...this.item
      };
      item.boost = value;
      this.onItemUpdate(item);
      await this.updateCampaignGroup(item.boost, item.is_blocked);
    },
    convertBoost(value) {
      let strValue = value.toString();
      if (strValue.includes(".")) {
        const parts = strValue.split(".");
        if (parts[1].length > 1) {
          return parseFloat(value).toFixed(1);
        } else if (parts[1].length <= 1) {
          return strValue;
        }
      } else {
        return strValue + ".0";
      }
    },
    onWeeklyEdit(type) {
      if (this.edit_campaign_loading) {
        return;
      }
      const fullType = type === "perf" ? "perfomance" : "discovery";
      if (this.processedItem[fullType].budgetAi !== "-" && this.processedItem[fullType].budgetCurrent !== "-") {
        const target = `weekly${type}${this.processedItem.id}`;
        const popups = document.querySelectorAll(".app-popup");
        popups.forEach(div => {
          const dataClasses = Array.from(div.classList).filter(cls => cls.startsWith("data-"));
          const secondParts = dataClasses.map(cls => cls.split("-")[1]);
          if (!secondParts.includes(target)) {
            document.getElementById(secondParts[0]).click();
          }
        });
        this.$emit("weekly-budget-edit", target);
      }
    },
    onWeeklyReset(type) {
      if (this.edit_campaign_loading) {
        return;
      }
      const fullType = type === "perf" ? "perfomance" : "discovery";
      const updatedCampaigns = this.processedItem[fullType].campaigns;
      updatedCampaigns.forEach(campaign => {
        campaign.budgetCurrent = campaign.budgetAi;
      });
      this.updated_budget = {
        type: type,
        current: this.processedItem[fullType].budgetAi,
        campaigns: updatedCampaigns
      };
      this.edit_campaign_type = "budget";
      this.loadingMap[`${this.edit_campaign_type}_${this.updated_budget?.type}_loading`] = true;
      this.editCampaign();
    },
    onWeeklyEditPopupShown(target) {
      if (target.startsWith("weekly")) {
        this.weeklyBudgetShown = target;
      }
    },
    onWeeklyEditPopupHidden(target) {
      this.weeklyBudgetShown = null;
    },
    onWeeklyBudgetEditCancel() {
      document.getElementById(this.weeklyBudgetTarget).click();
      this.$emit("weekly-budget-edit", null);
      this.weeklyBudgetShown = null;
    },
    onWeeklyBudgetEditApply(result) {
      document.getElementById(this.weeklyBudgetTarget)?.click();
      this.$emit("weekly-budget-edit", null);
      this.weeklyBudgetShown = null;
      if (this.edit_campaign_loading) {
        return;
      }
      this.edit_campaign_type = "budget";
      this.updated_budget = result;
      this.loadingMap[`${this.edit_campaign_type}_${this.updated_budget?.type}_loading`] = true;
      this.editCampaign();
    },
    aCOSCap(item) {
      return item?.asin_group_margin * Math.max(this.bid_settings?.non_branded_keyword_targeting ?? 0, this.bid_settings?.branded_keyword_product_targeting ?? 0, this.bid_settings?.competitor_product_targeting ?? 0);
    },
    rOASCap(item) {
      return 1 / this.aCOSCap(item);
    },
    updateLocalItemBlock(data) {
      this.ppcCampaignUpdateSet({
        'data': data,
        'campaign_group_id': this.item.campaign_group_id
      });
      this.loadingMap[`${this.edit_campaign_type}_loading`] = false;
    },
    updateLocalItemBudget(data) {
      const item = {
        ...this.item
      };
      item[`${this.updated_budget?.type}_allocated_budget_current`] = this.updated_budget?.current;
      item.campaign_details = item.campaign_details.map(campaign => {
        const updatedCampaign = this.updated_budget?.campaigns.find(item => item.id === campaign.campaign_id);
        if (updatedCampaign) {
          campaign.allocated_budget_current = updatedCampaign.budgetCurrent;
        }
        return campaign;
      });
      this.onItemUpdate(item);
      this.loadingMap[`${this.edit_campaign_type}_${this.updated_budget?.type}_loading`] = false;
    },
    async updateCampaignGroup(boost, is_blocked) {
      const payload = [{
        campaign_group_id: this.item.campaign_group_id,
        profile_id: this.selected_profile_id,
        country_code: this.item.country_code,
        campaign_group_name: this.item.campaign_group_name,
        sales_channel: this.item.sales_channel,
        asin_group: this.item.asin_group,
        boost: boost,
        is_blocked: is_blocked
      }];
      await this.ppcProductPrioritizationCampaignGroupUpdate(payload);
    },
    handleIdle() {
      this.edit_campaign_loading = false;
    },
    handleRunning() {
      this.edit_campaign_loading = true;
      this.checkTaskStatusPeriodically(this.campaignEditTaskIdentifier);
    },
    handleFailed(data) {
      this.edit_campaign_loading = false;
      this.loadingMap.block_loading = false;
      this.loadingMap.budget_discovery_loading = false;
      this.loadingMap.budget_perf_loading = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showDanger", {
        visible: true,
        message: "Campaign Edit Task failed \n" + data.task_result,
        delay: 60 // Show alert less than 60 secs
      });
      // throw new Error("Campaign Edit Task failed");
    },
    handleFinished(data) {
      const updateLocalItemMap = {
        block: this.updateLocalItemBlock,
        budget: this.updateLocalItemBudget
      };
      updateLocalItemMap[this.edit_campaign_type](data.task_result);
      this.edit_campaign_loading = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: "Campaign Edit Task has finished with success",
        delay: 60 // Show alert less than 60 secs
      });
    }
  }
};
export default {
  name: 'AlertOctagon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    },
    color: {
      type: String,
      default: '#D7AA0F'
    }
  }
};
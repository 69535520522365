import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextValue = _resolveComponent("TextValue");
  const _component_WrapWithSectionItem = _resolveComponent("WrapWithSectionItem");
  return _openBlock(), _createBlock(_component_WrapWithSectionItem, {
    selected: $props.selected,
    onSelect: _cache[0] || (_cache[0] = $event => _ctx.$emit('select', {
      symbol: $props.symbol
    })),
    onDeselect: _cache[1] || (_cache[1] = $event => _ctx.$emit('deselect', {
      symbol: $props.symbol
    })),
    onSelectOnly: _cache[2] || (_cache[2] = $event => _ctx.$emit('selectOnly', {
      symbol: $props.symbol
    }))
  }, {
    default: _withCtx(() => [_createVNode(_component_TextValue, {
      class: "name",
      value: $props.currency,
      main: ""
    }, null, 8 /* PROPS */, ["value"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["selected"]);
}
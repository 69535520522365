import WrapWithCopy from "@/components/WrapWithCopy.vue";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
import ProductScore from "@/components/ProductScore";
export default {
  name: "SeoPushStatusCell",
  props: {
    item: Object,
    pushModal: Boolean,
    showPopover: Boolean
  },
  emits: ['onConfirmPushSingleContent', "onCopyingHover", "onCopyingHoverLeave"],
  components: {
    AppIcon,
    AppPopover,
    WrapWithCopy,
    Popper,
    AppTd,
    WrapWithStatusIcon,
    ProductScore
  },
  computed: {
    isPossibleToPush() {
      const pushableStatus = ["draft", "not_accepted", "sync_error", "seller_central_not_accepted"];
      return item => {
        return pushableStatus.includes(item.statusPush.status);
      };
    }
  },
  methods: {
    getPushStatusClass(item) {
      return `seo-popover ${item.statusPush.status}`;
    },
    onMouseOver() {
      this.isHovering = true;
      this.$emit("onCopyingHover", null);
    },
    onMouseLeave() {
      this.isHovering = false;
      this.$emit("onCopyingHoverLeave", null);
    }
  }
};
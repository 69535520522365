import GroupBy from "@/components/GroupBy.vue";
import LoadingElement from "@/components/shared/LoadingElement.vue";
import { mapState } from "vuex";
export default {
  name: "ProductTableGroupBy",
  components: {
    GroupBy,
    LoadingElement
  },
  props: {
    tabBusy: {
      type: Boolean
    }
  },
  emits: ["refresh", "clicked"],
  computed: {
    ...mapState({
      groupByElements: state => state.ppc_v2.product_table.group_by_elements,
      group_by: state => state.ppc_v2.product_table.group_by,
      disabled_group_by_elements: state => state.ppc_v2.product_table.disabled_group_by_elements
    }),
    groupByIndex: {
      get() {
        return this.group_by;
      },
      set(val) {
        if (val != this.group_by) {
          this.$store.dispatch("ppcV2SetProductTableGroupBy", val);
          this.$emit("refresh");
        }
      }
    }
  }
};
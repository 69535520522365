import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = {
  "clip-path": "url(#clip0_5723_94697)"
};
const _hoisted_3 = ["stroke"];
const _hoisted_4 = {
  id: "clip0_5723_94697"
};
const _hoisted_5 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 11 10",
    fill: "none"
  }, [_createElementVNode("g", _hoisted_2, [_createElementVNode("path", {
    d: "M4.66919 5.41502C4.84813 5.65424 5.07642 5.85218 5.33859 5.99542C5.60075 6.13865 5.89065 6.22382 6.18863 6.24516C6.4866 6.2665 6.78569 6.22351 7.06559 6.1191C7.34548 6.01469 7.59966 5.8513 7.81086 5.64002L9.06086 4.39002C9.44035 3.9971 9.65034 3.47085 9.6456 2.92461C9.64085 2.37836 9.42175 1.85584 9.03548 1.46957C8.64921 1.0833 8.12669 0.8642 7.58044 0.859454C7.0342 0.854707 6.50795 1.0647 6.11502 1.44419L5.39836 2.15669M6.33586 4.58169C6.15692 4.34247 5.92863 4.14453 5.66646 4.0013C5.4043 3.85807 5.1144 3.77289 4.81642 3.75155C4.51844 3.73021 4.21936 3.7732 3.93946 3.87762C3.65956 3.98203 3.40539 4.14541 3.19419 4.35669L1.94419 5.60669C1.5647 5.99961 1.35471 6.52587 1.35945 7.07211C1.3642 7.61835 1.5833 8.14088 1.96957 8.52715C2.35584 8.91341 2.87836 9.13252 3.42461 9.13726C3.97085 9.14201 4.4971 8.93202 4.89002 8.55252L5.60252 7.84002",
    stroke: $props.color,
    "stroke-width": "0.833333",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_3)]), _createElementVNode("defs", null, [_createElementVNode("clipPath", _hoisted_4, [_createElementVNode("rect", {
    width: $props.width,
    height: $props.height,
    fill: "white",
    transform: "translate(0.5)"
  }, null, 8 /* PROPS */, _hoisted_5)])])], 8 /* PROPS */, _hoisted_1);
}
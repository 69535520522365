import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "split-name"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["id", "innerHTML"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dotted_value = _resolveComponent("dotted-value");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_filter_divider = _resolveComponent("filter-divider");
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_coins_stacked_icon = _resolveComponent("coins-stacked-icon");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_variable_styled_bar = _resolveComponent("variable-styled-bar");
  const _component_layer_bars = _resolveComponent("layer-bars");
  const _component_bar_slot_with_value = _resolveComponent("bar-slot-with-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_composite_colorful_bar = _resolveComponent("composite-colorful-bar");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_dial_value = _resolveComponent("dial-value");
  const _component_slot_with_value = _resolveComponent("slot-with-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_compound_table = _resolveComponent("compound-table");
  return _openBlock(), _createBlock(_component_compound_table, {
    class: "strategic-period-table",
    topBgColor: "#F8F9FF",
    topLeftGap: 20,
    fields: $options.fields,
    loading: $props.loading
  }, {
    header: _withCtx(() => [_createTextVNode(_toDisplayString($options.periodTitle), 1 /* TEXT */)]),
    "header-sub": _withCtx(() => [_createVNode(_component_flex_row, {
      gap: 16
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.TARGETING_TYPE_SPLIT, ({
        name,
        color
      }) => {
        return _openBlock(), _createBlock(_component_dotted_value, {
          gap: 8,
          value: name,
          color: color,
          size: 12,
          styles: {
            padding: 0
          }
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(name), 1 /* TEXT */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "color"]);
      }), 256 /* UNKEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    })]),
    "group-by-title": _withCtx(() => [_createVNode(_component_filter_divider, {
      height: 20
    })]),
    actions: _withCtx(() => [_createVNode(_component_app_icon_group, {
      icons: [{
        icon: 'download-csv',
        size: 20,
        help: 'Download CSV',
        click: $options.downloadStrategicPeriodCSV
      }]
    }, null, 8 /* PROPS */, ["icons"])]),
    table: _withCtx(() => [_createVNode(_component_b_table_lite, {
      class: _normalizeClass(['lite-table', 'lite-table-hoverable', 'strategic-performance-table']),
      fields: $options.fields,
      bordered: "",
      responsive: "",
      fixed: "",
      items: $options.items,
      "sticky-header": "",
      tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
      onRowClicked: _cache[0] || (_cache[0] = item => _ctx.handleRowClicked(item, $options.handleDateReset, $options.handleDateClick, 'period_agg'))
    }, {
      "head()": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field?.thStyle
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          target: `strategic-period-table-${col?.label}`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
          }, null, 8 /* PROPS */, _hoisted_2)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [_createElementVNode("div", {
            id: `strategic-period-table-${col?.label}`,
            innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
            class: "title"
          }, null, 8 /* PROPS */, _hoisted_3)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles"])]),
      "head(big_spend)": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field?.thStyle
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          target: `strategic-period-table-spend`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey('spend').title
          }, null, 8 /* PROPS */, _hoisted_4)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey('spend').detail), 1 /* TEXT */)]),
          default: _withCtx(() => [_createVNode(_component_flex_row, {
            gap: 8,
            id: "strategic-period-table-spend"
          }, {
            default: _withCtx(() => [_createVNode(_component_coins_stacked_icon), _createElementVNode("div", {
              class: "flex-1 d-flex w-100",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_5)]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles"])]),
      "head(targeting_type_ROAS)": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field?.thStyle
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          target: `strategic-period-table-targeting_type_ROAS`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey('ROAS').title
          }, null, 8 /* PROPS */, _hoisted_6)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey('ROAS').detail), 1 /* TEXT */)]),
          default: _withCtx(() => [_createElementVNode("div", {
            id: "strategic-period-table-targeting_type_ROAS",
            innerHTML: col?.label
          }, null, 8 /* PROPS */, _hoisted_7)]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles"])]),
      "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_AppIcon, {
          icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
          color: row.item.selected ? null : '#ADADAD',
          size: 16
        }, null, 8 /* PROPS */, ["icon", "color"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
        value: row.item?.period_agg,
        format: $options.getDateValueFormat,
        styles: {
          justifyContent: 'flex-start',
          ..._ctx.activateDateValueStyles(row)
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
      "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_asin,
        color: "#4C8EFF",
        min: $options.minMax?.sponsored_asin?.min,
        max: $options.minMax?.sponsored_asin?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.sponsored_asin,
          charCount: $options.maxCharCount.number?.sponsored_asin,
          style: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(cannibalization_pc)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.cannibalization_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.TACOS,
        background: "#EBD4F7",
        borderColor: "#CD93EB",
        min: $options.minMax?.TACOS?.min,
        max: $options.minMax?.TACOS?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.TACOS
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(percent_time_in_budget)": _withCtx(row => [_createVNode(_component_bar_slot_with_value, {
        loading: $props.loading,
        value: row.item?.percent_time_in_budget,
        reverse: ""
      }, {
        bar: _withCtx(() => [_createVNode(_component_layer_bars, null, {
          default: _withCtx(() => [_createVNode(_component_variable_styled_bar, {
            value: $options.minMax?.percent_time_in_budget?.max,
            max: $options.minMax?.percent_time_in_budget?.max,
            min: $options.minMax?.percent_time_in_budget?.min,
            color: "#E8EBF3",
            height: "6"
          }, null, 8 /* PROPS */, ["value", "max", "min"]), _createVNode(_component_variable_styled_bar, {
            value: row.item?.percent_time_in_budget,
            max: $options.minMax?.percent_time_in_budget?.max,
            min: $options.minMax?.percent_time_in_budget?.min,
            color: "#5F799B",
            height: "6"
          }, null, 8 /* PROPS */, ["value", "max", "min"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.percent_time_in_budget,
          charCount: $options.maxCharCount.percentage?.percent_time_in_budget
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "value"])]),
      "cell(share_sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.share_sponsored_sales,
        min: $options.minMax?.share_sponsored_sales?.min,
        color: "#5A52AE",
        max: $options.minMax?.share_sponsored_sales?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_sponsored_sales,
          charCount: $options.maxCharCount.percentage?.share_sponsored_sales
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(big_spend)": _withCtx(row => [_createVNode(_component_bar_slot_with_value, {
        loading: $props.loading,
        onMouseenter: $event => $options.handleShowComposites(row.index),
        onMouseleave: $event => $options.handleHideComposites(row.index)
      }, {
        bar: _withCtx(() => [_createVNode(_component_composite_colorful_bar, {
          ref: `spendComposite_${row.index}`,
          barValues: [$options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend) + $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_spend)],
          values: [$options.findTargetingTypePercentage($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend, row.item?.big_spend), $options.findTargetingTypePercentage($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_spend, row.item?.big_spend), $options.findTargetingTypePercentage($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_spend, row.item?.big_spend), $options.findTargetingTypePercentage($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_spend, row.item?.big_spend)],
          currencies: [$options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_spend), $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_spend)],
          min: 0,
          max: row.item?.spend,
          colors: [$data.COLORS.COMPETITORS_PURPLE, $data.COLORS.NON_BRANDED_BLUE, $data.COLORS.BRANDED_PINK, $data.COLORS.DISCOVERY_GREY],
          types: $data.TARGETING_TYPE_SPLIT
        }, {
          date: _withCtx(() => [_createVNode(_component_date_value, {
            value: row.item?.period_agg,
            format: $options.getDateValueFormat,
            align: "right",
            loading: $props.loading,
            styles: {
              width: '100%'
            }
          }, null, 8 /* PROPS */, ["value", "format", "loading"])]),
          total: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.big_spend,
            limit: 1_000,
            precision: 2,
            "zero-initials": ""
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["barValues", "values", "currencies", "max", "colors", "types"])]),
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.spend,
          charCount: $options.maxCharCount?.currency?.spend
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onMouseenter", "onMouseleave"])]),
      "cell(targeting_type_ROAS)": _withCtx(row => [_createVNode(_component_flex_row, null, {
        default: _withCtx(() => [_createVNode(_component_boxed_value, {
          value: row.item?.targeting_type_ROAS?.[0]?.targeting_type_ROAS,
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_ROAS', $data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            tight: "",
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_ROAS),
            precision: 1,
            color: $data.COLORS.PURPLE700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_ROAS),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_ROAS', $data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            tight: "",
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_ROAS),
            precision: 1,
            color: $data.COLORS.BLUE700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_ROAS),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_ROAS', $data.TARGETING_TYPE_TYPES.BRANDED),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            tight: "",
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_ROAS),
            precision: 1,
            color: $data.COLORS.PINK700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_ROAS),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_ROAS', $data.TARGETING_TYPE_TYPES.DISCOVERY),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            tight: "",
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_ROAS),
            precision: 1,
            color: $data.COLORS.GREY700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"])]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]),
      "cell(targeting_type_CPC)": _withCtx(row => [_createVNode(_component_flex_row, null, {
        default: _withCtx(() => [_createVNode(_component_boxed_value, {
          value: row.item?.targeting_type_CPC?.[0]?.targeting_type_CPC,
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_CPC', $data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT, row.item?.targeting_type_CPC),
            precision: 2,
            color: $data.COLORS.PURPLE700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_CPC),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_CPC', $data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.NON_BRANDED_KWS, row.item?.targeting_type_CPC),
            precision: 2,
            color: $data.COLORS.BLUE700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_CPC),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_CPC', $data.TARGETING_TYPE_TYPES.BRANDED),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.BRANDED, row.item?.targeting_type_CPC),
            precision: 2,
            color: $data.COLORS.PINK700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"]), _createVNode(_component_boxed_value, {
          value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_CPC),
          bgColor: $options.getTargetingTypeSplitDecileColor(row, 'targeting_type_CPC', $data.TARGETING_TYPE_TYPES.DISCOVERY),
          loading: $props.loading,
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $options.findTargetingTypeValue($data.TARGETING_TYPE_TYPES.DISCOVERY, row.item?.targeting_type_CPC),
            precision: 2,
            color: $data.COLORS.GREY700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "bgColor", "loading"])]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]),
      "cell(total_ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.total_ROAS,
        background: "#E6E9FE",
        borderColor: "#D8DCFD",
        min: $options.minMax?.total_ROAS?.min,
        max: $options.minMax?.total_ROAS?.max,
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          styles: {
            padding: 0
          },
          value: row.item?.total_ROAS,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(share_of_voice)": _withCtx(row => [_createVNode(_component_slot_with_value, {
        gap: 8,
        value: row.item?.share_of_voice
      }, {
        value: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_of_voice,
          color: "#DB2473"
        }, null, 8 /* PROPS */, ["value"])]),
        indicator: _withCtx(() => [_createVNode(_component_dial_value, {
          value: row.item?.share_of_voice ?? 0
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["fields", "items", "tbodyTrClass"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["fields", "loading"]);
}
import NavigationTabs from "@/components/NavigationTabs";
import FlexColumn from "@/components/FlexColumn";
import { mapState, mapGetters } from "vuex";
import StrategicPeriodTable from "@/components/ppc_v2/StrategicPeriodTable";
import PerformancePeriodTable from "@/components/ppc_v2/PerformancePeriodTable";
import PerformanceCards from "@/components/kpi-cards/ppc_v2/PerformanceCards";
import AppDropdown from "@/components/AppDropdown";
import AppButtonRadioGroup from "@/components/AppButtonRadioGroup";
import { EVENTS } from "@/utils/constants";
import { DATE_RANGES_FROM_SELECTOR, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES, getDatePickerUIObjectFromDateRange } from "@/utils/datepicker";
import PPCManagementV2Mixin from "@/mixins/PPCManagementV2Mixin.vue";
import PpcV2Filters from "@/components/PpcV2Filters.vue";
import moment from "moment";
import PpcV2MoreFilters from "@/components/PpcV2MoreFilters.vue";
import ProductTable from "@/components/ppc_v2/ProductTable.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import PpcSettings from "@/components/ppc/PpcSettings.vue";
import PpcSettingsFilters from "@/components/ppc/PpcSettingsFilters.vue";
export default {
  name: "PPCManagementV2Page",
  components: {
    NavigationTabs,
    FlexColumn,
    StrategicPeriodTable,
    PerformancePeriodTable,
    PerformanceCards,
    AppDropdown,
    AppButtonRadioGroup,
    PpcV2Filters,
    PpcV2MoreFilters,
    ProductTable,
    PpcSettingsFilters,
    PpcSettings
  },
  mixins: [PPCManagementV2Mixin, LiteTableMixin],
  beforeUnmount() {
    window.$bus.off(EVENTS.REFRESH_PPC_MANAGEMENT, async () => await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]));
    window.$bus.off(EVENTS.SET_MORE_FILTERS, this.handleMoreFilters);
  },
  async mounted() {
    window.$bus.on(EVENTS.SET_MORE_FILTERS, this.handleMoreFilters);
    const sellers = await this.getOmnyAccountSellers();
    await this.initializeFilters({
      sellers
    }, "ppc_v2", {
      seller: "sellers",
      marketplace: "marketplaces",
      product: "products",
      match_type: "match_types",
      campaign: "campaigns",
      targeting: "targetings",
      portfolio: "portfolios",
      ad_format: "ad_formats"
    }, "ppc/v2");
    this.$store.dispatch("ppcV2SetProductTableGroupByElements");
    this.$nextTick(() => {});
    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
      rangeTitle: this.periodGroupBy,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });
    this.storeDatePickerDate(this.defaultDatePickerDate);
    await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]);
    window.$bus.on(EVENTS.REFRESH_PPC_MANAGEMENT, async () => await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]));
  },
  data() {
    return {
      today: moment(),
      defaultDatePickerDate: null,
      periodTableLoading: true,
      productTableLoading: true,
      kpisLoading: true,
      moreFilters: false,
      activateSettings: true,
      openModal: false,
      hideSettingsFooterButtons: true,
      rightStripFeatureName: "ppcsettingsfeature",
      mediatorRefreshers: {
        KPIS: this.refreshKpis,
        PERIOD_TABLE: this.refreshPeriodTable,
        PRODUCT_TABLE: this.refreshProductTable
      }
    };
  },
  computed: {
    ...mapState({
      currentTab: state => state.ppc_v2.currentTab,
      currentTabValue: state => state.ppc_v2.currentTabValue,
      tabs: state => state.ppc_v2.tabs,
      betatabs: state => state.ppc_v2.betatabs,
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isOmnyUser: state => state.auth.isOmnyUser,
      currentPeriod: state => state.ppc_v2.currentPeriod,
      periodSelectorOptions: state => state.ppc_v2.periodSelectorOptions,
      floatingTableState: state => state.ppc_v2.product_table.state,
      productTableGroupBy: state => state.ppc_v2.product_table.group_by_text,
      productTable: state => state.ppc_v2.product_table
    }),
    ...mapGetters(["ppcV2GetPeriod", "getSystemWeekType", "ppcV2SecondaryFiltersEnabled"]),
    period: {
      set(value) {
        this.$store.dispatch("ppcV2SetPeriod", value);
        this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
          ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
          rangeTitle: this.periodGroupBy,
          compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
        });
        this.storeDatePickerDate(this.defaultDatePickerDate);
        this.refreshMediator(["PERIOD_TABLE", "KPIS", "PRODUCT_TABLE"]);
      },
      get() {
        return this.ppcV2GetPeriod();
      }
    },
    tab: {
      async set(value) {
        console.log('state', this.$store);
        this.$store.dispatch("ppcV2SetTab", value);
      },
      get() {
        return this.currentTab;
      }
    },
    periodGroupBy() {
      return this.getPeriodGroupFromCurrentPeriod(this.currentPeriod, this.period);
    }
  },
  methods: {
    getAdvertisingTabs() {
      return this.isOmnyAdmin || this.isOmnyUser ? this.tabs : this.betatabs;
    },
    storeDatePickerDate(date) {
      this.$store.dispatch("ppcV2SetProductTablePeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
    },
    async refreshKpis() {
      this.kpisLoading = true;
      await this.$store.dispatch("ppcV2FetchKpis");
      this.kpisLoading = false;
    },
    async refreshPeriodTable() {
      this.periodTableLoading = true;
      await this.$store.dispatch("ppcV2FetchPeriodTableData");
      this.periodTableLoading = false;
    },
    async refreshProductTable() {
      if (this.currentTabValue !== "settings") {
        this.productTableLoading = true;
        const groupByFetch = this.productTable[this.currentTabValue].data[this.productTableGroupBy]?.fetch;
        if (groupByFetch) await this.$store.dispatch(groupByFetch);
        this.productTableLoading = false;
      }
    },
    async refreshMediator(refresheeList) {
      let promises = refresheeList.map(refreshee => this.mediatorRefreshers[refreshee]());
      await Promise.all(promises);
    },
    handleMoreFilters(value) {
      this.moreFilters = value;
    },
    async handleDateChange(date) {
      if (!date) {
        date = this.defaultDatePickerDate;
      }
      this.storeDatePickerDate(date);
      await this.refreshMediator(["KPIS", "PRODUCT_TABLE"]);
    },
    handleSetPeriodTablePeriod(date) {
      this.handleSetLiteTablePeriod(date, this.period, this.handleDateChange);
    },
    handleProductTableSort({
      sort,
      direction
    }) {
      this.$store.dispatch("ppcV2SetProductTableSort", {
        sort,
        direction
      });
      this.refreshMediator(["PRODUCT_TABLE"]);
    },
    async handleProductTableRefresh() {
      await this.refreshMediator(["KPIS", "PRODUCT_TABLE", "PERIOD_TABLE"]);
    },
    getFeatureName() {
      return "ppcsettingsfeature";
    }
  }
};
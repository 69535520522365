import { mapState, mapMutations } from "vuex";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import ContentMixin from "@/mixins/ContentMixin.vue";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import TasksMixin from "@/mixins/TasksMixin.vue";
import Popper from "vue3-popper";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import AppPopover from "@/components/AppPopover";
import ProductScore from "@/components/ProductScore";
import AppTableHeadSeo from "@/components/AppTableHeadSeo";
import AppTd from "@/components/AppTd";
import EditTextModal from "@/components/EditTextModal";
import ImageManagerModal from "@/components/ImageManagerModal";
import SeoEditItemNew from "@/components/SeoEditItemNew";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
import WrapWithCopy from "@/components/WrapWithCopy.vue";
import SeoRowV2 from "@/components/seo/SeoRowV2.vue";
import SeoRow from "@/components/seo/SeoRow.vue";
import SeoParentRow from "@/components/seo/SeoParentRow.vue";
import { filterOut, EVENTS } from "@/utils";
export default {
  name: "ContentOptimisationTableNewWithParents",
  mixins: [ContentMixin, ValidateMixin, ContentDisplayRulesMixin, TasksMixin, InitializeFiltersMixin],
  emits: ["data-loading", "edit-single-item", "reset-paging-values"],
  components: {
    Popper,
    AppIcon,
    AppModal,
    AppPopover,
    ProductScore,
    AppTableHeadSeo,
    AppTd,
    EditTextModal,
    ImageManagerModal,
    SeoEditItemNew,
    ErrorOccured,
    WrapWithCopy,
    SeoRowV2,
    SeoRow,
    SeoParentRow
  },
  data() {
    return {
      editSingleItem: false,
      errorOccured: false,
      sellerAccounts: [],
      countriesMapping: [],
      content_list: [],
      imgWidth: "28px",
      imgHeight: "28px",
      imgBorder: "none",
      sortBy: "country",
      sortDesc: false,
      editTextModal: false,
      seoEditModal: false,
      editAsin: null,
      editParentAsin: null,
      editCountry: null,
      editValue: "",
      editOriginal: "",
      editContentId: "",
      editContentLink: "",
      editImage: "",
      editProductName: "",
      editSku: "",
      type: "",
      title: "",
      singleContentToPush: {},
      modifiedAsins: [],
      sellerIds: [],
      selectAll: false,
      filter: {
        seller: "",
        country: "",
        sku: "",
        asin: ""
      },
      imageManagerModal: false,
      pushTaskIdentifier: "",
      pushModalActiveButton: true,
      pushModalSpinButton: false,
      confirmPushSingleContent: false,
      editSingleItem: false,
      showNavigationBtn: -1,
      isCopying: false,
      isCollapsedImageList: true,
      showAcceptAmazon: false,
      children_num: 1,
      showPopover: true,
      show_save_all_children: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      products: state => state.content.list,
      modified_product_contents: state => state.content.modified_list,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.content.sellers_filter,
      marketplaces_filter: state => state.content.marketplaces_filter,
      products_filter: state => state.content.products_filter,
      selected_contents: state => state.content.selected_contents,
      current_mode: state => state.content.current_mode,
      pushModal: state => state.content.pushModal,
      page: state => state.content.page,
      perPage: state => state.content.perPage,
      parent_asin_to_child_asin_map: state => state.content.parent_asin_to_child_asin_map,
      parent_asin_string_to_asin_array: state => state.content.parent_asin_string_to_asin_array,
      solo_items: state => state.content.solo_items // This isn't used for rendering but for querying
    }),
    workspaceCurrent() {
      return this.workspaceCurrentOld;
    },
    allowHtmlInputAndSpecialCharacters() {
      return ["product_description"].includes(this.type);
    },
    tableFields() {
      const tableHeaders = [{
        key: "product_name",
        label: "Product name",
        sortable: true,
        stickyColumn: true,
        left: this.pushModal ? "20.4px" : "0px",
        thStyle: {
          width: "368px"
        }
      }, {
        key: "images",
        label: "Images",
        align: "center",
        thStyle: {
          width: "60px"
        }
      }, {
        key: "points",
        label: "Bullet Points",
        colspan: 5,
        thStyle: {
          width: "780px"
        }
      }, {
        key: "generic_keyword",
        label: "Search Terms",
        align: "center",
        sortable: true,
        thStyle: {
          width: "107px"
        }
      }, {
        key: "product_description",
        label: "Description",
        sortable: true,
        thStyle: {
          width: "107px"
        }
      }, {
        key: "image_list",
        label: "Images",
        align: "start",
        expandable: "true",
        isCollapsed: this.isCollapsedImageList,
        thStyle: {
          width: "75px"
        }
      }, {
        key: "category",
        label: "Category",
        align: "center",
        thStyle: {
          width: "160px"
        }
      }, {
        key: "a_plus",
        label: "A+",
        align: "center",
        thStyle: {
          width: "39px"
        }
      }, {
        key: "brand",
        label: "Brand",
        align: "center",
        thStyle: {
          width: "107px"
        }
      }, {
        key: "amz_score",
        label: "AMZ",
        align: "center",
        stickyColumn: true,
        right: this.pushModal ? "111px" : "147px",
        thStyle: {
          width: "32px"
        }
      }, {
        key: "draft_score",
        label: "Omny",
        align: "center",
        stickyColumn: true,
        right: this.pushModal ? "79px" : "115px",
        thStyle: {
          width: "32px"
        }
      }, {
        key: "push_status",
        label: "Push Status",
        align: "center",
        sortable: true,
        stickyColumn: true,
        right: this.pushModal ? "0px" : "36px",
        thStyle: {
          width: "32px"
        }
      }, {
        key: "edit",
        align: "center",
        stickyColumn: true,
        showHeader: !this.pushModal,
        right: "0px",
        thStyle: {
          width: "32px"
        }
      }];
      let headersWithCheckbox = [{
        key: "checkbox",
        label: "",
        sortable: true,
        stickyColumn: true
      }, ...tableHeaders];
      headersWithCheckbox.pop();
      return this.pushModal ? headersWithCheckbox : tableHeaders;
    },
    taskStatusMessages() {
      return {
        NOTASK: "Push to Amazon task is not running",
        PENDING: "Push to Amazon task is pending",
        STARTED: "Push to Amazon task is running",
        RETRY: "Push to Amazon task is being retried",
        SUCCESS: "Push to Amazon task is successful",
        FAILURE: "Push to Amazon task failed"
      };
    },
    pushButtonName() {
      return this.selected_contents.length > 0 ? "Push selected updates to Amazon" : "Push all updates to Amazon";
    }
  },
  async mounted() {
    //const data = await this.getCountries();
    //this.contentCountriesListSet(data);
    if (!this.pushModal) {
      this.$emit("data-loading", true);
      try {
        this.contentCurrentModeSet("parent_view_mode");
        await this.refreshSellers();
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE, this.refreshSellers);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_COUNTRIES, this.refreshCountries);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS, this.refreshContentsAndSuggestions);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE, this.refreshContents);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_SUGGESTIONS, this.refreshSuggestions);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_CANCEL, this.onPushModalCancel);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT, this.onPushModalSubmitModifiedContent);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_OPEN, this.onAmazonPush);
        this.pushTaskIdentifier = this.current_workspace.workspace_id + "_" + "content_push";
        this.getAsyncUpdate(this.pushTaskIdentifier);
        this.checkTaskStatusPeriodically(this.pushTaskIdentifier);
        this.$emit("data-loading", false);
        this.errorOccured = false;
      } catch (error) {
        console.log("Error", error);
        this.$emit("data-loading", false);
        this.errorOccured = true;
      }
    }
  },
  methods: {
    ...mapMutations(["contentCurrentModeSet", "contentSelectedContentsSet", "contentPushModalStatusSet"]),
    toggleSelectAll(checked) {
      this.contentSelectedContentsSet(checked ? this.products.map(item => item) : []);
      this.selectAll = checked;
    },
    updateSelectedItems(item, isSelectedItem) {
      let item_suggestion = this.contentSuggestionsListGet().find(suggestion => item.asin == suggestion.asin);
      if (isSelectedItem) {
        this.handleContentSelect({
          item: item_suggestion,
          only: false
        });
      } else {
        this.handleContentDeselect(item_suggestion);
      }
    },
    selectItem($event, item) {
      if ($event.target.checked) {
        this.contentSelectedContentsSet(item);
      } else {
        this.contentSelectedContentsSet(this.selected_contents.filter(selected => item.content_id != selected.content_id));
      }
      //this.updateSelectedItems(item,$event.target.checked);
    },
    isSelectedItem(item) {
      return this.selected_contents.some(aValue => item.content_id == aValue.content_id);
    },
    onEdit(item, type, title) {
      console.log("item", item, type, title);
      if (!item.sku) {
        return;
      }
      const originalItem = this.findOriginalItem(item);
      if (originalItem && !this.pushModal && !this.confirmPushSingleContent) {
        this.editAsin = item.asin;
        if (item.parent_asin) {
          this.editParentAsin = item.parent_asin;
        }
        this.editCountry = item.country;
        //this.editValue = originalItem.omny_version[type]; //logic to show null
        // this.editValue = originalItem.omny_version[type]
        //   ? originalItem.omny_version[type]
        //   : originalItem.amazon_version[type];
        // this.editOriginal = originalItem.amazon_version[type]; //old logic for showing amazon version

        this.editValue = this.contentToDisplay(originalItem, type, true);
        this.editOriginal = originalItem.amazon_version[type];
        const notAcceptedStatus = ["not_accepted"];
        this.showAcceptAmazon = notAcceptedStatus.includes(item[type + "_style"].status) && originalItem?.amazon_version[type] != "";
        this.editTextModal = true;
        this.type = type;
        this.title = title;
        this.editProductName = item.product_name;
        this.editSku = item.sku;
        this.editImage = item.image_small;
        this.editDescription = item.product_description;
        this.editBulletPoints = [item.bullet_point_1 ?? "", item.bullet_point_2 ?? "", item.bullet_point_3 ?? "", item.bullet_point_4 ?? "", item.bullet_point_5 ?? ""];
        this.editContentId = item.content_id;
        /*
           PROPOSAL FOR PERFORMANCE IMPROVEMENT FOR THIS SECTION
           Explanation:
            -> Currently, recreating Objects from the entire `Map` to find
            the `child_asins` isn't ideal.
            This will hurt perf when we have 100 parents with each having a minimum 100 children.
            And we do not need to recreate to find the associations.
             The `Map` model looks like this
               {
                k -> v
              }
               where k isn't a string but an entire `Object` which stores already
              the `child_asins_structured_array`.
               The task is to use the `parent_asins` field from the `item` to retrieve
              the child asins.
               map.get(item.parent_asins) -> Reaching O(1).
               At the moment, this is a little hard as the best we can do
              would be to do `Array.from(map.keys()).find(el => el.parent_asin === item.parent_asins)?.child_asins_structured_array`
               This will still be O(n) at best O(logn).
               We can do better by keeping another map/object mapping `parent_asins` -> `child_asins_structured_array`
              whenever we receive the data.
               REF: content.js -> state
        */
        if (item.parent_asin) {
          this.show_save_all_children = true;
          this.children_num = this.parent_asin_string_to_asin_array.get(item.parent_asin)?.length ?? 1;
        }
      }
    },
    async onPaste(item, type, title) {
      if (!item.sku) {
        return;
      }
      const originalItem = this.findOriginalItem(item);
      if (originalItem && !this.pushModal && !this.confirmPushSingleContent) {
        try {
          const copiedValue = await navigator.clipboard?.readText();
          this.setConfigForSubmit(item, originalItem, type, copiedValue);
          this.title = title;
          this.onSubmit();
          this.isCopying = false;
        } catch (err) {
          window.$bus.trigger("showDanger", {
            visible: true,
            message: "Clipboard permission is denied. Please activate clipboard permission on your browser and try again",
            delay: 59999 // Show alert less than 1 minute
          });
          this.isCopying = false;
          return;
        }
      }
    },
    onUpdate(val) {
      this.editValue = val;
    },
    handleIdle(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      // if (data) {
      //   window.$bus.trigger("showWarning", {
      //     visible: true,
      //     message: this.taskStatusMessages[data.task_status],
      //   });
      // }
    },
    async handleFailed(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      window.$bus.trigger("showDanger", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 59999 // Show alert less than 1 minute
      });
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async handleRunning(data) {
      this.pushModalActiveButton = false;
      this.pushModalSpinButton = true;
      data.task_result?.map(item => this.contentUpdateAfterPush(item));
      window.$bus.trigger("showInfo", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 59999 // Show alert less than 1 minute
      });
      this.checkTaskStatusPeriodically(this.pushTaskIdentifier);
    },
    async handleFinished(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      data.task_result?.map(item => this.contentUpdateAfterPush(item));
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 5999 // Show alert less than 1 minute
      });
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    modifiedContentToPush() {
      return this.selected_contents.length > 0 ? this.selected_contents : this.products;
    },
    statusCalculate(rules) {
      var score_sum = 0;
      var coefficient_sum = 0;
      rules.forEach(rule => {
        score_sum += rule.score * rule.coefficient;
        coefficient_sum += rule.coefficient;
      });
      const global_score = Math.floor(score_sum / coefficient_sum);
      if (global_score >= 7) {
        return {
          status: "success",
          score: global_score
        };
      } else if (global_score >= 4 && global_score < 7) {
        return {
          status: "warning",
          score: global_score
        };
      } else if (global_score < 4) {
        return {
          status: "error",
          score: global_score
        };
      }

      /* if (rules.some((item) => item.status == "error")) {
              return "error";
            }
      
            const warnings = rules.find((item) => item.status == "warning");
            if (warnings && warnings.length > 3) {
              return "warning";
            }
      
            return "success"; */
    },
    async onSubmit() {
      this.contentFieldSet({
        asin: this.editAsin,
        type: this.type,
        value: this.editValue,
        content_id: this.editContentId
      });
      const data = await this.contentUpdate({
        content_id: this.editContentId
      });
      if (data) {
        if (!this.modifiedAsins.includes(this.editAsin)) {
          this.modifiedAsins.push(this.editAsin);
        }
        this.editTextModal = false;
        this.editAsin = null;
        this.editValue = "";
        this.editOriginal = "";
        this.editContentId = "";
        this.type = "";
        this.title = "";
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
      }
    },
    async onSubmitAllChildren() {
      this.show_save_all_children = true;
      const child_asins = this.parent_asin_string_to_asin_array.get(this.editParentAsin);
      if (!child_asins) {
        return;
      }
      const items = child_asins.map(({
        marketplace_id,
        asin,
        seller_id
      }) => this.products.find(el => el.asin === asin && el.seller_id === seller_id && el.marketplace_id === marketplace_id && el.parent_asin === this.editParentAsin)).filter(Boolean);
      if (!items) {
        return;
      }
      items.forEach(async item => await this.submitContentForChild(item.content_id, item.asin));
      this.editTextModal = false;
      this.editAsin = null;
      this.editValue = "";
      this.editOriginal = "";
      this.editContentId = "";
      this.type = "";
      this.title = "";
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    onCloseEdit() {
      this.editTextModal = false;
      this.editAsin = null;
      this.editValue = "";
      this.editOriginal = "";
      this.editContentId = "";
      this.type = "";
      this.title = "";
      this.editImage = "";
      this.editProductName = "";
    },
    onEditSingleItem(item) {
      if (!item.sku) {
        return;
      }
      this.$emit("edit-single-item", true);
      this.editSingleItem = true;
      this.editContentId = item.content_id;
      this.editContentLink = item.amazon_link;
    },
    onEditSingleItemBack() {
      this.$emit("edit-single-item", false);
      this.editSingleItem = false;
    },
    onImageChange(item) {
      //we comment it temporarily because we might work on the image upload or image processing
      // const originalItem = this.products.find((el) => el.asin == item.asin);

      // this.imageManagerModal = true;
      // this.editAsin = item.asin;
      // this.editImage =
      //   originalItem.image_large ||
      //   originalItem.image_medium ||
      //   originalItem.image_small;

      //temporary solution
      let link = item.amazon_link;
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    onImageUpdate(val) {
      this.editImage = val;
    },
    onImageSubmit(val) {
      this.contentFieldSet({
        asin: this.editAsin,
        type: "image",
        value: val
      });
      if (!this.modifiedAsins.includes(this.editAsin)) {
        this.modifiedAsins.push(this.editAsin);
      }
      this.imageManagerModal = false;
      this.editAsin = null;
      this.editImage = "";
    },
    async onPushModalSubmitModifiedContent() {
      await this.onPushModalSubmit(this.modifiedContentToPush());
    },
    getPushStatusClass(item) {
      return `seo-popover ${item.statusPush.status}`;
    },
    showNavigation(index) {
      this.showNavigationBtn = index;
    },
    hideNavigation() {
      this.showNavigationBtn = -1;
    },
    isCopied(cell) {
      let table = this.$refs["seo-table-content"];
      if (!table) {
        return;
      }
      const scrollPosition = table.scrollTop;
      this.isCopying = true;
      let copyClass = " is-copied";
      cell.className += copyClass;
      requestAnimationFrame(() => {
        table.scroll({
          behavior: "instant",
          top: scrollPosition
        });
      });
      setTimeout(() => {
        cell.className = cell.className.replace(copyClass, "");
        requestAnimationFrame(() => {
          table.scroll({
            behavior: "instant",
            top: scrollPosition
          });
        });
      }, 1000);
    },
    onCopyingHover(cell) {
      this.showPopover = true;
      if (this.isCopying && cell) {
        let copyClass = " copying-hovered";
        cell.className = !cell.className.includes(copyClass) ? cell.className + copyClass : cell.className;
      }
    },
    onCopyingHoverLeave(cell) {
      this.showPopover = false;
      if (cell) {
        let copyClass = "copying-hovered";
        cell.className = cell.className.replace(copyClass, "");
      }
    },
    keydownEscHandler() {
      this.isCopying = false;
    },
    keepFocus() {
      if (this.isCopying) {
        let divFocusable = this.$refs["seo-table"];
        divFocusable.focus();
      }
    },
    filterImageList(imageList) {
      let array = [];
      imageList.forEach(image => {
        if (!array.some(item => item.variant === image.variant)) {
          array.push(image);
        }
      });
      array.forEach(image => {
        const priorityOrder = ["image_large", "image_medium", "image_small"];
        let hoverImage = imageList.sort((a, b) => priorityOrder.indexOf(a.image_size) - priorityOrder.indexOf(b.image_size)).find(img => img.variant === image.variant);
        image["hover_img_link"] = hoverImage.link;
        image["hover_img_size"] = hoverImage.image_size;
      });
      if (array.length < 9) {
        for (let i = array.length; i < 9; i++) {
          let emptyImage = {
            variant: "PT0" + (i - 1),
            image_size: "",
            link: "",
            hover_img_link: ""
          };
          array.push(emptyImage);
        }
      }
      return array;
    },
    getProductImage(imageList) {
      const priorityOrder = ["image_small", "image_medium", "image_large"];
      return imageList.sort((a, b) => priorityOrder.indexOf(a.image_size) - priorityOrder.indexOf(b.image_size)).find(img => img.variant === "MAIN");
    },
    expandImageList() {
      this.isCollapsedImageList = false;
      this.scrollToRight();
    },
    collapseImageList() {
      this.isCollapsedImageList = true;
    },
    scrollToRight() {
      let table = this.$refs["seo-table-content"];
      if (!table) {
        return;
      }
      this.$nextTick(() => {
        table.scroll({
          behavior: "smooth",
          left: table.scrollWidth
        });
      });
    },
    setConfigForSubmit(item, originalItem, key, copiedValue) {
      this.editAsin = originalItem.asin;
      this.editValue = copiedValue;
      this.editOriginal = originalItem.amazon_version[key];
      this.type = key;
      this.editContentId = item.content_id;
    },
    async handlePushItem(item) {
      const originalItem = this.products.find(element => element.content_id == item.content_id);
      let all_attributes = Object.keys(originalItem.amazon_version);
      let attributes_to_modify = new Set(originalItem.modified_attributes || []);
      all_attributes.map(async attribute => {
        if (!stringsAreTheSameWithCaseSensitive(item[attribute], originalItem.amazon_version[attribute]) && !attributes_to_modify.has(attribute)) {
          const copiedValue = item[attribute];
          this.setConfigForSubmit(item, originalItem, attribute, copiedValue);
          attributes_to_modify.add(attribute);
          await this.onSubmit();
        }
      });
      this.contentUpdateAfterPush({
        modified_attributes: [...attributes_to_modify]
      });
      this.onConfirmPushSingleContent(item);
    },
    handleToggle(key) {
      const parent = this.parent_asin_to_child_asin_map.get(key);
      parent.collapsed = !parent.collapsed;
    },
    getTableItemsForAsins(parent_asin) {
      return this.products.filter(el => el.parent_asin === parent_asin);
    },
    transformParent(parent) {
      const country = this.getCountry(parent.marketplace_id);
      const flag = this.getCountryFlag(this.getCountryCode(country));
      return {
        ...parent,
        country,
        flag
      };
    },
    HidePopover() {
      this.showPopover = false;
    },
    findOriginalItem(item, key = 'content_id') {
      return this.products.find(el => el[key] === item[key]) || this.solo_items.find(el => el[key] === item[key]);
    },
    async submitContentForChild(content_id, asin) {
      this.contentFieldSet({
        asin,
        type: this.type,
        value: this.editValue,
        content_id
      });
      const data = await this.contentUpdate({
        content_id
      });
      if (data) {
        if (!this.modifiedAsins.includes(asin)) {
          console.log('modifiedAsins', this.modifiedAsins);
          this.modifiedAsins.push(asin);
        }
      }
    }
  }
};
import ImageOverlayer from "@/components/ImageOverlayer.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import ProductWithFlagOverlay from "@/components/ProductWithFlagOverlay.vue";
export default {
  name: 'SeoParentProductCell',
  props: {
    parent: Object,
    collapsed: Boolean,
    childrenSize: Number
  },
  components: {
    ImageOverlayer,
    ChevronDownIcon,
    ChevronRightIcon,
    ProductWithFlagOverlay
  }
};
import { mapActions, mapState, mapGetters } from "vuex";
import { EVENTS, isObjectEmpty } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import AppIcon from "./AppIcon.vue";
import PercentageValue from "@/components/tableElements/PercentageValue";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import PpcBidSettingInput from "@/components/PpcBidSettingInput";
import PpcBidSimulator from "@/components/PpcBidSimulator.vue";
import AppCheckbox from "@/components/AppCheckbox.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
export default {
  name: "PpcBidSettings",
  mixins: [ValidateMixin],
  props: {
    currency: {
      type: String
    }
  },
  components: {
    AppIcon,
    PercentageValue,
    PpcBidSettingInput,
    PpcBidSimulator,
    AppCheckbox,
    LinkIcon
  },
  data() {
    return {
      displayedValues: {},
      isEdited: [],
      isChangingBidValue: "",
      simulator: {
        non_branded_keyword_targeting: {
          product_margin: 30,
          cvr: 10
        },
        competitor_product_targeting: {
          product_margin: 30,
          cvr: 10
        },
        branded_keyword_product_targeting: {
          product_margin: 30,
          cvr: 10
        },
        discovery_targeting: {
          product_margin: 30,
          cvr: 10
        }
      },
      isValid: {
        non_branded_keyword_targeting: true,
        competitor_product_targeting: true,
        branded_keyword_product_targeting: true,
        discovery_targeting: true
      },
      scale_proportionally: true
    };
  },
  computed: {
    bidSimulatorExists() {
      return !isObjectEmpty(this.bid_simulator);
    },
    ppcBidSimulatorCampaignGroupName() {
      return this.ppcBidSimulatorCampaignGroupNameGet();
    },
    ppcBidSimulatorCampaignGroupCount() {
      return this.ppcBidSimulatorCampaignGroupCountGet();
    },
    ppcBidSimulatorCampaignGroupImage() {
      return this.ppcBidSimulatorCampaignGroupImageGet();
    },
    ...mapState({
      bid_settings: state => state.ppc_settings.bid_settings,
      bid_simulator: state => state.ppc_settings.bid_simulator
    }),
    ...mapGetters(["ppcBidSimulatorCampaignGroupNameGet", "ppcBidSimulatorCampaignGroupImageGet", "ppcBidSimulatorCampaignGroupCountGet"])
  },
  mounted() {
    this.updateBidSettingsToDisplay();
  },
  watch: {
    bid_settings(new_bid_settings) {
      this.updateBidSettingsToDisplay();
    }
  },
  methods: {
    ...mapActions(["ppcBidSettingSave"]),
    updateBidSettingsToDisplay() {
      this.displayedValues = {
        non_branded_keyword_targeting: Math.round(this.bid_settings.non_branded_keyword_targeting * 100),
        branded_keyword_product_targeting: Math.round(this.bid_settings.branded_keyword_product_targeting * 100),
        competitor_product_targeting: Math.round(this.bid_settings.competitor_product_targeting * 100),
        discovery_targeting: Math.round(this.bid_settings.discovery_targeting * 100)
      };
    },
    onChangeValue(refName) {
      if (!this.isEdited.some(item => item === refName)) {
        this.isEdited.push(refName);
      }
    },
    isChangedValue(refName) {
      return this.isEdited.some(item => item === refName);
    },
    onReverseClick(refName) {
      if (this.scale_proportionally) {
        this.reverseAllValue();
        return;
      }
      this.reverseValue(refName);
    },
    reverseValue(refName) {
      this.displayedValues[refName] = Math.round(this.bid_settings[refName] * 100);
      this.isEdited = this.isEdited.filter(item => item !== refName);
    },
    reverseAllValue() {
      this.isEdited.map(refName => {
        this.reverseValue(refName);
      });
    },
    onChangeBidValue(refName) {
      this.isChangingBidValue = refName;
      setTimeout(() => {
        this.$refs[refName].focus();
      }, 500);
    },
    async onBlur(refName) {
      this.isChangingBidValue = "";
      if (this.displayedValues[refName] * 1 !== Math.round(this.bid_settings[refName] * 100)) {
        this.onChangeValue(refName);
        if (this.scale_proportionally) {
          const percentChange = (this.displayedValues[refName] - Math.round(this.bid_settings[refName] * 100)) / Math.round(this.bid_settings[refName] * 100);
          Object.keys(this.displayedValues).forEach(item => {
            if (item !== refName) {
              this.displayedValues[item] = Math.round(this.bid_settings[item] * 100) + percentChange * Math.round(this.bid_settings[item] * 100);
              this.onChangeValue(item);
            }
          });
        }
        return;
      }
      this.reverseAllValue();
    },
    async onSubmit() {
      const isValid = Object.values(this.isValid).some(targeting => !targeting);
      if (isValid) return;
      let payload = {
        seller_id: this.bid_settings.seller_id,
        profile_id: this.bid_settings.profile_id,
        marketplace_id: this.bid_settings.marketplace_id,
        bid_settings_id: `${this.bid_settings.seller_id}_${this.bid_settings.profile_id}_${this.bid_settings.marketplace_id}`,
        non_branded_keyword_targeting: this.displayedValues.non_branded_keyword_targeting / 100,
        branded_keyword_product_targeting: this.displayedValues.branded_keyword_product_targeting / 100,
        competitor_product_targeting: this.displayedValues.competitor_product_targeting / 100,
        discovery_targeting: this.displayedValues.discovery_targeting / 100
      };
      await this.ppcBidSettingSave({
        data: [payload]
      });
      window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_BID_SETTINGS);
      this.isEdited = [];
    }
  }
};
import { mapState, mapGetters } from "vuex";
import FlexRow from "@/components/FlexRow.vue";
import { SellerFilter, MarketplaceFilter, ProductFilter, FilterDivider, FilterItemSelector, PortfolioFilter, AdFormatFilter, CampaignFilter, MatchTypeFilter, TargetingFilter } from "@/components/new-filters";
import { EVENTS, PPC_EXTRA_FILTERS, HANDLE_ADD_FILTER_MODES } from "@/utils/constants";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppIcon from "@/components/AppIcon.vue";
import LayoutIcon from "@/components/icons/LayoutIcon.vue";
import TrendingUpIcon from "@/components/icons/TrendingUpIcon.vue";
import CertificateIcon from "@/components/icons/CertificateIcon.vue";
import EqualsIcon from "@/components/icons/EqualsIcon.vue";
import TargetIcon from "@/components/icons/TargetIcon.vue";
export default {
  name: "PpcV2Filters",
  components: {
    FlexRow,
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    FilterDivider,
    FilterItemSelector,
    WrapWithPopover,
    AppDropdown,
    AppIcon,
    CampaignFilter,
    MatchTypeFilter,
    TargetingFilter,
    LayoutIcon,
    CertificateIcon,
    TrendingUpIcon,
    EqualsIcon,
    TargetIcon,
    PortfolioFilter,
    AdFormatFilter
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    currentTabValue: {
      type: String,
      default: "performance"
    }
  },
  mounted() {
    window.$bus.on(EVENTS.ADD_PPC_FILTER, this.handleAddFilter);

    // NOTE: For now, we have disabled this section which was originally for removing the `SELLER, MARKETPLACE, PRODUCT` filters
    // when the users chose any secondary filters (Campaigns, Portfolio, Targeting, Match Type, Ad Format)
  },
  data() {
    return {
      moreFilterOptions: [{
        name: PPC_EXTRA_FILTERS.CAMPAIGN,
        icon: "TrendingUpIcon"
      }, {
        name: PPC_EXTRA_FILTERS.MATCH_TYPE,
        icon: "EqualsIcon"
      }, {
        name: PPC_EXTRA_FILTERS.TARGETING,
        icon: "TargetIcon"
      }, {
        name: PPC_EXTRA_FILTERS.AD_FORMAT,
        icon: "LayoutIcon"
      }, {
        name: PPC_EXTRA_FILTERS.PORTFOLIO,
        icon: "CertificateIcon"
      }],
      renderedFilterNames: new Set()
    };
  },
  computed: {
    ...mapState({
      sellers: state => state.ppc_v2.sellers,
      marketplaces: state => state.ppc_v2.marketplaces,
      products: state => state.ppc_v2.products,
      match_types: state => state.ppc_v2.match_types,
      campaigns: state => state.ppc_v2.campaigns,
      targetings: state => state.ppc_v2.targetings,
      portfolios: state => state.ppc_v2.portfolios,
      ad_formats: state => state.ppc_v2.ad_formats
    }),
    ...mapGetters(["ppcV2SecondaryFiltersEnabled"]),
    filterDropdownOptions() {
      return this.moreFilterOptions.filter(({
        name
      }) => !this.renderedFilterNames.has(name));
    },
    onlyMode() {
      return this.currentTabValue === "settings" ?? false;
    },
    processedFilters() {
      return Array.from(this.renderedFilterNames).map(filter => {
        const componentConfig = this.getFilterComponent(filter);
        return {
          name: componentConfig.name,
          props: componentConfig.props,
          methods: componentConfig.methods
        };
      });
    },
    extraFilters() {
      return {
        [PPC_EXTRA_FILTERS.CAMPAIGN]: {
          name: "CampaignFilter",
          props: {
            campaigns: this.campaigns,
            sellers: this.sellers
          },
          methods: {
            apply: this.applyCampaigns
          }
        },
        [PPC_EXTRA_FILTERS.MATCH_TYPE]: {
          name: "MatchTypeFilter",
          props: {
            match_types: this.match_types
          },
          methods: {
            apply: this.applyMatchTypes
          }
        },
        [PPC_EXTRA_FILTERS.TARGETING]: {
          name: "TargetingFilter",
          props: {
            targetings: this.targetings,
            sellers: this.sellers,
            marketplaces: this.marketplaces
          },
          methods: {
            apply: this.applyTargetings
          }
        },
        [PPC_EXTRA_FILTERS.AD_FORMAT]: {
          name: "AdFormatFilter",
          props: {
            ad_formats: this.ad_formats
          },
          methods: {
            apply: this.applyAdFormats
          }
        },
        [PPC_EXTRA_FILTERS.PORTFOLIO]: {
          name: "PortfolioFilter",
          props: {
            portfolios: this.portfolios,
            sellers: this.sellers
          },
          methods: {
            apply: this.applyPortfolios
          }
        }
      };
    }
  },
  methods: {
    async applySellersForCurrentTab() {
      let applySellersFunction = this.currentTabValue === "settings" ? this.applySellersForSettingsTab : this.applySellers;
      applySellersFunction();
    },
    async applyMarketPlacesForCurrentTab() {
      let applyMarketPlacesFunction = this.currentTabValue === "settings" ? this.applyMarketPlacesForSettingsTab : this.applyMarketplaces;
      applyMarketPlacesFunction();
    },
    async applySellers() {
      if (this.sellers.isModified) {
        this.sellers.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Fetching sub filters for sellers...  ",
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_SELLERS_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces.isModified) {
        this.marketplaces.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Fetching sub filters for marketplace...  ",
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    async applySellersForSettingsTab() {
      if (this.sellers.isModified) {
        this.sellers.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_MARKETPLACES_FILTER);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    },
    async applyMarketPlacesForSettingsTab() {
      if (this.marketplaces.isModified) {
        this.marketplaces.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    },
    applyMatchTypes() {
      if (this.match_types.isModified) {
        this.match_types.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCampaigns() {
      if (this.campaigns.isModified) {
        this.campaigns.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyTargetings() {
      if (this.targetings.isModified) {
        this.targetings.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyPortfolios() {
      if (this.portfolios.isModified) {
        this.portfolios.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyAdFormats() {
      if (this.ad_formats.isModified) {
        this.ad_formats.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyProducts() {
      if (this.products.isModified) {
        this.products.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCurrencies() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    handleSellerCleared() {
      window.$bus.trigger(EVENTS.ADD_SELLER_GROUPBY);
    },
    handleMarketplaceCleared() {
      window.$bus.trigger(EVENTS.ADD_MARKETPLACE_GROUPBY);
    },
    handleAddFilter({
      value,
      mode
    }) {
      if (!mode) {
        mode = HANDLE_ADD_FILTER_MODES.POPUP;
      }
      this.renderedFilterNames.add(value);
      this.$nextTick(() => {
        // Ensure DOM is updated before scrolling
        const filtersWrapper = this.$refs.filtersWrapper.$el; // Access the wrapper

        if (filtersWrapper) {
          filtersWrapper.scroll({
            behavior: "smooth",
            // Smooth scrolling effect
            left: filtersWrapper.scrollWidth // Scroll to the maximum scroll position
          });
        }
        if (mode === HANDLE_ADD_FILTER_MODES.POPUP) {
          // Find the ref
          let el = this.$refs[this.getFilterComponent(value).name];
          if (el) {
            if (Array.isArray(el) && el.length > 0) {
              el = el[0];
              if ("openDropdown" in el) {
                el.openDropdown();
              }
            }
          }
        }
      });
    },
    getFilterComponent(key) {
      return this.extraFilters[key];
    }
  }
};
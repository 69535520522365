export default (tab, view) => [{
  key: 'seller_name',
  title: "Seller Name",
  detail: 'The name of the seller.'
}, {
  key: 'period_agg',
  title: "Period",
  detail: 'The period of time the data is aggregated over.'
}, {
  key: 'sales_channel',
  detail: 'The sales channel the data is aggregated over.'
}, {
  key: 'revenue_excl_VAT',
  title: "Revenue (excl. VAT)",
  detail: 'Total income generated, excluding the Value Added Tax (VAT). This includes pending orders waiting for payment finalization.'
}, {
  key: 'total_quantity',
  title: "Total Quantity",
  detail: view === 'Advertising' || tab === 'Advertising' ? 'The number of units sold due to advertising efforts' : 'The number of items or products sold.'
}, {
  key: 'units_sold',
  title: "Units Sold",
  detail: view === 'Advertising' || tab === 'Advertising' ? 'The number of units sold due to advertising efforts' : 'The number of items or products sold.'
}, {
  key: 'units_sold_clicks_14_d',
  title: "Units Sold",
  detail: view === 'Advertising' || tab === 'Advertising' ? 'The number of units sold due to advertising efforts' : 'The number of items or products sold.'
}, {
  key: 'days_inventory',
  title: "Days of Inventory",
  detail: 'The estimated number of days remaining before being Out-Of-Stock with the inventory stored at Amazon Warehouses.'
}, {
  key: 'share_sku_sold',
  title: "Share SKU Sold",
  detail: 'The percentage of the total unique products sold out of the entire range of products in stock at Amazon Warehouses.'
}, {
  key: 'share_asin_sold',
  title: "Share ASIN Sold",
  detail: 'The percentage of the total unique products sold out of the entire range of products in stock at Amazon Warehouses.'
}, {
  key: 'lost_revenue_excl_VAT',
  title: "Lost Revenue (excl. VAT)",
  detail: 'The potential income not earned due to out-of-stock items (it excludes non orderable products).'
}, {
  key: 'TACOS',
  title: "Total Advertising Cost of Sale (TACOS)",
  detail: 'TACOS (Total Advertising Cost of Sale)\nYour advertising spend relative to the total revenue generated.'
}, {
  key: 'traffic_by_asin_buy_box_percentage',
  title: "Share Buybox",
  detail: 'The estimated percentage of time your product is featured in the "Buy Box" \
                                            on Amazon\'s product detail page (data checked once a day for Vendor account).'
}, {
  key: 'share_sponsored_sales',
  title: "Share Sponsored Sales",
  detail: 'The percentage of total sales generated through Pay-Per-Click (PPC) advertising.'
}, {
  key: 'sponsor_sales',
  title: "Sponsored Sales",
  detail: 'Sales that have resulted directly from your sponsored ads (14 days post click). \
                                            The conversion could have been made on another product than the click.'
}, {
  key: 'CVR',
  title: "Conversion Rate (CVR)",
  detail: 'CVR (Conversion Rate)\nThe percentage of users who made a purchase after clicking on your ad.'
}, {
  key: 'spend',
  title: "Spend",
  detail: 'The total amount of money spent on advertising.'
}, {
  key: 'ACOS',
  title: "Advertising Cost of Sale (ACOS)",
  detail: '(Advertising Cost of Sale)\nThe ratio of ad spend to sponsored sales.'
}, {
  key: 'cannibalization_cost',
  title: "Cannibalization Cost",
  detail: 'The estimated cost of advertising spend on users that would have purchased your products even without the ads.'
}, {
  key: 'sponsored_net_margin_after_cannibalization_cost',
  title: "Sponsored Net Margin After Cannibalization Cost",
  detail: 'The profit margin from sales that resulted directly from sponsored ads, \
                    after accounting for the costs of those ads and cannibalization.'
}, {
  key: 'sponsored_asin',
  title: "Sponsored ASIN",
  detail: 'The number of products sponsored during the period'
}, {
  key: 'match_type',
  title: "Match Type",
  detail: `
            The parameters that determine how closely the customer's search needs to match your keywords in your Amazon PPC campaign:<br/>
                \t<b>TARGETING_EXPRESSION_PREDEFINED</b>: refers to Amazon's built-in or automatic targeting criteria<br/>
                \t<b>TARGETING_EXPRESSION</b>: manually defined product or category targeting criteria<br/>
                \t<b>EXACT</b>: your ad is shown only when the exact phrase is typed by the customer, with no other words before, after, or in between.<br/>
                \t<b>PHRASE</b>: your ad will appear when users search for your exact keyword phrase in the order<br/>
                            you enter it, but there might be other words before or after that phrase.<br/>
                \t<b>BROAD</b>: your ad will appear when users' search terms contain all the keyword terms or their synonyms,<br/>
                    in any order. For example, if your broad match keyword is “women's hats,” your ad might be displayed<br/>
                    when a user searches for “buy hats for women,” "women's caps," or “ladies' hats.”<br/>
        `
}, {
  key: 'keyword_bid',
  title: "Keyword Bid",
  detail: `The maximum amount you'll pay when a shopper clicks your ad (last update)`
}, {
  key: 'clicks',
  title: "Clicks",
  detail: 'The number of times users have clicked on your ads.'
}, {
  key: 'impressions',
  title: "Impressions",
  detail: 'The number of times your ad was viewed.'
}, {
  key: 'CTR',
  title: "Click-Through Rate (CTR)",
  detail: `(Click-Through Rate)\nThe ratio of users who clicked on your ads to the total number of impressions.`
}, {
  key: 'position',
  title: "Position",
  detail: `The organic average rank of your product(s) on the search results on a specific keyword.`
}, {
  key: 'best_position',
  title: "Best Position",
  detail: `The best organic rank of your product(s) on the search results on a specific keyword.`
}, {
  key: 'targeting',
  title: "Targeting",
  detail: 'The PPC targeting strategy (words, asin…) defined in the campaign'
}, {
  key: 'sponsored_sales',
  title: "Sponsored Sales",
  detail: `Sales that have resulted directly from your sponsored ads (14 days post click).
              The conversion could have been made on another product than the click.`
}, {
  key: 'campaign_name',
  title: "Campaign Name",
  detail: 'Campaign Name'
}, {
  key: 'evolution_vs_previous',
  title: "Evolution vs Previous",
  detail: 'The evolution of revenue compared to the previous period'
}, {
  key: 'product_name',
  title: "Product Name",
  detail: 'Product name'
}, {
  key: 'margin_ccy',
  title: "Margin (ccy)",
  detail: "The difference between a product's selling price, production cost, and all direct Amazon fees."
}, {
  key: 'margin_rate',
  title: "Margin Rate",
  detail: 'The ratio of margin to selling price.'
}, {
  key: 'prep_fees',
  title: "Prep Fees",
  detail: 'The cost Amazon charges for preparing an item for shipment.'
}, {
  key: 'fba_fees',
  title: "FBA Fees",
  detail: 'The cost Amazon charges for fulfilling orders (if using FBA).'
}, {
  key: 'referal_fee',
  title: "Referal Fee",
  detail: 'The commission fee that Amazon charges sellers for each item sold.'
}, {
  key: 'tax_est',
  title: "Tax Estimation",
  detail: 'The part of the taxes already included in the price.'
}, {
  key: 'small_and_light',
  title: "Small and Light",
  detail: "Indicates if the sku is enrolled in the Amazon's Small and Light \
              program, an FBA option designed for small and lightweight products \
              offering reduced fulfillment costs to sellers and free shipping to customers."
}, {
  key: 'fulfilmment_program',
  title: "Fulfilment Program",
  detail: 'The method by which products are stored, packed, and shipped, such as Fulfillment by Amazon (FBA).'
}, {
  key: 'COGS',
  title: "Cost of Goods Sold (COGS)",
  detail: '(Cost of Goods Sold)\n\
                The cost to produce the goods, communicated by the brand.'
}, {
  key: 'currency',
  title: "Currency",
  detail: 'The currency used in the country where the product is sold.'
}, {
  key: 'price',
  title: "Price",
  detail: 'The price of the product.'
}, {
  key: 'buybox_winner',
  title: "Buybox Winner",
  detail: `Signifies if you were winning the "Buy Box" on Amazon's product detail page during the last check (once a day)`
}, {
  key: 'country_code',
  title: "Country Code",
  detail: 'The country where the product is sold.'
}, {
  key: 'fees_estimate',
  title: "Fees Estimate",
  detail: 'The total amount of fees Amazon charges for selling the product.'
}, {
  key: 'afn_total_inventory_days',
  title: "AFN Total Inventory Days",
  detail: 'The estimated number of days remaining before being out of stock with the inventory stored at Amazon Warehouses and not sold or already sent to Amazon.'
}, {
  key: 'alert_inventory_level_days',
  title: "Alert Inventory Level Days",
  detail: 'The number of days of inventory below which a future Out Of Stock could be expected if a new replenishment isn’t planned in the 7 coming days.'
}, {
  key: 'total_inventory_minus_threshold',
  title: "Total Inventory Minus Threshold",
  detail: 'The number of days of inventory minus the alert threshold.'
}, {
  key: 'afn_total_quantity',
  title: "AFN Total Quantity",
  detail: 'The number of units in stock at Amazon Warehouses and not already sold to customers or in transition to Amazon Warehouses.'
}, {
  key: 'daily_velocity',
  title: "Daily Velocity",
  detail: 'The average number of units sold daily when the product is available.'
}, {
  key: 'share_total_revenue',
  title: "Share Total Revenue",
  detail: 'The percentage of the total revenue generated by the product out of the total revenue generated by the seller.'
}, {
  key: 'CPC',
  title: "Cost Per Click (CPC)",
  detail: 'The average cost per click.'
}, {
  key: 'targeting_type_CPC',
  title: "Cost Per Click (CPC)",
  detail: 'The average cost per click.'
}, {
  key: 'campaign_budget',
  title: "Daily Budget",
  detail: 'The daily budget of the campaign.'
}, {
  key: 'campaign_group_budget',
  title: "Daily Budget",
  detail: 'The daily budget of the campaign.'
}, {
  key: 'campaign_group_name',
  title: 'Product Group',
  detail: 'The name of the product group.'
}, {
  key: 'ROAS',
  title: "ROAS",
  detail: 'Return on Advertising Spend (ROAS).\nThe ratio of revenue to ad spend.'
}, {
  key: 'organic_CVR',
  title: "Conversion Rate (CVR)",
  detail: 'CVR (Conversion Rate)\nUnits sold divided by page views'
}, {
  key: 'rating',
  title: "Rating",
  detail: 'Average rating of your listed product (data checked once a week).'
}, {
  key: 'page_views',
  title: 'Page Views',
  detail: 'Page views of your listed product.'
}, {
  key: 'active_asin_count',
  title: 'Active Asin',
  detail: 'The number of ASINs with an active offer compared to the total number of ASINs listed on Amazon Seller Central.'
}, {
  key: 'return_rate',
  title: 'Return Rate',
  detail: 'The percentage of quantities sold during the period that were returned by customers to Amazon.'
}, {
  key: 'listed_asin_count',
  title: '# active vs listed ASIN',
  detail: "The number of ASINs with an active offer compared to the total number of ASINs listed on Amazon Seller Central."
}, {
  key: 'ratings_total',
  title: "Reviews",
  detail: "Total number of reviews received."
}, {
  key: 'cannibalization_pc',
  title: "Cannibalization Cost",
  detail: 'The estimated cost of advertising spend on users that would have purchased your products even without the ads.'
}, {
  key: "percent_time_in_budget",
  title: "Time in budget",
  detail: "The percentage of time the campaigns remain active without exceeding their allocated budget."
}, {
  key: 'total_ROAS',
  title: "Total ROAS",
  detail: 'Return on Advertising Spend (ROAS).\nThe ratio of revenue to ad spend.'
}, {
  key: "pl_net_revenue",
  title: "Accounting Revenue",
  detail: "Total revenue, excluding taxes, recorded on the expedition date (differs from order date revenue shown in Performance). Represents sellout for Vendors. See details in the Profit and Loss module."
}, {
  key: "pl_contributive_margin",
  title: "Net Margin",
  detail: "Accounting Revenue minus direct and indirect costs. More details available in the Profit and Loss module."
}, {
  key: "pl_net_percentage_revenue",
  title: "% of revenue",
  detail: "Net Margin as a percentage of Accounting Revenue."
}, {
  key: "pl_fba_direct_pc",
  title: "FBA Direct Fees",
  detail: "Variable fulfillment fees for FBA. More details in the Profit and Loss module."
}, {
  key: "pl_amazon_commission_pc",
  title: "Amazon Commission",
  detail: " Referral fees for Sellers or the difference between sellout (minus taxes) and sell-in for Vendors. See details in the Profit and Loss module."
}, {
  key: "pl_fba_indirect_pc",
  title: "FBA Indirect Fees",
  detail: "Fees for inbound, storage, and adjustments. See more details in the Profit and Loss module."
}, {
  key: "pl_refund_pc",
  title: "Refunds & Guarantees",
  detail: "Net costs of refunds and guarantees accounted a the date of the refunds transaction - detail available in the Profit and Loss module."
}, {
  key: "pl_coop_pc",
  title: "Co-op Deductions",
  detail: "Vendor co-op charges as part of Amazon agreements. More details available in the Profit and Loss module."
}, {
  key: "pl_cogs_pc",
  title: "Cost of Goods Sold (COGS)",
  detail: "Costs associated with the Accounting Revenue."
}, {
  key: "pl_other_costs_pc",
  title: "Other Costs",
  detail: "Additional costs not covered in other categories. See details in the Profit and Loss module."
}, {
  key: "pl_advertising_costs",
  title: "Advertising Costs",
  detail: "SP (Sponsored Products) and SB (Sponsored Brands) ad spend, recorded on the date of the expense."
}, {
  key: "avg_unit_price",
  title: "Avg unit price",
  detail: "Revenue excluding tax divided by number of units sold"
}, {
  key: "ad_product",
  title: "Ad Format",
  detail: "Ad Formats"
}, {
  key: "share_of_voice",
  title: "Branded Share of Voice",
  detail: "The percentage of the top 4 Sponsored Product positions occupied by your products on branded keywords. This value is weighted by the 30-day impression volume for each keyword."
}];
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17c5d635"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = ["for"];
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_pause_icon = _resolveComponent("pause-icon");
  const _component_play_icon = _resolveComponent("play-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-switch-container', {
      simple: $props.simple
    }, {
      between: $props.between
    }, {
      state: $props.state
    }])
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    type: "checkbox",
    id: $data.id,
    onChange: _cache[1] || (_cache[1] = (...args) => $options.onChange && $options.onChange(...args))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelCheckbox, $options.value]]), _createElementVNode("label", {
    for: $data.id,
    class: "app-switch"
  }, [!$props.state ? (_openBlock(), _createElementBlock("span", _hoisted_3, [!$props.modelValue ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: $props.noIcon ? '' : 'cross',
    size: 12,
    color: "#fff"
  }, null, 8 /* PROPS */, ["icon"])) : (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "check2",
    size: 12,
    color: "#fff"
  }))])) : (_openBlock(), _createElementBlock("span", _hoisted_4, [!$props.modelValue ? (_openBlock(), _createBlock(_component_pause_icon, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_play_icon, {
    key: 1
  }))]))], 8 /* PROPS */, _hoisted_2)], 2 /* CLASS */);
}
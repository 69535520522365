import { mapState, mapMutations } from "vuex";
import moment from "moment";
import AppCardSeo from "@/components/AppCardSeo";
import AppInput from "@/components/AppInput";
import AppIcon from "@/components/AppIcon";
import AppPagination from "@/components/AppPagination";
import SeoTopSection from "@/components/SeoTopSection";
import ContentMixin from "@/mixins/ContentMixin.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import { filterOut, EVENTS } from "@/utils";
import { CSV_EXPORT_ROW_LIMIT } from "@/utils/constants";
import ContentOptimisationTableNew from "../components/seo/ContentOptimisationTableNew.vue";
import ContentOptimisationTableNewWithParents from "../components/seo/ContentOptimisationTableNewWithParents.vue";
import ContentExportDropDown from "@/components/ContentExportDropDown.vue";
export default {
  name: "SEOPage",
  mixins: [ContentMixin, DownloadMixin, ContentDisplayRulesMixin],
  components: {
    AppCardSeo,
    AppIcon,
    AppInput,
    AppPagination,
    SeoTopSection,
    ContentOptimisationTableNew,
    ContentOptimisationTableNewWithParents,
    ContentExportDropDown
  },
  data() {
    return {
      dataLoading: false,
      search: "",
      editSingleItem: false,
      downloadParamPerPage: 20,
      //let's keep it 20 as each content is big per page.
      selectedOption: null,
      isDownloadRunning: false
    };
  },
  computed: {
    ...mapState({
      pushModal: state => state.content.pushModal,
      sellers_filter: state => state.content.sellers_filter,
      total_count: state => state.content.total_count,
      page: state => state.content.page,
      perPage: state => state.content.perPage,
      currentTab: state => state.seo.currentTabValue,
      current_workspace: state => state.workspace.current_workspace
    }),
    sellersFromFilter() {
      return this.sellers_filter;
    }
  },
  methods: {
    ...mapMutations(["contentPageSet", "contentPerPageSet", "contentsKeyWordListSet"]),
    async changePage(newPage) {
      this.contentPageSet(newPage);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async changeElements(elementsPerPage) {
      this.contentPageSet(1);
      this.contentPerPageSet(elementsPerPage);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    onResetPagingValues() {
      this.contentPageSet(1);
      this.contentPerPageSet(20);
    },
    onDataLoading($event) {
      this.dataLoading = $event;
    },
    onEditSingleItem($event) {
      this.editSingleItem = $event;
    },
    async onPushModalCancel($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_CANCEL);
    },
    async onPushModalSubmitModifiedContents($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT);
    },
    async onPushModal($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_OPEN);
    },
    async searchItem($event) {
      const value = $event.split(",").filter(v => v.length > 0);
      this.contentsKeyWordListSet(value);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async handleSelectionDownloadTypeCSV(download_option) {
      if (download_option === "omny_content") {
        this.downloadConentsCSV("omny");
      } else if (download_option === "all_content") {
        this.downloadConentsCSV("all");
      }
    },
    async downloadConentsCSV(download_type) {
      try {
        let csv_headers = this.getHeadersforMappingContentDataCSV();
        const totalPages = this.totalPagetoDownload();
        if (this.totalPages < 0) {
          this.throwIfError({});
        }
        window.$bus.trigger("showSuccess", {
          visible: true,
          message: "CSV download request initiated successfully.",
          delay: 5999 // Show alert less than 1 minute
        });
        this.isDownloadRunning = true;
        let fileName = `OMNY_${this.current_workspace.workspace_name}_${moment().format("YYYY_MM_DD_HH:mm")}_${download_type === "omny" ? "omny_content_data.csv" : "all_content_data.csv"}`;
        let rowGenerationFunction = download_type === "omny" ? this.downloadContentDataRowsOmny : this.downloadContentDataRowsAll;
        let csv_string = await this.generateCsvStringWithPagination(csv_headers, totalPages, rowGenerationFunction);
        this.throwIfError(csv_string);
        this.downloadCsv(csv_string, fileName);
        this.isDownloadRunning = false;
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
      }
    },
    async downloadContentDataRowsOmny(currentPage) {
      let result_download = await this.fetchContentFilterDataToDownload(currentPage, CSV_EXPORT_ROW_LIMIT //this.downloadParamPerPage
      );
      let contentAttributes = this.getContentAttributesCSV();
      let headersMapping = this.getHeadersforMappingContentDataCSV();
      let processedOmnyContents = result_download.map(content => {
        let result = {};
        Object.keys(headersMapping).forEach(key => {
          result[key] = content[key] || ""; // Default to an empty string if the attribute is undefined
        });
        const omnyVersion = content.omny_version || {}; // Get the omny_version object or an empty object if undefined

        // Update result with omny_version data for specified attributes, if available
        Object.keys(contentAttributes).forEach(key => {
          if (omnyVersion[key]) {
            result[key] = omnyVersion[key];
          }
        });
        result["push_status"] = this.calculatePushStatus(content);
        result["last_push_date"] = this.contentLastPushDate(content).text;
        result["seller_name"] = this.getSellerName(result["seller_id"]);
        result["country_code"] = this.getCountryCode(this.getCountry(result["marketplace_id"]));
        return result;
      });
      return processedOmnyContents;
    },
    async downloadContentDataRowsAll(currentPage) {
      let result_download = await this.fetchContentFilterDataToDownload(currentPage, CSV_EXPORT_ROW_LIMIT //this.downloadParamPerPage
      );
      let contentAttributes = this.getContentAttributesCSV();
      let headersMapping = this.getHeadersforMappingContentDataCSV();
      let processedPreferredContents = result_download.map(content => {
        let result = {};

        // First assign all content keys with empty strings or their respective values from the content
        Object.keys(headersMapping).forEach(key => {
          result[key] = content[key] || ""; // Default to an empty string if the attribute is undefined
        });

        // Prepare the version objects, defaulting to empty objects if undefined
        const omnyVersion = content.omny_version || {};
        const amazonSellerCentralVersion = content.amazon_seller_central_version || {};
        const amazonVersion = content.amazon_version || {};

        // Update result with the first available version data for specified attributes
        Object.keys(contentAttributes).forEach(key => {
          if (omnyVersion[key]) {
            result[key] = omnyVersion[key];
          } else if (amazonSellerCentralVersion[key]) {
            result[key] = amazonSellerCentralVersion[key];
          } else if (amazonVersion[key]) {
            result[key] = amazonVersion[key];
          }
        });
        result["push_status"] = this.calculatePushStatus(content);
        result["last_push_date"] = this.contentLastPushDate(content).text;
        result["seller_name"] = this.getSellerName(result["seller_id"]);
        result["country_code"] = this.getCountryCode(this.getCountry(result["marketplace_id"]));
        return result;
      });
      return processedPreferredContents;
    },
    totalPagetoDownload() {
      return Math.ceil(this.total_count / CSV_EXPORT_ROW_LIMIT); // this.downloadParamPerPage
    },
    calculatePushStatus(item) {
      const attributeTextStyles = {
        product_name_style: this.contentToDisplayTextStyle(item, "product_name"),
        bullet_point_1_style: this.contentToDisplayTextStyle(item, "bullet_point_1"),
        bullet_point_2_style: this.contentToDisplayTextStyle(item, "bullet_point_2"),
        bullet_point_3_style: this.contentToDisplayTextStyle(item, "bullet_point_3"),
        bullet_point_4_style: this.contentToDisplayTextStyle(item, "bullet_point_4"),
        bullet_point_5_style: this.contentToDisplayTextStyle(item, "bullet_point_5"),
        generic_keyword_style: this.contentToDisplayTextStyle(item, "generic_keyword"),
        product_description_style: this.contentToDisplayTextStyle(item, "product_description")
      };
      let statusPush = this.contentPushIconToDisplay(item, attributeTextStyles);
      return statusPush ? statusPush.status : null;
    },
    getSellerName(sellerId) {
      let seller = this.sellersFromFilter.filterState.selected.find(item => item.id === sellerId);
      if (seller) {
        return seller.name;
      }
      seller = this.sellersFromFilter.filterState.list.find(item => item.id === sellerId);
      return seller ? seller.name : "Unknown Seller"; // Return 'Unknown Seller' if not found
    }
  }
};
import { mapGetters } from "vuex";
import AppIcon from "@/components/AppIcon";
import AiGenerationTabs from "@/components/AiGenerationTabs";
import AiGenerationMarketDefinition from "@/components/AiGenerationMarketDefinition";
import AiGenerationProductData from "@/components/AiGenerationProductData";
import AppButton from "@/components/AppButton";
import AiGenerationCategoriesSelection from "@/components/AiGenerationCategoriesSelection";
import { generateAiProposition } from "@/components/aiCategory/service";
import { isObjectEmpty } from "@/utils";
export default {
  name: "AiGeneration",
  emits: ["close", "aigenerate", "type-change"],
  components: {
    AppIcon,
    AiGenerationTabs,
    AppButton,
    AiGenerationMarketDefinition,
    AiGenerationProductData,
    AiGenerationCategoriesSelection
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    all_versions_list: {
      type: Object,
      default: () => {}
    },
    content_amazon_link: {
      type: String,
      default: ""
    }
  },
  expose: ["getCurrentType"],
  watch: {
    product(newVal) {
      //console.log('[AI Generation] watch product value');
      //console.log(newVal);
      //this.selected_product_data = newVal;
    }
  },
  created() {
    //console.log('[AI Generation created phase]');
    //console.log(this.product);
  },
  data() {
    return {
      competitors: [],
      benefits: [],
      usecases: [],
      selected_competitors: [],
      generatedProposition: [],
      selected_product_data: {},
      selected_benefits: [],
      selected_usecases: [],
      userInput: "",
      userProductTitle: "",
      tabs: [
      /* {
        value: "market_definition",
        text: "Market definition",
        description:
          "Begin your journey by defining the competitive landscape for your product on Amazon. This crucial step helps you understand the market and position your product effectively.",
        canSwitchTab: this.canSwitchProductBenefitsTab,
        subtitle: "",
      },*/
      {
        value: "product_data",
        text: "Product data",
        description: "Select the product data sources to be used to generate a new product listing",
        canSwitchTab: this.canSwitchProductUseCasesTab,
        subtitle: ""
      }, {
        value: "product_benefits",
        text: "Product benefits",
        description: "OmnyAI allows you to use your product’s positive reviews and other aspects that your customers appreciate from other products for your category. This content strategy would highlight the best parts about your product authentically, ultimately increasing conversions.",
        canSwitchTab: this.canSwitchProductDataTab,
        subtitle: "Select between 3 and 5 benefits for the most effective results."
      }, {
        value: "product_usecases",
        text: "Product usecases",
        description: "OmnyAI allows you to use your product’s positive reviews and other aspects that your customers appreciate from other products for your category. This content strategy would highlight the best parts about your product authentically, ultimately increasing conversions.",
        canSwitchTab: this.canSwitchProductBenefitsTab,
        subtitle: ""
      }],
      current_tab: "product_data",
      actions: {
        leftLoading: false,
        rightLoading: false,
        texts: {
          /*market_definition: {
            left: "Previous",
            right: "Next",
          },*/
          product_data: {
            left: "Previous",
            right: "Next"
          },
          product_benefits: {
            left: "Previous",
            right: "Next"
          },
          product_usecases: {
            left: "Previous",
            right: "Generate AI proposition"
          }
        }
      }
    };
  },
  computed: {
    current_description() {
      let tab = this.tabs.find(aTab => aTab.value == this.current_tab);
      return tab.description;
    },
    current_title() {
      let tab = this.tabs.find(aTab => aTab.value == this.current_tab);
      return tab.text;
    },
    current_subtitle() {
      let tab = this.tabs.find(aTab => aTab.value == this.current_tab);
      return tab.subtitle;
    },
    disableLeftAction() {
      return this.current_tab == "product_data";
    },
    disableRightAction() {
      return this.actions.rightLoading || this.disableRightActions[this.current_tab]();
    },
    leftAction() {
      return this.actions.texts[this.current_tab].left;
    },
    rightAction() {
      return this.actions.texts[this.current_tab].right;
    },
    rightActions() {
      return {
        // market_definition: this.changeTabProductData,
        product_data: this.changeTabProductBenefits,
        product_benefits: this.changeTabProductUseCases,
        product_usecases: this.handleGenerateAIProposition
      };
    },
    leftActions() {
      return {
        // market_definition: null,
        product_data: null,
        product_benefits: this.changeTabProductData,
        product_usecases: this.changeTabProductBenefits
      };
    },
    disableRightActions() {
      return {
        // market_definition: this.disableRightTabMarketDefinition,
        product_data: this.disableRightTabProductData,
        product_benefits: this.disableRightTabProductBenefits,
        product_usecases: this.disableRightTabProductUseCases
      };
    },
    getSelectedProductData() {
      return this.selected_product_data;
    },
    getUserInputData() {
      return this.userInput;
    },
    productdataClass() {
      return {
        product_data: this.current_tab == 'product_data' ?? false
      };
    }
  },
  watch: {
    current_tab(val) {
      this.$emit("type-change", val);
    }
  },
  methods: {
    handleUserInputAIGeneration(userCustomInput) {
      this.userInput = userCustomInput;
    },
    handleUserProductTitle(userProductTitle) {
      this.userProductTitle = userProductTitle;
    },
    onClose() {
      this.$emit("close");
    },
    onSelect(id) {
      this.selected = id;
    },
    onChangeTab(tab) {
      this.current_tab = tab;
      this.$emit("type-change", tab);
    },
    changeTabProductData() {
      return this.onChangeTab("product_data");
    },
    disableRightTabMarketDefinition() {
      return this.selected_competitors <= 0;
    },
    changeTabMarketDefinition() {
      return this.onChangeTab("market_definition");
    },
    disableRightTabProductData() {
      const conditionPerVersion = {
        'Amazon version': !this.selected_product_data.product_name || !this.selected_product_data.brand || !this.userProductTitle,
        'Local version': !this.product.product_name || !this.product.brand || !this.userProductTitle,
        'Custom version': !this.selected_product_data.product_name || !this.selected_product_data.brand || this.userInput.length < 100
      };
      return isObjectEmpty(this.selected_product_data) || conditionPerVersion[this.selected_product_data?.title];
    },
    changeTabProductBenefits() {
      return this.onChangeTab("product_benefits");
    },
    changeTabProductUseCases() {
      return this.onChangeTab("product_usecases");
    },
    canSwitchMarketDefinitionTab() {
      return !this.disableRightActions["market_definition"]();
    },
    canSwitchProductDataTab() {
      return !this.disableRightActions["product_data"]();
    },
    canSwitchProductBenefitsTab() {
      return !this.disableRightActions["product_benefits"]();
      ;
    },
    canSwitchProductUseCasesTab() {
      return true;
    },
    disableRightTabProductBenefits() {
      return this.selected_benefits.length < 3;
    },
    disableRightTabProductUseCases() {
      return this.selected_usecases.length <= 0;
    },
    onSelectedCompetitors(val) {
      this.selected_competitors = val;
    },
    onGeneratedProposition(val) {
      this.generatedProposition = val;
    },
    rightActionClick() {
      return this.rightActions[this.current_tab]();
    },
    leftActionClick() {
      return this.current_tab == "product_data" ? null : this.leftActions[this.current_tab]();
    },
    handleGenerateAIProposition() {
      this.actions.rightLoading = true;

      //this is checking whether user has selected any version such as amazon version.
      const useSelectedProductData = this.selectedProductData && this.selectedProductData.title !== null && this.selectedProductData.title !== "Local version";

      //based on the user selected version,return the value associated with the version.
      const getPropertyValue = property => {
        return useSelectedProductData ? this.selectedProductData[property] : this.product[property];
      };
      const params = {
        asin: this.selectedProductData?.asin ?? this.product.asin,
        marketplace_id: this.selectedProductData?.marketplace_id ?? this.product.marketplace_id,
        seller_id: this.selected_product_data?.seller_id ?? this.product.seller_id,
        category_id: this.selected_product_data?.category_id ?? this.product.category_id,
        benefits_list: this.selected_benefits,
        use_cases_list: this.selected_usecases,
        brand: this.selected_product_data?.brand ?? this.product.brand,
        product_name: getPropertyValue("product_name"),
        product_description: getPropertyValue("product_description"),
        user_text: this.userInput,
        user_product_title: this.userProductTitle,
        bullet_points: [getPropertyValue("bullet_point_1"), getPropertyValue("bullet_point_2"), getPropertyValue("bullet_point_3"), getPropertyValue("bullet_point_4"), getPropertyValue("bullet_point_5")].filter(Boolean)
      };

      //existing working solution
      // const params = {
      //   asin: this.selected_product_data?.asin ?? this.product.asin,
      //   marketplace_id:
      //     this.selected_product_data?.marketplace_id ??
      //     this.product.marketplace_id,
      //   benefits_list: this.selected_benefits,
      //   use_cases_list: this.selected_usecases,
      //   seller_id:
      //     this.selected_product_data?.seller_id ?? this.product.seller_id,
      //   category_id:
      //     this.selected_product_data?.category_id ?? this.product.category_id,
      //   product_name:
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product.product_name
      //       : this.selected_product_data.product_name,
      //   product_description:
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product.product_description
      //       : this.selected_product_data.product_description,
      //   user_text: this.userInput,
      //   bullet_points: [
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product?.bullet_point_1 ?? ""
      //       : this.selected_product_data?.bullet_point_1 ?? "",
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product?.bullet_point_2 ?? ""
      //       : this.selected_product_data?.bullet_point_2 ?? "",
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product?.bullet_point_3 ?? ""
      //       : this.selected_product_data?.bullet_point_3 ?? "",
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product?.bullet_point_4 ?? ""
      //       : this.selected_product_data?.bullet_point_4 ?? "",
      //     !this.selected_product_data ||
      //     this.selected_product_data.title === null ||
      //     this.selected_product_data.title === "Local version"
      //       ? this.product?.bullet_point_5 ?? ""
      //       : this.selected_product_data?.bullet_point_5 ?? "",
      //   ].filter(Boolean),
      // };

      //keep this code for now as it is the first implementation and if we want to generate list from the amazon listing.
      // const params = {
      //   asin: this.selected_product_data.asin,
      //   marketplace_id: this.selected_product_data.marketplace_id,
      //   benefits_list: this.selected_benefits,
      //   use_cases_list: this.selected_usecases,
      //   seller_id: this.selected_product_data.seller_id,
      //   category_id: this.selected_product_data.category_id,
      //   product_name: this.selected_product_data.product_name,
      //   product_description: this.selected_product_data.product_description,
      //   user_text:this.userInput,
      //   bullet_points: [
      //     this.selected_product_data?.bullet_point_1 ?? "",
      //     this.selected_product_data?.bullet_point_2 ?? "",
      //     this.selected_product_data?.bullet_point_3 ?? "",
      //     this.selected_product_data?.bullet_point_4 ?? "",
      //     this.selected_product_data?.bullet_point_5 ?? "",
      //   ].filter(Boolean),
      // };
      generateAiProposition({
        params
      }).then(result => {
        window.$bus.trigger("showSuccess", {
          visible: true,
          dismissSecs: 5,
          message: "AI proposition generated successfully"
        });

        //console.log(result);
        this.$emit("aigenerate", result);
      }).catch(err => {
        //console.log(err);
        window.$bus.trigger("showDanger", {
          visible: true,
          dismissSecs: 5,
          message: "Error occured generating AI proposition"
        });
      }).finally(() => {
        this.actions.rightLoading = false;
      });
    },
    onSelectedProductData(val) {
      //console.log("[selected product in ai generation]");
      //console.log(val);
      this.selected_product_data = val;
    },
    onSelectedProductBenefits(val) {
      this.selected_benefits = val.length > 5 ? val.slice(0, 5) : val;
    },
    onSelectedProductUseCases(val) {
      this.selected_usecases = val;
    },
    getCurrentType() {
      return this.current_tab;
    },
    setCompetitorsBenefitsAndUsecases(val) {
      this.competitors = val.competitors;
      this.usecases = val.usecases_array;
      this.benefits = val.benefits_array;
    },
    setBenefits(val) {
      this.benefits = val;
    },
    setUseCases(val) {
      this.usecases = val;
    }
  }
};
import { CurrencyValue, HugValue, NumberValue, PercentageValue } from '@/components/tableElements';
import FlexRow from '@/components/FlexRow.vue';
import { mapState } from 'vuex';
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
export default {
  name: "PerformanceProductTableTotal",
  mixins: [LiteTableMixin],
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    total: {
      type: Object,
      default: () => ({})
    },
    currencyConfig: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    evolutionType: {
      type: String
    }
  },
  components: {
    CurrencyValue,
    HugValue,
    NumberValue,
    PercentageValue,
    FlexRow,
    EvolutionValue,
    SlotWithEvolutionValue
  },
  computed: {
    ...mapState({
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    revenueEvol() {
      console.log('Total', this.total, this.evolCategory);
      return this.periodCategory === 'prev_period' ? this.total.revenue_excl_VAT_evol : this.total.last_year_revenue_excl_VAT_evol;
    }
  },
  methods: {
    isTotalColumn(field) {
      return ['seller_name', 'sales_channel', 'campaign_group_name', 'campaign_name', 'targeting', 'product_name'].includes(field.key);
    }
  }
};
import ExportDropDown from "@/components/ExportDropDown.vue";
import AppIcon from "@/components/AppIcon";
import CSVIcon from "@/components/icons/CSVIcon.vue";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
export default {
  name: "ContentExportDropDown",
  emits: ["download-content-csv"],
  components: {
    ExportDropDown,
    AppIcon,
    CSVIcon,
    AppDropdownItem
  },
  data() {
    return {
      export_options: [{
        value: "omny_content",
        text: "Export Omny Content",
        sub_category: "omny_content",
        icon: "CSVIcon"
      }, {
        value: "all_content",
        text: "Export All Content",
        sub_category: "all_content",
        icon: "CSVIcon"
      }],
      export_options_categories: ["omny_content", "all_content"]
    };
  },
  props: {
    isDownloadRunning: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async handleDownload(download_option) {
      console.log("[handle download clicked]");
      this.$emit("download-content-csv", download_option);
    }
  }
};
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_popover = _resolveComponent("b-popover");
  return _openBlock(), _createElementBlock("div", {
    id: `attached-to-${$props.target}`,
    style: _normalizeStyle($props.styles)
  }, [_createVNode(_component_b_popover, {
    target: $props.target,
    click: false,
    triggers: $props.triggers,
    customClass: `wrap-with-popover popover ${$props.customClass}`,
    placement: $props.placement,
    container: $props.container,
    boundary: $props.boundary,
    strategy: $props.strategy,
    delay: {
      show: 0,
      hide: 0
    },
    style: _normalizeStyle({
      ['--bs-popover-width']: $props.width,
      ['--bs-popover-padding']: $props.padding
    })
  }, {
    title: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "content")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["target", "triggers", "customClass", "placement", "container", "boundary", "strategy", "style"]), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default")])], 12 /* STYLE, PROPS */, _hoisted_1);
}
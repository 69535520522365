import moment from "moment";
import { isNull, mergeWith } from "lodash";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AppCardCustom from "@/components/AppCardCustom";
import AppPopover from "@/components/AppPopover";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import PpcGraph from "@/components/PpcGraph";
import PpcStrategiesModal from "@/components/PpcStrategiesModal";
import PercentageValue from "@/components/tableElements/PercentageValue";
import { CurrencyValue } from "@/components/tableElements";
import FlexRow from "./FlexRow.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { EVENTS, getCurrencySymbol, isObjectEmpty } from "../utils";
export default {
  name: "PpcStrategies",
  components: {
    AppCardCustom,
    AppPopover,
    AppButton,
    AppIcon,
    PpcGraph,
    PpcStrategiesModal,
    PercentageValue,
    CurrencyValue,
    FlexRow,
    WrapWithPopover
  },
  props: {
    currency: {
      type: String
    }
  },
  emits: ["deleteStrategy"],
  async mounted() {
    this.autoScrollToToday();
    window.$bus.on(EVENTS.PPC_STRATEGY_AUTO_SCROLL, this.autoScrollToToday);
  },
  // updated() {
  //   this.autoScrollToToday();
  // },
  unmounted() {
    window.$bus.off(EVENTS.PPC_STRATEGY_AUTO_SCROLL, this.autoScrollToToday);
  },
  data() {
    return {
      hoverWeek: null,
      hoverRow: null,
      hoverCampaignId: null,
      maximum_budget: 0,
      isModalVisible: false,
      startWeek: null,
      deleteModalVisible: false,
      deleteStrategyWeek: null,
      strategyType: null,
      confirmUpdate: false,
      updateConfirmed: null
    };
  },
  computed: {
    ...mapState({
      strategies: state => state.ppc_settings.strategies,
      currentWeek: state => state.ppc_settings.currentWeek.clone(),
      weeksRange: state => state.ppc_settings.weeksRange
    }),
    isEditStrategy() {
      return this.strategyType === "edit";
    },
    updatedWeeksStrategy() {
      let result = this.weeksRange.map(currentWeekData => {
        const prevWeek = currentWeekData.moment.clone().subtract(1, "week");
        const nextWeek = currentWeekData.moment.clone().add(1, "week");
        const prevWeekData = this.weeksRange.find(weekData => weekData.week == prevWeek.format("YYYY-MM-DD"));
        const nextWeekData = this.weeksRange.find(weekData => weekData.week == nextWeek.format("YYYY-MM-DD"));
        let edit = this.calculateEditFromStrategy(currentWeekData, prevWeekData, nextWeekData);
        let status = this.calculateStatusForStrategy(currentWeekData, prevWeekData, nextWeekData);
        let products_activated_diff = currentWeekData?.products_activated ? this.calculateChangeInProductsActivatedForStrategy(currentWeekData, prevWeekData, nextWeekData) : {};
        let budget = currentWeekData?.available_budget_for_update ? this.calculateWeekBudgets(currentWeekData, prevWeekData, nextWeekData) : {};
        return {
          ...mergeWith(currentWeekData, {
            edit: edit,
            budget: budget,
            products_activated_diff: products_activated_diff,
            maximum_budget: this.maximum_budget,
            ...status
          }, (o, s) => isNull(s) ? o : s)
        };
      });
      return result;
    },
    rowClassName() {
      return (week, row) => {
        const result = [row == "week" ? "week" : "content-row", `row-${row}`];
        if (week == this.hoverWeek && row == this.hoverRow) {
          result.push("current");
        } else {
          if (week == this.hoverWeek || row == this.hoverRow) {
            result.push("sibling");
          } else {
            const strategy = this.weeksRange.find(item => item.week == week);
            if (!strategy) {
              result.push("before");
            } else {
              const id = strategy.strategy_id;
              if (id && id == this.hoverCampaignId) {
                result.push("sibling");
              }
            }
          }
        }
        return result.join(" ");
      };
    },
    saturationData() {
      return value => {
        const className = ["saturation"];
        let tooltip = "";
        if (value >= 0.7) {
          className.push("good");
          tooltip = "Good utilization";
        } else {
          if (value < 0.2) {
            className.push("low");
            tooltip = "Low utilization";
          } else {
            className.push("moderate");
            tooltip = "Moderate utilization";
          }
        }
        return {
          className: className.join(" "),
          tooltip
        };
      };
    },
    currentWeekString() {
      return this.currentWeek.clone().format("YYYY-MM-DD");
    },
    lastWeekString() {
      return this.currentWeek.clone().subtract(1, "week").format("YYYY-MM-DD");
    },
    nextWeekString() {
      return this.currentWeek.clone().add(1, "week").format("YYYY-MM-DD");
    },
    nextWeekDefaultData() {
      let nextWeek = this.currentWeek.clone().add(1, "week");
      return this.formatInStrategy(nextWeek);
    },
    isFutureStrategy() {
      return week => {
        return this.nonPastStrategyCondition(week) && this.nonStartedStrategyCondition(week);
      };
    }
  },
  methods: {
    ...mapMutations(["ppcSettingsWeeksRangeSet"]),
    getCurrencySymbol,
    autoScrollToToday() {
      const today = this.$refs.today;
      if (today && today.length) {
        const contentScroll = this.$refs.contentScroll;
        const scrollPosition = today[0].offsetLeft - (contentScroll.offsetWidth - today[0].offsetWidth) / 2;
        contentScroll.scrollLeft = scrollPosition;
      }
    },
    autoScrollToWeek(week) {
      const weekRef = this.$refs[week];
      if (weekRef && weekRef.length) {
        const contentScroll = this.$refs.contentScroll;
        const scrollPosition = weekRef[0].offsetLeft;
        contentScroll.scroll({
          behavior: "smooth",
          left: scrollPosition
        });
      }
    },
    onWheel(e) {
      const contentScroll = this.$refs.contentScroll;
      contentScroll.scrollLeft += e.deltaY;
    },
    onEnter(week, row, status) {
      this.hoverWeek = week;
      this.hoverRow = status != "after" ? row : null;
    },
    onLeave() {
      this.hoverWeek = null;
      this.hoverRow = null;
    },
    onStrategyEnter(week) {
      this.hoverCampaignId = week.strategy_id;
    },
    onStrategyLeave() {
      this.hoverCampaignId = null;
    },
    pastStrategyCondition(week) {
      return this.currentWeek.clone().isSameOrAfter(week.moment.clone());
    },
    nonPastStrategyCondition(week) {
      return this.currentWeek.clone().isSameOrBefore(week.moment.clone());
    },
    nonStartedStrategyCondition(week) {
      return !!week?.strategy && !isObjectEmpty(week?.strategy) && this.currentWeek.clone().isBefore(moment(week.start_date));
    },
    isCurrentWeekCondition(week) {
      return this.currentWeek.clone().format("YYYY-MM-DD") == week.week;
    },
    showStrategyEditForWeek(week) {
      // if (week?.week == "2024-10-07") {
      //   console.log("ACD2S2AQ0CCGV_2870509756146287_ATVPDKIKX0DER_2024-10-07");
      // }
      // if (week?.week == "2024-09-30") {
      //   console.log("ACD2S2AQ0CCGV_2870509756146287_ATVPDKIKX0DER_2024-10-07");
      // }
      let nonPastStrategyCondition = this.nonPastStrategyCondition(week);
      let isCurrentWeekCondition = this.isCurrentWeekCondition(week);
      let hoverWeekCondition = this.hoverCampaignId == week.strategy_id && this.hoverWeek == week.week;
      let nonEmptyStrategyCondition = !!week?.strategy && !isObjectEmpty(week?.strategy);
      return nonPastStrategyCondition && (hoverWeekCondition && !isCurrentWeekCondition && nonEmptyStrategyCondition || hoverWeekCondition && isCurrentWeekCondition && !nonEmptyStrategyCondition);
    },
    onCreateOrEditStrategyForWeek(weekData, type = "create") {
      this.strategyType = type;
      let weeks = this.weeksRange.filter(week => !!week?.strategy && !isObjectEmpty(week?.strategy)).map(({
        start_date,
        end_date
      }) => ({
        start_date,
        end_date
      }));
      const lastWeek = weeks[weeks.length - 1];
      const endDate = moment(lastWeek?.end_date).startOf("isoWeek").add(1, "weeks");
      this.startWeekStrategy = weekData ? weekData : this.formatInStrategy(endDate);
      this.isModalVisible = true;
      this.autoScrollToWeek(this.startWeekStrategy?.week);
    },
    onDeleteStrategy(week) {
      this.deleteModalVisible = true;
      this.deleteStrategyWeek = week;
    },
    handleDeleteStrategy() {
      this.$emit("delete-strategy", this.deleteStrategyWeek);
      this.deleteModalVisible = false;
    },
    onModalClose() {
      this.startWeekStrategy = null;
      this.isModalVisible = false;
    },
    calculateEditFromStrategy(thisWeekData, prevWeekData, nextWeekData) {
      // if (thisWeekData?.week == "2024-10-07") {
      //   console.log("ACD2S2AQ0CCGV_2870509756146287_ATVPDKIKX0DER_2024-10-07");
      // }

      let edit = "";
      if (!nextWeekData?.strategy || !thisWeekData?.strategy || !!thisWeekData?.strategy && !!nextWeekData?.strategy && nextWeekData?.strategy_id != thisWeekData?.strategy_id) {
        edit = "end";
      }
      if (!prevWeekData?.strategy || !!thisWeekData?.strategy && !!prevWeekData?.strategy && prevWeekData?.strategy_id != thisWeekData?.strategy_id) {
        if (edit == "end") {
          edit = "single";
        } else {
          edit = "start";
        }
      }
      if (!!thisWeekData?.strategy && !!prevWeekData?.strategy && prevWeekData?.strategy_id == thisWeekData?.strategy_id && !!nextWeekData?.strategy && nextWeekData?.strategy_id == thisWeekData?.strategy_id) {
        edit = "middle";
      }
      return edit;
    },
    isDifferentStrategy(strategy1, strategy2) {
      return !strategy2?.strategy || !strategy1?.strategy || !!strategy1?.strategy && !!strategy2?.strategy && strategy2?.strategy_id !== strategy1?.strategy_id;
    },
    calculateStatusForStrategy(currentWeekData, prevWeekData, nextWeekData) {
      let status = "middle";
      let id = Math.floor(Math.random() * 9000) + 1000;
      if (this.isDifferentStrategy(currentWeekData, prevWeekData)) {
        status = this.isDifferentStrategy(currentWeekData, nextWeekData) ? "single" : "start";
      } else if (this.isDifferentStrategy(currentWeekData, nextWeekData)) {
        status = "end";
      }
      return {
        strategy_status: status,
        id
      };
    },
    calculateChangeInProductsActivatedForStrategy(currentWeekData, prevWeekData, nextWeekData) {
      let products_activated_diff = {
        type: null,
        value: null
      };
      const diff = (currentWeekData?.products_activated ?? 0) - (prevWeekData?.products_activated ?? 0);
      if (diff > 0) {
        products_activated_diff = {
          type: "positive",
          value: `+${diff}`
        };
      } else if (diff < 0) {
        products_activated_diff = {
          type: "negative",
          value: diff
        };
      } else {
        products_activated_diff = {
          type: "neutral",
          value: diff
        };
      }
      return products_activated_diff;
    },
    calculateWeekBudgets(currentWeekData, prevWeekData, nextWeekData) {
      // Round all values to integer
      let current_budget = Math.round(currentWeekData.available_budget_for_update);
      let current_spend = Math.round(currentWeekData.current_week_spend);
      let current_budget_forecast = Math.round(currentWeekData.budget_forecast);
      const budget_data = {
        current: current_budget,
        prev: Math.round(prevWeekData?.available_budget_for_update),
        next: Math.round(nextWeekData?.available_budget_for_update)
      };
      const spend_data = {
        current: current_spend,
        prev: Math.round(prevWeekData?.current_week_spend),
        next: Math.round(nextWeekData?.current_week_spend),
        current_forecast: current_budget_forecast,
        next_forecast: Math.round(nextWeekData?.budget_forecast)
      };
      this.maximum_budget = Math.max(this.maximum_budget, current_budget ?? 0, current_spend ?? 0);
      return {
        budget: budget_data,
        spend: spend_data
      };
    },
    formatInStrategy(date) {
      return {
        moment: date,
        title: date.format("DD") + " " + date.format("MMM"),
        week: date.format("YYYY-MM-DD"),
        week_status: "after",
        budget: {}
      };
    }
  }
};
export const Currencies = [{
  currency: "&#x52;&#x24; BRL",
  id: "&#x52;&#x24; BRL",
  region: "South America",
  country: "Brazil",
  symbol: "BRL"
}, {
  currency: "&#x24; CAD",
  id: "&#x24; CAD",
  region: "North America",
  country: "Canada",
  symbol: "CAD"
}, {
  currency: "&#x24; MXN",
  id: "&#x24; MXN",
  region: "North America",
  country: "Mexico",
  symbol: "MXN"
}, {
  currency: "&#x24; USD",
  id: "&#x24; USD",
  region: "North America",
  country: "United States",
  symbol: "USD"
}, {
  currency: "&#x62f;&#x2e;&#x625; AED",
  id: "&#x62f;&#x2e;&#x625; AED",
  region: "Middle East",
  country: "United Arab Emirates",
  symbol: "AED"
}, {
  currency: "&#8364; EUR",
  id: "&#8364; EUR",
  region: "Europe",
  country: "Belgium",
  symbol: "EUR"
}, {
  currency: "&#163; EGP",
  id: "&#163; EGP",
  region: "Africa",
  country: "Egypt",
  symbol: "EGP"
}, {
  currency: "&#8377; INR",
  id: "&#8377; INR",
  region: "Asia",
  country: "India",
  symbol: "INR"
}, {
  currency: "&#122;&#322; PLN",
  id: "&#122;&#322; PLN",
  region: "Europe",
  country: "Poland",
  symbol: "PLN"
}, {
  currency: "&#65020; SAR",
  id: "&#65020; SAR",
  region: "Middle East",
  country: "Saudi Arabia",
  symbol: "SAR"
}, {
  currency: "&#107;&#114; SEK",
  id: "SEK",
  region: "Europe",
  country: "Sweden",
  symbol: "SEK"
}, {
  currency: "&#; TRY",
  id: "&#; TRY",
  region: "Europe",
  country: "Turkey",
  symbol: "TRY"
}, {
  currency: "&#163; GBP",
  id: "GBP",
  region: "Europe",
  country: "United Kingdom",
  symbol: "GBP"
}, {
  currency: "&#x24; AUD",
  id: "AUD",
  region: "Australia/Oceania",
  country: "Australia",
  symbol: "AUD"
}, {
  currency: "&yen; JPY",
  id: "JPY",
  region: "Asia",
  country: "Japan",
  symbol: "JPY"
}, {
  currency: "&#x24; SGD",
  id: "SGD",
  region: "Asia",
  country: "Singapore",
  symbol: "SGD"
}];
export const currencies_symbols = {
  "USD": "$",
  "EUR": "€",
  "GBP": "£",
  "JPY": "¥",
  "AUD": "A$",
  "CAD": "C$",
  "CHF": "CHF",
  "CNY": "¥",
  "SEK": "kr",
  "NZD": "NZ$",
  "MXN": "$",
  "SGD": "S$",
  "HKD": "HK$",
  "NOK": "kr",
  "KRW": "₩",
  "TRY": "₺",
  "INR": "₹",
  "RUB": "₽",
  "BRL": "R$",
  "ZAR": "R"
};
import HelperMixin from "@/mixins/HelperMixin";
import ValidateMixin from "@/mixins/ValidateMixin";
import { CurrencyValue } from "@/components/tableElements";
import AppIcon from "@/components/AppIcon";
import { currencies_symbols } from '@/utils/currencies';
import AlertOctagon from '@/components/icons/AlertOctagon';
export default {
  name: "PpcBudgetAllocationWeeklyBudgetInput",
  emits: ["update:modelValue", "input-update"],
  mixins: [HelperMixin, ValidateMixin],
  components: {
    AppIcon,
    CurrencyValue,
    AlertOctagon
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    ai: {
      type: Number,
      default: 0
    },
    sufix: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String
    },
    country_code: {
      type: String
    },
    advanced: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currency_symbol: currencies_symbols[this.currency],
      editing_value: false
    };
  },
  computed: {
    localModelValue: {
      get() {
        return this.formatValue(this.modelValue);
      },
      set(value) {
        let newValue = this.formatValue(value);
        this.$emit("update:modelValue", newValue);
      }
    },
    isCustom() {
      return this.modelValue != this.ai;
    },
    validation() {
      return this.getValidation();
    }
  },
  watch: {
    /* focus: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$refs?.editInput?.focus();
          });
        }
      },
      immediate: true,
    }, */
  },
  methods: {
    onReset() {
      if (!this.disabled) {
        this.localModelValue = this.ai.toString();
        this.$emit("input-update", this.localModelValue);
      }
    },
    onInput(event) {
      let value = event.target.value;
      this.localModelValue = this.formatValue(value);
      this.$emit("input-update", this.localModelValue);
    },
    isNumber(event) {
      const allowedKeys = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", "Escape"];
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (event.key === "." && !event.target.value.includes(".")) {
        return;
      }
      if (event.key >= "0" && event.key <= "9") {
        return;
      }
      event.preventDefault();
    },
    formatValue(value) {
      value = value.toString().replace(/[^0-9.]/g, "");
      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts.slice(1).join("");
      }
      return Math.round(value * 100) / 100;
    },
    onCurrencyValueClick() {
      this.editing_value = true;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    getValidation() {
      const multiplier = this.advanced ? 1 : 2;
      return this.mValidatePPCBudgetWeekly(this.localModelValue, this.country_code, multiplier);
    }
  }
};
import AppIcon from "../../AppIcon.vue";
import FlexRow from "../../FlexRow.vue";
import { TextValue } from "../../tableElements";
import { WrapWithSectionItem } from "../sections";
export default {
  name: "MatchTypeFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    name: String,
    id: String,
    type: String,
    selected: Boolean
  },
  components: {
    WrapWithSectionItem,
    TextValue,
    FlexRow,
    AppIcon
  },
  computed: {
    formattedName() {
      return this.name.toUpperCase();
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ppc_budget_allocation_header = _resolveComponent("ppc-budget-allocation-header");
  const _component_ppc_budget_allocation_item = _resolveComponent("ppc-budget-allocation-item");
  const _component_app_card_custom = _resolveComponent("app-card-custom");
  return _openBlock(), _createBlock(_component_app_card_custom, {
    "no-padding": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_ppc_budget_allocation_header, {
      mode: $data.mode,
      "onUpdate:mode": _cache[0] || (_cache[0] = $event => $data.mode = $event),
      search: $data.search,
      "onUpdate:search": _cache[1] || (_cache[1] = $event => $data.search = $event),
      items: $options.filteredItems,
      currency: $props.currency
    }, null, 8 /* PROPS */, ["mode", "search", "items", "currency"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredItems, (item, index) => {
      return _openBlock(), _createBlock(_component_ppc_budget_allocation_item, {
        key: index,
        item: item,
        mode: $data.mode,
        currency: $props.currency,
        last: index === $options.filteredItems.length - 1,
        "weekly-budget-target": $data.weeklyBudgetTarget,
        selected_profile_id: $props.selected_profile_id,
        onItemUpdate: $options.onItemUpdate,
        onWeeklyBudgetEdit: $options.onWeeklyBudgetEdit
      }, null, 8 /* PROPS */, ["item", "mode", "currency", "last", "weekly-budget-target", "selected_profile_id", "onItemUpdate", "onWeeklyBudgetEdit"]);
    }), 128 /* KEYED_FRAGMENT */)), _renderSlot(_ctx.$slots, "pagination")]),
    _: 3 /* FORWARDED */
  });
}
export default {
  name: 'CalendarWithArrowLeftIcon',
  props: {
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: '#93999F'
    }
  }
};
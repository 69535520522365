import CategoryItem from "./CategoryItem.vue";
import draggable from "vuedraggable";
import AiCategorySectionTitle from "@/components/AiCategorySectionTitle.vue";
export default {
  name: "AiCategorySelection",
  components: {
    CategoryItem,
    draggable,
    AiCategorySectionTitle
  },
  emits: ["selectedCategories"],
  expose: ["getSelectedCategories"],
  props: {
    title: {
      type: String,
      default: "Catégorie"
    },
    details: {
      type: String,
      default: "Sélectionnez les catégories qui vous intéressent"
    },
    categories: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean
    }
  },
  watch: {
    categories: {
      handler: function (newVal) {
        this.$nextTick(() => {
          this.activeCategories = newVal.filter(item => item.active);
        });
        this.$emit("selectedCategories", newVal.filter(item => item.active).map(item => item.category));
      }
    }
  },
  data() {
    return {
      activeCategories: [],
      inactiveCategories: [],
      dragging: false,
      draggingTop: 0
    };
  },
  computed: {
    noInactive() {
      return this.inactiveCategories.length === 0;
    },
    noActive() {
      if (this.loading) return false;
      return this.activeCategories.length === 0;
    },
    updatedActiveCategories: {
      get() {
        return this.activeCategories;
      },
      set(newVal) {
        this.activeCategories = newVal;
        console.log("newVal", newVal);
        this.$emit("selectedCategories", this.getSelectedCategories());
      }
    },
    getDragStickStyle() {
      if (this.dragging) {
        return {
          display: "block",
          position: "absolute",
          top: `${this.draggingTop}px`,
          left: "0",
          width: "100%",
          height: "4px",
          zIndex: "0",
          backgroundColor: "#E4DFFF",
          transition: "all 0.05s ease-in-out"
        };
      }
      return {
        display: 'none',
        width: '0',
        height: '0'
      };
    }
  },
  methods: {
    moveToInactiveCategories({
      category,
      categoryId
    }) {
      this.activeCategories = this.activeCategories.filter(item => item.score !== categoryId);
      this.inactiveCategories = [...this.inactiveCategories, {
        category,
        score: categoryId
      }];
      this.$emit("selectedCategories", this.getSelectedCategories());
    },
    moveToActiveCategories({
      category,
      categoryId
    }) {
      this.inactiveCategories = this.inactiveCategories.filter(item => item.score !== categoryId);
      this.activeCategories = [...this.activeCategories, {
        category,
        score: categoryId,
        active: true
      }];
      this.$emit("selectedCategories", this.getSelectedCategories());
    },
    getSelectedCategories() {
      return this.activeCategories.map(item => item.category);
    },
    getState() {
      // We didn't use the `onEnd` event from `draggable` because
      // we make a single call on the `AiGeneration` which is supposed to
      // fetch the current state of both the benefits and the usecases.

      // In this case, we do not worry about ordering and selection,
      // and we use this method to return the current values in `activeCategories`
      // when needed.
      return this.getSelectedCategories();
    },
    handleDragging(e) {
      const dragContainer = this.$refs.dragContainer.$el.getBoundingClientRect();
      const top = dragContainer.top;
      this.draggingTop = e.relatedRect.top + e.draggedRect.height - top;
    }
  }
};
import { ref } from "vue";
export default {
  name: 'ImageOverlayer',
  props: {
    overlays: {
      type: Number,
      default: 3
    },
    count: {
      type: [Number, null],
      default: null
    }
  },
  setup(props) {
    const overlayStyles = [];
    let originX = 2;
    let originY = -2;
    let zIndex = props.overlays - 1;
    const multiplier = 2;
    for (let i = 1; i <= props.overlays; i++) {
      overlayStyles.push({
        transform: `translate(${originX}px, ${originY}px)`,
        zIndex: zIndex
      });
      originX += multiplier;
      originY -= multiplier;
      zIndex -= 1;
    }
    return {
      overlayStyles: ref(overlayStyles)
    };
  }
};
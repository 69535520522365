import { request } from "@/store";
import { addGhostIds, getCountriesMapping } from "@/utils";
export default {
  name: 'FetchFiltersMixin',
  methods: {
    async fetchBasedOnSellers(options) {
      if (!options.sellers) return [];
      if (!options.sellers.length) return [];
      if (!options.path) return [];
      let payload = {
        seller_id: options.sellers,
        ...options
      };
      const res = await request(options.path, "POST", payload, false, true, "cors");
      if (res && res.data) {
        return res.data;
      }
    },
    async getCountriesBasedOnSellers({
      sellers
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path
      });
      if (data && data.countries) {
        let countries = data.countries;
        let localCountriesMapping = await getCountriesMapping();
        countries = countries.map(country => {
          const extraInfo = localCountriesMapping.find(({
            name
          }) => name.toLowerCase() === country.sales_channel.toLowerCase());
          return {
            ...country,
            ...extraInfo
          };
        });
        return addGhostIds(countries);
      }
      return [];
    },
    async getProductsSuggestionsBasedOnSellers({
      sellers,
      keyword,
      sales_channel
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path,
        keyword_list: keyword,
        sales_channel
      });
      if (data && data.products) {
        let products = data.products;
        return products.map(product => {
          return {
            ...product,
            id: `${product.product_name}-${product.sku}-${product.asin}`
          };
        });
      }
      return [];
    },
    async getCampaignsBasedOnSellers({
      sellers,
      keyword
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path,
        keyword_list: keyword
      });
      if (data && data.campaigns) {
        let campaigns = data.campaigns;
        return campaigns.map(({
          campaign_name,
          campaign_id
        }) => {
          return {
            name: campaign_name,
            id: campaign_id
          };
        });
      }
      return [];
    },
    async getPortfoliosBasedOnSellers({
      sellers,
      keyword
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path,
        portfolio_list: keyword
      });
      if (data && data.portfolios) {
        let portfolios = data.portfolios;
        return portfolios;
      }
      return [];
    },
    async getAdFormatsBasedOnSellers({
      sellers,
      keyword
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path,
        keyword_list: keyword
      });
      if (data && data.ad_formats) {
        let ad_formats = data.ad_formats;
        return ad_formats;
      }
      return [];
    },
    async getMatchTypesBasedOnSellers({
      sellers
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path
      });
      if (data && data.match_types) {
        let match_types = data.match_types;
        return match_types.map(match_type => {
          return {
            id: match_type,
            name: match_type,
            type: match_type
          };
        });
      }
      return [];
    },
    async getTargetingsBasedOnSellers({
      sellers,
      sales_channel,
      keyword
    }, path) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        keyword_list: keyword,
        sales_channel,
        path
      });
      if (data && data.targetings) {
        let targetings = data.targetings;
        return targetings.map(({
          targeting,
          keyword_id_list
        }) => {
          return {
            targeting,
            keyword_id_list
          };
        });
      }
    },
    getCurrencies(sellers) {
      return [];
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d3408a22"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "topbar"
};
const _hoisted_2 = {
  class: "breadcrumbs-and-top-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_breadcrumb = _resolveComponent("b-breadcrumb");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_breadcrumb, {
    items: $options.breadcrumbItems,
    class: "topbar-bread-crumb-style"
  }, null, 8 /* PROPS */, ["items"])]), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}
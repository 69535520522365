export default {
  name: 'PlayIcon',
  props: {
    width: {
      type: Number,
      default: 11
    },
    height: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: '#22863D'
    }
  }
};
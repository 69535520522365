import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "groupby"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_loading_element, {
    loading: $data.loading,
    size: 300,
    height: 35,
    color: "#E3E3E3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      modelValue: $options.groupBy,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.groupBy = $event),
      class: _normalizeClass({
        'busy': $props.tabBusy
      })
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, ({
        key,
        name
      }, index) => {
        return _openBlock(), _createBlock(_component_b_tab, {
          key: index,
          title: name,
          disabled: $props.disabledTabs.includes(key),
          id: name.toLowerCase(),
          onClick: $options.handleClick
        }, null, 8 /* PROPS */, ["title", "disabled", "id", "onClick"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"])]);
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 12 11`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M1.5 3.4C1.5 2.55992 1.5 2.13988 1.66349 1.81901C1.8073 1.53677 2.03677 1.3073 2.31901 1.16349C2.63988 \n          1 3.05992 1 3.9 1H8.1C8.94008 1 9.36012 1 9.68099 1.16349C9.96323 1.3073 10.1927 1.53677 10.3365 1.81901C10.5 2.13988 \n          10.5 2.55992 10.5 3.4V6.1C10.5 6.94008 10.5 7.36012 10.3365 7.68099C10.1927 7.96323 9.96323 8.1927 9.68099 8.33651C9.36012 \n          8.5 8.94008 8.5 8.1 8.5H6.84187C6.52985 8.5 6.37383 8.5 6.22461 8.53063C6.09221 8.5578 5.9641 8.60274 5.84374 8.66423C5.70808 \n          8.73353 5.58626 8.83099 5.34261 9.02592L4.14988 9.9801C3.94184 10.1465 3.83781 10.2297 3.75027 10.2298C3.67413 10.2299 \n          3.60211 10.1953 3.55461 10.1358C3.5 10.0674 3.5 9.93418 3.5 9.66775V8.5C3.03501 8.5 2.80252 8.5 2.61177 8.44889C2.09413 \n          8.31019 1.68981 7.90587 1.55111 7.38823C1.5 7.19748 1.5 6.96499 1.5 6.5V3.4Z",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}
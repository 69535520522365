import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_WrapWithPopover = _resolveComponent("WrapWithPopover");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    gap: 4,
    align: $props.align
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_WrapWithPopover, null, _createSlots({
      content: _withCtx(() => [_createTextVNode(_toDisplayString($props.info), 1 /* TEXT */)]),
      default: _withCtx(() => [_createVNode(_component_AppIcon, {
        icon: "info",
        size: 16,
        color: "#999999"
      })]),
      _: 2 /* DYNAMIC */
    }, [$props.title ? {
      name: "title",
      fn: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)]),
      key: "0"
    } : undefined]), 1024 /* DYNAMIC_SLOTS */)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["align"]);
}
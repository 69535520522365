import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import ValidateMixin from "@/mixins/ValidateMixin";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppTabs from "@/components/AppTabs";
import AppCircleProgress from "@/components/AppCircleProgress";
import EditPageContent from "@/components/EditPageContent";
import EditPageSidebar from "@/components/EditPageSidebar";
import EditPageKeywords from "@/components/EditPageKeywords";
import ContentMixin from "@/mixins/ContentMixin.vue";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import TasksMixin from "@/mixins/TasksMixin.vue";
import Popper from "vue3-popper";
import { countries_languages, countries_browser_languages, country_amz_sp_api_marketplace_ids_flipped } from "@/utils/countries";
import { translateAiProposition } from "@/components/aiCategory/service";
import { isObjectEmpty } from "@/utils";
export default {
  name: "SeoEditItemNew",
  emits: ["back", "submit"],
  mixins: [ContentMixin, ValidateMixin, ContentDisplayRulesMixin, TasksMixin],
  components: {
    AppButton,
    AppIcon,
    AppTabs,
    EditPageContent,
    EditPageSidebar,
    AppCircleProgress,
    Popper,
    EditPageKeywords
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    content_id: {
      type: String,
      default: ""
    },
    content_amazon_link: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataLoading: true,
      loading_translation_spinner: false,
      is_translation_button_active: false,
      loading: false,
      content: {},
      tabs: [{
        value: "product_name",
        text: "Title",
        badge: 0,
        validateFunction: this.mValidateTitle,
        canSwitchTab: this.canSwitchTab
      }, {
        value: "bullet_points",
        text: "Bullet Points",
        badge: 0,
        validateFunction: this.statusValidateBulletPoints,
        canSwitchTab: this.canSwitchTab
      }, {
        value: "generic_keyword",
        text: "Search Keywords",
        badge: 0,
        validateFunction: this.mValidateKeyword,
        canSwitchTab: this.canSwitchTab
      }, {
        value: "product_description",
        text: "Description",
        badge: 0,
        validateFunction: this.mValidateDescription,
        canSwitchTab: this.canSwitchTab
      }],
      tab: "product_name",
      active_menu: "",
      translationTaskIdentifier: "",
      translateCode: null,
      translate: {},
      ai_translate_proposition: {},
      ai_content_proposition: {},
      ai_content_proposition_translation: {},
      omny_translated_content: {},
      amazon_translated_content: {},
      omny_version: {},
      all_versions_list: [],
      wasScroll: false,
      hovered_tab_button: "",
      sidebar_modal: false,
      keywords_disabled: false,
      show_keywords: false,
      show_ai_generated_content: false,
      show_ai_translated_content: false,
      show_comparison: true,
      show_translation: false,
      translations_loading: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      product: state => state.content.item,
      current_workspace: state => state.workspace.current_workspace,
      title_limit_exceeded: state => state.content.title_limit_exceeded,
      keyword_limit_exceeded: state => state.content.keyword_limit_exceeded
    }),
    ...mapGetters(["contentItem", "getCountry", "getCountryCode", "getCountryFlag"]),
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    isLoggedIn() {
      return !!this.token;
    },
    omnyVersionStatusValidate() {
      return this.statusValidate(this.content, "omny");
    },
    version() {
      //console.log("[version computed]");
      return version => {
        const versionData = key => {
          return version === "amazon" ? this.content.amazon_version[key] : this.contentToDisplay(this.content, key, true);
        };
        return {
          product_name: versionData("product_name"),
          generic_keyword: versionData("generic_keyword"),
          product_description: versionData("product_description"),
          bullet_point_1: versionData("bullet_point_1"),
          bullet_point_2: versionData("bullet_point_2"),
          bullet_point_3: versionData("bullet_point_3"),
          bullet_point_4: versionData("bullet_point_4"),
          bullet_point_5: versionData("bullet_point_5"),
          brand: this.content.brand,
          asin: this.content.asin,
          sku: this.content.sku,
          seller_id: this.content.seller_id,
          marketplace_id: this.content.marketplace_id,
          content_id: this.content.content_id,
          image: this.content.image_small,
          category_id: this.content.category_id,
          category_name: this.content.category_name,
          review_count: this.content.review_count,
          price: this.content.price,
          currency: this.content.currency,
          rating: this.content.rating,
          ratings_total: this.content.ratings_total
        };
      };
    },
    isFirstSection() {
      return this.tabs.findIndex(item => item.value == this.tab) == 0;
    },
    isLastSection() {
      return this.tabs.findIndex(item => item.value == this.tab) == this.tabs.length - 1;
    },
    validateTabs() {
      return [...this.tabs].map(item => {
        const result = item.value == "bullet_points" ? this.statusValidateBulletPointTab(item) : this.statusValidateTab(item);
        item.badge = result.filter(el => el.status != "success").length;
        return item;
      });
    },
    successRules() {
      var success_rules = 0;
      this.omnyVersionStatusValidate.forEach(rule => {
        success_rules += rule.score * rule.coefficient;
      });
      return success_rules;
    },
    allRules() {
      var all_rules = 0;
      this.omnyVersionStatusValidate.forEach(rule => {
        all_rules += 10 * rule.coefficient;
      });
      return all_rules;
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      this.dataLoading = true;
      this.content = this.contentItem({
        content_id: this.content_id
      });
      if (this.content) {
        this.translationTaskIdentifier = this.current_workspace.workspace_id + "_" + "content_translation" + "_" + this.version("omny").content_id;
        this.dataLoading = false;
        this.all_versions_list = this.createAllVersionsList();
        //console.log("[printing all version list]");
        //console.log(this.all_versions_list);
      }
      //console.log("[omny_version] mounted");

      const params = {
        content: this.version('amazon'),
        mother_language: countries_browser_languages[navigator.language] || "English"
      };
      translateAiProposition({
        params
      }).then(result => {
        this.amazon_translated_content = result;
      }).catch(err => {
        //console.log(err);
        this.translations_loading = false;
        window.$bus.trigger("showDanger", {
          visible: true,
          dismissSecs: 5,
          message: "Error occured amazon version translation"
        });
      });
    }
  },
  beforeUnmount() {
    if (this.asyncUpdateInterval) {
      clearTimeout(this.asyncUpdateInterval);
    }
  },
  watch: {
    content() {
      //console.log("[watch omny_version value]");
      this.omny_version = this.version("omny");
    },
    tab(ref) {
      if (!this.wasScroll) {
        const el = this.$refs[ref];
        if (el) {
          //el.scrollIntoView({ behavior: 'smooth' })
          this.$refs.scroll.scrollTop = el.offsetTop - 120;
        }
      }
      this.wasScroll = false;
    }
  },
  methods: {
    ...mapMutations(["contentFieldSet", "contentSmartListingFieldSet"]),
    ...mapActions(["authStatus", "productValidate", "workspaceList", "contentUpdate", "contentGetTranslation"]),
    onBack() {
      this.$emit("back");
    },
    canSwitchTab() {
      return true;
    },
    onTabChange(value) {
      this.tab = value;
    },
    statusValidateTab(tab) {
      return [tab.validateFunction(this.omny_version[tab.value])];
    },
    statusValidateBulletPointTab(tab) {
      const bullet_points = [this.omny_version["bullet_point_1"], this.omny_version["bullet_point_2"], this.omny_version["bullet_point_3"], this.omny_version["bullet_point_4"], this.omny_version["bullet_point_5"]];
      return tab.validateFunction(bullet_points);
    },
    onChange(key, event) {
      this.contentFieldSet({
        asin: this.content.asin,
        type: key,
        value: event,
        content_id: this.content.content_id
      });
    },
    onChangeSmartListing(key, event) {
      this.contentSmartListingFieldSet({
        asin: this.content.asin,
        type: key,
        value: event,
        content_id: this.content.content_id
      });
    },
    onPrev() {
      const currentIndex = this.tabs.findIndex(item => item.value == this.tab);
      if (currentIndex > 0) {
        this.tab = this.tabs[currentIndex - 1].value;
      }
    },
    onNext() {
      const currentIndex = this.tabs.findIndex(item => item.value == this.tab);
      if (currentIndex < this.tabs.length - 1) {
        this.tab = this.tabs[currentIndex + 1].value;
      }
    },
    onScroll() {
      const scrollTop = this.$refs.scroll.scrollTop;
      const scrollHeight = this.$refs.scroll.scrollHeight;
      const clientHeight = this.$refs.scroll.clientHeight;
      for (const item of this.tabs) {
        const ref = this.$refs[item.value];
        const top = ref.offsetTop - 100;
        if (top >= scrollTop && top <= scrollTop + clientHeight / 3) {
          if (this.tab != item.value) {
            this.wasScroll = true;
            this.tab = item.value;
          }
        }
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        const lastTab = this.tabs[this.tabs.length - 1].value;
        if (this.tab != lastTab) {
          this.wasScroll = true;
          this.tab = lastTab;
        }
      }
      if (scrollTop == 0) {
        const firstTab = this.tabs[0].value;
        if (this.tab != firstTab) {
          this.wasScroll = true;
          this.tab = firstTab;
        }
      }
    },
    createAllVersionsList() {
      //console.log("[create_all_versions_list]");
      const versionKeys = this.getVersionKeys();
      const versions = versionKeys.map(versionKey => {
        const versionData = key => {
          return this.content[versionKey][key];
        };
        return {
          version_name: this.getVersionName(versionKey),
          product_name: versionData("product_name"),
          generic_keyword: versionData("generic_keyword"),
          product_description: versionData("product_description"),
          bullet_point_1: versionData("bullet_point_1"),
          bullet_point_2: versionData("bullet_point_2"),
          bullet_point_3: versionData("bullet_point_3"),
          bullet_point_4: versionData("bullet_point_4"),
          bullet_point_5: versionData("bullet_point_5"),
          brand: this.content.brand,
          category_name: this.content.category_name,
          asin: this.content.asin,
          sku: this.content.sku,
          seller_id: this.content.seller_id,
          marketplace_id: this.content.marketplace_id,
          content_id: this.content.content_id,
          image: this.content.image_small,
          category_id: this.content.category_id,
          review_count: this.content.review_count,
          price: this.content.price,
          currency: this.content.currency,
          rating: this.content.rating,
          ratings_total: this.content.ratings_total
        };
      });
      const localVersionItem = {
        version_name: "Local version",
        id: "local_version"
      };
      return [...versions, localVersionItem];
    },
    async onTranslate(item, contentvalue) {
      const payload = {
        item_to_translate: item,
        target_marketplace_id: this.omny_version.marketplace_id,
        source_marketplace_id: item.marketplace_id,
        workspace_id: this.current_workspace.workspace_id,
        task_type: "content_translation",
        content_id: item.content_id || item.asin + "_" + item.marketplace_id
      };
      //keep this code,this will help to debug the async task translation problem easily
      //console.log("[sending payload to server to translate]");
      //console.log(payload);

      //console.log("[task_id before sending to server]");
      // console.log(
      //   `${this.current_workspace.workspace_id}_content_translation_${this.omny_version.content_id}`
      // );

      //console.log("[translation task identifier value]");
      //console.log(this.translationTaskIdentifier);
      const data = await this.contentGetTranslation(payload);
      if (data) {
        if (data.task_id === `${this.current_workspace.workspace_id}_content_translation_${item.content_id || item.asin + "_" + item.marketplace_id}`) {
          //console.log("[server has started running the translation process]");
          this.translationTaskIdentifier = `${this.current_workspace.workspace_id}_content_translation_${item.content_id || item.asin + "_" + item.marketplace_id}`;

          //console.log("[server task type]");

          this.executeTaskStatusHandler(data);
          this.checkTaskStatusPeriodically(this.translationTaskIdentifier);
        }
      }
    },
    onAigenerate(item) {
      this.ai_content_proposition = item;
      this.show_ai_translated_content = false;
      this.show_ai_generated_content = true;
      this.sidebar_modal = false;
      const target_language = countries_languages[country_amz_sp_api_marketplace_ids_flipped[this.omny_version.marketplace_id]];
      const mother_language = countries_browser_languages[navigator.language] || "English";
      if (target_language != mother_language) {
        const params = {
          content: item,
          mother_language: mother_language
        };
        translateAiProposition({
          params
        }).then(result => {
          this.ai_content_proposition_translation = result;
        }).catch(err => {
          //console.log(err);
          window.$bus.trigger("showDanger", {
            visible: true,
            dismissSecs: 5,
            message: "Error occured generating AI proposition translation"
          });
          return;
        });
      }
    },
    handleIdle() {
      this.is_translation_button_active = true;
      this.loading_translation_spinner = false;
      this.ai_translate_proposition = {};
    },
    handleRunning() {
      this.loading_translation_spinner = true;
      this.is_translation_button_active = false;
      this.ai_translate_proposition = {};
      this.checkTaskStatusPeriodically(this.translationTaskIdentifier);
    },
    handleFailed(data) {
      this.loading_translation_spinner = false;
      this.is_translation_button_active = true;
      this.ai_translate_proposition = {};
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showDanger", {
        visible: true,
        message: "AI Task failed \n" + data.task_result,
        delay: 60 // Show alert less than 60 secs
      });
      throw new Error("AI Task failed");
    },
    handleFinished(data) {
      this.ai_translate_proposition = data.task_result;
      this.is_translation_button_active = true;
      this.loading_translation_spinner = false;
      this.show_ai_generated_content = false;
      this.show_ai_translated_content = true;
      this.sidebar_modal = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: "AI Task has finished with success",
        delay: 60 // Show alert less than 60 secs
      });
    },
    onAsyncUpdate(val) {
      this.getAsyncUpdate(val);
    },
    async onValidate() {
      this.loading = true;
      if (!this.title_limit_exceeded && !this.keyword_limit_exceeded) {
        Object.keys(this.content.amazon_version).forEach(key => {
          this.onChange(key, this.omny_version[key]);
        });
        await this.contentUpdate({
          content_id: this.content_id
        });
        this.loading = false;
        this.$emit("back");
      }
      this.loading = false;
    },
    onClickTabButton(menu) {
      this.active_menu = menu;
      this.$refs.editPageSidebar.onToggleMenu(menu);
      this.sidebar_modal = true;
      this.show_comparison = false;
      this.show_keywords = false;
    },
    onSidebarClose(menu) {
      if (menu == "ai_generation" && isObjectEmpty(this.ai_content_proposition)) {
        menu = "";
      }
      this.$refs.editPageSidebar.onToggleMenu(menu);
      this.sidebar_modal = false;
    },
    onToggleMenu(menu) {
      this.active_menu = menu;
    },
    listingPageAmazon() {
      let link = this.content_amazon_link;
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    updateTranslationButtonActive(val) {
      this.is_translation_button_active = val;
    },
    onKeywords() {
      this.keywords_disabled = false;
    },
    onClickComparison() {
      this.show_comparison = !this.show_comparison;
      if (this.show_comparison) {
        this.sidebar_modal = false;
        this.show_ai_generated_content = false;
        this.show_ai_translated_content = false;
        this.show_keywords = false;
      }
    },
    onClickKeyword() {
      this.show_keywords = !this.show_keywords;
      this.show_comparison = false;
    },
    async computeTranslations() {
      if (this.show_translation) {
        this.show_translation = false;
        return;
      }
      this.translations_loading = true;
      this.show_translation = false;
      const params = {
        content: this.omny_version,
        mother_language: countries_browser_languages[navigator.language] || "English"
      };
      translateAiProposition({
        params
      }).then(result => {
        this.omny_translated_content = result;
        this.translations_loading = false;
        this.show_translation = true;
      }).catch(err => {
        //console.log(err);
        this.translations_loading = false;
        window.$bus.trigger("showDanger", {
          visible: true,
          dismissSecs: 5,
          message: "Error occured in omny version translation"
        });
      });
    }
  }
};
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-277e1ccc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ba-weekly-value"
};
const _hoisted_2 = {
  class: "ba-weekly-budget ba-text ba-text-left"
};
const _hoisted_3 = {
  class: "ba-weekly-info"
};
const _hoisted_4 = {
  key: 0,
  class: "ba-weekly-badge"
};
const _hoisted_5 = {
  class: "ba-weekly-badge-price ba-text ba-text-purple ba-text-lighter"
};
const _hoisted_6 = {
  key: 1,
  class: "ba-weekly-sufix ba-text ba-text-gray"
};
const _hoisted_7 = ["disabled"];
const _hoisted_8 = {
  class: "ba-weekly-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ba-weekly-input", {
      'ba-weekly-custom': $options.isCustom,
      'ba-weekly-disabled': $props.disabled,
      'ba-weekly-no-sufix': !$props.sufix || $props.sufix === ''
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.localModelValue) + " € ", 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [$options.isCustom ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_app_icon, {
    icon: "ai",
    size: 14,
    color: "#8D85E5"
  }), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.priceFormatter($props.ai)), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.sufix && $props.sufix != '' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.sufix), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _withDirectives(_createElementVNode("input", {
    ref: "editInput",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.localModelValue = $event),
    type: "text",
    class: "ba-text ba-text-left",
    disabled: $props.disabled,
    onInput: _cache[1] || (_cache[1] = (...args) => $options.onInput && $options.onInput(...args)),
    onKeydown: _cache[2] || (_cache[2] = (...args) => $options.isNumber && $options.isNumber(...args))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_7), [[_vModelText, $options.localModelValue]]), _createElementVNode("div", _hoisted_8, [!$options.isCustom ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "ai",
    size: 16,
    color: "#5A52AE"
  })) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "ba-weekly-icon-reset",
    onClick: _cache[3] || (_cache[3] = (...args) => $options.onReset && $options.onReset(...args))
  }, [_createVNode(_component_app_icon, {
    icon: "gear",
    size: 16,
    color: "#4C8EFF",
    class: "gear"
  }), _createVNode(_component_app_icon, {
    icon: "reset",
    size: 16,
    color: "#4C8EFF",
    class: "reset"
  })]))])], 2 /* CLASS */);
}
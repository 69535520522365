import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "category-cell-content"
};
const _hoisted_2 = {
  class: "category-name"
};
const _hoisted_3 = {
  class: "category-id"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "border-table-row category-cell"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString($props.item.category_name), 1 /* TEXT */), _createElementVNode("p", _hoisted_3, _toDisplayString($props.item.category_id), 1 /* TEXT */)])]),
    _: 1 /* STABLE */
  });
}
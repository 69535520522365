import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ec4f378"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_sidebar_section = _resolveComponent("date-picker-sidebar-section");
  const _component_date_value = _resolveComponent("date-value");
  const _component_multi_mode_date_picker_selected_period = _resolveComponent("multi-mode-date-picker-selected-period");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_date_picker_period_preview = _resolveComponent("date-picker-period-preview");
  const _component_multi_mode_date_picker_selector_item = _resolveComponent("multi-mode-date-picker-selector-item");
  const _component_multi_mode_date_picker_quarter_selector_item = _resolveComponent("multi-mode-date-picker-quarter-selector-item");
  const _component_date_picker_layout = _resolveComponent("date-picker-layout");
  return _openBlock(), _createBlock(_component_date_picker_layout, {
    class: "multi-mode-date-picker",
    onSubmit: $options.handleSubmit,
    onCancel: $options.handleCancel
  }, {
    sidebar: _withCtx(() => [_createVNode(_component_date_picker_sidebar_section, {
      modelValue: $data.range,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.range = $event),
      options: $data.periodOptions
    }, {
      default: _withCtx(() => [_createTextVNode(" Period ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_date_picker_sidebar_section, {
      class: "compare",
      modelValue: $data.compare,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.compare = $event),
      options: $data.compareOptions
    }, {
      default: _withCtx(() => [_createTextVNode(" Compare ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options"])]),
    body: _withCtx(() => [_createVNode(_component_flex_row, {
      styles: {
        height: 'auto'
      },
      align: "left"
    }, {
      default: _withCtx(() => [_createVNode(_component_multi_mode_date_picker_selected_period, null, {
        title: _withCtx(() => [_createTextVNode("Selected Period")]),
        default: _withCtx(() => [['monthly', 'yearly'].includes($data.range) ? (_openBlock(), _createBlock(_component_date_value, {
          key: 0,
          value: $data.currentDate,
          format: $data.previousPeriodFormats[$data.range]
        }, null, 8 /* PROPS */, ["value", "format"])) : (_openBlock(), _createBlock(_component_date_value, {
          key: 1,
          value: $data.currentDate,
          format: $data.previousPeriodFormats[$data.range]
        }, {
          prepend: _withCtx(() => [_createTextVNode("Q")]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value", "format"]))]),
        _: 1 /* STABLE */
      }), _createVNode(_component_multi_mode_date_picker_selected_period, {
        previous: ""
      }, {
        title: _withCtx(() => [_createTextVNode("Preceding Period")]),
        default: _withCtx(() => [['monthly', 'yearly'].includes($data.range) ? (_openBlock(), _createBlock(_component_date_value, {
          key: 0,
          value: $options.state?.compare?.start,
          format: $data.previousPeriodFormats[$data.range]
        }, null, 8 /* PROPS */, ["value", "format"])) : (_openBlock(), _createBlock(_component_date_value, {
          key: 1,
          value: $options.state?.compare?.start,
          format: $data.previousPeriodFormats[$data.range]
        }, {
          prepend: _withCtx(() => [_createTextVNode("Q")]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value", "format"]))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }), _createVNode(_component_date_picker_period_preview, {
      onPrevious: $options.handlePrevious,
      onNext: $options.handleNext
    }, {
      default: _withCtx(() => [_createVNode(_component_date_value, {
        value: $data.currentYear,
        format: "YYYY"
      }, null, 8 /* PROPS */, ["value"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onPrevious", "onNext"]), _createElementVNode("div", _hoisted_1, [['monthly', 'yearly'].includes($data.range) ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList($data.generatedYears[$data.range], (collection, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "collection",
        key: index
      }, [$data.range === 'monthly' ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(collection, ({
        date
      }, index) => {
        return _openBlock(), _createBlock(_component_multi_mode_date_picker_selector_item, {
          key: index,
          value: date,
          active: $options.currentActive(date),
          current: $options.currentMonth(date),
          onClick: $event => $options.handleDateChange(date),
          previous: $options.activePreviousMonth(date),
          disabled: $options.disableDate(date)
        }, {
          default: _withCtx(() => [_createVNode(_component_date_value, {
            value: date,
            format: $data.bodyFormats[$data.range]
          }, null, 8 /* PROPS */, ["value", "format"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "active", "current", "onClick", "previous", "disabled"]);
      }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(collection, ({
        date
      }, index) => {
        return _openBlock(), _createBlock(_component_multi_mode_date_picker_selector_item, {
          disabled: $options.disableDate(date),
          key: index,
          value: date,
          active: $options.currentActive(date),
          onClick: $event => $options.handleDateChange(date),
          previous: $options.activePreviousYear(date)
        }, {
          default: _withCtx(() => [_createVNode(_component_date_value, {
            value: date,
            format: $data.bodyFormats[$data.range]
          }, null, 8 /* PROPS */, ["value", "format"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "value", "active", "onClick", "previous"]);
      }), 128 /* KEYED_FRAGMENT */))]);
    }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true), $data.range === 'quarterly' ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 1
    }, _renderList($data.generatedYears[$data.range], (quarter, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "collection quarter",
        key: index
      }, [_createVNode(_component_multi_mode_date_picker_quarter_selector_item, {
        quarter: quarter,
        onClick: _cache[2] || (_cache[2] = date => $options.handleDateChange(date)),
        active: $options.activeQuarter(quarter.months[0].date),
        previous: $options.activePreviousQuarter(quarter.months[0].date),
        disabled: $options.disableQuarter(quarter)
      }, null, 8 /* PROPS */, ["quarter", "active", "previous", "disabled"])]);
    }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onSubmit", "onCancel"]);
}
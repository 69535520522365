import AppModal from '@/components/AppModal';
import AppIcon from '@/components/AppIcon';
import EditTextField from '@/components/EditTextField';
import ExpandTextBox from '@/components/ExpandTextBox';
import EditTextModalContent from '@/components/EditTextModalContent';
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import { countries_browser_languages } from "@/utils/countries";
import { mapActions, mapGetters } from "vuex";
import DiffMatchPatch from 'diff-match-patch';
export default {
  name: 'EditTextModal',
  emits: ['update:modelValue', 'submit', 'update', 'close'],
  mixins: [ContentDisplayRulesMixin],
  components: {
    AppModal,
    AppIcon,
    EditTextField,
    ExpandTextBox,
    EditTextModalContent
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    original: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    allowHtmlInputAndSpecialCharacters: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: '/assets/images/amazon-logo-transparent.svg'
    },
    name: {
      type: String,
      default: ''
    },
    asin: {
      type: String,
      default: ''
    },
    sku: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    bullet_points: {
      type: Array,
      default: []
    },
    content_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      charactersMap: {
        product_name: {
          min: 100,
          max: 200
        },
        generic_keyword: {
          min: 100,
          max: 500
        },
        product_description: {
          min: 1000,
          max: 2000
        },
        bullet_points: {
          min: 50,
          max: 300
        }
      },
      show_translation: false,
      show_comparism: false,
      translation_loading: false,
      comparison_loading: false,
      omny_translation: "",
      amazon_translation: "",
      omny_text_with_diffs: "",
      amazon_text_with_diffs: "",
      content: {},
      apply_keywords_loading: false
    };
  },
  watch: {
    content_id() {
      this.content = this.contentItem({
        content_id: this.content_id
      });
    }
  },
  computed: {
    ...mapGetters(["contentItem"]),
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    valueModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.show_comparism = false;
        this.show_translation = false;
        let newValue = this.allowHtmlInputAndSpecialCharacters ? val : this.removeHtmlTagsAndSpecialCharacters(val);
        this.$emit('update', newValue);
      }
    },
    characterRangePerContentType() {
      return this.charactersMap[this.type] || {
        min: 150,
        max: 500
      };
    }
  },
  methods: {
    ...mapActions(["simpleTranslationGet", "contentApplySuggestedKeywords"]),
    onSubmit() {
      if (this.valueModel.length <= this.characterRangePerContentType.max) {
        this.$emit('submit', this.valueModel);
      }
    },
    onCancel() {
      this.omny_translation = "";
      this.amazon_translation = "";
      this.omny_text_with_diffs = "";
      this.amazon_text_with_diffs = "";
      this.show_comparism = false;
      this.show_translation = false;
      this.$emit('close');
    },
    async onApplyKeywords() {
      this.apply_keywords_loading = true;
      const keywords = await this.contentApplySuggestedKeywords({
        seller_id: this.content.seller_id,
        asin: this.asin,
        marketplace_id: this.content.marketplace_id,
        product_name: this.name,
        product_description: this.description,
        bullet_points: this.bullet_points,
        brand: this.content.brand
      });
      this.valueModel = keywords;
      this.apply_keywords_loading = false;
    },
    async computeTranslations() {
      this.show_comparism = false;
      if (this.show_translation) {
        this.show_translation = false;
        return;
      }
      this.translation_loading = true;
      this.omny_translation = await this.simpleTranslationGet({
        text: this.valueModel,
        target_language: countries_browser_languages[navigator.language] || "English"
      });
      this.amazon_translation = this.amazon_translation || (await this.simpleTranslationGet({
        text: this.original,
        target_language: countries_browser_languages[navigator.language] || "English"
      }));
      this.translation_loading = false;
      this.show_translation = true;
    },
    computeComparism() {
      this.show_translation = false;
      if (this.show_comparism) {
        this.show_comparism = false;
        return;
      }
      this.comparison_loading = true;
      const dmp = new DiffMatchPatch();
      const diffs = dmp.diff_main(this.original, this.valueModel);
      dmp.diff_cleanupSemantic(diffs);
      this.amazon_text_with_diffs = this.generateHtmlDiff(diffs, 'text1');
      this.omny_text_with_diffs = this.generateHtmlDiff(diffs, 'text2');
      this.comparison_loading = false;
      this.show_comparism = true;
      console.log('Ehem showing comparism.');
      console.log('Ehem omny diffs: ', this.omny_text_with_diffs);
      console.log('Ehem amazon diffs: ', this.amazon_text_with_diffs);
    },
    generateHtmlDiff(diffs, textType) {
      return diffs.map(([op, text]) => {
        const formattedText = text.replace(/\n/g, '<br>');
        if (textType === 'text1' && op === DiffMatchPatch.DIFF_DELETE) {
          return `<span style="background: #FAA5A5; padding: Opx 2px;">${formattedText}</span>`;
        } else if (textType === 'text2' && op === DiffMatchPatch.DIFF_INSERT) {
          return `<span style="background: #89E3C5; padding: Opx 2px;">${formattedText}</span>`;
        } else if (op === DiffMatchPatch.DIFF_EQUAL) {
          return `${text}`;
        }
        return '';
      }).join('');
    }
  }
};
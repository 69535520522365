import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b2fb1516"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "copy-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    ref: "copiee",
    classes: "wrap-with-copy"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _createVNode(_component_FlexRow, {
      onClick: _cache[0] || (_cache[0] = e => $options.handleClick(e)),
      onMouseover: $options.onHover,
      onMouseleave: $options.onLeave,
      classes: "copy"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_AppIcon, {
        icon: $data.copyIcon,
        size: $props.size,
        color: "#616161"
      }, null, 8 /* PROPS */, ["icon", "size"])])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onMouseover", "onMouseleave"])]),
    _: 3 /* FORWARDED */
  }, 512 /* NEED_PATCH */);
}
import AppRating from '@/components/AppRating';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'EditPagePreviewItem',
  components: {
    AppRating,
    AppIcon
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  }
};
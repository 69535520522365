import { mapActions } from "vuex";
import AppCheckbox from "@/components/AppCheckbox";
import AppIcon from "@/components/AppIcon";
import AppPopover from "@/components/AppPopover";
import ProductDataContentRow from "@/components/ProductDataContentRow";
export default {
  name: "ProductDataContent",
  emits: ["select", "sort", "update"],
  components: {
    AppCheckbox,
    AppIcon,
    AppPopover,
    ProductDataContentRow
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    sortField: {
      type: String,
      default: "product_name"
    },
    sortDirection: {
      type: String,
      default: "asc"
    },
    scaleType: {
      type: String,
      default: "metric"
    },
    defaultScaleType: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    lengthUnit() {
      return this.lengthUnitScale[this.scaleType];
    },
    weightUnit() {
      return this.weightUnitScale[this.scaleType];
    }
  },
  data() {
    return {
      toggleAll: false,
      dataLoading: false,
      weightUnitScale: {
        imperial: "lb",
        metric: "kg"
      },
      lengthUnitScale: {
        imperial: "in",
        metric: "cm"
      }
    };
  },
  methods: {
    ...mapActions(["dfUpdateProductData"]),
    onToggleAll() {
      if (this.toggleAll) {
        this.$emit("select", this.items.filter(item => item.orderable).map(item => item.asin));
      } else {
        this.$emit("select", []);
      }
    },
    async onUpdate(item, editedField) {
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        [editedField]: item[editedField]
      };
      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      await this.updateProductData(data);
    },
    async updateProductData(payload) {
      this.dataLoading = true;
      let data = this.$store.dispatch("dfUpdateProductData", payload);
      if (data.count) {
        window.$bus.trigger("showSuccess", {
          visible: true,
          message: data.status
        });
        this.dataLoading = false;
      }
    },
    onSelect(item) {
      //this logic is for selecting all checkboxes,this is the old default code
      /* let selected = this.selected.slice();
      if (selected.includes(asin)) {
        selected = selected.filter((item) => item != asin);
      } else {
        selected.push(asin);
      }
      console.log("[onSelect called from ProductData Content]");
      this.$emit("select", selected); */
      //let selected = this.selected;
      //this code ensures only one check box is selected at a time.
      if (this.selected.length === 1 && this.selected[0] === item.asin) {
        return;
      }
      console.log("[product data content selected item]");
      console.log(item);
      this.$emit("select", item);
      this.$emit("save-product-data", item);
    },
    async onExpirationDateRequired(item) {
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        expiration_date_required: item.expiration_date_required
      };
      console.log("[product data content expiration date]");
      console.log(item_to_update);
      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      await this.updateProductData(data);
    },
    onSort(field) {
      const newDirection = field == this.sortField ? this.sortDirection == "asc" ? "desc" : "asc" : "asc";
      const newField = field;
      this.$emit("sort", [newField, newDirection]);
    }
  }
};
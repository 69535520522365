import AppIcon from "@/components/AppIcon";
export default {
  name: 'ProductSegmentFilter',
  emits: ['input', 'change', 'add-option', 'hideTableCell', 'showTableCell'],
  components: {
    AppIcon
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Assign product segment'
    },
    uniqueToolTipID: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: '',
      computedValue: {
        text: '',
        value: ''
      },
      isDropdownOpen: false,
      isSearchActive: false
    };
  },
  computed: {
    filteredOptions() {
      if (!this.searchQuery) {
        return this.options;
      }
      const query = this.searchQuery.toLowerCase();
      return this.options.filter(option => option.text.toLowerCase().includes(query));
    },
    sanitizedSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(value) {
        //const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 20);
        let filteredValue = value.replace(/[^a-zA-Z0-9 _-]/g, '').slice(0, 20);
        // Replace multiple spaces with a single space
        filteredValue = filteredValue.replace(/\s+/g, ' ').trim();
        this.searchQuery = filteredValue;
      }
    }
  },
  watch: {
    value(newValue) {
      this.computedValue = newValue || {
        text: '',
        value: ''
      };
    },
    options: {
      immediate: true,
      handler() {
        if (this.value && !this.options.find(option => option.value === this.value.value)) {
          this.computedValue = {
            text: '',
            value: ''
          };
        }
      }
    }
  },
  methods: {
    selectOption(option) {
      this.computedValue = option;
      this.$emit('input', option);
      this.$emit('change', option);
      this.searchQuery = '';
      this.isDropdownOpen = false;
    },
    keepDropdownOpen() {
      console.log("[keepDropdownOpen] called");
      this.isDropdownOpen = true;
    },
    handleInputBlur() {
      console.log("[handleInputBlur] called");
      setTimeout(() => {
        this.isDropdownOpen = false;
      }, 100);
    },
    addOption() {
      const inputValue = this.searchQuery.trim();
      if (inputValue.length < 2) {
        return;
      }
      const newOption = {
        text: this.searchQuery,
        value: this.searchQuery
      };
      this.$emit('add-option', newOption);
      this.selectOption(newOption);
    },
    enforcePattern(event) {
      const input = event.target.value;
      if (!/^[a-zA-Z0-9]*$/.test(input)) {
        event.target.value = input.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
        this.searchQuery = input.replace(/[^a-zA-Z0-9]/g, "");
      }
    },
    hideButtonContent() {
      console.log("[hideButtonContent] called");
      this.isSearchActive = true;
      this.$emit("hideTableCell", true);
      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          console.log("[activating focus]");
          this.$refs.searchInput.focus();
        }
      });
    },
    showButtonContent() {
      console.log("[showButtonContent] called");
      this.isSearchActive = false;
      this.$emit("showTableCell", true);
    }
  },
  mounted() {
    try {
      if (typeof this.value === 'string') {
        this.computedValue = JSON.parse(this.value) || {
          text: '',
          value: ''
        };
      } else {
        this.computedValue = this.value || {
          text: '',
          value: ''
        };
      }
    } catch (e) {
      this.computedValue = {
        text: '',
        value: ''
      };
    }
  }
};
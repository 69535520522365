import moment from "moment";
import _ from "lodash";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
import PpcStrategies from "@/components/PpcStrategies";
import PpcProducts from "@/components/PpcProducts";
import PpcBooster from "@/components/PpcBooster";
import PpcBidOptimizer from "@/components/PpcBidOptimizer";
import PpcProductPrioritization from "@/components/PpcProductPrioritization";
import PPCSettingsSection from "@/components/ppc/PPCSettingsSection.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
import FetchFiltersMixin from "@/mixins/FetchFiltersMixin.vue";
import PpcBidSettings from "@/components/PpcBidSettings.vue";
import { amz_sp_api_marketplace_ids_country_domains_flipped } from "@/utils/countries";
export default {
  name: "PpcSettings",
  mixins: [FetchFiltersMixin, InitializeFiltersMixin],
  components: {
    PpcStrategies,
    PpcProducts,
    PpcBooster,
    PpcBidOptimizer,
    PpcProductPrioritization,
    PPCSettingsSection,
    ErrorOccured,
    PpcBidSettings
  },
  data() {
    return {
      dataLoading: true,
      dataLoadingStrategies: true,
      dataLoadingBidSettings: true,
      dataLoadingProductPrioritization: true,
      errorOccured: false,
      errorOccuredStrategies: false,
      errorOccuredBidSettings: false,
      errorOccuredProductPrioritization: false,
      initializationFinished: false,
      initializationFinishedStrategies: false,
      initializationFinishedBidSettings: false,
      initializationFinishedProductPrioritization: false,
      filter_settings: {},
      selected_profile_id: ""
    };
  },
  props: {
    activateSettings: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    try {
      await this.initializeSellersFilter(this.ppcSellersFilterSet);
      this.sellers_filter.selectWhenNoSelected(this.sellers_filter.filterItems[0], "id");
      this.sellers_filter.apply();
      await this.onRefreshMarketPlacesFilter(true);
      await this.createWeeksRange();
      await this.refreshPpcSettings();
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_SELLERS_FILTER, this.refreshSellersFilter);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_MARKETPLACES_FILTER, this.onRefreshMarketPlacesFilter);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_BUDGET_STRATEGY, this.refreshPpcBudgetStrategy);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS, this.refreshPpcSettings);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_BID_SETTINGS, this.refreshPpcBidSettings);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_PRODUCT_PRIORITIZATION, this.refreshPpcProductPrioritization);
      window.$bus.on(EVENTS.REFRESH_PPC_SETTINGS_BID_SIMULATOR, this.refreshPpcBidSimulator);
      window.$bus.on(EVENTS.REFRESH_PPC_WEEKS_RANGE, this.createWeeksRange);
      window.$bus.on(EVENTS.DELETE_PPC_STRATEGY, this.handleDeleteStrategy);
      this.initializationFinished = true;
    } catch (error) {
      window.$bus.trigger("showDanger", {
        message: `Error loading PPC Settings`,
        delay: 50,
        visible: true
      });
      this.dataLoading = false;
      this.errorOccured = true;
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      strategies: state => state.ppc_settings.strategies,
      weekFilled: state => state.ppc_settings.weekFilled,
      sellers_filter: state => state.ppc_v2.sellers_filter,
      marketplaces_filter: state => state.ppc_v2.marketplaces_filter,
      currencies_filter: state => state.ppc_settings.currencies_filter,
      currentWeek: state => state.ppc_settings.currentWeek,
      connected_profiles_for_seller: state => state.ppc_settings.connected_profiles_for_seller,
      weeksRange: state => state.ppc_settings.weeksRange,
      // products: state => state.ppc.settings.products,
      // boost: state => state.ppc.settings.boost,
      bid_settings: state => state.ppc_settings.bid_settings,
      isOmnyUser: state => state.auth.isOmnyUser,
      account_profiles: state => state.account.profiles
    }),
    ppcSettings() {
      return this.$store.state.ppcSettings;
    },
    ...mapGetters(["workspaceCurrent", "accountProfileIdGet", "accountAdvertisingBySellerIdGet", "ppcSettingsWeeksGet", "accountProfileCurrencyGet", "ppcV2GetFilters", "ppcCampaignIdsGet", "ppcCampaignGroupIdsGet"]),
    currency() {
      return this.accountProfileCurrencyGet(this.ppcSettingsFilters);
    },
    ppcSettingsFilters() {
      return {
        seller_id: this.sellers_filter.selectedValues.slice(-1).pop(),
        sales_channel: this.marketplaces_filter.selectedValues.slice(-1).pop(),
        country_code: this.marketplaces_filter.selectedItems.slice(-1).pop()?.country_code
      };
    }
  },
  methods: {
    ...mapActions(["ppcBudgetDelete", "ppcBudgetStrategy", "ppcBudgetStrategyFromBQ", "ppcBudget", "ppcProducts", "workspaceList", "ppcBoost", "ppcBidAdjustement", "authStatus", "ppcBidSettingGet", "ppcProductPrioritizationGetFromBQ", "ppcBidSimulatorGetFromBQ", "ppcProductPrioritizationCampaigns", "ppcProductPrioritizationCampaignGroups"]),
    ...mapMutations(["ppcSellersFilterSet", "ppcMarketplacesFilterSet", "ppcSettingsWeeksRangeSet", "ppcConnectedAdvertisingProfilesSet"]),
    async handleDeleteStrategy(week) {
      let filter_settings = this.ppcSettingsFilters;
      let weeks_to_delete = this.weeksRange.filter(weekData => weekData.strategy_id == week.strategy_id && weekData.moment.clone().isAfter(moment.max([moment().startOf("isoWeek"), moment(week.start_date).startOf("isoWeek").subtract(1, "week")])));
      const profile_id = this.accountProfileIdGet(filter_settings);
      let payload = {
        seller_list: [filter_settings.seller_id],
        profile_list: [profile_id],
        week_id_list: weeks_to_delete.map(weekData => weekData.week_id),
        weeks: weeks_to_delete.map(weekData => weekData.week)
      };
      await this.ppcBudgetDelete(payload);
      await this.createWeeksRange();
      await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_BUDGET_STRATEGY);
    },
    async refreshPpcSettings() {
      try {
        this.dataLoading = true;
        this.filter_settings = this.ppcSettingsFilters;
        this.selected_profile_id = this.accountProfileIdGet(this.filter_settings);
        this.throwIfError(this.selected_profile_id);
        this.refreshPpcBudgetStrategy();
        this.refreshPpcBidSettings();
        this.refreshPpcProductPrioritization();
        window.$bus.trigger(EVENTS.PPC_STRATEGY_AUTO_SCROLL);
        this.dataLoading = false;
        this.errorOccured = false;
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: `No profile Id found for ${this.filter_settings?.seller_id} in ${this.filter_settings?.sales_channel}`,
          delay: 50,
          visible: true
        });
        this.dataLoading = false;
        this.errorOccured = true;
      }
    },
    async refreshPpcBudgetStrategy() {
      try {
        this.dataLoadingStrategies = true;
        let weeks = this.ppcSettingsWeeksGet();
        let payload = {
          seller_list: [this.filter_settings.seller_id],
          profile_list: [this.selected_profile_id],
          weeks: weeks
        };
        let payload2 = {
          seller_id: this.filter_settings.seller_id,
          profile_id: this.selected_profile_id,
          weeks: weeks
        };
        await this.ppcBudgetStrategy(payload);
        let result = await this.ppcBudgetStrategyFromBQ(payload2);
        this.throwIfError(result.data);
        this.dataLoadingStrategies = false;
        this.errorOccuredStrategies = false;
        this.initializationFinishedStrategies = true;
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: `Error loading PPC Budget Strategy`,
          delay: 50,
          visible: true
        });
        this.dataLoadingStrategies = false;
        this.errorOccuerrorOccuredStrategies = true;
      }
    },
    handleErrorOccured() {
      this.throwIfError(false);
      this.dataLoading = false;
      this.errorOccured = true;
    },
    async refreshPpcBidSettings() {
      try {
        this.dataLoadingBidSettings = true;
        let payload = {
          seller_list: [this.filter_settings.seller_id],
          profile_list: [this.selected_profile_id],
          marketplace_id: amz_sp_api_marketplace_ids_country_domains_flipped[this.filter_settings.sales_channel.toLowerCase()]
        };
        let result = await this.ppcBidSettingGet(payload);
        await this.refreshPpcBidSimulator();
        this.throwIfError(result.data);
        this.dataLoadingBidSettings = false;
        this.errorOccuredBidSettings = false;
        this.initializationFinishedBidSettings = true;
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: `Error loading PPC Bid Settings`,
          delay: 50,
          visible: true
        });
        this.dataLoadingBidSettings = false;
        this.errorOccuredBidSettings = true;
      }
    },
    async refreshPpcProductPrioritization(data) {
      try {
        this.dataLoadingProductPrioritization = true;
        let payload = {
          seller_id: this.filter_settings.seller_id,
          country_code: this.filter_settings.country_code,
          profile_id: this.selected_profile_id,
          page_limit: data?.page_limit || 20,
          page_offset: data?.page_offset || 1
        };
        let result = await this.ppcProductPrioritizationGetFromBQ(payload);
        this.throwIfError(result.data);
        let campaign_id_list = this.ppcCampaignIdsGet();
        if (campaign_id_list) {
          let payload_campaigns = {
            ...payload,
            campaign_id_list: campaign_id_list,
            should_refresh: true
          };
          let result_campaigns = await this.ppcProductPrioritizationCampaigns(payload_campaigns);
        }
        let campaign_group_id_list = this.ppcCampaignGroupIdsGet();
        if (campaign_group_id_list) {
          let payload_campaign_groups = {
            ...payload,
            campaign_group_id_list: campaign_group_id_list
          };
          let result_campaign_groups = await this.ppcProductPrioritizationCampaignGroups(payload_campaign_groups);
        }
        this.dataLoadingProductPrioritization = false;
        this.errorOccuredProductPrioritization = false;
        this.initializationFinishedProductPrioritization = true;
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: `Error loading PPC Product Prioritization`,
          delay: 50,
          visible: true
        });
        this.dataLoadingProductPrioritization = false;
        this.errorOccuredProductPrioritization = true;
      }
    },
    async refreshPpcBidSimulator() {
      let payload = {
        seller_id: this.filter_settings.seller_id,
        profile_id: this.selected_profile_id
      };
      await this.ppcBidSimulatorGetFromBQ(payload);
    },
    async getFilterValuesForMarketPlacesFilter() {
      await this.initializeConnectedAdvertisingProfilesForSeller();
      let result = await this.getCountriesBasedOnSellers({
        sellers: this.sellers_filter.filterValues
      }, "/ppc/filters/countries");
      let filtered_result = result.filter(item => this.connected_profiles_for_seller.some(profile => item.country_code == profile.countryCode));
      let result_account_profiles_union = filtered_result.map(this.mergeAccountProfileForMarketPlaces);
      return result_account_profiles_union.sort((profile1, profile2) => profile2.spend_eur_30d - profile1.spend_eur_30d);
    },
    async getFilterValuesForSellersFilter() {
      let sellers = await this.getOmnyAccountSellers();
      let result_sellers_profiles_union = sellers.map(this.mergeAccountProfileForSellers);
      return result_sellers_profiles_union.sort((profile1, profile2) => profile2.spend_eur_30d - profile1.spend_eur_30d);
    },
    mergeAccountProfileForMarketPlaces(item) {
      return _.merge(_.find(this.account_profiles, {
        marketplace_id: item?.marketplace_id
      }), item);
    },
    mergeAccountProfileForSellers(item) {
      return _.merge(_.find(this.account_profiles, {
        seller_id: item?.seller_id
      }), item);
    },
    async onRefreshMarketPlacesFilter(check_local_storage) {
      await this.getOmnyAccountProfileIds(this.sellers_filter.filterItems);
      //await this.refreshMarketPlacesFilter();
      await this.initializeMarketplacesFilter(this.ppcMarketplacesFilterSet, check_local_storage);
      this.marketplaces_filter.selectWhenNoSelected(this.marketplaces_filter.filterItems[0], "marketplace_id");
      this.marketplaces_filter.apply();
    },
    async initializeConnectedAdvertisingProfilesForSeller() {
      try {
        let selected_seller_id = this.sellers_filter.selectedValues.slice(-1).pop();
        let connected_profiles_for_seller = this.accountAdvertisingBySellerIdGet(selected_seller_id);
        this.throwIfError(connected_profiles_for_seller);
        await this.ppcConnectedAdvertisingProfilesSet(connected_profiles_for_seller);
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "No Connected Amazon Advertising Account profile found",
          delay: 50,
          visible: true
        });
        this.dataLoading = false;
        this.errorOccured = true;
      }
    },
    async createWeeksRange() {
      const startWeek = this.currentWeek.clone().subtract(12, "weeks");
      const endWeek = this.currentWeek.clone().add(36, "weeks");
      let nextWeek = startWeek.clone();
      const range = [];
      while (nextWeek.isSameOrBefore(endWeek)) {
        let week_status = "before";
        if (nextWeek.isSame(this.currentWeek)) {
          week_status = "today";
        } else {
          if (nextWeek.isAfter(this.currentWeek)) {
            week_status = "after";
          }
        }
        range.push({
          moment: nextWeek,
          title: nextWeek.format("DD") + " " + nextWeek.format("MMM"),
          week: nextWeek.format("YYYY-MM-DD"),
          week_status: week_status,
          budget_obj: {}
        });
        nextWeek = nextWeek.clone().add(1, "week");
      }
      this.ppcSettingsWeeksRangeSet(range);
    }
  }
};
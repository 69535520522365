import AccountsMixin from "./AccountsMixin.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "DashboardV2Mixin",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  mounted() {},
  methods: {
    async getFilterValuesForSellersFilter() {
      this.sellers = await this.getOmnyAccountSellers();
      if (this.sellers.length === 0) {
        this.handleErrorOccured();
        return;
      }
      return this.sellers;
    },
    async getFilterValuesForMarketPlacesFilter() {
      const path = `/dashboard/v2/filters/countries`;
      this.marketplaces = await this.getCountriesBasedOnSellers({
        sellers: this.currentSellerIds
      }, path);
      return this.marketplaces;
    }
  }
};
import Filter from "./filter";
import moment from "moment";
import { request } from "@/store";
import { computeMinMax, transformWithCountries } from "@/utils";
import { COLORS, GROUP_BY_TAB_ELEMENTS } from "@/utils/constants";
import { periodTableHeaders, productTableHeaders } from "@/components/ppc";
import { computeDetailsForKpis } from "@/utils/transformers";
const ppc = {
  state: {
    accountState: {
      sellers: null,
      marketplaces: null
    },
    sellers: new Filter([], [], "id"),
    marketplaces: new Filter([], [], "sales_channel"),
    product_groups: new Filter([], [], "campaign_group_name"),
    products: new Filter([], [], "products"),
    campaigns: new Filter([], [], 'campaign_id'),
    targetings: new Filter([], [], "targeting"),
    match_types: new Filter([], [], "match_type"),
    currencies: new Filter([], [], "currency"),
    periodTable: {
      period: 1,
      periodText: "weekly",
      periodTableElements: [],
      minMax: {},
      items: [],
      fields: periodTableHeaders,
      weekly: {
        limit: 12,
        groupBy: "Current week",
        dateFormat: "MMM DD, YYYY",
        title: "Last 12 weeks",
        currentDateFunc: date => date.clone().startOf("isoWeek"),
        endDateFunc: date => date.clone().endOf("isoWeek"),
        currentPeriodDate: moment().startOf("isoWeek")
      },
      monthly: {
        limit: 12,
        groupBy: "Current month",
        dateFormat: "MMM YYYY",
        title: "Last 12 months",
        currentDateFunc: date => date.clone().startOf("month"),
        endDateFunc: date => date.clone().endOf("month"),
        currentPeriodDate: moment().startOf("month")
      },
      daily: {
        limit: 14,
        groupBy: "Yesterday",
        dateFormat: "MMM DD, YYYY",
        title: "Last 14 days",
        currentDateFunc: date => date.clone(),
        endDateFunc: date => date.clone(),
        currentPeriodDate: moment().subtract(1, "days")
      }
    },
    kpis: {
      CONSIDERED_KPIS: [{
        key: "sponsored_sales",
        name: "sponsored sales",
        subtitle: {
          name: "units sold",
          key: "units_sold_clicks_14_d"
        },
        percentageColor: COLORS.DARK_GRAY,
        percentageBgColor: COLORS.GRAY_BLUE,
        subtitlePercentageColor: COLORS.DARK_GRAY
      }, {
        key: "spend",
        name: "spend",
        subtitle: {
          name: "clicks",
          key: "clicks"
        },
        percentageColor: COLORS.DARK_GRAY,
        percentageBgColor: COLORS.GRAY_BLUE,
        subtitlePercentageColor: COLORS.DARK_GRAY
      }, {
        key: "TACOS",
        name: "TACOS",
        subtitle: {
          name: "ROAS",
          key: "ROAS"
        }
      }, {
        key: "CVR",
        name: "CVR",
        subtitle: {
          name: "CPC",
          key: "CPC"
        }
      }],
      data: []
    },
    productTable: {
      period: null,
      groupBy: 0,
      groupByText: '',
      groupByElements: [],
      groupBys: {
        seller: {
          minMax: {},
          minMaxExclude: ["seller_name"],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.seller,
          fetch: "ppcFetchReportGroupedBySellers"
        },
        marketplace: {
          minMax: {},
          minMaxExclude: ["sales_channel"],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.marketplace,
          fetch: "ppcFetchReportGroupedByMarketplaces"
        },
        product: {
          minMax: {},
          minMaxExclude: [],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.product,
          fetch: "ppcFetchReportGroupedByProducts"
        },
        campaign_group: {
          minMax: {},
          minMaxExclude: ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.campaign_group,
          fetch: "ppcFetchReportGroupedByCampaignGroups"
        },
        campaign: {
          minMax: {},
          minMaxExclude: [],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.campaign,
          fetch: "ppcFetchReportGroupedByCampaigns"
        },
        targeting: {
          minMax: {},
          minMaxExclude: [],
          items: new Array(24).fill({}),
          total: [],
          maxCharCount: {},
          sort: {
            spend: "desc"
          },
          fields: productTableHeaders.targeting,
          fetch: "ppcFetchReportGroupedByTargetings"
        }
      },
      state: "closed",
      // "closed | intermediate | full-size"
      page: 1,
      perPage: 20,
      totalPages: 0
    },
    currencyConfig: {
      full: false,
      currency: "EUR",
      precision: 0,
      showCurrencySign: true,
      digitSeparator: ".",
      limit: 10_000
    },
    settings: {
      strategies: [],
      products: [],
      boost: null,
      bid: null
    }
  },
  mutations: {
    setAccountState(state, payload) {
      state.accountState = payload;
    },
    setSellers(state, payload) {
      state.sellers.list = payload;
    },
    setSelectedSellers(state, payload) {
      state.sellers.selected = payload;
    },
    setMarketplaces(state, payload) {
      state.marketplaces.list = payload;
    },
    setSelectedMarketplaces(state, payload) {
      state.marketplaces.selected = payload;
    },
    setProductGroups(state, payload) {
      state.product_groups.list = payload;
    },
    setSelectedProductGroups(state, payload) {
      state.product_groups.selected = payload;
    },
    setCampaigns(state, payload) {
      state.campaigns.list = payload;
    },
    setSelectedCampaigns(state, payload) {
      state.campaigns.selected = payload;
    },
    setTargetings(state, payload) {
      state.targetings.list = payload;
    },
    setSelectedTargetings(state, payload) {
      state.targetings.selected = payload;
    },
    setMatchTypes(state, payload) {
      state.match_types.list = payload;
    },
    setSelectedMatchTypes(state, payload) {
      state.match_types.selected = payload;
    },
    setPeriodTablePeriod(state, payload) {
      state.periodTable.period = payload;
      state.periodTable.periodText = state.periodTable.periodTableElements[payload].key;
    },
    setProductTableGroupBy(state, payload) {
      state.productTable.groupBy = payload;
      console.log("setProductTableGroupBy", state.productTable.groupByElements[payload].key);
      state.productTable.groupByText = state.productTable.groupByElements[payload].key;
    },
    setProductTablePeriod(state, payload) {
      state.productTable.period = payload;
    },
    setProductTableState(state, payload) {
      state.productTable.state = payload;
    },
    setProductTablePage(state, payload) {
      state.productTable.page = payload;
    },
    setProductTablePerPage(state, payload) {
      state.productTable.perPage = payload;
    },
    setProductTableTotalPages(state, payload) {
      state.productTable.totalPages = payload;
    },
    setPeriodTableMinMax(state, payload) {
      state.periodTable.minMax = payload;
    },
    setPeriodTableItems(state, payload) {
      state.periodTable.items = payload;
    },
    setPeriodTableFields(state, payload) {
      state.periodTable.fields = payload;
    },
    setKpisData(state, payload) {
      state.kpis.data = payload;
    },
    setSellersTableItems(state, payload) {
      state.productTable.groupBys.seller.items = payload;
    },
    setSellersTableTotal(state, payload) {
      state.productTable.groupBys.seller.total = payload;
    },
    setSellersTableMinMax(state, payload) {
      state.productTable.groupBys.seller.minMax = payload;
    },
    setMarketplacesTableItems(state, payload) {
      state.productTable.groupBys.marketplace.items = payload;
    },
    setMarketplacesTableTotal(state, payload) {
      state.productTable.groupBys.marketplace.total = payload;
    },
    setMarketplacesTableMinMax(state, payload) {
      state.productTable.groupBys.marketplace.minMax = payload;
    },
    setProductsTableItems(state, payload) {
      state.productTable.groupBys.product.items = payload;
    },
    setProductsTableTotal(state, payload) {
      state.productTable.groupBys.product.total = payload;
    },
    setProductsTableMinMax(state, payload) {
      state.productTable.groupBys.product.minMax = payload;
    },
    setCampaignGroupsTableItems(state, payload) {
      state.productTable.groupBys.campaign_group.items = payload;
    },
    setCampaignGroupsTableTotal(state, payload) {
      state.productTable.groupBys.campaign_group.total = payload;
    },
    setCampaignGroupsTableMinMax(state, payload) {
      state.productTable.groupBys.campaign_group.minMax = payload;
    },
    setCampaignsTableItems(state, payload) {
      state.productTable.groupBys.campaign.items = payload;
    },
    setCampaignsTableTotal(state, payload) {
      state.productTable.groupBys.campaign.total = payload;
    },
    setCampaignsTableMinMax(state, payload) {
      state.productTable.groupBys.campaign.minMax = payload;
    },
    setTargetingsTableItems(state, payload) {
      state.productTable.groupBys.targeting.items = payload;
    },
    setTargetingsTableTotal(state, payload) {
      state.productTable.groupBys.targeting.total = payload;
    },
    setTargetingsTableMinMax(state, payload) {
      state.productTable.groupBys.targeting.minMax = payload;
    },
    ppcProductTableGroupByElementsSet(state, payload) {
      state.productTable.groupByElements = payload;
      state.periodTable.periodTableElements = [{
        name: "Day",
        datePickerTitle: 'Yesterday',
        key: "daily",
        active: false
      }, {
        name: "Week",
        key: "weekly",
        datePickerTitle: 'Current week',
        active: true
      }, {
        name: "Month",
        key: "monthly",
        datePickerTitle: 'Current month',
        active: false
      }];
      console.log('ppc', state);
    },
    ppcSetProductTableSort(state, {
      sort,
      direction
    }) {
      state.productTable.groupBys[state.productTable.groupByText].sort = {
        [sort]: direction
      };
    }
  },
  getters: {
    ppcGetAccountState(state) {
      return () => {
        const res = {
          sellers: state.sellers.defaultList,
          marketplaces: state.marketplaces.defaultList
        };
        return res;
      };
    },
    ppcGetSellers(state) {
      return () => {
        return state.sellers.filterValues;
      };
    },
    ppcGetSellerNames(state) {
      return () => {
        return state.sellers.filterState.selected.map(seller => seller.name);
      };
    },
    ppcGetMarketplaces(state) {
      return () => {
        return state.marketplaces.filterValues;
      };
    },
    ppcGetMarketplacesObjects(state) {
      return () => {
        return state.marketplaces.filterState.selected;
      };
    },
    ppcGetCampaigns(state) {
      return () => {
        return state.campaigns.selectedValues;
      };
    },
    ppcGetTargetings(state) {
      return () => {
        return state.targetings.selectedValues;
      };
    },
    ppcGetKeywords(state) {
      // Extract keyword ids from targetings
      return () => {
        return state.targetings.filterState.selected.map(targeting => targeting.id);
      };
    },
    ppcGetMatchTypes(state) {
      return () => {
        return state.match_types.selectedValues;
      };
    },
    ppcGetSkus(state) {
      return () => {
        return state.products.selected.map(product => product.sku).filter(Boolean);
      };
    },
    ppcGetAsins(state) {
      return () => {
        return state.products.selected.map(product => product.asin).filter(Boolean);
      };
    },
    ppcGetPeriodTablePeriod(state) {
      return () => {
        return state.periodTable.period;
      };
    },
    ppcGetProductTablePeriod(state) {
      return () => {
        return state.productTable.period;
      };
    },
    ppcGetProductTableGroupBy(state) {
      return () => {
        return state.productTable.groupBy;
      };
    },
    ppcGetCurrencyConfig(state) {
      return () => {
        return state.currencyConfig;
      };
    },
    ppcGetProductTableState(state) {
      return () => {
        return state.productTable.state;
      };
    },
    ppcGetProductTablePage(state) {
      return () => {
        return state.productTable.page;
      };
    },
    ppcGetProductTablePerPage(state) {
      return () => {
        return state.productTable.perPage;
      };
    },
    ppcGetProductTableTotalPages(state) {
      return () => {
        return state.productTable.totalPages;
      };
    },
    ppcGetFilters(_, getters) {
      return () => {
        return {
          seller_id: getters["ppcGetSellers"](),
          sales_channel: getters["ppcGetMarketplaces"](),
          campaigns_list: getters["ppcGetCampaigns"](),
          targeting_list: getters["ppcGetTargetings"](),
          match_type_list: getters["ppcGetMatchTypes"](),
          sku_list: getters["ppcGetSkus"](),
          asin_list: getters["ppcGetAsins"]()
        };
      };
    },
    ppcGetPeriodTableItems(state) {
      return () => {
        return state.periodTable.items;
      };
    },
    ppcGetPeriodTableMinMax(state) {
      return () => {
        return state.periodTable.minMax;
      };
    },
    ppcGetPeriodTableFields(state) {
      return () => {
        return state.periodTable.fields;
      };
    },
    ppcGetProductTableGroupByText(state) {
      return () => {
        return state.productTable.groupByText;
      };
    },
    ppcGetPeriodTableGroupByText(state) {
      return () => {
        return state.periodTable.periodText;
      };
    },
    ppcGetProductTableSort(state) {
      return () => {
        let obj = {};
        for (const [key, value] of Object.entries(state.productTable.groupBys?.[state.productTable.groupByText]?.sort)) {
          obj['sort_by'] = key;
          obj['sort_direction'] = value;
        }
        return obj;
      };
    },
    ppcTargetingFiltersEnabled(_, getters) {
      return () => {
        const {
          campaigns_list,
          targeting_list,
          match_type_list
        } = getters.ppcGetFilters();
        return [campaigns_list, match_type_list, targeting_list].reduce((acc, curr) => acc || curr.length > 0, false);
      };
    }
  },
  actions: {
    ppcSetAccountState({
      commit,
      state
    }) {
      const payload = {
        sellers: state.sellers.defaultList,
        marketplaces: state.marketplaces.defaultList
      };
      commit("setAccountState", payload);
    },
    ppcSetPeriodTablePeriod({
      commit
    }, payload) {
      commit("setPeriodTablePeriod", payload);
    },
    ppcSetProductTablePeriod({
      commit
    }, payload) {
      commit("setProductTablePeriod", payload);
    },
    ppcSetProductTableGroupBy({
      commit
    }, payload) {
      commit("setProductTableGroupBy", payload);
    },
    ppcSetProductTableState({
      commit
    }, payload) {
      commit("setProductTableState", payload);
    },
    ppcSetProductTablePage({
      commit
    }, payload) {
      commit("setProductTablePage", payload);
    },
    ppcSetProductTablePerPage({
      commit
    }, payload) {
      commit("setProductTablePerPage", payload);
    },
    ppcSetProductTableTotalPages({
      commit
    }, payload) {
      commit("setProductTableTotalPages", payload);
    },
    ppcSetPeriodTableFields({
      commit
    }, payload) {
      commit("setPeriodTableFields", payload);
    },
    async ppcFetchPeriodTableData({
      commit,
      getters
    }) {
      try {
        const params = {
          period: getters.ppcGetPeriodTableGroupByText(),
          ...getters.ppcGetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        let response = await request('/ppc/global/evolution', 'POST', params, false, true, 'cors');
        if (!response) {
          return [];
        }
        let data = response.data.graph;

        // Compute and store the minMax object
        if (data) {
          commit('setPeriodTableMinMax', computeMinMax(data, ["period_agg"]));
          commit('setPeriodTableItems', data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async ppcFetchKpis({
      commit,
      getters,
      state
    }) {
      try {
        const period = getters.ppcGetPeriodTableGroupByText();
        const params = {
          period,
          ...getters.ppcGetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        let response = await request('/ppc/current_period/kpis', 'POST', params, false, true, 'cors');
        if (response) {
          let data = response.data;
          data = data.kpis.pop();
          commit('setKpisData', computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS));
        }
      } catch (err) {
        console.log(err);
      }
    },
    async ppcFetchReportGroupedBySellers({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...pager,
          period: {
            range,
            compare
          },
          ...getters.ppcGetFilters(),
          ...getters.ppcGetProductTableSort(),
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/sellers", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            count,
            result
          } = report;
          commit('setProductTableTotalPages', count);
          commit('setSellersTableItems', result);
          commit('setSellersTableTotal', total);
          commit('setSellersTableMinMax', computeMinMax(result, ["seller_name"]));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async ppcFetchReportGroupedByMarketplaces({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...getters.ppcGetFilters(),
          ...pager,
          ...getters.ppcGetProductTableSort(),
          period: {
            range,
            compare
          },
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/marketplace", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          commit('setProductTableTotalPages', count);
          commit('setMarketplacesTableItems', result);
          commit('setMarketplacesTableTotal', total);
          commit('setMarketplacesTableMinMax', computeMinMax(result, ["sales_channel"]));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async ppcFetchReportGroupedByProducts({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...getters.ppcGetFilters(),
          ...pager,
          ...getters.ppcGetProductTableSort(),
          period: {
            range,
            compare
          },
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/product", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          commit('setProductTableTotalPages', count);
          commit('setProductsTableItems', result);
          commit('setProductsTableTotal', total);
          commit('setProductsTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async ppcFetchReportGroupedByCampaignGroups({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...getters.ppcGetFilters(),
          ...pager,
          ...getters.ppcGetProductTableSort(),
          period: {
            range,
            compare
          },
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/product_group", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          commit('setProductTableTotalPages', count);
          commit('setCampaignGroupsTableItems', result);
          commit('setCampaignGroupsTableTotal', total);
          commit('setCampaignGroupsTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async ppcFetchReportGroupedByCampaigns({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...getters.ppcGetFilters(),
          ...pager,
          ...getters.ppcGetProductTableSort(),
          period: {
            range,
            compare
          },
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/campaign", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          commit('setProductTableTotalPages', count);
          commit('setCampaignsTableItems', result);
          commit('setCampaignsTableTotal', total);
          commit('setCampaignsTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async ppcFetchReportGroupedByTargetings({
      commit,
      getters
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        const {
          range,
          compare
        } = getters.ppcGetProductTablePeriod();
        if (!pager) {
          pager = {
            page: getters.ppcGetProductTablePage(),
            page_size: getters.ppcGetProductTablePerPage()
          };
        }
        const params = {
          ...getters.ppcGetFilters(),
          ...pager,
          ...getters.ppcGetProductTableSort(),
          period: {
            range,
            compare
          },
          ...getters.currencyConfigQueryGet()
        };
        let response = await request("/ppc/report/targeting", "POST", params, false, true, "cors");
        if (response && store) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          commit('setProductTableTotalPages', count);
          commit('setTargetingsTableItems', result);
          commit('setTargetingsTableTotal', total);
          commit('setTargetingsTableMinMax', computeMinMax(result));
        }
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    ppcSetProductTableGroupByElements({
      commit,
      getters
    }) {
      const {
        sellers,
        marketplaces
      } = getters.ppcGetAccountState();
      const {
        PRODUCT,
        CAMPAIGN_GROUP,
        CAMPAIGN,
        TARGETING,
        SELLER,
        MARKETPLACE
      } = GROUP_BY_TAB_ELEMENTS;
      const defaultElements = [PRODUCT, CAMPAIGN_GROUP, CAMPAIGN, TARGETING];
      if (sellers.length > 1 && marketplaces.length > 1) {
        commit("ppcProductTableGroupByElementsSet", [SELLER, MARKETPLACE, ...defaultElements]);
        return;
      } else if (marketplaces.length > 1) {
        commit("ppcProductTableGroupByElementsSet", [MARKETPLACE, ...defaultElements]);
        return;
      }
      commit("ppcProductTableGroupByElementsSet", defaultElements);
    },
    ppcSetProductTableSort({
      commit
    }, payload) {
      commit('ppcSetProductTableSort', payload);
    }
  }
};
export default ppc;
import { mapActions } from "vuex";
import { isObjectEmpty } from "@/utils";
export default {
  name: "TasksMixin",
  computed: {
    taskStatusHandlers() {
      return {
        NOTASK: this.handleIdle,
        PENDING: this.handleRunning,
        STARTED: this.handleRunning,
        RETRY: this.handleRunning,
        SUCCESS: this.handleFinished,
        FAILURE: this.handleFailed
      };
    }
  },
  data() {
    return {
      asyncUpdateInterval: null,
      asyncUpdateFileUpload: null
    };
  },
  methods: {
    ...mapActions(["tasksGetAsyncUpdate", "tasksGetAsyncFileUploadUpdate"]),
    async checkTaskStatusPeriodically(task_identifier_omny) {
      console.log("[setting up checkTaskStatusPeriodically]");
      if (this.asyncUpdateInterval) {
        console.log("[clearing any previous interval]");
        clearTimeout(this.asyncUpdateInterval);
      }
      this.asyncUpdateInterval = setTimeout(function () {
        this.getAsyncUpdate(task_identifier_omny);
      }.bind(this), 5000); // Call every 1 seconds
    },
    async checkFileUploadTaskStatusPeriodically(task_id) {
      console.log("[checkFileUploadTaskStatusPeriodically]");
      if (this.asyncUpdateFileUpload) {
        console.log("[clearing any previous same interval]");
        clearTimeout(this.asyncUpdateFileUpload);
      }
      if (task_id) {
        this.asyncUpdateFileUpload = setTimeout(function () {
          this.getAsyncFileUploadUpdate(task_id);
        }.bind(this), 5000); // Call every 1 seconds
      }
    },
    async getAsyncFileUploadUpdate(task_id) {
      let result = await this.tasksGetAsyncFileUploadUpdate({
        task_id: task_id
      });
      console.log("[get update for async file upload]");
      console.log(result);
      if (result?.task_status) {
        this.executeTaskStatusHandler(result);
      }
      if (this.asyncUpdateFileUpload && result.task_status === "SUCCESS" || isObjectEmpty(result)) {
        console.log("[clearing interval inside async update]");
        clearTimeout(this.asyncUpdateFileUpload);
        this.asyncUpdateFileUpload = null;
      }
    },
    async getAsyncUpdate(task_identifier_omny) {
      this.translationTaskIdentifier = task_identifier_omny;
      console.log("[get async update for the selected item in the parent event]" + task_identifier_omny);
      console.log(task_identifier_omny);
      let result = await this.tasksGetAsyncUpdate({
        task_identifier_omny: task_identifier_omny
      });
      if (result?.task_status) {
        this.executeTaskStatusHandler(result);
      }
      if (this.asyncUpdateInterval && result.task_status === "SUCCESS" || isObjectEmpty(result)) {
        console.log("[clearing interval inside async update]");
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
    },
    executeTaskStatusHandler(data) {
      this.taskStatusHandlers[data.task_status](data);
    }
  }
};
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_b_col = _resolveComponent("b-col");
  const _component_number_value = _resolveComponent("number-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_b_col, {
    onClick: $options.handleNameClick,
    class: _normalizeClass(['name', `level-${$props.level}`, `type-${$props.type}`])
  }, {
    default: _withCtx(() => [_createVNode(_component_hug_value, {
      class: "cursor-pointer",
      loading: $props.loading,
      align: $props.fields[0].justify,
      styles: {
        padding: '8px'
      }
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["loading", "align"])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onClick", "class"]), _createVNode(_component_b_col, null, {
    default: _withCtx(() => [_createVNode(_component_hug_value, {
      loading: $props.loading,
      align: $props.fields[1].justify,
      styles: {
        padding: '8px'
      }
    }, {
      default: _withCtx(() => [$options.isQuantity ? (_openBlock(), _createBlock(_component_number_value, {
        key: 0,
        styles: {
          padding: 0
        },
        value: $props.value.amount.period1,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])) : (_openBlock(), _createBlock(_component_currency_value, {
        key: 1,
        value: $props.value.amount.period1,
        loading: $props.loading,
        full: "",
        allowPrecisionModification: false
      }, null, 8 /* PROPS */, ["value", "loading"]))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "align"])]),
    _: 1 /* STABLE */
  }), _createVNode(_component_b_col, null, {
    default: _withCtx(() => [_createVNode(_component_hug_value, {
      loading: $props.loading,
      align: $props.fields[2].justify,
      styles: {
        padding: '8px'
      }
    }, {
      default: _withCtx(() => [$options.isQuantity ? (_openBlock(), _createBlock(_component_number_value, {
        key: 0,
        styles: {
          padding: 0
        },
        value: $props.value.amount.period2,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])) : (_openBlock(), _createBlock(_component_currency_value, {
        key: 1,
        value: $props.value.amount.period2,
        loading: $props.loading,
        full: "",
        allowPrecisionModification: false
      }, null, 8 /* PROPS */, ["value", "loading"]))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "align"])]),
    _: 1 /* STABLE */
  }), _createVNode(_component_b_col, null, {
    default: _withCtx(() => [_createVNode(_component_hug_value, {
      loading: $props.loading,
      align: $props.fields[3].justify,
      styles: {
        padding: '8px'
      }
    }, {
      default: _withCtx(() => [$options.isQuantity ? (_openBlock(), _createBlock(_component_number_value, {
        key: 0,
        styles: {
          padding: 0
        },
        value: $props.value?.evolution ?? null,
        loading: $props.loading,
        "show-sign": "",
        color: $options.getEvolutionColor($props.value?.evolution)
      }, null, 8 /* PROPS */, ["value", "loading", "color"])) : (_openBlock(), _createBlock(_component_currency_value, {
        key: 1,
        value: $props.value?.evolution ?? null,
        "show-sign": "",
        color: $options.getEvolutionColor($props.value?.evolution),
        full: "",
        allowPrecisionModification: false
      }, null, 8 /* PROPS */, ["value", "color"]))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "align"])]),
    _: 1 /* STABLE */
  }), _createVNode(_component_b_col, null, {
    default: _withCtx(() => [_createVNode(_component_hug_value, {
      loading: $props.loading,
      align: $props.fields[4].justify,
      styles: {
        padding: '8px'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_percentage_value, {
        value: $props.value?.evolution_percentage,
        color: $options.getEvolutionColor($props.value?.evolution_percentage),
        "show-sign": ""
      }, null, 8 /* PROPS */, ["value", "color"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "align"])]),
    _: 1 /* STABLE */
  })], 64 /* STABLE_FRAGMENT */);
}
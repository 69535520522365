import { mapState, mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import CustomAccountQuickSearchElement from "@/components/shared/CustomAccountQuickSearchElement";
import { EVENTS } from '@/utils';
export default {
  name: "TopAccount",
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      topAccountNameLoading: false,
      showTopAccountListView: false,
      searchQuery: '',
      searchPlaceHolder: 'Search account',
      colors: ['#E7747A', '#5DE481', '#00A7FF', '#9447FF']
    };
  },
  computed: {
    ...mapState({
      workspaces: state => state.workspace.list,
      current_workspace: state => state.workspace.current_workspace,
      isOmnyAdmin: state => state.auth.isOmnyAdmin
    }),
    workspaceName() {
      const workspace = this.current_workspace;
      return workspace ? workspace.workspace_name : "";
    },
    firstLetterOfWorkspace() {
      return this.workspaceName ? this.workspaceName.charAt(0) : '';
    },
    totalWorkspaces() {
      return this.filteredWorkspaces.length;
      //return this.workspaces.length;
    },
    topWorkspacesList() {
      return this.workspaces;
    },
    filteredWorkspaces() {
      if (this.searchQuery) {
        return this.workspaces.filter(workspace => workspace.workspace_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      return this.workspaces;
    },
    sortedWorkspaces() {
      return this.filteredWorkspaces.filter(workspace => workspace.workspace_name !== this.current_workspace.workspace_name).sort((a, b) => a.workspace_name.localeCompare(b.workspace_name)).map((workspace, index) => ({
        ...workspace,
        firstLetter: workspace.workspace_name.charAt(0),
        colorIndex: index % this.colors.length
      })).slice(0, 12);
    }
  },
  methods: {
    ...mapActions(["workspaceCurrent", "workspaceChange", "accountList", "workspaceList", "authLogout"]),
    toggleTopAccountListView() {
      this.showTopAccountListView = !this.showTopAccountListView;
      this.$emit('list-view-toggled', this.showTopAccountListView);
    },
    async onCurrentChange(workspace_id) {
      this.topAccountNameLoading = true;
      await this.workspaceChange({
        workspace_id: workspace_id
      });
      this.toggleTopAccountListView();
      //this.$router.go(0);
      window.$bus.trigger(EVENTS.REFRESH_APPLICATION);
      this.topAccountNameLoading = false;
    },
    async onClick(item) {
      if (item.url) {
        this.$router.push(item.url);
      }
      if (item.action) {
        this.loading = true;
        await this.authLogout();
        this.loading = false;
        this.$router.push('/login');
      }
    },
    handleAccountSearch(accountToSearch) {
      console.log('[top account search ]');
      console.log(accountToSearch);
      this.searchQuery = accountToSearch.value[0];
    },
    navigateToAddAccount() {
      this.$router.push({
        path: '/manage-accounts',
        query: {
          openModal: 'addAccountFromTop'
        }
      });
    }
  },
  components: {
    AppIcon,
    CustomAccountQuickSearchElement
  }
};
import TableGraph from '@/components/TableGraph.vue';
import DashboardMixin from '@/mixins/DashboardMixin';
import { computeMinMax, EVENTS, computeTableWidths } from '@/utils';
import { getGraphHeaderByTabView, getGraphTitles } from '@/components/shared/chartHelpers';
import { defineComputedPeriod, getGraphDefaultSortBy } from '@/components/shared/chartHelpers';
import { mapState } from 'vuex';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
export default {
  name: 'SellersPerformancePage',
  mixins: [DashboardMixin],
  data() {
    return {
      positions: [],
      totalPositions: [],
      totalWidths: [],
      graphLoading: false,
      totalLoading: false,
      showTotal: false,
      minMax: {},
      graphHeaders: [],
      graphDescription: [],
      sortBy: "",
      sortDirection: "desc",
      tab: "Sellers",
      view: "Performance",
      currentViewPeriodOptions: {},
      pageRenderedLoading: false,
      showError: false,
      graphId: 'sellers_advertising'
    };
  },
  async mounted() {
    console.log("Sellers Performance: ", this.$props);
    this.$store.dispatch('dashboardSetCurrentTab', 'Sellers');
    this.$store.dispatch('dashboardSetCurrentView', 'Performance');

    // Get graph Title
    this.graphDescription = this.headerDescription(this.tab, this.view);

    // Update Graph Headers
    this.graphHeaders = getGraphHeaderByTabView(this.tab, this.view, this.period);

    // Set the default sortBy
    this.sortBy = getGraphDefaultSortBy(this.tab, this.view);
    await this.updateComponent({
      period: this.period
    });
    this.$nextTick(() => {
      this.totalWidths = computeTableWidths(this.graphId);
    });
    window.$bus.on(EVENTS.SELLERS_PERFORMANCE, () => {
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    });
    window.$bus.on(EVENTS.SIDEBAR_CHANGED, () => {
      this.totalWidths = computeTableWidths(this.graphId);
      console.log("Sidebar changed: ", this.totalWidths);
    });
  },
  unmounted() {
    window.$bus.off(EVENTS.SELLERS_PERFORMANCE);
    window.$bus.off(EVENTS.SIDEBAR_CHANGED);
  },
  computed: {
    ...mapState({
      graph: state => state.dashboard.pageReports['sellers_performance'].graph,
      period: state => state.period.datePickerPeriod
    }),
    graphTitle() {
      return `${getGraphTitles()[this.tab][this.view]} ${defineComputedPeriod(this.period)}` || "";
    },
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    }
  },
  methods: {
    retry() {
      this.showError = false;
      this.updateComponent({
        period: this.period
      }).catch(err => {
        console.log("Failed to update component", err);
      });
    },
    async innerFetch({
      period
    }) {
      return await this.fetchKpis({
        path: '/sellers/performance',
        filters: this.filters,
        period,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      });
    },
    async updateComponent({
      period
    }) {
      this.graphLoading = true;
      const {
        graph,
        total,
        error
      } = await this.setupComponent({
        period
      });
      if (error) {
        this.showError = true;
        return;
      }

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.totalPositions = total[0];
      this.setGraph({
        graph,
        tabView: 'sellers_performance'
      });
      this.graphLoading = false;
    },
    async setupComponent({
      period
    }) {
      await this.getOmnyAccountSellers();
      return await this.innerFetch({
        period
      });
    },
    async sortTable(sortBy) {
      this.pageRenderedLoading = true;
      if (this.sortBy !== sortBy) this.sortDirection = 'desc';else {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortBy = sortBy;
      const {
        graph
      } = await this.setupComponent({
        period: this.period
      });

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        graph,
        tabView: 'sellers_performance'
      });
      this.pageRenderedLoading = false;
    }
  },
  components: {
    TableGraph,
    ErrorWrapper
  }
};
import AppIcon from '@/components/AppIcon';
import PlayIcon from '@/components/icons/PlayIcon.vue';
import PauseIcon from '@/components/icons/PauseIcon.vue';
import { ref } from 'vue';
export default {
  name: 'StateSwitch',
  emits: ['update:modelValue', 'change'],
  components: {
    AppIcon,
    PauseIcon,
    PlayIcon
  },
  props: {
    modelValue: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      id: null,
      checked: this.modelValue
    };
  },
  watch: {
    modelValue(newValue) {
      this.checked = newValue;
    }
  },
  mounted() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  },
  methods: {
    onChange(e) {
      this.$emit('update:modelValue', !this.checked);
    }
  }
};
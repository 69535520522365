import { CM_TO_INCH_COEF, KG_TO_LB_COEF } from '@/utils/constants';
export default {
  name: "DemandForecastingMixins",
  computed: {
    additionalBoostRuleForItem() {
      return {
        trend: this.additionalTrendBoostRule,
        manual: this.additionalManualBoostRule,
        seasonal: this.additionalSeasonalBoostRule
      };
    }
  },
  methods: {
    totalCoverageFromSettings(settings) {
      let result = this.totalLeadTimeFromSettings(settings) + +settings.target_order_frequency * 1 + (settings.fba_buffer_stock_custom || this.fbaBufferStockFromSettings(settings)) * 1;
      return result;
    },
    fbaBufferStockFromSettings(settings) {
      let result = 2.05 * 0.75 * Math.sqrt(this.totalLeadTimeFromSettings(settings) + +settings.target_order_frequency * 1);
      return result;
    },
    totalLeadTimeFromSettings(settings) {
      return settings.supplier_manufacturing_leadtime * 1 + settings.supplier_preparation_leadtime * 1 + settings.supplier_to_fufillment_center_leadtime * 1 + settings.fufillment_center_preparation_leadtime * 1;
    },
    isSlowMoverRule(item, settings) {
      return item.weighted_30d_velocity < settings.velocity_threshold;
    },
    additionalManualBoostRule(item, settings) {
      return settings.manual_boost_value.value;
    },
    additionalSeasonalBoostRule(item, settings) {
      return item.seasonal_boost_value * 100;
    },
    additionalTrendBoostRule(item, settings) {
      return item.weighted_30d_velocity > 20 ? 16 * item.daily_velocity_14D / (30 * item.daily_velocity_30D - 14 * item.daily_velocity_14D) - 1 : 0;
    },
    weighted30DSalesForecastRule(item, settings) {
      return item.weighted_30d_velocity * (1 + this.additionalBoostRuleForItem[settings.boost_type](item, settings) / 100);
    },
    daysLeftRule(item, settings) {
      let result = 30.999 * (item.available_inventory / this.weighted30DSalesForecastRule(item, settings));
      return result;
    },
    rawQuantityToSendRule(item, settings) {
      if (this.isSlowMoverRule(item, settings)) {
        return item.orderable ? Math.max(settings.target_inventory - item.available_inventory, 0) : 0;
      }
      return item.orderable ? Math.max(this.totalCoverageFromSettings(settings) - this.daysLeftRule(item, settings), 0) * (this.weighted30DSalesForecastRule(item, settings) / 30) : 0;
    },
    rawQuantityToSendDaysRule(item, settings) {
      return 30 * this.rawQuantityToSendRule(item, settings) / this.weighted30DSalesForecastRule(item, settings);
    },
    attributeFromItem(item, settings, key) {
      return item[key];
    },
    attributeConvertedValueFromItemLB(item, settings, key) {
      if (this.scaleType === 'imperial') {
        return this.convertToImperialKgtoLb(item[key]);
      }
      return item[key];
    },
    attributeConvertedValueFromItemCM(item, settings, key) {
      if (this.scaleType === 'imperial') {
        return this.convertToImperialCmtoIn(item[key]);
      }
      return item[key];
    },
    attributeFromSettings(item, settings, key) {
      return settings[key];
    },
    convertToImperialCmtoIn(value) {
      if (value === null || value === undefined || value === '') {
        return null;
      }
      return (parseFloat(value) * CM_TO_INCH_COEF).toFixed(2);
    },
    convertToImperialKgtoLb(value) {
      if (value === null || value === undefined || value === '') {
        return null;
      }
      return (parseFloat(value) * KG_TO_LB_COEF).toFixed(2);
    },
    // productPackageDimension(item, settings, key) {
    //   return (
    //     item?.product_package_length_cm *
    //     item?.product_package_width_cm *
    //     item?.product_package_height_cm
    //   );
    // },
    productPackageDimension(item, settings, key) {
      const convertValue = value => value ? this.scaleType === 'imperial' ? this.convertToImperialCmtoIn(value) : value : null;
      const length = convertValue(item?.product_package_length_cm);
      const width = convertValue(item?.product_package_width_cm);
      const height = convertValue(item?.product_package_height_cm);
      if (length === null || width === null || height === null) {
        return null;
      }
      return (length * width * height).toFixed(2);
    },
    quantityUnitarySkuBox(item, settings, key) {
      return settings.packaging_required ? this.quantityUnitarySkuBoxRounded(item, settings, key) : this.quantityUnitarySkuBoxNonRounded(item, settings, key);
    },
    quantityUnitarySkuBoxNonRounded(item, settings, key) {
      return Math.ceil(item.manual_override ? item.orderable_units : this.rawQuantityToSendRule(item, settings));
    },
    quantityUnitarySkuBoxRounded(item, settings, key) {
      let units_per_box = Math.max(item.units_per_box, 1);
      let full_boxes = Math.ceil((item.manual_override ? item.orderable_units : this.rawQuantityToSendRule(item, settings)) / units_per_box);
      // let additional_box = units_per_box > 1 ? Number(
      //   item.manual_override ? item.orderable_units :
      //     this.rawQuantityToSendRule(item, settings) % units_per_box > 0
      // ) : 0;
      //return (full_boxes + additional_box) * units_per_box;
      return full_boxes * units_per_box;
    },
    quantityBoxes(item, settings, key) {
      return Math.round(this.quantityUnitarySkuBox(item, settings, key) / Math.max(item.units_per_box, 1));
    }
  }
};
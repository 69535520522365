import AccountsMixin from "./AccountsMixin.vue";
import InitializeFiltersMixin from "./InitializeFiltersMixin.vue";
import FetchFiltersMixin from "./FetchFiltersMixin.vue";
export default {
  name: "PPCManagementMixin",
  mixins: [AccountsMixin, InitializeFiltersMixin, FetchFiltersMixin],
  methods: {
    async getFilterValuesForSellersFilter() {
      this.sellers = await this.getOmnyAccountSellers();
      if (this.sellers.length === 0) {
        this.handleErrorOccured();
        return;
      }
      return this.sellers;
    },
    async getFilterValuesForMarketPlacesFilter() {
      const path = `/ppc/v2/filters/countries`;
      this.marketplaces = await this.getCountriesBasedOnSellers({
        sellers: this.currentSellerIds
      }, path);
      return this.marketplaces;
    }
  }
};
export default {
  expose: ["openAsyncFilterDropdown"],
  methods: {
    openAsyncFilterDropdown(filterRef, initializer = async () => {}, callback = () => {}) {
      this.$nextTick(() => {
        const innerFilterItem = filterRef;
        if (innerFilterItem) {
          innerFilterItem.openDropdown();
          initializer().then(() => {
            callback();
          }).catch(err => {
            console.log("Error occurred running the callback");
          });
        }
      });
    }
  }
};
import { request } from '@/store';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { filterOut, truncateString, computeTableWidths, transformWithCountries } from '@/utils';
import { getGraphId } from '@/components/shared/chartHelpers';
import { getGraphHeaderByTabView, getPeriodColumnName, getGraphTitles, defineComputedPeriod, reformatRoute, transformDataForCheckbox, periodsSwitches } from '@/components/shared/chartHelpers';
import { headerDescription, getAdvertisingAccountsFromList, formatSellersFromAdvertisingAccounts } from '@/components/shared/helpers';
import { getCountriesMapping, addGhostIds, updatedField, EVENTS } from '@/utils';
export default {
  name: "DashboardMixin",
  async mounted() {
    const sellers = await this.getOmnyAccountSellers();
    this.defaultSellers = sellers;
  },
  data() {
    return {
      defaultSellers: [],
      refreshTriggers: {
        Global: {
          Performance: EVENTS.GLOBAL_PERFORMANCE,
          Advertising: EVENTS.GLOBAL_ADVERTISING
        },
        Country: {
          Performance: EVENTS.COUNTRY_PERFORMANCE,
          Advertising: EVENTS.COUNTRY_ADVERTISING
        },
        Product: {
          Performance: EVENTS.PRODUCT_PERFORMANCE,
          Advertising: EVENTS.PRODUCT_ADVERTISING,
          'Inventory Coverage': EVENTS.PRODUCT_INVENTORY_COVERAGE,
          'Pricing and Margin': EVENTS.PRODUCT_PRICING_MARGIN
        },
        Advertising: {
          Targeting: EVENTS.ADVERTISING_TARGETING,
          Campaigns: EVENTS.ADVERTISING_CAMPAIGNS
        },
        Sellers: {
          Performance: EVENTS.SELLERS_PERFORMANCE,
          Advertising: EVENTS.SELLERS_ADVERTISING
        }
      }
    };
  },
  computed: {
    ...mapState({
      currentTab: state => state.dashboard.currentTab,
      currentView: state => state.dashboard.currentView
    }),
    ...mapGetters(['accountByProviderGet', 'accountAdvertisingGet', 'dashboardPositionsGraphReportGet', 'dashboardPositionsChartReportGet', 'dashboardPeriodGet', 'dashboardSellersListGet', 'dashboardProductListGet', 'dashboardCurrentViewGet', 'dashboardCurrentTabGet', 'dashboardCountriesSelectedGet', 'dashboardCountriesListGet', 'dashboardProductSuggestionsListGet', 'dashboardProductSuggestionsSelectedGet', 'dashboardSellersSelectedGet', 'dashboardCampaignsSelectedGet', 'dashboardCampaignsListGet', 'dashboardMatchTypeSelectedGet', 'dashboardMatchTypeListGet', 'dashboardPositionsMinMaxGet', 'dashboardShowTotalGet', 'dashboardPageSizeGet', 'dashboardPageGet', 'dashboardPositionsTotalCountGet', 'dashboardPositionsTotalGet', 'workspaceNameById']),
    sellersList() {
      return this.dashboardSellersListGet();
    },
    sellersSelected() {
      return this.dashboardSellersSelectedGet();
    },
    productsList() {
      return this.dashboardProductSuggestionsListGet();
    },
    countriesList() {
      return this.dashboardCountriesListGet();
    },
    campaignsList() {
      return this.dashboardCampaignsListGet();
    },
    matchTypeList() {
      return this.dashboardMatchTypeListGet();
    },
    currentCountries() {
      return this.dashboardCountriesSelectedGet();
    },
    graphControllers() {
      const defaultControllers = {
        Global: {
          views: [{
            name: "Performance",
            tab: "Global",
            active: false,
            path: "/global/performance"
          }, {
            name: 'Advertising',
            active: false,
            tab: "Global",
            path: "/global/advertising"
          }],
          filters: [],
          periods: periodsSwitches.DEFAULT
        },
        Product: {
          views: [{
            name: "Performance",
            active: false,
            tab: "Product",
            path: "/product/performance"
          }, {
            name: 'Pricing and Margin',
            active: false,
            tab: "Product",
            path: "/product/pricing_and_margin"
          }, {
            name: 'Advertising',
            active: false,
            tab: "Product",
            path: "/product/advertising"
          }, {
            name: 'Inventory Coverage',
            active: false,
            tab: "Product",
            path: "/product/inventory_coverage"
          }],
          filters: [],
          periods: periodsSwitches.DAY_INTERVAL
        },
        Advertising: {
          views: [{
            name: "Targeting",
            active: false,
            tab: "Advertising",
            path: "/advertising/targeting"
          }, {
            name: "Campaigns",
            active: false,
            tab: "Advertising",
            path: "/advertising/campaigns"
          }],
          filters: [],
          periods: periodsSwitches.DAY_INTERVAL
        }
      };
      const adminControllers = {
        ...defaultControllers,
        Sellers: {
          views: [{
            name: "Performance",
            active: false,
            tab: "Sellers",
            path: "/sellers/performance"
          }, {
            name: 'Advertising',
            active: false,
            tab: "Sellers",
            path: "/sellers/advertising"
          }],
          filters: [],
          periods: periodsSwitches.DAY_INTERVAL
        }
      };
      if (this.omnyAccountHasMoreThanOneSeller) return adminControllers;
      return defaultControllers;
    },
    getGraphControllers() {
      return this.graphControllers?.[this.currentTab]?.views || [];
    },
    computeGraphTitle() {
      return `${this.graphTitles[this.currentTab][this.currentView]} ${this.getComputedPeriod}`;
    },
    getEvolutionTitle() {
      if (this.currentTab === 'Product' && this.currentView === 'Inventory Coverage') return `Inventory Coverage over the last ${this.getComputedPeriod}`;
      return '';
    },
    getComputedPeriod() {
      const period = this.currentPeriod.key;
      return defineComputedPeriod(period, this.currentView);
    },
    getTabPeriods() {
      if (['Pricing and Margin', 'Inventory Coverage'].includes(this.currentView)) return [];
      return this.graphControllers?.[this.currentTab]?.periods || [];
    },
    currentCountry() {
      return this.dashboardCountrySelectedGet();
    },
    currentProducts() {
      return this.dashboardProductSuggestionsSelectedGet();
    },
    currentSellers() {
      return this.dashboardSellersSelectedGet();
    },
    currentCampaigns() {
      return this.dashboardCampaignsSelectedGet();
    },
    currentMatchType() {
      return this.dashboardMatchTypeSelectedGet();
    },
    getGraphHeader() {
      return getGraphHeaderByTabView(this.currentTab, this.currentView, this.periodColName, this.omnyAccountHasMoreThanOneSeller);
    },
    getGraphSortBy() {
      const activeController = this.currentView;
      return this.graphHeaders?.[this.currentTab]?.[activeController]?.sortBy ?? '';
    },
    periodColName() {
      return getPeriodColumnName(this.currentPeriod.name);
    },
    graphTitles() {
      return getGraphTitles(this.omnyAccountHasMoreThanOneSeller);
    },
    getDescription() {
      return headerDescription(this.currentTab, this.currentView);
    },
    filterMapping() {
      return {
        'Sellers': {
          list: this.sellersList,
          setList: this.dashboardSellersListSet,
          selected: this.currentSellers,
          setSelected: this.dashboardSellersSelectedSet,
          refreshList: () => {
            const sellers = updatedField(this.sellersList, this.currentSellers, 'id', 'id');
            Promise.all([this.initializeCountriesFilters({
              sellers
            }), this.initializeProductsFilters({
              sellers
            }), this.initializeCampaignsFilters({
              sellers
            }), this.initializeMatchTypesFilters({
              sellers
            })]).catch(err => {
              console.log("Failed to refresh other filters based on countries", err);
            });
          }
        },
        'Country': {
          list: this.countriesList,
          setList: this.dashboardCountriesListSet,
          selected: this.currentCountries,
          setSelected: this.dashboardCountriesSelectedSet,
          refreshList: () => {
            const sellers = updatedField(this.sellersList, this.currentSellers, 'id', 'id');
            Promise.all([this.initializeProductsFilters({
              sellers
            }), this.initializeCampaignsFilters({
              sellers
            }), this.initializeMatchTypesFilters({
              sellers
            })]).catch(err => {
              console.log("Failed to refresh other filters based on countries", err);
            });
          }
        },
        'Products': {
          list: this.productsList,
          setList: this.dashboardProductSuggestionsListSet,
          selected: this.currentProducts,
          setSelected: this.dashboardProductSuggestionsSelectedSet
        },
        'Campagins': {
          list: this.campaignsList,
          setList: this.dashboardCampaignsListSet,
          selected: this.currentCampaigns,
          setSelected: this.dashboardCampaignsSelectedSet
        },
        'Match Type': {
          list: this.matchTypeList,
          setList: this.dashboardMatchTypeListSet,
          selected: this.currentMatchType,
          setSelected: this.dashboardMatchTypeSelectedSet
        }
      };
    },
    renderTableWithChart() {
      return ['Inventory Coverage'].includes(this.currentView);
    },
    getPdfExportPages() {
      const controllers = this.graphControllers;

      // Remove all but ['Global', 'Product', 'Country'] with filter
      const pages = Object.keys(controllers).filter(page => ['Global', 'Product', 'Country'].includes(page));
      const topX = 10;
      const modifiedPageList = pages.map(page => {
        let data = controllers[page].views.filter(view => !['Inventory Coverage', 'Pricing and Margin'].includes(view.name)).map(view => {
          const additional = page === 'Product' ? ' - ' + `Top ${topX}` : '';
          return {
            tab: reformatRoute(page),
            view: reformatRoute(view.name),
            name: `${page} - ${view.name} ${additional}`,
            periods: controllers[page].periods,
            path: view.path
          };
        });
        data = data.map(({
          tab,
          ...rest
        }) => {
          if (tab === 'Product') return {
            tab,
            ...rest,
            limit: topX,
            showTotal: true
          };
          if (tab === 'Country') return {
            tab,
            ...rest,
            showTotal: true
          };
          return {
            tab,
            ...rest,
            showTotal: false
          };
        });
        return data;
      });
      return transformDataForCheckbox(modifiedPageList.flat());
    },
    getPdfExportPeriods() {
      return transformDataForCheckbox([{
        period: 'Weekly',
        simple_period: '7D',
        name: 'Weekly/ 7 days'
      }, {
        period: 'Monthly',
        simple_period: '28D',
        name: 'Monthly/ 28 days'
      }, {
        period: 'Daily',
        simple_period: '1D',
        name: 'Daily/Yesterday'
      }]);
    },
    searchSellers() {
      return this.$store.getters['dashboardGetSellers']();
    }
  },
  methods: {
    headerDescription,
    ...mapActions(['accountList', 'resetSort', 'dashboardAbortMainRequest', 'dashboardGetPositionsReport', 'dashboardGetFormattedSellers', 'dashboardGetProductsSuggestions', 'dashboardGetSuggestions', 'dashboardComputeMinMax', 'authStatus', 'dashboardUpdatePayloadWithPath', 'dashboardShowTotal', 'dashboardGetCountries', 'dashboardResetPositions', "workspaceList"]),
    ...mapMutations(['dashboardSellersSelectedSet', 'dashboardSellersListSet', 'dashboardPeriodSet', 'dashboardAsinSelectedSet', 'dashboardProductSuggestionsListSet', 'dashboardProductSuggestionsSelectedSet', 'dashboardCountriesListSet', 'dashboardCountriesSelectedSet', 'dashboardCurrentTabSet', 'dashboardCurrentViewSet', 'dashboardCampaignsListSet', 'dashboardCampaignsSelectedSet', 'dashboardMatchTypeListSet', 'dashboardMatchTypeSelectedSet', 'dashboardSortFieldSet', 'dashboardSortDirectionSet', 'dashboardPositionReportSet', 'dashboardPageSet', 'dashboardPositionsTotalCountSet']),
    validateKpisStructure(data) {
      /*
          {
              data: {
                  graph: [],
                  chart?: []
              },
              total?: [],
              totalPositions?: 0
          }
      */

      if (!data.data) {
        console.error("Invalid KPIs structure: ", data);
        return false;
      }
      if (!data.data.graph) {
        console.error("No `graph` Array found ", data);
        return false;
      }
      if (data.data.chart && !Array.isArray(data.data.chart)) {
        console.error("Invalid `chart` Array found ", data);
        return false;
      }
      if (data.total && !Array.isArray(data.total)) {
        console.error("Invalid `total` Array found ", data);
        return false;
      }
      if (data.totalPositions && typeof data.totalPositions !== 'number') {
        console.error("Invalid `totalPositions` number found ", data);
        return false;
      }
      return true;
    },
    getTabFilters({
      omnyAccountHasMoreThanOneSeller,
      refreshTrigger
    }) {
      const defaultFilters = [{
        title: 'Sellers',
        titleName: 'Sellers',
        showSearch: true,
        showIcon: false,
        searchPlaceholder: 'Search Seller',
        values: this.sellersList,
        selected: this.currentSellers,
        handleSelect: this.handleSellerSelect,
        handleDeselect: this.handleSellerDeselect,
        handleSearch: this.handleSellerSearch,
        handleClear: this.clearSellerFilter,
        refreshTrigger
      }, {
        title: 'Country',
        titleName: 'Country',
        values: this.countriesList,
        showSearch: false,
        showIcon: true,
        searchPlaceholder: 'Search Country',
        selected: this.currentCountries,
        handleSelect: this.handleCountrySelect,
        handleDeselect: this.handleCountryDeselect,
        handleSearch: () => {},
        handleClear: this.clearCountryFilter,
        refreshTrigger
      }];
      const productDefaultFilters = [...defaultFilters, {
        title: 'Product',
        titleName: 'Product &bull; Asin &bull; Sku',
        values: this.productsList,
        showSearch: true,
        showIcon: false,
        searchPlaceholder: 'Search Product, Asin, Sku',
        selected: this.currentProducts,
        handleSelect: this.handleProductSelect,
        handleDeselect: this.handleProductDeselect,
        handleSearch: this.handleProductSearch,
        handleClear: this.clearProductFilter,
        refreshTrigger
      }];
      const advertisingFilters = [...defaultFilters, {
        title: 'Campaigns',
        titleName: 'Campaigns',
        values: this.campaignsList,
        showSearch: true,
        showIcon: false,
        searchPlaceholder: 'Search Campaigns',
        selected: this.currentCampaigns,
        handleSelect: this.handleCampaignsSelect,
        handleDeselect: this.handleCampaignsDeselect,
        handleSearch: this.handleCampaignsSearch,
        handleClear: this.clearCampaignsFilter,
        refreshTrigger
      }, {
        title: 'Match Type',
        titleName: 'Match Type',
        values: this.matchTypeList,
        showSearch: false,
        showIcon: false,
        searchPlaceholder: 'Search Match Type',
        selected: this.currentMatchType,
        handleSelect: this.handleMatchTypeSelect,
        handleDeselect: this.handleMatchTypeDeselect,
        handleClear: this.clearMatchTypeFilter,
        refreshTrigger
      }];
      const tabFilters = {
        Global: [...productDefaultFilters],
        Country: [...productDefaultFilters],
        Product: [...productDefaultFilters],
        Advertising: [...advertisingFilters]
      };
      const adminTabFilters = {
        ...tabFilters,
        Sellers: [...productDefaultFilters]
      };
      if (omnyAccountHasMoreThanOneSeller) {
        return adminTabFilters[this.currentTab];
      }
      return tabFilters[this.currentTab];
    },
    pages({
      omnyAccountHasMoreThanOneSeller
    }) {
      const defaultPages = [{
        name: "Global",
        active: false
      }, {
        name: "Country",
        active: false
      }, {
        name: "Product",
        active: false
      }, {
        name: "Advertising",
        active: false
      }];
      const adminPages = [...defaultPages, {
        name: "Sellers",
        active: false
      }];
      if (omnyAccountHasMoreThanOneSeller) return adminPages;
      return defaultPages;
    },
    async fetchKpis({
      path,
      period,
      sortBy,
      sortDirection,
      wrapWithCountries,
      page,
      pageSize,
      filters = {
        seller_id: []
      }
    }) {
      const empty = {
        graph: [],
        chart: [],
        total: [],
        totalCount: 0,
        error: {
          message: "Invalid KPIs structure"
        }
      };
      if (!period) return empty;
      let payload = {
        ...filters,
        period: period,
        sort_by: sortBy,
        sort_direction: sortDirection,
        page,
        page_size: pageSize
      };
      console.log("Payload: ", payload);
      const data = await request(`/kpis${path}`, 'POST', payload, false, true, 'cors');
      console.log("Fetch KPIs: ", data);
      if (data && this.validateKpisStructure(data)) {
        return {
          graph: wrapWithCountries ? await transformWithCountries(data.data.graph) : data.data.graph,
          chart: data.data.chart,
          total: data.total,
          totalCount: data.total_count
        };
      } else {
        return empty;
      }
    },
    filterOut,
    truncateString,
    getAdvertisingAccountsFromList,
    formatSellersFromAdvertisingAccounts,
    getGraphId,
    computeTableWidths,
    getFilterMapping(filterType) {
      return this.filterMapping[filterType];
    },
    async initializeSellersFilters({
      sellers
    }) {
      this.$store.dispatch('dashboardSetSellers', {
        list: addGhostIds(sellers),
        selected: []
      });
      // Return `id`s array
      return sellers.map(seller => seller.id);
    },
    async initializeCountriesFilters({
      sellers
    }) {
      // Initialize Countries filter
      const countries = await this.getCountriesBasedOnSellers({
        sellers
      });
      this.$store.dispatch('dashboardSetCountries', {
        list: countries,
        selected: []
      });
    },
    async initializeProductsFilters({
      sellers
    }) {
      // Get the sales channel from the store
      const products = await this.getProductsSuggestionsBasedOnSellers({
        sellers
      });
      this.$store.dispatch('dashboardSetProducts', {
        list: products,
        selected: []
      });
    },
    async initializeCampaignsFilters({
      sellers
    }) {
      const campaigns = await this.getCampaignsBasedOnSellers({
        sellers
      });
      this.$store.dispatch('dashboardSetCampaigns', {
        list: campaigns,
        selected: []
      });
    },
    async initializeMatchTypesFilters({
      sellers
    }) {
      const matchTypes = await this.getMatchTypesBasedOnSellers({
        sellers
      });
      this.$store.dispatch('dashboardSetMatchTypes', {
        list: matchTypes,
        selected: []
      });
    },
    async initializeFilters({
      sellers
    }) {
      const seller_ids = await this.initializeSellersFilters({
        sellers
      });
      await Promise.all([this.initializeCountriesFilters({
        sellers: seller_ids
      }), this.initializeProductsFilters({
        sellers: seller_ids
      }), this.initializeCampaignsFilters({
        sellers: seller_ids
      }), this.initializeMatchTypesFilters({
        sellers: seller_ids
      })]);
    },
    getSellersBasedOnKeyword({
      keyword = []
    }) {
      const sellers = this.defaultSellers;
      if (!Array.isArray(keyword) || keyword.length === 0) {
        return sellers;
      }
      const lowercasedKeywords = keyword.map(k => k.toLowerCase());
      return sellers.filter(item => {
        const lowercasedName = item.name.toLowerCase();
        const lowercasedId = item.id.toLowerCase();
        return lowercasedKeywords.some(aValue => lowercasedName.includes(aValue) || lowercasedId.includes(aValue));
      });
    },
    async getOmnyAccountSellers() {
      await this.accountList({});
      await this.workspaceList({});
      const sellerCentralAccounts = this.accountByProviderGet('Amazon Seller Central');
      const vendorCentralAccounts = this.accountByProviderGet('Amazon Vendor Central');
      return [...sellerCentralAccounts, ...vendorCentralAccounts].map(account => {
        return {
          id: account.account_id,
          region: account.region,
          name: account.account_name || this.workspaceNameById(account.workspace_id)
        };
      });
    },
    handleFilterSelect(filterName, refreshList, {
      item,
      only
    }) {
      // A few assumptions:
      // When an array is received, we assume it's `[All] List -> [All] Selected`
      const filter = this.filterMapping[filterName];
      const listSet = filter.setList;
      const selectedSet = filter.setSelected;
      const definedList = only ? (() => {
        this.filterMapping[filterName];
        listSet([...this.filterMapping[filterName].list, ...this.filterMapping[filterName].selected]);
        selectedSet([]);
        return [...this.filterMapping[filterName].list];
      })() : this.filterMapping[filterName].list;
      if (Array.isArray(item)) {
        selectedSet([...this.filterMapping[filterName].selected, ...this.filterMapping[filterName].list]);

        // Add item to `dashboardSellersListGet`
        listSet([]);
        if (refreshList) this.filterMapping[filterName].refreshList();
        return;
      }
      const definedListFiltered = definedList.filter(el => el.id !== item.id);
      listSet(definedListFiltered);
      selectedSet([...this.filterMapping[filterName].selected, item]);
      if (refreshList) this.filterMapping[filterName].refreshList();
    },
    handleFilterDeselect(filterName, refreshList, item) {
      const filter = this.filterMapping[filterName];
      const listSet = filter.setList;
      const selectedSet = filter.setSelected;
      let removedItems = [];

      // Remove value from `dashboardSellersSelectedGet`
      if (item) {
        if (!Array.isArray(item)) {
          item = [item];
        }
        removedItems = this.filterMapping[filterName].selected;
        item.forEach(innerItem => {
          const filteredSelected = this.filterMapping[filterName].selected.filter(el => el.id !== innerItem.id);
          selectedSet(filteredSelected);

          // Add item to `dashboardSellersListGet`
          listSet([...this.filterMapping[filterName].list, innerItem]);
        });
      } else {
        removedItems = this.filterMapping[filterName].selected;
        listSet([...this.filterMapping[filterName].list, ...this.filterMapping[filterName].selected]);
        selectedSet([]);
      }

      // Removes refresh to control the refreshing in the `FilterItem` using `handleRefresh`.
      if (refreshList) this.filterMapping[filterName].refreshList();
      return removedItems;
    },
    handleFilterListSearch(filterName, value) {
      /// DEPRECATED
      /// Use `handleFilterAsyncSearch` instead to use a single search algorithm.

      this.searchLoading = true;
      const filter = this.filterMapping[filterName];
      const listSet = filter.setList;
      if (!this.validateSearch({
        value,
        refresh: filter.refreshList
      })) {
        return;
      }

      // Filter the list of sellers in `dashboardSellersListGet` by the value
      listSet(this.filterMapping[filterName].list.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));

      // Set the filtered list of sellers in `dashboardSellersListGet`
      this.searchLoading = false;
    },
    async handleFilterAsyncSearch(filterName, value, searchFunction, {
      sellers,
      list_key = 'id',
      selected_key = 'id'
    }) {
      this.searchLoading = true;
      const filter = this.filterMapping[filterName];
      const listSet = filter.setList;
      console.log("Searching in ", searchFunction, " with ", {
        value,
        sellers
      });
      let res = await searchFunction({
        keyword: value,
        sellers
      });

      // Remove the selected items from the list
      res = res.filter(item => {
        return !this.filterMapping[filterName].selected.map(selected => selected[selected_key]).includes(item[list_key]);
      });
      listSet(res);

      // Set the filtered list of sellers in `dashboardSellersListGet`
      this.searchLoading = false;
    },
    handleSellerSelect({
      item,
      only
    }) {
      this.handleFilterSelect('Sellers', true, {
        item,
        only
      });
    },
    handleSellerDeselect(value) {
      this.handleFilterDeselect('Sellers', true, value);
    },
    async handleSellerSearch({
      value
    }) {
      this.handleFilterAsyncSearch('Sellers', value, this.getSellersBasedOnKeyword, {});
    },
    handleCountrySelect({
      item,
      only
    }) {
      this.handleFilterSelect('Country', true, {
        item,
        only
      });
    },
    handleCountryDeselect(value) {
      this.handleFilterDeselect('Country', true, value);
    },
    handleProductSelect({
      item,
      only
    }) {
      this.handleFilterSelect('Products', false, {
        item,
        only
      });
    },
    handleProductDeselect(value) {
      this.handleFilterDeselect('Products', false, value);
    },
    async handleProductSearch({
      value
    }) {
      await this.handleFilterAsyncSearch('Products', value, this.getProductsSuggestionsBasedOnSellers, {
        sellers: this.searchSellers,
        list_key: 'id',
        selected_key: 'id'
      });
    },
    handleCampaignsSelect({
      item,
      only
    }) {
      this.handleFilterSelect('Campagins', false, {
        item,
        only
      });
    },
    handleCampaignsDeselect(value) {
      this.handleFilterDeselect('Campagins', false, value);
    },
    async handleCampaignsSearch({
      value
    }) {
      this.handleFilterAsyncSearch('Campagins', value, this.getCampaignsBasedOnSellers, {
        sellers: this.searchSellers
      });
    },
    handleMatchTypeSelect({
      item,
      only
    }) {
      this.handleFilterSelect('Match Type', false, {
        item,
        only
      });
    },
    handleMatchTypeDeselect(value) {
      this.handleFilterDeselect('Match Type', false, value);
    },
    clearSellerFilter() {
      this.handleSellerDeselect(null);
    },
    clearProductFilter() {
      this.handleProductDeselect(null);
    },
    clearCountryFilter() {
      this.handleCountryDeselect(null);
    },
    clearCampaignsFilter() {
      this.handleCampaignsDeselect(null);
    },
    clearMatchTypeFilter() {
      this.handleMatchTypeDeselect(null);
    },
    refreshSelectedProducts(sellers) {
      sellers = sellers.map(seller => seller.id);
      // Refresh the currently selected products and remove the products that belong to the deselected seller
      const removeSellerFromSelected = (sellers, selected) => {
        return selected.filter(item => {
          return !sellers.includes(item.seller_id);
        });
      };
      this.dashboardProductSuggestionsSelectedSet(removeSellerFromSelected(sellers, this.currentProducts));
    },
    async searchDashboardForProducts({
      keyword
    }) {
      const {
        products
      } = await this.dashboardGetSuggestions({
        keyword
      });
      return products;
    },
    async searchDashboardForCampaigns({
      keyword
    }) {
      const {
        campaigns
      } = await this.dashboardGetSuggestions({
        keyword
      });
      return campaigns;
    },
    async fetchBasedOnSellers({
      sellers,
      sales_channel,
      path,
      keyword_list
    }) {
      if (!sellers) return [];
      if (!sellers.length) return [];
      if (!path) return [];
      let payload = {
        seller_id: sellers,
        sales_channel
      };
      if (keyword_list) {
        payload.keyword_list = keyword_list;
      }
      const res = await request(path, 'POST', payload, false, true, 'cors');
      if (res && res.data) {
        return res.data;
      }
    },
    async getCountriesBasedOnSellers({
      sellers
    }) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path: '/kpis/countries'
      });
      if (data && data.countries) {
        let countries = data.countries;
        let localCountriesMapping = await getCountriesMapping();
        countries = countries.map(({
          sales_channel
        }) => sales_channel);
        // Find the country in the local mapping and add the flag
        const res = localCountriesMapping.filter(({
          name
        }) => countries.includes(name));
        const resWithIds = addGhostIds(res);
        return resWithIds;
      }
      return [];
    },
    async getProductsSuggestionsBasedOnSellers({
      sellers,
      keyword
    }) {
      const sales_channel = this.$store.getters['dashboardGetSalesChannels']();
      const data = await this.fetchBasedOnSellers({
        sellers,
        path: '/kpis/products/suggestions',
        keyword_list: keyword,
        sales_channel
      });
      if (data && data.products) {
        let products = data.products;
        return addGhostIds(products.map(product => {
          return {
            ...product,
            id: `${product.product_name}-${product.sku}-${product.asin}`
          };
        }));
      }
      return [];
    },
    async getCampaignsBasedOnSellers({
      sellers,
      keyword
    }) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path: '/kpis/advertising/campaigns/suggestions',
        keyword_list: keyword
      });
      if (data && data.campaigns) {
        let campaigns = data.campaigns;
        return addGhostIds(campaigns.map(campaign => {
          return {
            ...campaign,
            id: campaign.name
          };
        }));
      }
      return [];
    },
    async getMatchTypesBasedOnSellers({
      sellers
    }) {
      const data = await this.fetchBasedOnSellers({
        sellers,
        path: '/kpis/advertising/match_type/suggestions'
      });
      if (data && data.match_types) {
        let match_types = data.match_types;
        return addGhostIds(match_types.map(match_type => {
          return {
            id: match_type.match_type,
            name: match_type.match_type
          };
        }));
      }
    },
    setGraph({
      tabView,
      graph
    }) {
      this.$store.dispatch("dashboardSetPositions", {
        graph,
        tabView
      });
    },
    setChart({
      tabView,
      chart
    }) {
      this.$store.dispatch("dashboardSetPositions", {
        chart,
        tabView
      });
    }
  }
};
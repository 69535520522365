import AppDropdown from "./AppDropdown.vue";
import AppIcon from "@/components/AppIcon";
import ProductScore from "@/components/ProductScore";
import AppCircleProgress from "@/components/AppCircleProgress";
import ContentMixin from "@/mixins/ContentMixin.vue";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import { mapActions } from "vuex";
import { country_codes } from "@/utils/countries";
import { isObjectEmpty } from "@/utils";
import FlagValue from "./tableElements/FlagValue.vue";
import FlexRow from "./FlexRow.vue";
export default {
  components: {
    AppIcon,
    ProductScore,
    AppCircleProgress,
    AppDropdown,
    FlagValue,
    FlexRow
  },
  emits: ["selected-item"],
  mixins: [ContentMixin, ValidateMixin],
  props: {
    product: {
      type: Object,
      default: {}
    }
  },
  computed: {
    countryFlag() {
      return `https://flagsapi.com/${country_codes[this.selected] === "UK" ? "GB" : country_codes[this.selected]}/flat/16.png`;
    }
  },
  data() {
    return {
      isOpen: false,
      selected: null,
      calculating_score: true,
      listing_not_found: true,
      item: {},
      error_value: this.error,
      options: [{
        text: "Brazil",
        value: "Brazil"
      }, {
        text: "US",
        value: "US"
      }, {
        text: "Mexico",
        value: "Mexico"
      }, {
        text: "Canada",
        value: "Canada"
      }, {
        text: "Spain",
        value: "Spain"
      }, {
        text: "Belgium",
        value: "Belgium"
      }, {
        text: "UK",
        value: "UK"
      }, {
        text: "France",
        value: "France"
      }, {
        text: "Netherlands",
        value: "Netherlands"
      }, {
        text: "Germany",
        value: "Germany"
      }, {
        text: "Italy",
        value: "Italy"
      }, {
        text: "Sweden",
        value: "Sweden"
      }, {
        text: "Egypt",
        value: "Egypt"
      }, {
        text: "Poland",
        value: "Poland"
      }, {
        text: "Turkey",
        value: "Turkey"
      }, {
        text: "Saudi Arabia",
        value: "Saudi Arabia"
      }, {
        text: "U.A.E",
        value: "U.A.E"
      }, {
        text: "India",
        value: "India"
      }, {
        text: "Singapore",
        value: "Singapore"
      }, {
        text: "Australia",
        value: "Australia"
      }, {
        text: "Japan",
        value: "Japan"
      }, {
        text: "Ireland",
        value: "Ireland"
      }]
    };
  },
  watch: {
    error() {
      this.error_value = this.error;
    }
  },
  methods: {
    ...mapActions(["contentGetFromRainforest"]),
    async selectOption(option) {
      this.item = {};
      this.error_value = false;
      this.calculating_score = true;
      this.listing_not_found = true;
      const payload = {
        country: option,
        asin: this.product.asin
      };
      const result = await this.contentGetFromRainforest(payload);
      if (!isObjectEmpty(result.product)) {
        this.listing_not_found = false;
        this.item = result.product;
        const rulesAmzScore = this.statusValidate(this.item, "amazon");
        this.item["success_rules"] = this.successRules(rulesAmzScore);
        this.item["all_rules"] = this.allRules(rulesAmzScore);
      }
      this.calculating_score = false;
      this.$emit("selected-item", this.item);
    },
    successRules(array) {
      var success_rules = 0;
      array.forEach(rule => {
        success_rules += rule.score * rule.coefficient;
      });
      return success_rules;
    },
    allRules(array) {
      var all_rules = 0;
      array.forEach(rule => {
        all_rules += 10 * rule.coefficient;
      });
      return all_rules;
    }
  }
};
import HelperMixin from '@/mixins/HelperMixin';
import AppInput from '@/components/AppInput';
import AppSwitch from '@/components/AppSwitch';
import AppPopover from '@/components/AppPopover';
import PpcBudgetAllocationCircle from '@/components/PpcBudgetAllocationCircle';
export default {
  name: 'PpcBudgetAllocationHeader',
  emits: ['update:mode', 'update:search'],
  mixins: [HelperMixin],
  components: {
    AppInput,
    AppSwitch,
    AppPopover,
    PpcBudgetAllocationCircle
  },
  props: {
    mode: {
      type: String,
      default: 'acos'
    },
    search: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modes: [{
        value: 'roas',
        label: 'ROAS'
      }, {
        value: 'acos',
        label: 'ACOS'
      }]
    };
  },
  computed: {
    localMode: {
      get() {
        return this.mode;
      },
      set(value) {
        this.$emit('update:mode', value);
      }
    },
    localSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit('update:search', value);
      }
    },
    availableBudget() {
      // TO-DO
      // Neet to get from API
      return 30000;
    },
    perfomanceTotalBudget() {
      return this.items.reduce((acc, item) => {
        return acc + item.perf_allocated_budget_current * 1;
      }, 0);
    },
    discoveryTotalBudget() {
      return this.items.reduce((acc, item) => {
        return acc + (item.discovery_allocated_budget_current ?? item.discovery_allocated_budget_ai) * 1;
      }, 0);
    },
    totalBudget() {
      return this.perfomanceTotalBudget + this.discoveryTotalBudget;
    },
    percent() {
      return this.availableBudget / this.totalBudget * 100;
    }
  }
};
import { mapState, mapGetters, mapActions } from "vuex";
import ConnectionPageTitle from "@/components/ConnectionPageTitle";
import AppCardConnections from "@/components/AppCardConnections";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import { isObjectEmpty } from "@/utils";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
import CreateAccountSideBar from "@/components/CreateAccountSideBar";
import { country_codes } from "@/utils/countries";
export default {
  name: "AmazonAdvertisingPage",
  emits: ["update-total"],
  components: {
    ConnectionPageTitle,
    AppCardConnections,
    AppButton,
    AppModal,
    AppIcon,
    CustomSideBarAppModal,
    CreateAccountSideBar
  },
  data() {
    return {
      provider: "Amazon Advertising",
      searchPlaceHolder: "Search",
      accountsList: [],
      seller_vendorAccountsList: [{
        text: "Choose Seller/Vendor Account",
        value: ""
      }],
      vendorCountries: {},
      dataLoading: true,
      removeLoading: false,
      confirmRemoveModal: false,
      removeId: null,
      createAccountSideBar: false,
      regions: [{
        text: "Choose a region to connect",
        value: ""
      }, {
        text: "North America",
        value: "North America"
      }, {
        text: "Europe & Middle East",
        value: "Europe & Middle East"
      }, {
        text: "Far East",
        value: "Far East"
      }]
    };
  },
  props: {
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isOmnyUser: state => state.auth.isOmnyUser,
      isClientAdmin: state => state.auth.isClientAdmin,
      isClientUser: state => state.auth.isClientUser,
      isOmnyWorkspace: state => state.workspace.isOmnyWorkspace
    }),
    ...mapGetters(["accountAdvertisingGet", "accountByProviderGet", "workspaceNameById"]),
    canAddAccount() {
      return (this.isOmnyAdmin || this.isClientAdmin || this.isOmnyUser || this.isClientUser) && !this.isOmnyWorkspace && this.seller_vendorAccountsList.length > 1;
    },
    // isLoggedIn() {
    //   return !! this.token
    // },

    accounts() {
      let advertisingAccounts = this.accountAdvertisingGet();
      this.accountsList = [];
      const makeProfile = value => {
        const countryCode = value.countryCode.toUpperCase();
        return {
          country: countryCode,
          flag: `https://flagsapi.com/${countryCode === "UK" ? "GB" : countryCode}/flat/16.png`,
          profileId: value.profileId.toString()
        };
      };
      advertisingAccounts.forEach(account => {
        /*  
            New structure:
                Seller Account:
                    - Name: string
                    - Id: string
                    - Profile: {
                        - country: string
                        - flag: string (from FlagsAPI)
                        - profileId: string
                    }
                    - Credential: string
        */
        this.accountsList.push({
          account_name: account.account_name || account[0].accountInfo.name,
          account_id: account[0].accountInfo.id,
          workspace_id: account[0].workspace_id,
          profiles: account.length > 1 ? account.map(value => makeProfile(value)) : [makeProfile(account[0])],
          credentials: account[0].credentials
        });
      });
      this.updateTotalData();
      const startIndex = (this.page - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.accountsList.slice(startIndex, endIndex);
    },
    hasCountries() {
      return !isObjectEmpty(this.vendorCountries);
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.refreshAccountList();
      this.dataLoading = false;
      window.$bus.on("refreshAccountList", this.refreshAccountList);
      this.seller_vendor_accounts();
      console.log("[advertising page mounted]");
    }
  },
  async created() {
    //this.provider = this.account_provider_mapping[this.$route.matched[1].path];
    let currentBreadCrumbs = this.$store.getters.getCurrentBreadCrumbs();
    currentBreadCrumbs[currentBreadCrumbs.length - 1].text = this.provider;
    this.$store.dispatch("setBreadcrumbs", currentBreadCrumbs);
  },
  methods: {
    ...mapActions(["accountList", "accountRemove", "authStatus", "getAdvertisingAccounts"]),
    onRemoveConfirm(profiles) {
      this.removeIds = profiles.map(profile => profile.profileId);
      this.confirmRemoveModal = true;
    },
    workspaceName(workspace_id) {
      let found_workspace = this.workspaceNameById(workspace_id);
      return found_workspace ? found_workspace : "";
    },
    async onRemove() {
      this.confirmRemoveModal = false;
      this.removeLoading = true;
      await this.accountRemove({
        account_ids: [...this.removeIds]
      });
      await this.refreshAccountList();
      this.removeLoading = false;
      this.removeId = null;
    },
    async refreshAccountList() {
      let data = {};
      await this.accountList(data);
    },
    updateTotalData() {
      this.$emit('update-total', {
        totalPages: this.accountsList.length
      });
    },
    seller_vendor_accounts() {
      let sellerAccounts = this.accountByProviderGet("Amazon Seller Central");
      let vendorAccounts = this.accountByProviderGet("Amazon Vendor Central");
      sellerAccounts.forEach(account => {
        this.seller_vendorAccountsList.push({
          text: account.account_name || account.account_id + " - Seller - " + account.country,
          value: account.selling_partner_id
        });
      });
      vendorAccounts.forEach(account => {
        this.seller_vendorAccountsList.push({
          text: account.account_name || account.account_id + "- Vendor - " + account.country,
          value: account.selling_partner_id
        });
        if (!this.vendorCountries[account.selling_partner_id]) {
          this.vendorCountries[account.selling_partner_id] = [];
        }
        this.vendorCountries[account.selling_partner_id].push({
          text: account.country,
          value: country_codes[account.country]
        });
      });
    }
  }
};
import { mapMutations, mapState, mapGetters } from "vuex";
import FlexRow from "@/components/FlexRow.vue";
import { SellerFilter, MarketplaceFilter, ProductFilter, FilterDivider, FilterItemSelector, PortfolioFilter, AdFormatFilter, CampaignFilter, MatchTypeFilter, TargetingFilter } from "@/components/new-filters";
import { EVENTS, PPC_EXTRA_FILTERS, HANDLE_ADD_FILTER_MODES } from "@/utils/constants";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppIcon from "@/components/AppIcon.vue";
import LayoutIcon from "@/components/icons/LayoutIcon.vue";
import TrendingUpIcon from "@/components/icons/TrendingUpIcon.vue";
import CertificateIcon from "@/components/icons/CertificateIcon.vue";
import EqualsIcon from "@/components/icons/EqualsIcon.vue";
import TargetIcon from "@/components/icons/TargetIcon.vue";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
import PPCManagementMixin from "@/mixins/PPCManagementMixin.vue";
export default {
  name: "PpcV2Filters",
  components: {
    FlexRow,
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    FilterDivider,
    FilterItemSelector,
    WrapWithPopover,
    AppDropdown,
    AppIcon,
    CampaignFilter,
    MatchTypeFilter,
    TargetingFilter,
    LayoutIcon,
    CertificateIcon,
    TrendingUpIcon,
    EqualsIcon,
    TargetIcon,
    PortfolioFilter,
    AdFormatFilter
  },
  mixins: [InitializeFiltersMixin, PPCManagementMixin],
  props: {
    secondaryFilterInitializers: {
      type: Object
    }
  },
  mounted() {
    window.$bus.on(EVENTS.ADD_PPC_FILTER, this.handleAddFilter);

    // NOTE: For now, we have disabled this section which was originally for removing the `SELLER, MARKETPLACE, PRODUCT` filters
    // when the users chose any secondary filters (Campaigns, Portfolio, Targeting, Match Type, Ad Format)

    // Update: 10th January 2025:
    // We are bring back the `hiding` and `showing` of the `Group By` for Product Table
    // We remove [Seller, Marketplace, Product] when any secondary filters are enabled.
    // And bring them back when all the secondary filters are cleared.

    // Update: 7th March 2025:
    // We are changing the strategy a little bit due to some technical challenges with Vue making the components re-render (which in-turn triggers)
    // the `update:modelValue` event. This event is connected to the `refresh` event of the `ProductTable` so any time the `Group By` elements
    // re-render, the page refresh will be triggered.

    // The new strategy is to store in a different list `disabledGroupBy`. This will track elements and then disable the tabs when necessary

    this.$store.watch((_, getters) => getters.ppcV2SecondaryFiltersEnabled(), (newValue, oldValue) => {
      console.log("Watching secondary filters", newValue, oldValue);
      if (!oldValue && newValue) {
        // When it changes from false to true
        this.$nextTick(() => {
          this.enableProductFilter = false;
          this.$store.dispatch("ppcV2SetSecondaryFiltersProductTableGroupByElements");
        });
      }
      if (!newValue && oldValue) {
        // When it changes from true to false
        this.$nextTick(() => {
          this.enableProductFilter = true;
          this.$store.dispatch("ppcV2CleanDisabledGroupBy");
        });
      }
    }, {
      immediate: true
    });
  },
  data() {
    return {
      enableProductFilter: true,
      moreFilterOptions: [{
        name: PPC_EXTRA_FILTERS.CAMPAIGN,
        icon: "TrendingUpIcon"
      }, {
        name: PPC_EXTRA_FILTERS.MATCH_TYPE,
        icon: "EqualsIcon"
      }, {
        name: PPC_EXTRA_FILTERS.TARGETING,
        icon: "TargetIcon"
      }, {
        name: PPC_EXTRA_FILTERS.AD_FORMAT,
        icon: "LayoutIcon"
      }, {
        name: PPC_EXTRA_FILTERS.PORTFOLIO,
        icon: "CertificateIcon"
      }],
      renderedFilterNames: new Set()
    };
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.ppc_v2.sellers_filter,
      marketplaces_filter: state => state.ppc_v2.marketplaces_filter,
      products: state => state.ppc_v2.products,
      match_types: state => state.ppc_v2.match_types,
      campaigns: state => state.ppc_v2.campaigns,
      targetings: state => state.ppc_v2.targetings,
      portfolios: state => state.ppc_v2.portfolios,
      ad_formats: state => state.ppc_v2.ad_formats
    }),
    ...mapGetters(["ppcV2SecondaryFiltersEnabled", "ppcV2UserOnSettingsPage"]),
    filterDropdownOptions() {
      return this.moreFilterOptions.filter(({
        name
      }) => !this.renderedFilterNames.has(name));
    },
    onlyMode() {
      return this.ppcV2UserOnSettingsPage() ?? false;
    },
    processedFilters() {
      return Array.from(this.renderedFilterNames).map(filter => {
        const componentConfig = this.getFilterComponent(filter);
        return {
          name: componentConfig.name,
          props: componentConfig.props,
          methods: componentConfig.methods
        };
      });
    },
    extraFilters() {
      return {
        [PPC_EXTRA_FILTERS.CAMPAIGN]: {
          name: "CampaignFilter",
          props: {
            campaigns: this.campaigns,
            sellers: this.sellers_filter,
            marketplaces: this.marketplaces_filter
          },
          methods: {
            apply: this.applyCampaigns
          }
        },
        [PPC_EXTRA_FILTERS.MATCH_TYPE]: {
          name: "MatchTypeFilter",
          props: {
            match_types: this.match_types
          },
          methods: {
            apply: this.applyMatchTypes
          }
        },
        [PPC_EXTRA_FILTERS.TARGETING]: {
          name: "TargetingFilter",
          props: {
            targetings: this.targetings,
            sellers: this.sellers_filter,
            marketplaces: this.marketplaces_filter
          },
          methods: {
            apply: this.applyTargetings
          }
        },
        [PPC_EXTRA_FILTERS.AD_FORMAT]: {
          name: "AdFormatFilter",
          props: {
            ad_formats: this.ad_formats
          },
          methods: {
            apply: this.applyAdFormats
          }
        },
        [PPC_EXTRA_FILTERS.PORTFOLIO]: {
          name: "PortfolioFilter",
          props: {
            portfolios: this.portfolios,
            sellers: this.sellers_filter,
            marketplaces: this.marketplaces_filter
          },
          methods: {
            apply: this.applyPortfolios
          }
        }
      };
    },
    getProductFilterState() {
      if (this.ppcV2UserOnSettingsPage()) return false;
      return this.enableProductFilter;
    }
  },
  methods: {
    ...mapMutations(["ppcMarketplacesFilterSet"]),
    async applySellersForCurrentTab() {
      let applySellersFunction = this.ppcV2UserOnSettingsPage() ? this.applySellersForSettingsTab : this.applySellers;
      applySellersFunction();
    },
    async applyMarketPlacesForCurrentTab() {
      let applyMarketPlacesFunction = this.ppcV2UserOnSettingsPage() ? this.applyMarketPlacesForSettingsTab : this.applyMarketplaces;
      applyMarketPlacesFunction();
    },
    async applySellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Fetching sub filters for sellers...  ",
          visible: true,
          delay: 50
        });
        /*
          Refresh the list of filters [active]:
            Again, to avoid a lot of 423 (Too Many Reqs) Errors from the server
            We will run the requests in series.
            * Marketplace Filter
            * Product Filter
            * Any other secondary filters currently displayed.
        */
        // [DEPRECATED] Api will soon be changed to use `Filter` instance instead of the `name`
        // await this.initializeMarketplacesFilters(
        //   { sellers: this.sellers_filter.filterValues },
        //   "ppc_v2",
        //   "marketplaces",
        //   "ppc/v2"
        // );
        await this.initializeMarketplacesFilter(this.ppcMarketplacesFilterSet, true);
        await this.$nextTick();
        // We await the next tick to make sure the marketplace was updated.
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);

        // For each renderedFilterNames: Set, we look for their initialize and reset them
        await this.initializeProductsFilters({
          sellers: this.sellers_filter.filterValues,
          sales_channel: this.marketplaces_filter.filterValues
        }, "ppc_v2", "products", "ppc/v2");
        this.renderedFilterNames.forEach(async name => {
          await this.secondaryFilterInitializers[name]();
        });
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces_filter.isModified) {
        this.marketplaces_filter.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Fetching sub filters for marketplace...",
          visible: true,
          delay: 50
        });
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
        this.renderedFilterNames.forEach(async name => {
          await this.secondaryFilterInitializers[name]();
        });
      }
    },
    async applySellersForSettingsTab() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_MARKETPLACES_FILTER, false);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    },
    async applyMarketPlacesForSettingsTab() {
      if (this.marketplaces_filter.isModified) {
        this.marketplaces_filter.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    },
    handleErrorOccured() {
      //this.throwIfError({status:'error'});
    },
    applyMatchTypes() {
      if (this.match_types.isModified) {
        this.match_types.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCampaigns() {
      if (this.campaigns.isModified) {
        this.campaigns.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyTargetings() {
      if (this.targetings.isModified) {
        this.targetings.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyPortfolios() {
      if (this.portfolios.isModified) {
        this.portfolios.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyAdFormats() {
      if (this.ad_formats.isModified) {
        this.ad_formats.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyProducts() {
      if (this.products.isModified) {
        this.products.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCurrencies() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    handleSellerCleared() {
      window.$bus.trigger(EVENTS.ADD_SELLER_GROUPBY);
    },
    handleMarketplaceCleared() {
      window.$bus.trigger(EVENTS.ADD_MARKETPLACE_GROUPBY);
    },
    handleAddFilter({
      value,
      mode = HANDLE_ADD_FILTER_MODES.POPUP
    }) {
      this.renderedFilterNames.add(value);
      this.$nextTick(() => {
        const filtersWrapper = this.$refs.filtersWrapper?.$el;
        if (filtersWrapper) {
          filtersWrapper.scroll({
            behavior: "smooth",
            left: filtersWrapper.scrollWidth
          });
        }
        if (mode === HANDLE_ADD_FILTER_MODES.POPUP) {
          const filterComponent = this.getFilterComponent(value)?.name;
          let el = this.$refs[filterComponent];
          if (Array.isArray(el) && el.length > 0) {
            el = el[0];
          }
          if (el?.openDropdown) {
            el.openDropdown(this.secondaryFilterInitializers[value]);
          }
        }
      });
    },
    getFilterComponent(key) {
      return this.extraFilters[key];
    }
  }
};
import { TextValue } from "../../tableElements";
import { WrapWithSectionItem } from "../sections";
export default {
  name: "CurrencyFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    currency: {
      type: String
    },
    symbol: {
      type: String
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WrapWithSectionItem,
    TextValue
  }
};
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "text-content"
};
const _hoisted_2 = {
  class: "ellipsis ellipsis-3"
};
const _hoisted_3 = {
  class: "status-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WrapWithCopy = _resolveComponent("WrapWithCopy");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_WrapWithStatusIcon = _resolveComponent("WrapWithStatusIcon");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "bullet-point-background-color-cell"
  }, {
    default: _withCtx(() => [_createVNode(_component_WrapWithStatusIcon, {
      validationStatus: $props.bullet_point.ui_style.validationStatus,
      status: $props.bullet_point.ui_style.status,
      isHovering: $data.isHovering,
      isCopying: $props.isCopying,
      id: $props.bullet_point.id
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        ref: $props.bullet_point.id,
        class: "content-box bullet-point is-hovering",
        onClick: _cache[0] || (_cache[0] = $event => !this.isCopying ? _ctx.$emit('onEdit', {
          item: $props.item,
          type: $props.bullet_point.type,
          title: $props.bullet_point.title
        }) : _ctx.$emit('onPaste', {
          item: $props.item,
          type: $props.bullet_point.type,
          title: $props.bullet_point.title
        })),
        onMouseover: _cache[1] || (_cache[1] = $event => $options.onCopyingHover()),
        onMouseleave: _cache[2] || (_cache[2] = $event => $options.onCopyingHoverLeave())
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", {
        style: _normalizeStyle($props.bullet_point.ui_style.style)
      }, [$props.bullet_point.text !== null && typeof $props.bullet_point.text !== 'undefined' && $props.bullet_point.text.trim() != '' ? (_openBlock(), _createBlock(_component_WrapWithCopy, {
        key: 0,
        onCopied: $options.handleCopied
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.bullet_point.text.trim()), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onCopied"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), $props.bullet_point.ui_style.status != 'unknown' ? (_openBlock(), _createBlock(_component_app_popover, {
        key: 0,
        target: $props.bullet_point.id,
        "auto-width": "",
        placement: "top",
        "custom-class": `${$props.bullet_point.ui_style.status} ${!$props.showPopover ? 'hide-popover' : ''}`
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString($props.bullet_point.ui_style.tooltipText), 1 /* TEXT */)])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["target", "custom-class"])) : _createCommentVNode("v-if", true), !$props.item.sku ? (_openBlock(), _createBlock(_component_app_popover, {
        key: 1,
        target: $props.bullet_point.id,
        "auto-width": "",
        placement: "top",
        "custom-class": `not-editable ${!$props.showPopover ? 'hide-popover' : ''}`
      }, {
        default: _withCtx(() => [_createTextVNode(" Assign SKU to the item to start editing the content ")]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["target", "custom-class"])) : _createCommentVNode("v-if", true)])], 544 /* NEED_HYDRATION, NEED_PATCH */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["validationStatus", "status", "isHovering", "isCopying", "id"])]),
    _: 1 /* STABLE */
  });
}
import { mapMutations, mapState, mapGetters } from "vuex";
import AppButtonRadioGroup from "@/components/AppButtonRadioGroup.vue";
import DashboardFilters from "@/components/DashboardFilters.vue";
import DashboardV2Mixin from "@/mixins/DashboardV2Mixin.vue";
import NavigationTabs from "@/components/NavigationTabs.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import DateValue from "@/components/tableElements/DateValue.vue";
import PerformanceCards from "@/components/kpi-cards/dashboard_v2/PerformanceCards.vue";
import { EVENTS, APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
import PerformancePeriodTable from "@/components/dashboard_v2/PerformancePeriodTable.vue";
import ListingPeriodTable from "@/components/dashboard_v2/ListingPeriodTable.vue";
import PerformanceProductTable from "@/components/dashboard_v2/PerformanceProductTable.vue";
import ProfitPeriodTable from "@/components/dashboard_v2/ProfitPeriodTable.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import moment from "moment";
import ListingCards from "@/components/kpi-cards/dashboard_v2/ListingCards.vue";
import AdvertisingCards from "@/components/kpi-cards/dashboard_v2/AdvertisingCards.vue";
import AdvertisingPeriodTable from "@/components/dashboard_v2/AdvertisingPeriodTable.vue";
import { DATE_RANGES_FROM_SELECTOR, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, getDatePickerUIObjectFromDateRange, momentWeekType } from "@/utils/datepicker";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import CurrencyFilter from "@/components/new-filters/CurrencyFilter.vue";
export default {
  name: "DashboardV2Page",
  components: {
    AppButtonRadioGroup,
    DashboardFilters,
    NavigationTabs,
    FlexColumn,
    DateValue,
    PerformanceCards,
    ListingCards,
    AdvertisingCards,
    PerformancePeriodTable,
    ListingPeriodTable,
    PerformanceProductTable,
    AdvertisingPeriodTable,
    ProfitPeriodTable,
    AppDropdown,
    CurrencyFilter
  },
  mixins: [DashboardV2Mixin, LiteTableMixin],
  beforeUnmount() {
    window.$bus.off(EVENTS.REFRESH_DASHBOARD_V2, () => this.refreshAllKpis());
  },
  async mounted() {
    this.$store.dispatch("dashboardV2SetDefaultCurrency");
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2, () => this.refreshAllKpis());
    const dashboardRenderingStart = performance.now();
    await this.refreshDashboardSellersSubFilters();
    this.$store.dispatch("dashboardV2SetProductTableGroupByElements");
    console.log("this.store", this.$store.state.dashboard_v2.product_table.group_by_elements);
    // Set the default date picker date
    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
      rangeTitle: this.periodGroupBy,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });
    this.storeDatePickerDate(this.defaultDatePickerDate);
    this.refreshAllKpis();
    const dashboardRenderingEnd = performance.now();
    console.log(`[Perf][Dashboard Rendering]: ${(dashboardRenderingEnd - dashboardRenderingStart) / 1000} s`);
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS, this.refreshDashboardSellersSubFilters);
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS, this.refreshDashboardMarketPlacesSubFilters);
  },
  data() {
    return {
      today: moment(),
      sellers: [],
      marketplaces: [],
      kpisLoading: true,
      periodTableLoading: true,
      productTableLoading: true,
      defaultDatePickerDate: null,
      mediatorRefreshers: {
        KPIS: this.refreshKpis,
        PERIOD_TABLE: this.refreshPeriodTable,
        PRODUCT_TABLE: this.refreshProductTable
      },
      DATE_RANGES_FROM_SELECTOR
    };
  },
  computed: {
    ...mapState({
      kpis: state => state.dashboard_v2.kpis.data,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodOptions: state => state.dashboard_v2.periodOptions,
      periodSelectorOptions: state => state.dashboard_v2.periodSelectorOptions,
      tabs: state => state.dashboard_v2.tabs,
      productTable: state => state.dashboard_v2.product_table,
      productTableGroupBy: state => state.dashboard_v2.product_table.group_by_text,
      currentTabValue: state => state.dashboard_v2.currentTabValue,
      floatingTableState: state => state.dashboard_v2.product_table.state,
      currencies: state => state.dashboard_v2.currencies,
      sellers_filter: state => state.dashboard_v2.sellers_filter,
      marketplaces_filter: state => state.dashboard_v2.marketplaces_filter
    }),
    ...mapGetters(["dashboardV2GetPeriod", "dashboardV2GetPeriodCategory", "dashboardV2GetTab", "getSystemWeekType", "isUserInBetaAccessGroup"]),
    currentSellerIds() {
      return this.sellers_filter.filterValues;
    },
    currentMarketplaceIds() {
      return this.marketplaces_filter.filterValues;
    },
    period: {
      set(value) {
        this.$store.dispatch("dashboardV2SetPeriod", value);
        this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
          ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
          rangeTitle: this.periodGroupBy,
          compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
        });
        this.storeDatePickerDate(this.defaultDatePickerDate);
        this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]);
      },
      get() {
        return this.dashboardV2GetPeriod();
      }
    },
    periodCategory: {
      set(value) {
        this.$store.dispatch("dashboardV2SetPeriodCategory", value);
      },
      get() {
        return this.dashboardV2GetPeriodCategory();
      }
    },
    tab: {
      async set(value) {
        this.$store.dispatch("dashboardV2SetTab", {
          tabIndex: value,
          tabValue: this.tabs[value].value
        });
      },
      get() {
        return this.dashboardV2GetTab();
      }
    },
    periodGroupBy() {
      return this.getPeriodGroupFromCurrentPeriod(this.currentPeriod, this.period);
    },
    getCurrentPeriodDate() {
      if (this.period === APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value) {
        return momentWeekType[this.getSystemWeekType]();
      }
      return this.currentPeriod[this.period].date;
    }
  },
  methods: {
    ...mapMutations(["dashboardSellersFilterSet", "dashboardMarketplacesFilterSet"]),
    setAllLoading(value) {
      this.kpisLoading = value;
    },
    async refreshKpis() {
      this.kpisLoading = true;
      await this.$store.dispatch("dashboardV2FetchKpis");
      this.kpisLoading = false;
    },
    handleErrorOccured() {
      this.announceNoAccounts();
      this.periodTableLoading = false;
      this.productTableLoading = false;
      this.kpisLoading = false;
    },
    async refreshPeriodTable() {
      this.periodTableLoading = true;
      await this.$store.dispatch("dashboardV2FetchPeriodTableData");
      this.periodTableLoading = false;
    },
    async refreshMediator(refresheeList) {
      const perf = [];
      perf.push(performance.now());
      console.log("Refreshee List", refresheeList);
      refresheeList.forEach(refreshee => {
        this.mediatorRefreshers[refreshee]();
      });
      perf.push(performance.now());
      console.log(`[perf][refreshMediator]: ${(perf[1] - perf[0]) / 1000} s`);
    },
    async refreshProductTable() {
      this.productTableLoading = true;
      const groupByFetch = this.productTable[this.currentTabValue].data[this.productTableGroupBy]?.fetch;
      console.log("found groupBy method", groupByFetch, this.productTable[this.currentTabValue].data, this.productTableGroupBy);
      if (groupByFetch) await this.$store.dispatch(groupByFetch);
      this.productTableLoading = false;
    },
    storeDatePickerDate(date) {
      this.$store.dispatch("dashboardV2SetProductTablePeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
      window.$bus.trigger(EVENTS.SETUP_DATE_PICKER);
    },
    async handleDateChange(date) {
      if (!date) {
        date = this.defaultDatePickerDate;
      }
      this.storeDatePickerDate(date);
      await this.refreshMediator(["KPIS", "PRODUCT_TABLE"]);
    },
    handleSetPeriodTable(date) {
      this.handleSetLiteTablePeriod(date, this.period, this.handleDateChange);
    },
    handleProductTableSort({
      sort,
      direction
    }) {
      this.$store.dispatch("dashboardV2SetProductTableSort", {
        sort,
        direction
      });
      this.refreshMediator(["PRODUCT_TABLE"]);
    },
    refreshAllKpis() {
      this.refreshMediator(["KPIS", "PRODUCT_TABLE", "PERIOD_TABLE"]);
    },
    handleApplyForCurrencyChange() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        this.$store.dispatch("currencyConfigSetCurrency", this.currencies.filterValues[0]);
        this.refreshAllKpis();
      }
    },
    async refreshDashboardSellersSubFilters() {
      await this.initializeSellersFilter(this.dashboardSellersFilterSet);
      await this.initializeMarketplacesFilter(this.dashboardMarketplacesFilterSet, true);
      this.fetchSubFilters({
        sellers: this.currentSellerIds,
        sales_channel: this.currentMarketplaceIds
      }, "dashboard_v2", {
        product: "products"
      }, "dashboard/v2");
    },
    async refreshDashboardMarketPlacesSubFilters() {
      this.fetchSubFilters({
        sellers: this.currentSellerIds,
        sales_channel: this.currentMarketplaceIds
      }, "dashboard_v2", {
        product: "products"
      }, "dashboard/v2");
    }
  }
};
import { mapActions, mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin";
import AppCard from "@/components/AppCard";
import AppInput from "@/components/AppInput";
import AppButton from "@/components/AppButton";
export default {
  name: "ProfileInformation",
  mixins: [ValidateMixin],
  components: {
    AppCard,
    AppInput,
    AppButton
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        should_recieve_budget_end_notification: null,
        should_recieve_budget_change_notification: null
      },
      isEdit: false,
      isReadOnly: true,
      dataLoading: false,
      loading: false,
      showConfirmationText: false
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      token: state => state.auth.token
    }),
    // isLoggedIn() {
    //   return !! this.token
    // },

    notification() {
      return {
        should_recieve_budget_end_notification: this.form.should_recieve_budget_end_notification ?? (this.isOmnyAdmin || this.isOmnyUser ? true : false),
        should_recieve_budget_change_notification: this.form.should_recieve_budget_change_notification ?? (this.isOmnyAdmin || this.isOmnyUser ? true : false)
      };
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus(false);
    if (is_authenticated) {
      this.dataLoading = true;
      this.authMe().then(response => {
        console.log("res: ", response);
        this.setUserData(response);
        this.dataLoading = false;
      });
    }
  },
  methods: {
    ...mapActions(["authMe", "authUpdate", "authStatus"]),
    async onSubmit() {
      if (this.isEdit) {
        this.form.should_recieve_budget_end_notification = this.notification.should_recieve_budget_end_notification;
        this.form.should_recieve_budget_change_notification = this.notification.should_recieve_budget_change_notification;
        const valid = await this.mValidate();
        if (valid) {
          let isEmailChanged = false;
          if (this.form.email != this.me.email) {
            isEmailChanged = true;
          }
          this.loading = true;
          await this.authUpdate(this.form);
          this.loading = false;
          this.isEdit = false;
          if (isEmailChanged) {
            this.showConfirmationText = true;
          }
        }
      }
    },
    onEdit() {
      this.isEdit = true;
    },
    onCancel() {
      this.setUserData(this.me);
      this.isEdit = false;
    },
    setUserData(val) {
      this.form = {
        ...val
      };
    }
  }
};
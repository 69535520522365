import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6d0e8162"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "a-plus-cell-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_plus_icon = _resolveComponent("a-plus-icon");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "border-table-row a-plus-cell"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.item.has_a_plus_content ? (_openBlock(), _createBlock(_component_a_plus_icon, {
      key: 0
    })) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  });
}
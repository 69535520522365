import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "stick-to-right",
    stickyColumn: "",
    style: {
      "right": "0"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('onEditSingleItem', $props.item)),
      style: {
        "padding": "8px",
        "cursor": "pointer"
      }
    }, [_createVNode(_component_app_icon, {
      icon: $props.item.sku ? 'edit-new' : 'not-editable',
      id: `${$props.item.content_id}-edit`
    }, null, 8 /* PROPS */, ["icon", "id"])]), _ctx.isOmnyAdmin || _ctx.isOmnyUser ? (_openBlock(), _createBlock(_component_app_popover, {
      key: 0,
      target: `${$props.item.content_id}-edit`,
      "auto-width": "",
      placement: "top"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($options.testCaseTooltip($props.item)), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["target"])) : _createCommentVNode("v-if", true), !$props.item.sku ? (_openBlock(), _createBlock(_component_app_popover, {
      key: 1,
      target: `${$props.item.content_id}-edit`,
      "auto-width": "",
      placement: "top",
      "custom-class": "not-editable"
    }, {
      default: _withCtx(() => [_createTextVNode(" Assign SKU to the item to start editing the content ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["target"])) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  });
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_compare_value = _resolveComponent("compare-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_rating_value = _resolveComponent("rating-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_dotted_value = _resolveComponent("dotted-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_table_with_title = _resolveComponent("table-with-title");
  return _openBlock(), _createBlock(_component_table_with_title, {
    title: "Evolution",
    class: "listing-period-table",
    onDownload: $options.downloadPerformancePeriodCSV,
    fields: $data.fields,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createVNode(_component_b_table_lite, {
      class: "lite-table lite-table-hoverable period-table listing-table",
      fields: $data.fields,
      bordered: "",
      responsive: "",
      fixed: "",
      items: $options.items,
      "sticky-header": true,
      tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
      onRowClicked: _cache[0] || (_cache[0] = item => _ctx.handleRowClicked(item, $options.handleDateReset, $options.handleDateClick, 'period_agg'))
    }, {
      "head()": _withCtx(col => [_createVNode(_component_flex_row, {
        id: `listing-period-table-${col?.label}`,
        styles: {
          justifyContent: col.field.justify,
          ...col.field.thStyle
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          target: `listing-period-table-${col?.label}`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
          }, null, 8 /* PROPS */, _hoisted_1)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [_createElementVNode("div", {
            innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
            class: "title"
          }, null, 8 /* PROPS */, _hoisted_2)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "styles"])]),
      "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_AppIcon, {
          icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
          color: row.item.selected ? null : '#ADADAD',
          size: 16
        }, null, 8 /* PROPS */, ["icon", "color"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
        value: row.item?.period_agg,
        format: $options.getDateValueFormat,
        styles: {
          justifyContent: 'flex-start',
          ..._ctx.activateDateValueStyles(row)
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
      "cell(listed_asin_count)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_compare_value, {
          value1: row.item?.listed_asin_count,
          value2: row.item?.active_asin_count,
          max1: $options.minMax?.listed_asin_count?.max,
          max2: $options.minMax?.listed_asin_count.max,
          min1: $options.minMax?.listed_asin_count?.min,
          min2: $options.minMax?.listed_asin_count.min
        }, {
          value1: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.active_asin_count,
            styles: {
              padding: 0
            },
            charCount: $options.maxCharCount?.number?.active_asin_count
          }, null, 8 /* PROPS */, ["value", "charCount"])]),
          value2: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.listed_asin_count,
            styles: {
              padding: 0
            },
            color: "#999999"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value1", "value2", "max1", "max2", "min1", "min2"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(page_views)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 4
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views_evol`],
              color: color,
              "show-sign": "",
              charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.page_views,
            min: $options.minMax?.page_views?.min,
            color: "#8D85E5",
            max: $options.minMax?.page_views?.max,
            loading: $props.loading
          }, {
            default: _withCtx(() => [_createVNode(_component_number_value, {
              value: row.item?.page_views,
              charCount: $options.maxCharCount?.number?.page_views,
              tight: ""
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(return_rate)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.return_rate,
        min: $options.minMax?.return_rate?.min,
        color: "#93999F",
        max: $options.minMax?.return_rate?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.return_rate,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(rating)": _withCtx(row => [_createVNode(_component_rating_value, {
        value: row.item?.rating,
        color: "#FC7E47",
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.rating,
          precision: 1,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "loading"])]),
      "cell(organic_CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.organic_CVR, $data.ORGANIC_CVR_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.organic_CVR,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(total_quantity)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 16
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`],
              color: color,
              "show-sign": "",
              charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.total_quantity,
            loading: $props.loading,
            tight: ""
          }, null, 8 /* PROPS */, ["value", "loading"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(traffic_by_asin_buy_box_percentage)": _withCtx(row => [_createVNode(_component_dotted_value, {
        align: 'center',
        color: $options.getColorsBasedOnRange(row.item?.traffic_by_asin_buy_box_percentage, $data.BUYBOX_HEATMAP_COLORS),
        value: row.item?.traffic_by_asin_buy_box_percentage,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.traffic_by_asin_buy_box_percentage
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color", "value", "loading"])]),
      "cell(ratings_total)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.ratings_total,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(avg_unit_price)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 16
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`],
              color: color,
              "show-sign": "",
              charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.avg_unit_price,
            precision: 2,
            align: "right"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["fields", "items", "tbodyTrClass"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDownload", "fields", "loading"]);
}
export default {
  name: 'MultiModeDatePickerSelectorItem',
  emits: ['click'],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    previous: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    }
  }
};
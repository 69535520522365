import { request } from "@/store";
import moment from "moment";
import Filter from "@/store/filter";
var _ = require("lodash");
const ppcSettings = {
  state: {
    currentWeek: moment().startOf('isoWeek'),
    strategies: [],
    weekFilled: [],
    weeksRange: [],
    products: [],
    boost: null,
    bid: null,
    bid_settings: {},
    sellers_filter: new Filter([], [], "id"),
    marketplaces_filter: new Filter([], [], "sales_channel"),
    currencies_filter: new Filter([], [], "currency"),
    connected_profiles_for_seller: [],
    budget_allocation: [],
    product_prioritization: [],
    ppc_campaigns: [],
    bid_simulator: {},
    budget_allocation_total_rows: 0,
    product_prioritization_total_rows: 0
  },
  getters: {
    ppcCampaignIdsGet(state) {
      return () => {
        return state.product_prioritization.map(campaign_group => campaign_group.campaign_details.map(campaign => campaign.campaign_id)).flat();
        ;
      };
    },
    ppcCampaignGroupIdsGet(state) {
      return () => {
        return state.product_prioritization.map(campaign_group => campaign_group.campaign_group_id);
      };
    },
    ppcSettingsWeeksGet(state) {
      return () => {
        return state.weeksRange.map(weekData => weekData.week);
      };
    },
    ppcBidSimulatorCampaignGroupNameGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_group_name : "";
      };
    },
    ppcBidSimulatorCampaignGroupImageGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_group_image : "";
      };
    },
    ppcBidSimulatorCampaignGroupCountGet(state) {
      return () => {
        let result = state?.bid_simulator["C.Branded"];
        return !!result ? result[0].campaign_activated_products_count : "";
      };
    }
  },
  mutations: {
    ppcSettingsProductsSet(state, payload) {
      state.products = payload;
    },
    ppcSettingsStrategiesSet(state, payload) {
      state.strategies = payload;
    },
    ppcSettingsBoostSet(state, payload) {
      state.boost = payload;
    },
    ppcSettingsBidSet(state, payload) {
      state.bid = payload;
    },
    ppcBidSettingsSet(state, payload) {
      state.bid_settings = payload;
    },
    ppcSettingsWeekFilled(state, payload) {
      state.weekFilled = payload;
    },
    ppcSettingsWeeksRangeSet(state, payload) {
      state.weeksRange = payload;
    },
    ppcConnectedAdvertisingProfilesSet(state, payload) {
      state.connected_profiles_for_seller = payload;
    },
    ppcSettingsCurrencySet(state, payload) {
      state.currencies_filter = payload;
    },
    ppcBudgetAllocationSet(state, payload) {
      state.budget_allocation = payload;
    },
    ppcProductPrioritizationSet(state, payload) {
      state.product_prioritization = payload;
    },
    ppcCampaignsSet(state, payload) {
      state.ppc_campaigns = payload;
    },
    ppcCampaignGroupStateSet(state, payload) {
      let campaigns = Object.groupBy(payload, ({
        campaignId
      }) => campaignId);
      let enabled = {
        enabled: 1
      };
      let paused = {
        enabled: 0
      };
      state.product_prioritization = state.product_prioritization.map(campaign_item => {
        let campaign_details = campaign_item.campaign_details.map(campaign_detail => {
          let campaing_to_merge = campaigns[campaign_detail.campaign_id] ?? [];
          return {
            ...campaign_detail,
            ...{
              allocated_budget_current: (campaing_to_merge[0]?.budget?.budget ?? 0) * 7
            }
          };
        });
        let perf_allocated_budget_current = campaign_details.filter(item => item.campaign_theme === "PERF").reduce((accumulator, campaign) => accumulator + campaign?.allocated_budget_current ?? 0, 0);
        let discovery_allocated_budget_current = campaign_details.filter(item => item.campaign_theme === "DISCOVERY").reduce((accumulator, campaign) => accumulator + campaign?.allocated_budget_current ?? 0, 0);
        let campaign_groups = campaign_item.campaign_id_list.map(campaign_id => {
          let campaing_to_merge = campaigns[campaign_id] ?? [];
          return campaing_to_merge[0];
        }).filter(item => !!item);
        let campaign_item_by_state = campaign_groups.length > 0 ? Map.groupBy(campaign_groups, ({
          state
        }) => state === 'PAUSED' ? paused : enabled) : new Map();
        let is_enabled = campaign_item_by_state.size == 0 ? 2 : campaign_item_by_state.size > 1 ? 2 : campaign_item_by_state.keys().next().value.enabled;
        return {
          ..._.mergeWith(campaign_item, {
            campaign_details: campaign_details,
            perf_allocated_budget_current: perf_allocated_budget_current,
            discovery_allocated_budget_current: discovery_allocated_budget_current
          }, {
            is_enabled: is_enabled
          }, (o, s) => _.isNull(s) ? o : s)
        };
      });
    },
    ppcCampaignUpdateSet(state, payload) {
      let campaigns = Object.groupBy(payload.data, ({
        campaignId
      }) => campaignId);
      let enabled = {
        enabled: 1
      };
      let paused = {
        enabled: 0
      };
      state.product_prioritization = state.product_prioritization.map(campaign_item => {
        if (campaign_item.campaign_group_id === payload.campaign_group_id) {
          let campaign_details = campaign_item.campaign_details.map(campaign_detail => {
            let campaing_to_merge = campaigns[campaign_detail.campaign_id] ?? [];
            return {
              ...campaign_detail,
              ...{
                allocated_budget_current: (campaing_to_merge[0]?.budget?.budget ?? 0) * 7
              }
            };
          });
          let perf_allocated_budget_current = campaign_details.filter(item => item.campaign_theme === "PERF").reduce((accumulator, campaign) => accumulator + campaign?.allocated_budget_current ?? 0, 0);
          let discovery_allocated_budget_current = campaign_details.filter(item => item.campaign_theme === "DISCOVERY").reduce((accumulator, campaign) => accumulator + campaign?.allocated_budget_current ?? 0, 0);
          let campaign_groups = campaign_item.campaign_id_list.map(campaign_id => {
            let campaing_to_merge = campaigns[campaign_id] ?? [];
            return campaing_to_merge[0];
          }).filter(item => !!item);
          let campaign_item_by_state = campaign_groups.length > 0 ? Map.groupBy(campaign_groups, ({
            state
          }) => state === 'PAUSED' ? paused : enabled) : new Map();
          let is_enabled = campaign_item_by_state.size == 0 ? 2 : campaign_item_by_state.size > 1 ? 2 : campaign_item_by_state.keys().next().value.enabled;
          return {
            ..._.mergeWith(campaign_item, {
              campaign_details: campaign_details,
              perf_allocated_budget_current: perf_allocated_budget_current,
              discovery_allocated_budget_current: discovery_allocated_budget_current
            }, {
              is_enabled: is_enabled
            }, (o, s) => _.isNull(s) ? o : s)
          };
        }
        return campaign_item;
      });
    },
    ppcBidSimulatorSet(state, payload) {
      state.bid_simulator = payload;
    },
    ppcProductPrioritizationTotalRowsSet(state, payload) {
      state.product_prioritization_total_rows = payload;
    },
    ppcBudgetStrategySet(state, payload) {
      state.weeksRange = state.weeksRange.map(item => {
        let budget_strategy_items = payload.find(budget_strategy => budget_strategy.week === item.week);
        return {
          ..._.mergeWith({}, item, budget_strategy_items, (o, s) => _.isNull(s) ? o : s)
        };
      });
    }
  },
  actions: {
    async ppcBudgetStrategy({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/budget_strategy/get", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcBudgetStrategySet", result.data);
      }
      return result;
    },
    async ppcBudgetStrategyFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/budget_strategy/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcBudgetStrategySet", result.data);
      }
      return result;
    },
    async ppcProductPrioritizationGetFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/product_prioritization/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcProductPrioritizationSet", result.data);
        commit("ppcProductPrioritizationTotalRowsSet", result.total_rows);
      }
      return result;
    },
    async ppcProductPrioritizationCampaigns({
      commit,
      state
    }, payload) {
      let result = (await request("/ppc/campaign/get", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("ppcCampaignsSet", result.data);
        commit("ppcCampaignGroupStateSet", result.data);
      }
      return result;
    },
    async ppcBidSimulatorGetFromBQ({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/bid_simulator/get_from_bq", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        let result_to_save = Object.groupBy(result.data, ({
          targeting_type
        }) => targeting_type);
        commit("ppcBidSimulatorSet", result_to_save);
      }
      return result;
    },
    async ppcBudgetDelete(_, payload) {
      // eslint-disable-next-line no-unused-vars
      await request("/ppc/settings/budget_strategy/delete", "POST", payload);
    },
    async ppcBoost({
      commit
    }, payload) {
      let result = (await request("/ppc/boost", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsBoostSet", result.data);
      }
      return result;
    },
    async ppcProducts({
      commit
    }, payload) {
      let result = (await request("/ppc/products", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsProductsSet", result.data);
      }
      return result;
    },
    async ppcBidAdjustement({
      commit
    }, payload) {
      let result = (await request("/ppc/bid", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcSettingsBidSet", result.data);
      }
      return result;
    },
    async refreshPpcBudgetAllocation({
      commit
    }) {
      const data = await request(`/ppc/budget-allocation`);
      if (data) {
        //commit('ppcBudgetAllocationSet', data)
        commit("ppcProductPrioritizationSet", data);
        commit("ppcProductPrioritizationTotalRowsSet", data.length);
      }
      return data;
    },
    async ppcBudgetAllocationUpdate({
      state,
      commit
    }, payload) {
      // To save the updated value using current state instead default one
      const updatedData = state.budget_allocation.map(item => {
        if (item.campaign_group_id === payload.campaign_group_id) {
          return payload;
        }
        return item;
      });
      commit('ppcBudgetAllocationSet', updatedData);
      const data = await request(`/ppc/budget-allocation`);
      if (data) {
        // commit('ppcBudgetAllocationSet', data)
      }
      return data;
    },
    ppcProductPrioritizationUpdate({
      state,
      commit
    }, payload) {
      const updatedData = state.product_prioritization.map(item => {
        if (item.campaign_group_id === payload.campaign_group_id) {
          return payload;
        }
        return item;
      });
      commit('ppcProductPrioritizationSet', updatedData);
    },
    async ppcProductPrioritizationCampaignGroups({
      commit,
      state
    }, payload) {
      let result = (await request("/ppc/campaign/group/get", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        state.product_prioritization = state.product_prioritization.map(item => {
          const campaign_group = result.data.find(campaign_group => campaign_group.campaign_group_id === item.campaign_group_id);
          if (campaign_group) {
            return {
              ...item,
              ...campaign_group
            };
          }
          return item;
        });
      }
    },
    async ppcProductPrioritizationCampaignGroupUpdate(_, payload) {
      const result = await request("/ppc/campaign/group/update", "POST", {
        data: payload
      });
      return result;
    },
    async ppcStrategiesSave(_, payload) {
      // eslint-disable-next-line no-unused-vars
      let result = (await request("/ppc/settings/budget_strategy/create", "POST", payload)).data;
      return result;
    },
    async ppcBidSettingGet({
      commit
    }, payload) {
      let result = (await request("/ppc/settings/bid_settings/get", "POST", payload)) ?? {
        data: []
      };
      if (result) {
        commit("ppcBidSettingsSet", result.data[0] || {
          profile_id: payload.profile_list[0],
          seller_id: payload.seller_list[0],
          marketplace_id: payload.marketplace_id,
          non_branded_keyword_targeting: 1,
          branded_keyword_product_targeting: 0.4,
          competitor_product_targeting: 1,
          discovery_targeting: 1
        });
      }
      return result;
    },
    async ppcBidSettingSave(_, payload) {
      let result = (await request("/ppc/settings/bid_settings/create", "POST", payload)).data;
      //console.log("bid settings: ", result);
      return result;
    },
    async ppcEditCampaign(_, payload) {
      const data = await request("/ppc/edit_campaign", "POST", payload);
      if (data) {
        return data;
      }
    },
    ppcSettingsClearStrategies({
      commit
    }) {
      commit("ppcSettingsStrategiesSet", []);
      commit("ppcSettingsWeekFilled", []);
    },
    ppcSettingsInitializeSellers({
      commit
    }, payload) {
      commit("ppcSellersFilterSet", payload);
    },
    ppcSettingsInitializeMarketplaces({
      commit
    }, payload) {
      commit("ppcMarketplacesFilterSet", payload);
    }
  }
};
export default ppcSettings;
import AppTd from "@/components/AppTd";
import APlusIcon from "@/components/icons/APlusIcon.vue";
export default {
  name: 'SeoAPlusCell',
  props: {
    item: Object
  },
  components: {
    AppTd,
    APlusIcon
  }
};
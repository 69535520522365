const flipDictionary = data => {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
};
const country_amz_sp_api_marketplace_ids = {
  Canada: "A2EUQ1WTGCTBG2",
  US: "ATVPDKIKX0DER",
  Mexico: "A1AM78C64UM0Y8",
  Brazil: "A2Q3Y263D00KWC",
  Spain: "A1RKKUPIHCS9HS",
  UK: "A1F83G8C2ARO7P",
  France: "A13V1IB3VIYZZH",
  Netherlands: "A1805IZSGTT6HS",
  Belgium: "AMEN7PMS3EDWL",
  Germany: "A1PA6795UKMFR9",
  Italy: "APJ6JRA9NG5V4",
  Sweden: "A2NODRKZP88ZB9",
  Egypt: "ARBP9OOSHTCHU",
  Poland: "A1C3SOZRARQ6R3",
  Turkey: "A33AVAJ2PDY3EV",
  "Saudi Arabia": "A17E79C6D8DWNP",
  "U.A.E": "A2VIGQ35RCS4UG",
  India: "A21TJRUUN4KGV",
  Singapore: "A19VAU5U5O7RUS",
  Australia: "A39IBJ37TRP1C6",
  Japan: "A1VC38T7YXB528",
  Ireland: "A28R8C7NBKEWEA"
};
const country_amz_sp_api_marketplace_ids_flipped = flipDictionary(country_amz_sp_api_marketplace_ids);
const country_code_amz_sp_api_marketplace_ids = {
  CA: "A2EUQ1WTGCTBG2",
  US: "ATVPDKIKX0DER",
  MX: "A1AM78C64UM0Y8",
  BR: "A2Q3Y263D00KWC",
  ES: "A1RKKUPIHCS9HS",
  UK: "A1F83G8C2ARO7P",
  GB: "A1F83G8C2ARO7P",
  FR: "A13V1IB3VIYZZH",
  NL: "A1805IZSGTT6HS",
  BE: "AMEN7PMS3EDWL",
  DE: "A1PA6795UKMFR9",
  IT: "APJ6JRA9NG5V4",
  SE: "A2NODRKZP88ZB9",
  EG: "ARBP9OOSHTCHU",
  PL: "A1C3SOZRARQ6R3",
  TR: "A33AVAJ2PDY3EV",
  SA: "A17E79C6D8DWNP",
  AE: "A2VIGQ35RCS4UG",
  IN: "A21TJRUUN4KGV",
  SG: "A19VAU5U5O7RUS",
  AU: "A39IBJ37TRP1C6",
  JP: "A1VC38T7YXB528",
  IE: "A28R8C7NBKEWEA"
};
const country_code_amz_sp_api_marketplace_ids_flipped = flipDictionary(country_code_amz_sp_api_marketplace_ids);
const country_codes = {
  Canada: "CA",
  US: "US",
  Mexico: "MX",
  Brazil: "BR",
  Spain: "ES",
  UK: "UK",
  GB: "UK",
  France: "FR",
  Netherlands: "NL",
  Belgium: "BE",
  Germany: "DE",
  Italy: "IT",
  Sweden: "SE",
  Egypt: "EG",
  Poland: "PL",
  Turkey: "TR",
  "Saudi Arabia": "SA",
  "U.A.E": "AE",
  India: "IN",
  Singapore: "SG",
  Australia: "AU",
  Japan: "JP",
  Ireland: "IE"
};
const country_codes_flipped = flipDictionary(country_codes);
const country_codes_with_flags_and_marketplace_ids = [{
  "name": "Amazon.com",
  "id": "Amazon.com",
  "code": "US",
  "country_code": "US",
  "marketplace_id": "ATVPDKIKX0DER"
}, {
  "name": "Amazon.ca",
  "id": "Amazon.ca",
  "code": "CA",
  "country_code": "CA",
  "marketplace_id": "A2EUQ1WTGCTBG2"
}, {
  "name": "Amazon.com.mx",
  "id": "Amazon.com.mx",
  "code": "MX",
  "country_code": "MX",
  "marketplace_id": "A1AM78C64UM0Y8"
}, {
  "name": "Amazon.co.uk",
  "id": "Amazon.co.uk",
  "code": "UK",
  "country_code": "UK",
  "marketplace_id": "A1F83G8C2ARO7P"
}, {
  "name": "Amazon.de",
  "id": "Amazon.de",
  "code": "DE",
  "country_code": "DE",
  "marketplace_id": "A1PA6795UKMFR9"
}, {
  "name": "Amazon.fr",
  "id": "Amazon.fr",
  "code": "FR",
  "country_code": "FR",
  "marketplace_id": "A13V1IB3VIYZZH"
}, {
  "name": "Amazon.it",
  "id": "Amazon.it",
  "code": "IT",
  "country_code": "IT",
  "marketplace_id": "APJ6JRA9NG5V4"
}, {
  "name": "Amazon.es",
  "id": "Amazon.es",
  "code": "ES",
  "country_code": "ES",
  "marketplace_id": "A1RKKUPIHCS9HS"
}, {
  "name": "Amazon.nl",
  "id": "Amazon.nl",
  "code": "NL",
  "country_code": "NL",
  "marketplace_id": "A1805IZSGTT6HS"
}, {
  "name": "Amazon.se",
  "id": "Amazon.se",
  "code": "SE",
  "country_code": "SE",
  "marketplace_id": "A2NODRKZP88ZB9"
}, {
  "name": "Amazon.pl",
  "id": "Amazon.pl",
  "code": "PL",
  "country_code": "PL",
  "marketplace_id": "A1C3SOZRARQ6R3"
}, {
  "name": "Amazon.co.jp",
  "id": "Amazon.co.jp",
  "code": "JP",
  "country_code": "JP",
  "marketplace_id": "A1VC38T7YXB528"
}, {
  "name": "Amazon.com.au",
  "id": "Amazon.com.au",
  "code": "AU",
  "country_code": "AU",
  "marketplace_id": "A39IBJ37TRP1C6"
}, {
  "name": "Amazon.com.be",
  "id": "Amazon.com.be",
  "code": "BE",
  "country_code": "BE",
  "marketplace_id": "AMEN7PMS3EDWL"
}, {
  "name": "Amazon.com.tr",
  "id": "Amazon.com.tr",
  "code": "TR",
  "country_code": "TR",
  "marketplace_id": "A33AVAJ2PDY3EV"
}, {
  "name": "Amazon.in",
  "id": "Amazon.in",
  "code": "IN",
  "country_code": "IN",
  "marketplace_id": "A21TJRUUN4KGV"
}, {
  "name": "Amazon.com.br",
  "id": "Amazon.com.br",
  "code": "BR",
  "country_code": "BR",
  "marketplace_id": "A2Q3Y263D00KWC"
}, {
  "name": "Amazon.ie",
  "id": "Amazon.ie",
  "code": "IE",
  "country_code": "IE",
  "marketplace_id": "A28R8C7NBKEWEA"
}];
const countries_browser_languages = {
  "en-CA": "English",
  "fr-CA": "French",
  "en-US": "English",
  "es-ES": "Spanish",
  "en-GB": "English",
  "fr-FR": "French",
  "nl-NL": "Dutch",
  "fr-BE": "French",
  "nl-BE": "Dutch",
  "de-BE": "German",
  "de-DE": "German",
  "it-IT": "Italian",
  "sv-SE": "Swedish"
};
const countries_languages = {
  "Canada": "English",
  "US": "English",
  "Mexico": "Spanish",
  "Brazil": "Portuguese",
  "Spain": "Spanish",
  "UK": "English",
  "France": "French",
  "Netherlands": "Dutch",
  "Belgium": "Dutch",
  "Germany": "German",
  "Italy": "Italian",
  "Sweden": "Swedish",
  "Egypt": "Arabic",
  "Poland": "Polish",
  "Turkey": "Turkish",
  "Saudi Arabia": "Arabic",
  "U.A.E": "Arabic",
  "India": "Hindi",
  "Singapore": "English",
  "Australia": "English",
  "Japan": "Japanese",
  "Ireland": "English"
};
const amz_sp_api_marketplace_ids_country_domains = {
  "ATVPDKIKX0DER": "amazon.com",
  "A2EUQ1WTGCTBG2": "amazon.ca",
  "A1AM78C64UM0Y8": "amazon.com.mx",
  "A1F83G8C2ARO7P": "amazon.co.uk",
  "A1PA6795UKMFR9": "amazon.de",
  "A13V1IB3VIYZZH": "amazon.fr",
  "APJ6JRA9NG5V4": "amazon.it",
  "A1RKKUPIHCS9HS": "amazon.es",
  "A1805IZSGTT6HS": "amazon.nl",
  "A2NODRKZP88ZB9": "amazon.se",
  "A1C3SOZRARQ6R3": "amazon.pl",
  "A1VC38T7YXB528": "amazon.co.jp",
  "A39IBJ37TRP1C6": "amazon.com.au",
  "AMEN7PMS3EDWL": "amazon.com.be",
  "A33AVAJ2PDY3EV": "amazon.com.tr",
  "A21TJRUUN4KGV": "amazon.in",
  "A2Q3Y263D00KWC": "amazon.com.br",
  "A28R8C7NBKEWEA": "amazon.ie"
};
const amz_sp_api_marketplace_ids_country_domains_flipped = flipDictionary(amz_sp_api_marketplace_ids_country_domains);
const country_codes_min_max_ppc_budget_daily = {
  CA: {
    min: 1,
    max: 1000000
  },
  US: {
    min: 1,
    max: 1000000
  },
  MX: {
    min: 1,
    max: 21000000
  },
  BR: {
    min: 1.32,
    max: 5300000
  },
  ES: {
    min: 1,
    max: 1000000
  },
  UK: {
    min: 1,
    max: 1000000
  },
  FR: {
    min: 1,
    max: 1000000
  },
  NL: {
    min: 1,
    max: 1000000
  },
  BE: {
    min: 1,
    max: 1000000
  },
  DE: {
    min: 1,
    max: 1000000
  },
  IT: {
    min: 1,
    max: 1000000
  },
  SE: {
    min: 9,
    max: 9300000
  },
  EG: {
    min: 7,
    max: 7400000
  },
  PL: {
    min: 2,
    max: 2000000
  },
  TR: {
    min: 2,
    max: 2500000
  },
  SA: {
    min: 4,
    max: 3700000
  },
  AE: {
    min: 4,
    max: 3700000
  },
  IN: {
    min: 50,
    max: 21000000
  },
  SG: {
    min: 1.39,
    max: 1300000
  },
  AU: {
    min: 1.4,
    max: 1500000
  },
  JP: {
    min: 100,
    max: 21000000
  },
  CN: {
    min: 1,
    max: 21000000
  },
  ZA: {
    min: 20,
    max: 7000000
  },
  IE: {
    min: 1,
    max: 1000000
  }
};
export { country_amz_sp_api_marketplace_ids, country_code_amz_sp_api_marketplace_ids, country_codes, country_amz_sp_api_marketplace_ids_flipped, country_code_amz_sp_api_marketplace_ids_flipped, country_codes_flipped, country_codes_with_flags_and_marketplace_ids, countries_browser_languages, countries_languages, amz_sp_api_marketplace_ids_country_domains, amz_sp_api_marketplace_ids_country_domains_flipped, country_codes_min_max_ppc_budget_daily };
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e9ee674"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-data-content-search"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  ref: "el"
};
const _hoisted_4 = {
  class: "product-data-content-middle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_product_data_top_section = _resolveComponent("product-data-top-section");
  const _component_app_input = _resolveComponent("app-input");
  const _component_error_occured = _resolveComponent("error-occured");
  const _component_product_data_content = _resolveComponent("product-data-content");
  const _component_app_pagination = _resolveComponent("app-pagination");
  const _component_upload_product_data_sidebar = _resolveComponent("upload-product-data-sidebar");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0",
    modelValue: $data.openModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.openModal = $event),
    showPagination: true,
    activateSettings: true,
    settingsInfoUpload: "Upload Product Data",
    settingsInfoDownload: "Download Product Data",
    onModalShown: $options.handleSideOpened,
    onHandleDownloadCSVClick: $options.downloadCSV,
    onCloseFileUploadModal: $options.closeModal
  }, {
    "topbar-contents": _withCtx(() => [_createVNode(_component_product_data_top_section, {
      selected: $options.selectedFiltered
    }, null, 8 /* PROPS */, ["selected"])]),
    pagination: _withCtx(() => [_createVNode(_component_app_pagination, {
      page: $data.page,
      "onUpdate:page": [_cache[1] || (_cache[1] = $event => $data.page = $event), $options.changePage],
      perPage: $data.perPage,
      "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => $data.perPage = $event), $options.changeElements],
      total: _ctx.total_rows
    }, null, 8 /* PROPS */, ["page", "perPage", "total", "onUpdate:page", "onUpdate:perPage"])]),
    "settings-header-right-file-upload": _withCtx(() => []),
    "right-strip-modal-file-upload": _withCtx(() => [$data.openModal ? (_openBlock(), _createBlock(_component_upload_product_data_sidebar, {
      key: 0,
      onCloseUploadFileModal: $options.closeModal,
      onHandleDownloadCSVClick: $options.downloadCSV,
      onIsSidebarCollapsed: $options.onIsSidebarCollapsed
    }, null, 8 /* PROPS */, ["onCloseUploadFileModal", "onHandleDownloadCSVClick", "onIsSidebarCollapsed"])) : _createCommentVNode("v-if", true)]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_input, {
      modelValue: $data.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.search = $event),
      placeholder: "Search on this page...",
      v2: "",
      version: "search"
    }, null, 8 /* PROPS */, ["modelValue"])]), $data.errorOccured ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_error_occured, {
      onRetry: $options.refreshProductDataPage
    }, null, 8 /* PROPS */, ["onRetry"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
      class: _normalizeClass(["product-data-content", {
        blur: $data.dataLoading
      }])
    }, [_createElementVNode("div", _hoisted_4, [$options.dfDataInitialized && !$data.errorOccured ? (_openBlock(), _createBlock(_component_product_data_content, {
      key: 0,
      items: $options.dfFiltered,
      selected: $data.selected,
      sortField: $data.sortField,
      sortDirection: $data.sortDirection,
      scaleType: _ctx.scaleType,
      defaultScaleType: $data.defaultScaleType,
      onSelect: $options.onSelect,
      onSort: $options.onSort,
      onUpdate: $options.onUpdate,
      onSaveProductData: $options.onSaveProductData
    }, null, 8 /* PROPS */, ["items", "selected", "sortField", "sortDirection", "scaleType", "defaultScaleType", "onSelect", "onSort", "onUpdate", "onSaveProductData"])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */)], 512 /* NEED_PATCH */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onModalShown", "onHandleDownloadCSVClick", "onCloseFileUploadModal"]);
}
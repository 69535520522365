import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-404e2904"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  id: "button-content"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "p-2 vendor-search-input-style"
};
const _hoisted_5 = {
  class: "drop-downn-items"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_dropdown, {
    class: "custom-dropdown",
    disabled: $props.disabled,
    variant: "",
    style: {
      "width": "224px"
    },
    ref: "dropdown",
    show: $data.isDropdownOpen,
    "auto-close": "outside",
    "no-caret": "",
    boundary: "window",
    onShow: $options.hideButtonContent,
    onHide: $options.showButtonContent
  }, {
    "button-content": _withCtx(() => [!$data.isSearchActive ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.computedValue?.text ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.computedValue.text), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_3, [_createTextVNode(_toDisplayString($props.placeholder) + " ", 1 /* TEXT */), _createVNode(_component_app_icon, {
      icon: "caret-down-fill-custom",
      class: "placeholder-icon"
    })]))])) : _createCommentVNode("v-if", true)]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_b_form_input, {
      modelValue: $options.sanitizedSearchQuery,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.sanitizedSearchQuery = $event),
      placeholder: "Search...",
      ref: "searchInput",
      size: "sm",
      onFocus: $options.keepDropdownOpen,
      onBlur: $options.handleInputBlur,
      class: _normalizeClass({
        'is-invalid': $data.searchQuery && $data.searchQuery.trim().length !== 5,
        'custom-input': true
      }),
      title: $data.searchQuery && $data.searchQuery.trim().length !== 5 ? 'Must be exactly 5 characters' : '',
      style: {
        "width": "232px"
      }
    }, null, 8 /* PROPS */, ["modelValue", "onFocus", "onBlur", "class", "title"])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredOptions, (option, index) => {
      return _openBlock(), _createBlock(_component_b_dropdown_item, {
        key: index,
        onClick: $event => $options.selectOption(option),
        class: "dropdown-item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(option.text), 1 /* TEXT */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]);
    }), 128 /* KEYED_FRAGMENT */)), $options.filteredOptions.length === 0 ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
      key: 0,
      disabled: ""
    }, {
      default: _withCtx(() => [_createTextVNode(" No vendor code found ")]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true), $data.searchQuery ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
      key: 1,
      onClick: $options.addOption,
      class: "dropdown-item text-primary",
      disabled: $data.searchQuery.trim().length !== 5
    }, {
      default: _withCtx(() => [_createTextVNode(" Add \"" + _toDisplayString($data.searchQuery) + "\" ", 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick", "disabled"])) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "show", "onShow", "onHide"])]);
}
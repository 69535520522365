export default {
  name: "AppButton",
  emits: ['click'],
  props: {
    type: {
      type: String,
      default: "button"
    },
    variant: {
      type: String,
      default: "primary"
    },
    href: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    noMinWidth: {
      type: Boolean,
      default: false
    },
    v2: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bdfd4fca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_error_occured = _resolveComponent("error-occured");
  const _component_center_wrapper = _resolveComponent("center-wrapper");
  return _openBlock(), _createBlock(_component_center_wrapper, null, {
    default: _withCtx(() => [$props.showError ? (_openBlock(), _createBlock(_component_error_occured, {
      key: 0,
      onRetry: _cache[0] || (_cache[0] = $event => _ctx.$emit('retry'))
    })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]))]),
    _: 3 /* FORWARDED */
  });
}
import moment from 'moment';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'DatePickerMonthes',
  emits: ['month-change'],
  components: {
    AppIcon
  },
  props: {
    date: {
      type: Object,
      default: () => {}
    },
    weekMode: {
      type: Boolean,
      default: false
    },
    first: {
      type: Object,
      default: () => {}
    },
    last: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    console.log('date', this.date, this.first, this.last);
  },
  computed: {
    current() {
      const date = moment(this.date);
      const result = [date.format('MMMM'), date.year()];
      return result.join(' ');
    },
    previous() {
      const date = moment(this.date).subtract(1, 'month');
      const result = [date.format('MMMM')];
      if (date.month() == 11) {
        result.push(date.year());
      }
      return result.join(' ');
    },
    isFirstMonth() {
      return this.first ? this.date?.isSame(this.first, 'month') : false;
    },
    isLastMonth() {
      const today = moment();
      return this.date ? this.date?.isSame(today, 'month') : false;
    }
  },
  methods: {
    onClick(type) {
      if (type == 'next' && !this.isLastMonth || type == 'previous' && !this.isFirstMonth) {
        this.$emit('month-change', type);
      }
    }
  }
};
import VariableStyledBar from "@/components/tableElements/VariableStyledBar.vue";
import LayerBars from "@/components/tableElements/LayerBars.vue";
import CurrencyValue from "@/components/tableElements/CurrencyValue.vue";
import PercentageValue from "@/components/tableElements/PercentageValue.vue";
import DottedValue from "@/components/tableElements/DottedValue.vue";
import FlexRow from "@/components/FlexRow.vue";
import { normalizeToRange } from '@/utils';
import { BAR_CHART_MINIMUM_PERCENTAGE } from '@/utils/constants';
export default {
  name: "CompositeColorfulBar",
  components: {
    VariableStyledBar,
    LayerBars,
    CurrencyValue,
    PercentageValue,
    DottedValue,
    FlexRow
  },
  props: {
    loading: {
      type: Boolean
    },
    values: {
      type: Array
    },
    currencies: {
      type: Array
    },
    barValues: {
      type: Array
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    colors: {
      type: Array
    },
    types: {
      type: Array
    }
  },
  data() {
    return {
      showCurrenciesToggle: false
    };
  },
  expose: ["setShowCurrencies", "setHideCurrencies"],
  computed: {
    showLittleCurrency() {
      return [this.isWideEnough(this.barValues[0]), this.isWideEnough(this.barValues[1] - this.barValues[0]), this.isWideEnough(this.barValues[2] - this.barValues[1]), this.isWideEnough(this.max - this.barValues[2])];
    },
    littleCurrencyLeftPad() {
      return [this.barWidth(this.barValues[0]) / 2, this.barWidth(this.barValues[1] - this.barValues[0]) / 2, this.barWidth(this.barValues[2] - this.barValues[1]) / 2, this.barWidth(this.max - this.barValues[2]) / 2];
    },
    validValues() {
      return this.values.reduce((a, b) => a || b !== null, false);
    }
  },
  mounted() {
    this.calculatePositionForCurrencies();
  },
  methods: {
    barWidth(value) {
      return normalizeToRange(value ?? 0, BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
    },
    isWideEnough(value) {
      return this.barWidth(value) > 13.5;
    },
    calculateRepositioning(prevEl = null, currCurrencyEl) {
      const prevWidth = prevEl?.clientWidth ?? 0;
      if (currCurrencyEl) {
        currCurrencyEl.style.left = `${prevWidth}px`;
      }
    },
    calculatePositionForCurrencies() {
      this.calculateRepositioning(this.$refs.three?.$el, this.$refs.fourCurrency?.$el);
      this.calculateRepositioning(this.$refs.two?.$el, this.$refs.threeCurrency?.$el);
      this.calculateRepositioning(this.$refs.one?.$el, this.$refs.twoCurrency?.$el);
      this.calculateRepositioning(null, this.$refs.oneCurrency?.$el);
    },
    setShowCurrencies() {
      this.$refs.detailDropdown.open();
    },
    setHideCurrencies() {
      this.$refs.detailDropdown.close();
    }
  }
};
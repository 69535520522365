import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import FlexRow from '@/components/FlexRow.vue';
import { COLORS } from '@/utils/constants';
export default {
  name: 'SignedBar',
  components: {
    FlexRow,
    VariableStyledBar
  },
  props: {
    value: {
      type: [Number, null],
      required: true,
      default: 0
    },
    negativeColor: {
      type: String,
      required: true,
      default: COLORS.RED400
    },
    positveColor: {
      type: String,
      required: true,
      default: "#312783"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    height: {
      type: Number
    }
  },
  data() {
    return {
      COLORS
    };
  },
  computed: {
    hideOrShowNegative() {
      if (this.value < 0) {
        return {
          opacity: 1
        };
      }
      return {
        opacity: 0
      };
    },
    hideOrShowPositive() {
      if (this.value >= 0) {
        return {
          opacity: 1
        };
      }
      return {
        opacity: 0
      };
    }
  }
};
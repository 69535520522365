import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { EVENTS, isObjectEmpty } from "@/utils";
import Filter from "@/store/filter";
import { CM_TO_INCH_COEF, KG_TO_LB_COEF } from "@/utils/constants";
import moment from "moment";
import ValidateMixin from "@/mixins/ValidateMixin";
import ProductDataMixin from "@/mixins/ProductDataMixin";
import AppInput from "@/components/AppInput";
import AppPagination from "@/components/AppPagination";
import ProductDataTopSection from "@/components/ProductDataTopSection";
import ProductDataContent from "@/components/ProductDataContent";
import UploadProductDataSidebar from "@/components/UploadProductDataSidebar.vue";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "ProductDataPage",
  mixins: [AccountsMixin, ValidateMixin, ProductDataMixin, DownloadMixin, InitializeFiltersMixin],
  components: {
    AppInput,
    AppPagination,
    ProductDataTopSection,
    ProductDataContent,
    UploadProductDataSidebar,
    ErrorOccured
  },
  data() {
    return {
      dataLoading: false,
      errorOccured: false,
      page: 1,
      perPage: 20,
      search: "",
      sortField: "product_name",
      sortDirection: "asc",
      defaultScaleType: true,
      selected: [],
      selectedItem: null,
      openModal: false,
      openSideBar: true,
      seller_list: []
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      product_data: state => state.product_data.list,
      product_data_download: state => state.product_data.download_list,
      total_rows: state => state.product_data.total_rows,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.product_data.sellers_filter,
      scaleType: state => state.workspace.scaleType
    }),
    ...mapGetters(["productDataSellersFilterValuesIdsGet", "productDataGetProductSkus", "productDataGetProductAsins", "productDataGetProductIds"]),
    dfDataInitialized() {
      return !isObjectEmpty(this.product_data);
    },
    dfFiltered() {
      console.log("[df filtered data]");
      console.log(this.product_data);
      return this.product_data.sort((a, b) => {
        const aValue = a[this.sortField];
        const bValue = b[this.sortField];
        if (this.sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else if (this.sortDirection === "desc") {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
        return 0;
      }).filter(item => {
        const s = this.search.trim().toLowerCase();
        if (s != "") {
          return item?.sku?.toLowerCase().includes(s) || item?.asin?.toLowerCase().includes(s) || item?.product_name?.toLowerCase().includes(s);
        }
        return true;
      });
    },
    selectedFiltered() {
      return this.selected.filter(asin => {
        return this.dfFiltered.find(el => el.asin == asin && el);
      });
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.initializeSellersFilter(this.productDataSellersFilterSet);
      this.sellers_filter.selectOnly(this.sellers_filter.filterItems.slice(-1).pop(), "id");
      await this.refreshProductDataPage();
      window.$bus.on(EVENTS.REFRESH_PRODUCT_DATA_PAGE, this.refreshProductDataPage);
      window.$bus.on(EVENTS.CHANGE_PRODUCT_DATA_SCALE, this.handleProductDataScaleChange);
      //await this.refreshProductDataPage();
    }
  },
  methods: {
    ...mapMutations(["productDataListSet", "productDataSellersFilterSet"]),
    ...mapActions(["accountList", "getProductDataList", "getProductDataListToDownload", "getAllProductDataList", "getAllProductDataListToDownload", "authStatus", "workspaceList", "workspaceCurrent", "dfUpdateProductData", "dfProductDataList", "setScaleTypeFromUser"]),
    onSelect(item) {
      this.selectedItem = item;
      this.selected = [this.selectedItem.asin];
    },
    onSort(values) {
      this.sortField = values[0];
      this.sortDirection = values[1];
    },
    onUpdate(items) {
      this.productDataListSet(items);
    },
    onIsSidebarCollapsed($event) {
      this.isSideBarCollapsed = $event;
    },
    onSaveProductData() {
      console.log("[onSaveProduct Data Expiration date]");
      this.updateProductData();
    },
    async changePage(newPage) {
      this.page = newPage;
      await this.refreshProductDataPage();
    },
    async changeElements(elementsPerPage) {
      this.page = 1;
      this.perPage = elementsPerPage;
      await this.refreshProductDataPage();
    },
    async refreshProductDataPage() {
      this.dataLoading = true;
      this.seller_list = this.productDataSellersFilterValuesIdsGet();
      console.log("[refresh productData page seller list]");
      console.log(this.seller_list);
      if (this.seller_list.length > 0) {
        let result = await this.getProductDataList({
          seller_list: this.seller_list,
          page_offset: this.page,
          page_limit: this.perPage
        });
        this.throwIfError(result);
        result = await this.getAllProductDataList({
          seller_list: this.seller_list,
          sku_list: this.productDataGetProductSkus(),
          asin_list: this.productDataGetProductAsins(),
          product_id_list: this.productDataGetProductIds(),
          page_offset: this.page,
          page_limit: this.perPage
        });
      } else {
        this.errorOccured = true;
      }
      this.dataLoading = false;
    },
    async downloadCSV() {
      try {
        let headersMapping = {
          seller_id: "seller_id",
          sku: "sku",
          asin: "asin",
          product_name: "product_name",
          orderable: "orderable",
          //product_package_dimension:"product_package_dimension",
          product_package_length_cm: "product_package_length_cm",
          product_package_width_cm: "product_package_width_cm",
          product_package_height_cm: "product_package_height_cm",
          product_package_weight_kg: "product_package_weight_kg",
          units_per_box: "units_per_box",
          box_length_cm: "box_length_cm",
          box_width_cm: "box_width_cm",
          box_height_cm: "box_height_cm",
          box_weight_kg: "box_weight_kg"
        };
        if (this.scaleType === "imperial") {
          headersMapping = {
            seller_id: "seller_id",
            sku: "sku",
            asin: "asin",
            product_name: "product_name",
            orderable: "orderable",
            //product_package_dimension:"product_package_dimension",
            product_package_length_in: "product_package_length_in",
            product_package_width_in: "product_package_width_in",
            product_package_height_in: "product_package_height_in",
            product_package_weight_lb: "product_package_weight_lb",
            units_per_box: "units_per_box",
            box_length_in: "box_length_in",
            box_width_in: "box_width_in",
            box_height_in: "box_height_in",
            box_weight_lb: "box_weight_lb"
          };
        }
        let csv_headers = this.extractHeaders(headersMapping);
        this.seller_list = this.productDataSellersFilterValuesIdsGet();
        if (!this.seller_list.length) {
          this.throwIfError({});
        }
        window.$bus.trigger("showSuccess", {
          visible: true,
          message: "CSV download request initiated successfully.",
          delay: 5999 // Show alert less than 1 minute
        });
        let fileName = "product_data_" + this.seller_list.join("_") + "_" + moment().format("YYYY_MM_DD").toString() + ".csv";

        // Function to fetch data in chunks/pages and accumulate CSV content
        const totalPages = this.totalPagetoDownload();
        let csv_string = await this.generateCsvStringWithPagination(csv_headers, totalPages, this.downloadProductDataRows);
        this.throwIfError(csv_string);
        this.downloadCsv(csv_string, fileName);
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
      }
    },
    async handleProductDataScaleChange(newScaleType) {
      console.log("[handleProductDataScaleChange ]");
      await this.setScaleTypeFromUser(newScaleType);
    },
    async downloadProductDataRows(currentPage) {
      let result = await this.getProductDataListToDownload({
        seller_list: this.seller_list,
        page_offset: currentPage,
        page_limit: this.perPage
      });
      this.throwIfError(result);
      const sku_list = this.product_data_download.map(product => product.sku);
      const asin_list = this.product_data_download.map(product => product.asin);
      const product_id_list = this.product_data_download.map(product => [product.seller_id, product.asin, product.sku]);
      result = await this.getAllProductDataListToDownload({
        seller_list: this.seller_list,
        sku_list: sku_list,
        asin_list: asin_list,
        product_id_list: product_id_list,
        page_offset: currentPage,
        page_limit: this.perPage
      });
      if (this.scaleType === "imperial") {
        let processed_data = this.convertCSVToImperial(this.product_data_download);
        return processed_data;
      }
      return this.product_data_download;
    },
    extractHeaders(headersMapping) {
      // Ensure data is valid and not empty
      return Object.fromEntries(new Map(
      //Object.entries(headersMapping).map((fieldName) => [fieldName, fieldName])
      Object.entries(headersMapping).map(([key, value]) => [key, value])));
    },
    formatDateWithTimezone(date) {
      const pad = (num, size) => num.toString().padStart(size, "0");
      const year = date.getUTCFullYear();
      const month = pad(date.getUTCMonth() + 1, 2);
      const day = pad(date.getUTCDate(), 2);
      const hours = pad(date.getUTCHours(), 2);
      const minutes = pad(date.getUTCMinutes(), 2);
      const seconds = pad(date.getUTCSeconds(), 2);
      const milliseconds = pad(date.getUTCMilliseconds(), 3);
      const microseconds = pad(0, 3); // As JavaScript Date object doesn't support microseconds

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}UTC+0000`;
    },
    handleSideOpened() {
      console.log("[Clicked to open side bar]");
    },
    totalPagetoDownload() {
      return Math.ceil(this.total_rows / this.perPage);
    },
    closeModal() {
      this.openModal = false;
    },
    convertCSVToImperial(product_data_download) {
      return product_data_download.map(product => {
        return {
          ...product,
          //product_package_dimension: product.product_package_dimension ? ((parseFloat(product.product_package_length_cm) *CM_TO_INCH_COEF)*(parseFloat(product.product_package_width_cm)*CM_TO_INCH_COEF) * (parseFloat(product.product_package_height_cm)*CM_TO_INCH_COEF)).toFixed(2): null,
          product_package_length_in: product.product_package_length_cm ? (parseFloat(product.product_package_length_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          product_package_width_in: product.product_package_width_cm ? (parseFloat(product.product_package_width_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          product_package_height_in: product.product_package_height_cm ? (parseFloat(product.product_package_height_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          product_package_weight_lb: product.product_package_weight_kg ? (parseFloat(product.product_package_weight_kg) * KG_TO_LB_COEF).toFixed(2) : null,
          box_length_in: product.box_length_cm ? (parseFloat(product.box_length_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          box_width_in: product.box_width_cm ? (parseFloat(product.box_width_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          box_height_in: product.box_height_cm ? (parseFloat(product.box_height_cm) * CM_TO_INCH_COEF).toFixed(2) : null,
          box_weight_lb: product.box_weight_kg ? (parseFloat(product.box_weight_kg) * KG_TO_LB_COEF).toFixed(2) : null
        };
      });
    }
  }
};
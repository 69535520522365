import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_marketplace_filter = _resolveComponent("marketplace-filter");
  const _component_product_filter = _resolveComponent("product-filter");
  const _component_multi_mode_date_picker = _resolveComponent("multi-mode-date-picker");
  const _component_multi_mode_date_picker_u_i_text = _resolveComponent("multi-mode-date-picker-u-i-text");
  const _component_date_picker_filter = _resolveComponent("date-picker-filter");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_seller_filter, {
    sellers: _ctx.sellers,
    onApply: $options.applySellers
  }, null, 8 /* PROPS */, ["sellers", "onApply"]), _createVNode(_component_marketplace_filter, {
    marketplaces: _ctx.marketplaces,
    onApply: $options.applyMarketplaces
  }, null, 8 /* PROPS */, ["marketplaces", "onApply"]), _createVNode(_component_product_filter, {
    products: _ctx.products,
    sellers: _ctx.sellers,
    marketplaces: _ctx.marketplaces,
    onApply: $options.applyProducts
  }, null, 8 /* PROPS */, ["products", "sellers", "marketplaces", "onApply"]), _createVNode(_component_date_picker_filter, {
    "no-caret": "",
    date: $options.datePickerDate,
    ref: "datePickerFilter",
    loading: $props.loading,
    onHidden: $options.handleDatePickerCancel
  }, {
    body: _withCtx(() => [_createVNode(_component_multi_mode_date_picker, {
      ref: "datePicker",
      onSubmit: $options.handleDatePickerSubmit,
      onCancel: $options.handleDatePickerCancel
    }, null, 8 /* PROPS */, ["onSubmit", "onCancel"])]),
    "date-picker-body": _withCtx(() => [_createVNode(_component_multi_mode_date_picker_u_i_text, {
      start: $options.datePickerDisplayDate('start'),
      end: $options.datePickerDisplayDate('end')
    }, null, 8 /* PROPS */, ["start", "end"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["date", "loading", "onHidden"])], 64 /* STABLE_FRAGMENT */);
}
import { mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppForm from "@/components/AppForm.vue";
import AppInput from "@/components/AppInput.vue";
import AppButton from "@/components/AppButton.vue";
//import AppLink from '@/components/AppLink.vue'
import TheWrapperPublic from "@/components/TheWrapperPublic.vue";
import IntercomScript from "@/components/shared/IntercomScript";
export default {
  name: "LoginPage",
  mixins: [ValidateMixin],
  components: {
    AppForm,
    AppInput,
    AppButton,
    //AppLink,
    TheWrapperPublic,
    IntercomScript
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      isInvalidCredentials: false,
      loading: false,
      errorMessage: "",
      is_authenticated: false,
      intercomAppId: process.env.VUE_APP_OMNY_APP_INTERCOM_APP_ID,
      userName: "Guest user",
      userEmail: "Guest email"
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      is_authenticated: state => state.auth.is_authenticated
    })

    // async isLoggedIn() {
    //   //return !!this.token;
    //   //return !!this.token && this.is_authenticated;
    //   //return this.is_authenticated;
    //   //return this.authStatus();
    //   var result = await this.authStatus();
    //   console.log(result)
    //   return result
    //   //return this.is_authenticated
    // },
  },
  // created() {
  //   this.authStatus();
  // },

  async mounted() {
    window.$bus.on("showErrorLogin", this.showErrorLogin);
    //https://www.aleksandrhovhannisyan.com/blog/async-functions-that-return-booleans/
    this.is_authenticated = await this.authStatus(false);
    if (this.is_authenticated) {
      this.$router.replace("/");
    } else {
      this.$store.reset();
    }
  },
  destroyed() {
    window.$bus.off("hideErrorLogin", this.hideErrorLogin);
  },
  methods: {
    ...mapActions(["authLogin", "authStatus", "authMe", "workspaceCurrent", "accountList", "workspaceList"]),
    async onSubmit() {
      const valid = await this.mValidate();
      if (valid) {
        this.loading = true;
        const data_server = await this.authLogin(this.form);
        if (data_server && data_server.status !== "CredentialsError" && data_server.status !== "ServerCredentialsError") {
          await this.authMe();
          await this.accountList({});
          await this.workspaceList();
          await this.workspaceCurrent();
          this.$router.replace("/");
        }
        this.loading = false;
      }
    },
    recovery() {
      this.$router.push("/recovery");
    },
    showErrorLogin(errorMessage) {
      this.isInvalidCredentials = true;
      this.errorMessage = errorMessage;
    },
    hideErrorLogin() {
      this.isInvalidCredentials = false;
      this.errorMessage = "";
    }
  }
};
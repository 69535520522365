import TableWithTitle from '@/components/TableWithTitle.vue';
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { getDescriptionBasedOnKpiKey, getColorsBasedOnRange } from "@/utils/transformers";
import { SELECTOR, PERIOD_FIELD, REVENUE_EXCL_VAT_FIELD, DAYS_INVENTORY_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, PPC_SALES_FIELD, TACOS_FIELD, BUYBOX_FIELD, TOTAL_QUANTITY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, AVERAGE_UNIT_PRICE_FIELD } from "@/utils/table_fields";
import AppIcon from '@/components/AppIcon.vue';
import { mapState } from "vuex";
import { HugValue, DateValue, RateChangeValue, CurrencyValue, NumberValue, BoxedValue, BarWithValue, PercentageValue, DottedValue, OverlapBarValue } from "@/components/tableElements";
import LiteTableMixin from '@/mixins/LiteTableMixin.vue';
import DownloadMixin from '@/mixins/DownloadMixin.vue';
import { DEFAULT_DATE_FORMATS, DAYS_INVENTORY_HEATMAP_COLORS, ORGANIC_CVR_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, PERIOD_COLUMN_TITLES, COLORS } from '@/utils/constants';
import FlexRow from '@/components/FlexRow.vue';
import moment from 'moment';
import { capitalize } from 'vue';
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
export default {
  name: "PeriodTable",
  components: {
    TableWithTitle,
    WrapWithPopover,
    AppIcon,
    HugValue,
    DateValue,
    RateChangeValue,
    CurrencyValue,
    NumberValue,
    BoxedValue,
    BarWithValue,
    PercentageValue,
    DottedValue,
    OverlapBarValue,
    FlexRow,
    SlotWithEvolutionValue,
    EvolutionValue
  },
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      typ: Boolean,
      required: true
    }
  },
  emits: ["setDateRange", "refresh"],
  data() {
    return {
      DAYS_INVENTORY_HEATMAP_COLORS,
      ORGANIC_CVR_HEATMAP_COLORS,
      BUYBOX_HEATMAP_COLORS,
      PERIOD_COLUMN_TITLES,
      COLORS,
      fields: [SELECTOR, PERIOD_FIELD, REVENUE_EXCL_VAT_FIELD, TOTAL_QUANTITY_FIELD, AVERAGE_UNIT_PRICE_FIELD, DAYS_INVENTORY_FIELD, LOST_REVENUE_EXCL_VAT_FIELD, ASIN_SOLD_FIELD, ORGANIC_CVR_FIELD, BUYBOX_FIELD, PPC_SALES_FIELD, TACOS_FIELD],
      csvSources: period => ({
        [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
        [REVENUE_EXCL_VAT_FIELD.label]: REVENUE_EXCL_VAT_FIELD.key,
        ...this.getEvolutionsForField(REVENUE_EXCL_VAT_FIELD, {
          hasCurrencyEvolution: true
        }),
        [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
        ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
        [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
        ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
        [DAYS_INVENTORY_FIELD.label]: DAYS_INVENTORY_FIELD.key,
        [LOST_REVENUE_EXCL_VAT_FIELD.label]: LOST_REVENUE_EXCL_VAT_FIELD.key,
        [ASIN_SOLD_FIELD.label]: ASIN_SOLD_FIELD.key,
        [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
        [BUYBOX_FIELD.label]: BUYBOX_FIELD.key,
        [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
        [TACOS_FIELD.label]: TACOS_FIELD.key
      })
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.dashboard_v2.periodTable,
      tab: state => state.dashboard_v2.currentTabValue,
      period: state => state.dashboard_v2.period,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    csvFileName() {
      return `Dashboard_Performance_Evolution_${capitalize(this.period)}.csv`;
    }
  },
  methods: {
    getDescriptionBasedOnKpiKey,
    getColorsBasedOnRange,
    handleDateClick(period) {
      period = moment(period);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    async downloadPerformancePeriodCSV() {
      const csv_headers = this.csvSources(this.period);
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    },
    handleRowClick(item) {
      this.handleRowClicked(item, this.handleDateReset, this.handleDateClick, 'period_agg');
    }
  }
};
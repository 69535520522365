import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a9efdbc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "images-content"
};
const _hoisted_3 = ["id", "onClick"];
const _hoisted_4 = {
  class: "status-item"
};
const _hoisted_5 = {
  class: "images-counter"
};
const _hoisted_6 = {
  class: "images-number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_img = _resolveComponent("b-img");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_wrap_with_status_icon = _resolveComponent("wrap-with-status-icon");
  const _component_app_modal = _resolveComponent("app-modal");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "image-background-cell"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.renderedImageList, (image, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "image-list",
        key: index
      }, [_createVNode(_component_wrap_with_status_icon, {
        validationStatus: $options.computedStatus(image)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "image-container",
          id: image.link,
          ref_for: true,
          ref: `${image.link}/${index}`,
          onClick: $event => $options.onClickImage(`${image.link}/${index}`, index)
        }, [image.link != '' ? (_openBlock(), _createBlock(_component_b_img, {
          key: 0,
          src: image.link,
          alt: "Image",
          style: _normalizeStyle(image.width > image.height ? 'width: 44px' : 'height:44px')
        }, null, 8 /* PROPS */, ["src", "style"])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_3), _createVNode(_component_app_popover, {
          target: image.link,
          "auto-width": "",
          placement: "left",
          "custom-class": "seo-popover"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_b_img, {
            src: image.hover_img_link,
            alt: "Image",
            style: _normalizeStyle(image.width > image.height ? 'width: 500px' : 'height:500px')
          }, null, 8 /* PROPS */, ["src", "style"])])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"]), $props.isCollapsed ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expand-button",
          onClick: _cache[0] || (_cache[0] = (...args) => $options.expandColumn && $options.expandColumn(...args))
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_app_icon, {
          icon: "seo-image-counter",
          size: "12"
        }), _createElementVNode("p", _hoisted_6, _toDisplayString($options.imageListSize), 1 /* TEXT */)]), _createVNode(_component_app_icon, {
          icon: "chevron-right",
          size: "12"
        })])) : _createCommentVNode("v-if", true)])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["validationStatus"]), !$props.pushModal ? (_openBlock(), _createBlock(_component_app_modal, {
        key: 0,
        "no-header": "",
        "no-footer": "",
        modelValue: $data.hoverImage[`${image.link}/${index}`],
        "onUpdate:modelValue": $event => $data.hoverImage[`${image.link}/${index}`] = $event,
        custom_class: "image-popup"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_img, {
          src: image.hover_img_link,
          alt: "Image",
          onMouseleave: $options.onLeaveImage,
          style: _normalizeStyle(image.width > image.height ? 'width: 500px' : 'height:500px')
        }, null, 8 /* PROPS */, ["src", "onMouseleave", "style"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))])]),
    _: 1 /* STABLE */
  });
}
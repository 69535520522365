import { mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import EditPageContentPreview from "@/components/EditPageContentPreview";
import EditPageContentEdit from "@/components/EditPageContentEdit";
import { OnClickOutside } from '@vueuse/components';
import DiffMatchPatch from "diff-match-patch";
import { generateHtmlDiff } from "@/utils";
import { countries_browser_languages } from "@/utils/countries";
export default {
  name: "EditpageContent",
  emits: ["update:modelValue", 'hide-comparison'],
  components: {
    AppIcon,
    EditPageContentPreview,
    EditPageContentEdit,
    OnClickOutside
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    amazon: {
      type: Object,
      default: () => {}
    },
    ai_translated_content: {
      type: Object,
      default: () => {}
    },
    ai_generated_content: {
      type: Object,
      default: () => {}
    },
    ai_generated_content_translation: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    active_menu: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    show_ai_generated_content: {
      type: Boolean,
      default: false
    },
    show_ai_translated_content: {
      type: Boolean,
      default: false
    },
    omny_translated_content: {
      type: Object,
      default: () => {}
    },
    amazon_translated_content: {
      type: Object,
      default: () => {}
    },
    keyword_sidebar_open: {
      type: Boolean,
      default: false
    },
    show_comparison: {
      type: Boolean,
      default: false
    },
    show_translation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      charactersMap: {
        product_name: {
          min: 100,
          max: 200
        },
        generic_keyword: {
          min: 100,
          max: 250
        },
        product_description: {
          min: 1000,
          max: 2000
        },
        bullet_points: {
          min: 50,
          max: 300
        }
      },
      show_amazon_version: false,
      suggested_keywords: "",
      omny_text_with_diffs: "",
      amazon_text_with_diffs: ""
    };
  },
  created() {
    this.computeComparison();
  },
  async mounted() {
    this.suggested_keywords = await this.suggestedKeywords();
  },
  computed: {
    amazonContent() {
      return {
        product_name: this.amazon?.product_name ?? "",
        generic_keyword: this.amazon?.generic_keyword ?? "",
        product_description: this.amazon?.product_description ?? "",
        bullet_points: [this.amazon.bullet_point_1, this.amazon.bullet_point_2, this.amazon.bullet_point_3, this.amazon.bullet_point_4, this.amazon.bullet_point_5]
      }[this.type] ?? "";
    },
    amazonContentTranslation() {
      let content = {
        product_name: this.amazon_translated_content?.product_name ?? "",
        generic_keyword: this.amazon_translated_content?.generic_keyword ?? "",
        product_description: this.amazon_translated_content?.product_description ?? "",
        bullet_points: [this.amazon_translated_content?.bullet_point_1 ?? "", this.amazon_translated_content?.bullet_point_2 ?? "", this.amazon_translated_content?.bullet_point_3 ?? "", this.amazon_translated_content?.bullet_point_4 ?? "", this.amazon_translated_content?.bullet_point_5 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    omnyContentTranslation() {
      let content = {
        product_name: this.omny_translated_content?.product_name ?? "",
        generic_keyword: this.omny_translated_content?.generic_keyword ?? "",
        product_description: this.omny_translated_content?.product_description ?? "",
        bullet_points: [this.omny_translated_content?.bullet_point_1 ?? "", this.omny_translated_content?.bullet_point_2 ?? "", this.omny_translated_content?.bullet_point_3 ?? "", this.omny_translated_content?.bullet_point_4 ?? "", this.omny_translated_content?.bullet_point_5 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    aiContent() {
      return this.show_ai_translated_content && this.translateContent || this.show_ai_generated_content && this.aiGenerateContent;
    },
    aiContentTranslate() {
      return this.show_ai_generated_content && this.aiGenerateContentTranslate;
    },
    aiContentTitle() {
      return this.show_ai_translated_content && "AI Translated Content" || this.show_ai_generated_content && "AI proposition";
    },
    translateContent() {
      let content = {
        product_name: this.ai_translated_content?.product_name ?? "",
        generic_keyword: this.suggested_keywords ?? this.amazon?.generic_keyword,
        product_description: this.ai_translated_content?.product_description ?? "",
        bullet_points: [this.ai_translated_content?.bullet_point_1 ?? "", this.ai_translated_content?.bullet_point_2 ?? "", this.ai_translated_content?.bullet_point_3 ?? "", this.ai_translated_content?.bullet_point_4 ?? "", this.ai_translated_content?.bullet_point_5 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    aiGenerateContent() {
      let content = {
        product_name: [this.ai_generated_content?.product_name_1 ?? "", this.ai_generated_content?.product_name_2 ?? "", this.ai_generated_content?.product_name_3 ?? ""],
        generic_keyword: this.ai_generated_content?.generic_keyword ?? "",
        product_description: this.ai_generated_content?.product_description ?? "",
        bullet_points: [this.ai_generated_content?.bullet_point_1 ?? "", this.ai_generated_content?.bullet_point_2 ?? "", this.ai_generated_content?.bullet_point_3 ?? "", this.ai_generated_content?.bullet_point_4 ?? "", this.ai_generated_content?.bullet_point_5 ?? "", this.ai_generated_content?.bullet_point_6 ?? "", this.ai_generated_content?.bullet_point_7 ?? "", this.ai_generated_content?.bullet_point_8 ?? "", this.ai_generated_content?.bullet_point_9 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    aiGenerateContentTranslate() {
      let content = {
        product_name: [this.ai_generated_content_translation?.product_name_1 ?? "", this.ai_generated_content_translation?.product_name_2 ?? "", this.ai_generated_content_translation?.product_name_3 ?? ""],
        generic_keyword: this.ai_generated_content_translation?.generic_keyword ?? "",
        product_description: this.ai_generated_content_translation?.product_description ?? "",
        bullet_points: [this.ai_generated_content_translation?.bullet_point_1 ?? "", this.ai_generated_content_translation?.bullet_point_2 ?? "", this.ai_generated_content_translation?.bullet_point_3 ?? "", this.ai_generated_content_translation?.bullet_point_4 ?? "", this.ai_generated_content_translation?.bullet_point_5 ?? "", this.ai_generated_content_translation?.bullet_point_6 ?? "", this.ai_generated_content_translation?.bullet_point_7 ?? "", this.ai_generated_content_translation?.bullet_point_8 ?? "", this.ai_generated_content_translation?.bullet_point_9 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    omnyContent: {
      get() {
        return {
          product_name: this.modelValue?.product_name ?? "",
          generic_keyword: this.modelValue?.generic_keyword ?? "",
          product_description: this.modelValue?.product_description ?? "",
          bullet_points: [this.modelValue.bullet_point_1, this.modelValue.bullet_point_2, this.modelValue.bullet_point_3, this.modelValue.bullet_point_4, this.modelValue.bullet_point_5]
        }[this.type] ?? "";
      },
      set(val) {
        const newValue = {
          ...this.modelValue
        };
        const updateBulletPointsAttribute = (newVal, val) => {
          for (const index in val) {
            newVal[`bullet_point_${index * 1 + 1}`] = val[index];
          }
          return newVal;
        };
        const updateAttribute = (newVal, val) => {
          newVal[this.type] = val;
          return newVal;
        };
        let result = this.type == "bullet_points" ? updateBulletPointsAttribute(newValue, val) : updateAttribute(newValue, val);
        this.$emit("update:modelValue", result);
      }
    },
    characterRangePerContentType() {
      return this.charactersMap[this.type] || {
        min: 150,
        max: 500
      };
    }
  },
  watch: {
    show_comparison() {
      if (this.show_comparison) {
        this.computeComparison();
      }
    }
  },
  methods: {
    ...mapActions(['contentApplySuggestedKeywords', 'simpleTranslationGet']),
    async suggestedKeywords() {
      const keywords = await this.contentApplySuggestedKeywords({
        seller_id: this.modelValue.seller_id,
        asin: this.modelValue.asin,
        marketplace_id: this.modelValue.marketplace_id,
        product_name: this.modelValue.product_name,
        product_description: this.modelValue.product_description,
        bullet_points: [this.modelValue.bullet_point_1, this.modelValue.bullet_point_2, this.modelValue.bullet_point_3, this.modelValue.bullet_point_4, this.modelValue.bullet_point_5],
        brand: this.modelValue.brand
      });
      return keywords;
    },
    onAdd(val) {
      this.omnyContent = val;
    },
    computeComparison() {
      const dmp = new DiffMatchPatch();
      if (typeof this.amazonContent === 'string') {
        const diffs = dmp.diff_main(this.amazonContent, this.omnyContent);
        dmp.diff_cleanupSemantic(diffs);
        this.amazon_text_with_diffs = generateHtmlDiff(diffs, "text1");
        this.omny_text_with_diffs = generateHtmlDiff(diffs, "text2");
        return;
      }
      this.amazon_text_with_diffs = [];
      this.omny_text_with_diffs = [];
      this.amazonContent.forEach((item, index) => {
        const diffs = dmp.diff_main(item, this.omnyContent[index]);
        dmp.diff_cleanupSemantic(diffs);
        this.amazon_text_with_diffs.push(generateHtmlDiff(diffs, "text1"));
        this.omny_text_with_diffs.push(generateHtmlDiff(diffs, "text2"));
      });
    },
    hideComparison() {
      this.$emit('hide-comparison');
    },
    hideTranslation() {
      this.$emit('hide-translation');
    }
  }
};
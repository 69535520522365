import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-277e1ccc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ba-weekly-input-container"
};
const _hoisted_2 = {
  class: "ba-weekly-budget ba-text ba-text-left"
};
const _hoisted_3 = {
  key: 1,
  style: {
    "font-size": "13px",
    "color": "rgb(0,0,0) !important"
  },
  class: "input-container"
};
const _hoisted_4 = ["disabled"];
const _hoisted_5 = {
  class: "ba-weekly-info"
};
const _hoisted_6 = {
  key: 0,
  class: "ba-weekly-badge"
};
const _hoisted_7 = {
  class: "ba-weekly-badge-price ba-text ba-text-purple ba-text-lighter"
};
const _hoisted_8 = {
  key: 1,
  class: "ba-weekly-sufix ba-text ba-text-gray"
};
const _hoisted_9 = {
  class: "ba-weekly-icon"
};
const _hoisted_10 = {
  key: 0,
  class: "budget-error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["ba-weekly-input", {
      'ba-weekly-custom': $options.isCustom,
      'ba-weekly-disabled': $props.disabled,
      'ba-weekly-no-sufix': !$props.sufix || $props.sufix === '',
      'error': $options.validation.status !== 'success'
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(['ba-weekly-value', {
      editing_value: $data.editing_value
    }, {
      'error': $options.validation.status !== 'success'
    }])
  }, [_createElementVNode("div", _hoisted_2, [!$data.editing_value ? (_openBlock(), _createBlock(_component_currency_value, {
    key: 0,
    value: $options.localModelValue,
    currency: $props.currency,
    precision: 2,
    allowPrecisionModification: false,
    onClick: $options.onCurrencyValueClick
  }, null, 8 /* PROPS */, ["value", "currency", "onClick"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createTextVNode(_toDisplayString($data.currency_symbol) + " ", 1 /* TEXT */), _withDirectives(_createElementVNode("input", {
    ref: "editInput",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.localModelValue = $event),
    type: "text",
    disabled: $props.disabled,
    onInput: _cache[1] || (_cache[1] = (...args) => $options.onInput && $options.onInput(...args)),
    onKeydown: _cache[2] || (_cache[2] = (...args) => $options.isNumber && $options.isNumber(...args)),
    onFocusout: _cache[3] || (_cache[3] = $event => $data.editing_value = false)
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4), [[_vModelText, $options.localModelValue]])]))]), _createElementVNode("div", _hoisted_5, [$options.isCustom ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_app_icon, {
    icon: "ai",
    size: 14,
    color: "#8D85E5"
  }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_currency_value, {
    value: $props.ai,
    currency: $props.currency,
    precision: 2,
    allowPrecisionModification: false
  }, null, 8 /* PROPS */, ["value", "currency"])])])) : _createCommentVNode("v-if", true), $props.sufix && $props.sufix != '' ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.sufix), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), _createElementVNode("div", _hoisted_9, [!$options.isCustom ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "ai",
    size: 16,
    color: "#5A52AE"
  })) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "ba-weekly-icon-reset",
    onClick: _cache[4] || (_cache[4] = (...args) => $options.onReset && $options.onReset(...args))
  }, [_createVNode(_component_app_icon, {
    icon: "gear",
    size: 16,
    color: "#4C8EFF",
    class: "gear"
  }), _createVNode(_component_app_icon, {
    icon: "reset",
    size: 16,
    color: "#4C8EFF",
    class: "reset"
  })]))])], 2 /* CLASS */), $options.validation.text ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createCommentVNode("alert-octagon color=\"red\" :height=\"10\" :width=\"10\"/"), _createTextVNode(" " + _toDisplayString($options.validation.text), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}
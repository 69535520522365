import { truncateString } from "@/utils";
import FilterItem from "./FilterItem.vue";
import AdFormatFilterBody from "./bodies/AdFormatFilterBody.vue";
import FiltersMixin from "@/mixins/FiltersMixin.vue";
export default {
  name: "PortfolioFilter",
  components: {
    FilterItem,
    AdFormatFilterBody
  },
  emits: ["apply", "cleared"],
  expose: ["openDropdown"],
  mixins: [FiltersMixin],
  props: {
    ad_formats: {
      type: Object,
      required: true,
      default: () => ({
        filterState: {
          selected: [],
          list: []
        },
        isModified: false,
        select: () => {},
        deselect: () => {},
        clear: () => {},
        apply: () => {},
        listSearch: () => {}
      })
    }
  },
  computed: {
    getAdFormatTitle() {
      let joinedContents = this.ad_formats.selected.map(el => el?.name).join(", ");
      joinedContents = joinedContents.length > 0 ? joinedContents : 'Ad Formats';
      return truncateString(joinedContents, 16);
    }
  },
  methods: {
    handleAdFormatSearch({
      value
    }) {
      // If value is empty, clear the searching state
      if (value.length === 0) {
        this.ad_formats.searching = false;
      }
      this.ad_formats.listSearch(value, ['ad_format']);
    },
    handleClear() {
      this.ad_formats.clear();
      this.$emit('apply');
    },
    handleSelect({
      ad_format
    }) {
      this.ad_formats.select({
        ad_format
      }, 'ad_format');
    },
    handleSelectOnly({
      ad_format
    }) {
      this.ad_formats.selectOnly({
        ad_format
      }, 'ad_format');
    },
    handleDeselect({
      ad_format
    }) {
      this.ad_formats.deselect({
        ad_format
      }, 'ad_format');
      this.$emit('apply');
    },
    handleClearAll() {
      this.ad_formats.clear();
      this.$emit('cleared');
    },
    openDropdown(initializer = async () => {}, callback = () => {}) {
      this.openAsyncFilterDropdown(this.$refs.adFormatFilterItem, initializer, callback);
    }
  }
};
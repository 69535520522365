import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterItemSelector = _resolveComponent("FilterItemSelector");
  const _component_dropdown_body = _resolveComponent("dropdown-body");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", {
    id: $props.id,
    ref: "filterItemWrapper",
    class: "filter-item-wrapper"
  }, [_createVNode(_component_b_dropdown, {
    ref: "filterDropdown",
    offset: "8",
    "no-caret": "",
    onHidden: $options.handleHidden,
    strategy: "fixed"
  }, {
    "button-content": _withCtx(() => [_createVNode(_component_FilterItemSelector, {
      icon: $props.icon,
      color: $props.color,
      iconSize: $props.iconSize,
      title: $options.getComputedTitle,
      selectedCount: $props.selectedCount,
      onClearAll: _cache[0] || (_cache[0] = $event => _ctx.$emit('clearAll')),
      noCount: $props.noCount
    }, _createSlots({
      _: 2 /* DYNAMIC */
    }, [_ctx.$slots.title ? {
      name: "title",
      fn: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
      key: "0"
    } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon", "color", "iconSize", "title", "selectedCount", "noCount"])]),
    default: _withCtx(() => [_createVNode(_component_dropdown_body, {
      class: "filter-dropdown-body"
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "body", _normalizeProps(_guardReactiveProps(_ctx.$)))]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onHidden"])], 8 /* PROPS */, _hoisted_1);
}
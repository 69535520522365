import NavigationTabs from "@/components/NavigationTabs";
import FlexColumn from "@/components/FlexColumn";
import { mapMutations, mapState, mapGetters } from "vuex";
import StrategicPeriodTable from "@/components/ppc_v2/StrategicPeriodTable";
import PerformancePeriodTable from "@/components/ppc_v2/PerformancePeriodTable";
import PerformanceCards from "@/components/kpi-cards/ppc_v2/PerformanceCards";
import AppDropdown from "@/components/AppDropdown";
import AppButtonRadioGroup from "@/components/AppButtonRadioGroup";
import { EVENTS, PPC_EXTRA_FILTERS } from "@/utils/constants";
import { DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, getDatePickerUIObjectFromDateRange } from "@/utils/datepicker";
import PPCManagementMixin from "@/mixins/PPCManagementMixin.vue";
import PpcV2Filters from "@/components/PpcV2Filters.vue";
import moment from "moment";
import PpcV2MoreFilters from "@/components/PpcV2MoreFilters.vue";
import ProductTable from "@/components/ppc_v2/ProductTable.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import PpcSettings from "@/components/ppc/PpcSettings.vue";
import PpcSettingsFilters from "@/components/ppc/PpcSettingsFilters.vue";
import CurrencyFilter from "@/components/new-filters/CurrencyFilter.vue";
export default {
  name: "PPCManagementPage",
  components: {
    NavigationTabs,
    FlexColumn,
    StrategicPeriodTable,
    PerformancePeriodTable,
    PerformanceCards,
    AppDropdown,
    AppButtonRadioGroup,
    PpcV2Filters,
    PpcV2MoreFilters,
    ProductTable,
    PpcSettingsFilters,
    PpcSettings,
    CurrencyFilter
  },
  mixins: [PPCManagementMixin, LiteTableMixin],
  beforeUnmount() {
    window.$bus.off(EVENTS.REFRESH_PPC_MANAGEMENT, () => this.refreshAllKpis());
    window.$bus.off(EVENTS.SET_MORE_FILTERS, this.handleMoreFilters);
  },
  async mounted() {
    this.$store.dispatch("ppcV2SetDefaultCurrency");
    window.$bus.on(EVENTS.REFRESH_PPC_MANAGEMENT, () => this.refreshAllKpis());
    const perf = [];
    perf.push(performance.now());
    window.$bus.on(EVENTS.SET_MORE_FILTERS, this.handleMoreFilters);
    await this.initializeSellersFilter(this.ppcSellersFilterSet);
    await this.initializeMarketplacesFilter(this.ppcMarketplacesFilterSet, true);
    this.$store.dispatch("ppcV2SetProductTableGroupByElements");
    // await this.$nextTick(() => { });
    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
      rangeTitle: this.periodGroupBy,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });
    this.storeDatePickerDate(this.defaultDatePickerDate);
    this.refreshAllKpis();
    perf.push(performance.now());
    console.log(`[Perf][PPC Rendering]: ${(perf[1] - perf[0]) / 1000} s`);
    this.fetchSubFilters({
      sellers: this.currentSellerIds,
      marketplaces: this.currentMarketplaceIds
    }, "ppc_v2", {
      product: "products"
    }, "ppc/v2");
  },
  data(ctx) {
    return {
      today: moment(),
      sellers: [],
      marketplaces: [],
      defaultDatePickerDate: null,
      periodTableLoading: true,
      productTableLoading: true,
      kpisLoading: true,
      moreFilters: false,
      activateSettings: true,
      openModal: false,
      hideSettingsFooterButtons: true,
      rightStripFeatureName: "ppcsettingsfeature",
      mediatorRefreshers: {
        KPIS: this.refreshKpis,
        PERIOD_TABLE: this.refreshPeriodTable,
        PRODUCT_TABLE: this.refreshProductTable
      },
      secondaryFilterInitializers: {
        [PPC_EXTRA_FILTERS.CAMPAIGN]: () => ctx.initializeCampaignsFilters({
          sellers: ctx.sellers_filter.filterValues,
          sales_channel: ctx.marketplaces_filter.filterValues
        }, ctx.campaigns, "ppc/v2"),
        [PPC_EXTRA_FILTERS.MATCH_TYPE]: () => ctx.initializeMatchTypesFilters({
          sellers: ctx.sellers_filter.filterValues,
          sales_channel: ctx.marketplaces_filter.filterValues
        }, ctx.match_types, "ppc/v2"),
        [PPC_EXTRA_FILTERS.TARGETING]: () => ctx.initializeTargetingsFilters({
          sellers: ctx.sellers_filter.filterValues,
          sales_channel: ctx.marketplaces_filter.filterValues
        }, ctx.targetings, "ppc/v2"),
        [PPC_EXTRA_FILTERS.AD_FORMAT]: () => ctx.initializeAdFormatsFilters({
          sellers: ctx.sellers_filter.filterValues,
          sales_channel: ctx.marketplaces_filter.filterValues
        }, ctx.ad_formats, "ppc/v2"),
        [PPC_EXTRA_FILTERS.PORTFOLIO]: () => ctx.initializePortfoliosFilters({
          sellers: ctx.sellers_filter.filterValues,
          sales_channel: ctx.marketplaces_filter.filterValues
        }, ctx.portfolios, "ppc/v2")
      }
    };
  },
  watch: {
    currentTabValue: {
      handler(newTab, oldTab) {
        if (oldTab === "settings" && ["performance", "strategic"].includes(newTab)) {
          this.refreshAllKpis();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      currentTab: state => state.ppc_v2.currentTab,
      currentTabValue: state => state.ppc_v2.currentTabValue,
      tabs: state => state.ppc_v2.tabs,
      betatabs: state => state.ppc_v2.betatabs,
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isOmnyUser: state => state.auth.isOmnyUser,
      currentPeriod: state => state.ppc_v2.currentPeriod,
      periodSelectorOptions: state => state.ppc_v2.periodSelectorOptions,
      floatingTableState: state => state.ppc_v2.product_table.state,
      productTableGroupBy: state => state.ppc_v2.product_table.group_by_text,
      productTable: state => state.ppc_v2.product_table,
      currencies: state => state.ppc_v2.currencies,
      sellers_filter: state => state.ppc_v2.sellers_filter,
      marketplaces_filter: state => state.ppc_v2.marketplaces_filter,
      products: state => state.ppc_v2.products,
      match_types: state => state.ppc_v2.match_types,
      campaigns: state => state.ppc_v2.campaigns,
      targetings: state => state.ppc_v2.targetings,
      portfolios: state => state.ppc_v2.portfolios,
      ad_formats: state => state.ppc_v2.ad_formats
    }),
    ...mapGetters(["ppcV2GetPeriod", "getSystemWeekType", "ppcV2SecondaryFiltersEnabled", "ppcV2UserOnSettingsPage"]),
    currentSellerIds() {
      return this.sellers_filter.filterItems.map(seller => seller.id);
    },
    currentMarketplaceIds() {
      return this.marketplaces_filter.filterItems.filter(({
        sales_channel
      }) => sales_channel !== "-").map(marketplace => marketplace.sales_channel);
    },
    period: {
      set(value) {
        this.$store.dispatch("ppcV2SetPeriod", value);
        this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
          ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
          rangeTitle: this.periodGroupBy,
          compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
        });
        this.storeDatePickerDate(this.defaultDatePickerDate);
        this.refreshMediator(["PERIOD_TABLE", "KPIS", "PRODUCT_TABLE"]);
      },
      get() {
        return this.ppcV2GetPeriod();
      }
    },
    tab: {
      async set(value) {
        this.$store.dispatch("ppcV2SetTab", value);
      },
      get() {
        return this.currentTab;
      }
    },
    periodGroupBy() {
      return this.getPeriodGroupFromCurrentPeriod(this.currentPeriod, this.period);
    }
  },
  methods: {
    ...mapMutations(["ppcSellersFilterSet", "ppcMarketplacesFilterSet"]),
    getAdvertisingTabs() {
      return this.isOmnyAdmin || this.isOmnyUser ? this.tabs : this.tabs;
    },
    storeDatePickerDate(date) {
      this.$store.dispatch("ppcV2SetProductTablePeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
    },
    async refreshKpis() {
      this.kpisLoading = true;
      await this.$store.dispatch("ppcV2FetchKpis");
      this.kpisLoading = false;
    },
    async refreshPeriodTable() {
      this.$store.dispatch("ppcV2SetPeriodTableErrorState", false);
      this.periodTableLoading = true;
      await this.$store.dispatch("ppcV2FetchPeriodTableData");
      this.periodTableLoading = false;
    },
    async refreshProductTable() {
      if (this.currentTabValue !== "settings") {
        this.productTableLoading = true;
        this.$store.dispatch("ppcV2SetProductTableErrorState", false);
        const groupByFetch = this.productTable[this.currentTabValue].data[this.productTableGroupBy]?.fetch;
        console.log("found groupBy method", groupByFetch);
        if (groupByFetch) await this.$store.dispatch(groupByFetch);
        this.productTableLoading = false;
      }
    },
    async refreshMediator(refresheeList) {
      const perf = [];
      perf.push(performance.now());
      refresheeList.forEach(refreshee => {
        this.mediatorRefreshers[refreshee]();
      });
      perf.push(performance.now());
      console.log(`[perf][refreshMediator]: ${(perf[1] - perf[0]) / 1000} s`);
    },
    handleMoreFilters(value) {
      this.moreFilters = value;
    },
    handleErrorOccured() {
      this.announceNoAccounts();
      this.periodTableLoading = false;
      this.productTableLoading = false;
      this.kpisLoading = false;
    },
    async handleDateChange(date) {
      if (!date) {
        date = this.defaultDatePickerDate;
      }
      this.storeDatePickerDate(date);
      await this.refreshMediator(["KPIS", "PRODUCT_TABLE"]);
    },
    handleSetPeriodTablePeriod(date) {
      this.handleSetLiteTablePeriod(date, this.period, this.handleDateChange);
    },
    async handleProductTableSort({
      sort,
      direction
    }) {
      this.$store.dispatch("ppcV2SetProductTableSort", {
        sort,
        direction
      });
      await this.refreshMediator(["PRODUCT_TABLE"]);
    },
    handleProductTableRefresh() {
      this.refreshAllKpis();
    },
    getFeatureName() {
      return "ppcsettingsfeature";
    },
    refreshAllKpis() {
      this.refreshMediator(["KPIS", "PRODUCT_TABLE", "PERIOD_TABLE"]);
    },
    handleApplyForCurrencyChange() {
      if (this.currencies.isModified) {
        this.currencies.apply();
        this.$store.dispatch("currencyConfigSetCurrency", this.currencies.filterValues[0]);
        this.refreshAllKpis();
      }
    }
  }
};
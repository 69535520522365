import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "product-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_img = _resolveComponent("b-img");
  const _component_image_overlayer = _resolveComponent("image-overlayer");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    class: "parent-product-value",
    loading: $props.loading,
    align: $props.align,
    styles: {
      width: '100%',
      padding: $options.padding
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_flex_row, {
      gap: 2
    }, {
      default: _withCtx(() => [$props.hasChildren ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [$props.state ? (_openBlock(), _createBlock(_component_app_icon, {
        key: 0,
        onClick: $options.handleToggle,
        class: "arrow active",
        icon: "chevron-down2",
        color: "#616161",
        size: 20
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createBlock(_component_app_icon, {
        key: 1,
        onClick: $options.handleToggle,
        class: "arrow",
        icon: "chevron-right2",
        color: "#616161",
        size: 20
      }, null, 8 /* PROPS */, ["onClick"]))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_image_overlayer, {
        count: $props.count,
        overlays: 4
      }, {
        default: _withCtx(() => [_createVNode(_component_b_img, {
          lazy: "",
          src: $props.value?.image ?? '/assets/images/amazon-logo-transparent.svg',
          class: "custom-img",
          height: "22.5",
          width: "22.5"
        }, null, 8 /* PROPS */, ["src"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["count"]), _createVNode(_component_flex_column, {
        classes: "product",
        gap: 2,
        styles: {
          width: '100%',
          padding: 0,
          overflow: 'hidden'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          width: "495px",
          customClass: "product-value-popover"
        }, {
          title: _withCtx(() => [_createTextVNode(_toDisplayString($props.value.name), 1 /* TEXT */)]),
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass(['name', {
              'opened': $props.state
            }])
          }, _toDisplayString($options.productName), 3 /* TEXT, CLASS */)]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      })])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "align", "styles"]);
}
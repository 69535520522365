import AppIcon from '@/components/AppIcon';
import PlayIcon from '@/components/icons/PlayIcon.vue';
import PauseIcon from '@/components/icons/PauseIcon.vue';
export default {
  name: 'AppSwitch',
  emits: ['update:modelValue', 'change'],
  components: {
    AppIcon,
    PauseIcon,
    PlayIcon
  },
  props: {
    modelValue: {
      type: [Boolean, String],
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    between: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null
    };
  },
  computed: {
    value: {
      get() {
        let value = this.modelValue;
        if (this.between) {
          value = this.options.findIndex(option => option.value === this.modelValue) === 1;
        }
        return value;
      },
      set(val) {
        let value = val;
        if (this.between) {
          value = val ? this.options[1].value : this.options[0].value;
        }
        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  },
  methods: {
    onChange(e) {
      let value = e.target.checked;
      if (this.between) {
        value = e.target.checked ? this.options[1].value : this.options[0].value;
      }
      this.$emit('change', value);
    }
  }
};
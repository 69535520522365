import HelperMixin from "@/mixins/HelperMixin";
import ValidateMixin from '@/mixins/ValidateMixin';
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import PpcBudgetAllocationWeeklyBudgetInput from "@/components/PpcBudgetAllocationWeeklyBudgetInput";
import PpcBudgetAllocationWeeklyBudgetCampaign from "@/components/PpcBudgetAllocationWeeklyBudgetCampaign";
export default {
  name: "PpcBudgetAllocationWeeklyBudgetEdit",
  emits: ["cancel", "apply"],
  mixins: [HelperMixin, ValidateMixin],
  components: {
    AppButton,
    AppIcon,
    PpcBudgetAllocationWeeklyBudgetInput,
    PpcBudgetAllocationWeeklyBudgetCampaign
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ""
    },
    currency: {
      type: String
    },
    country_code: {
      type: String
    }
  },
  data() {
    return {
      advanced: false,
      localModel: this.data.budgetCurrent,
      localCampaigns: this.data.campaigns
    };
  },
  computed: {
    /* isMainInputDisabled() {
      return (
        this.localCampaigns.some(
          (item) => item.budgetCurrent != item.budgetCalculated
        ) && this.localModel != this.data.budgetAi
      );
    }, */
    campaignPotentialBudgetsSum() {
      let campaignPotentialBudgetsSum = this.localCampaigns.reduce((accumulator, campaign) => accumulator + campaign?.potential ?? 0, 0);
      return campaignPotentialBudgetsSum;
    },
    applyButtonDisabled() {
      return this.getApplyButtonDisabled();
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.localModel = this.data.budgetCurrent;
        this.localCampaigns = this.data.campaigns;
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    onApply() {
      this.$emit("apply", {
        type: this.type,
        current: this.localModel,
        campaigns: this.localCampaigns
      });
    },
    onAdvancedToggle() {
      this.advanced = !this.advanced;
    },
    onUpdateCampaign(campaign) {
      this.localCampaigns = this.localCampaigns.map(item => {
        if (item.id === campaign.id) {
          return campaign;
        }
        return item;
      });
      this.localModel = this.numberFormatter(this.localCampaigns.reduce((acc, item) => acc + item.budgetCurrent * 1, 0), 2);
    },
    onInputUpdate(value) {
      this.localCampaigns = this.localCampaigns.map(item => {
        if (this.localModel == this.data.budgetAi) {
          item.budgetCurrent = item.budgetAi;
          item.budgetCalculated = item.budgetAi;
        } else {
          const percent = item.potential / this.campaignPotentialBudgetsSum;
          item.budgetCurrent = this.numberFormatter(value * percent, 2);
          item.budgetCalculated = this.numberFormatter(value * percent, 2);
        }
        return item;
      });
    },
    getApplyButtonDisabled() {
      const validationArray = [];
      validationArray.push(!!this.mValidatePPCBudgetWeekly(this.localModel, this.country_code, 2).text);
      this.localCampaigns.forEach(campaign => {
        validationArray.push(!!this.mValidatePPCBudgetWeekly(campaign.budgetCurrent, this.country_code, 1).text);
      });
      return validationArray.some(value => value === true);
    }
  }
};
import KeySettingsIcon from "@/components/icons/KeySettingsIcon.vue";
import StrokedStarIcon from "@/components/icons/StrokedStarIcon.vue";
import StrokedPercentIcon from "@/components/icons/StrokedPercentIcon.vue";
import LayoutIcon from "@/components/icons/LayoutIcon.vue";
import CertificateIcon from "@/components/icons/CertificateIcon.vue";
import StrokedCartIcon from "@/components/icons/StrokedCartIcon.vue";
import MarketplaceIcon from "@/components/icons/MarketplaceIcon.vue";
import ProductsIcon from "@/components/icons/ProductsIcon.vue";
import TrendingUpIcon from "@/components/icons/TrendingUpIcon.vue";
import MessageSquareIcon from "@/components/icons/MessageSquareIcon.vue";
import BracketsCheckIcon from "@/components/icons/BracketsCheckIcon.vue";
import BracketsPlusIcon from "@/components/icons/BracketsPlusIcon.vue";
import ZoomOutIcon from "@/components/icons/ZoomOutIcon.vue";
import EqualsIcon from "@/components/icons/EqualsIcon.vue";
import TargetIcon from "@/components/icons/TargetIcon.vue";
import MaximizeIcon from "@/components/icons/MaximizeIcon.vue";
import MinimizeIcon from "@/components/icons/MinimizeIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import SheetIcon from "@/components/icons/SheetIcon.vue";
import CSVIcon from "@/components/icons/CSVIcon.vue";
import ApplyKeywordsIcon from "@/components/icons/ApplyKeywordsIcon.vue";
import AnnotationAlertIcon from "@/components/icons/AnnotationAlertIcon.vue";
import SettingHexagonIcon from "@/components/icons/SettingHexagonIcon.vue";
import AmazonIcon from "@/components/icons/AmazonIcon.vue";
export default {
  KeySettingsIcon,
  StrokedStarIcon,
  StrokedPercentIcon,
  LayoutIcon,
  CertificateIcon,
  StrokedCartIcon,
  MarketplaceIcon,
  ProductsIcon,
  TrendingUpIcon,
  MessageSquareIcon,
  BracketsCheckIcon,
  BracketsPlusIcon,
  ZoomOutIcon,
  EqualsIcon,
  TargetIcon,
  MaximizeIcon,
  MinimizeIcon,
  MinusIcon,
  SettingsIcon,
  SheetIcon,
  CSVIcon,
  ApplyKeywordsIcon,
  AnnotationAlertIcon,
  SettingHexagonIcon,
  AmazonIcon
};
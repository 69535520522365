import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { EVENTS, isObjectEmpty, asyncWait } from "@/utils";
import Filter from "@/store/filter";
import moment from "moment";
import ValidateMixin from "@/mixins/ValidateMixin";
import TasksMixin from "@/mixins/TasksMixin.vue";
import AppInput from "@/components/AppInput";
import DemandForecastingTop from "@/components/DemandForecastingTop";
import DemandForecastingContent from "@/components/DemandForecastingContent";
import DemandForecastingSidebar from "@/components/DemandForecastingSidebar";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
import DemandForecastingMixins from "@/mixins/DemandForecastingMixins.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "DemandForecastingPage",
  mixins: [AccountsMixin, ValidateMixin, DemandForecastingMixins, DownloadMixin, InitializeFiltersMixin, TasksMixin],
  components: {
    AppInput,
    DemandForecastingTop,
    DemandForecastingContent,
    DemandForecastingSidebar,
    ErrorOccured
  },
  data() {
    return {
      dataLoading: false,
      errorOccured: false,
      page: 1,
      perPage: 20,
      search: "",
      sortField: "weighted_30d_velocity",
      sortDirection: "desc",
      isSideBarCollapsed: true,
      selected: [],
      currentSettings: {},
      sortValueForKey: {
        raw_quantity_to_send: this.sortByRawQtyValue,
        product_name: this.sortByObjectValue,
        weighted_30d_velocity: this.sortByObjectValue
      },
      sheet_generationTaskIdentifier: ""
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      demand_forecasting: state => state.demand_forecasting.list,
      total_rows: state => state.demand_forecasting.total_rows,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.demand_forecasting.sellers_filter,
      isOmnyWorkspace: state => state.workspace.isOmnyWorkspace,
      scaleType: state => state.workspace.scaleType
    }),
    ...mapGetters(["dfSellersFilterValuesIdsGet", "dfGetProductSkus", "dfGetProductAsins", "dfGetProductIds", "demandForecastingSettingsForAccountGet"]),
    dfDataInitialized() {
      return !isObjectEmpty(this.demand_forecasting);
    },
    dfFiltered() {
      return this.demand_forecasting.sort((a, b) => {
        const aValue = this.sortValueForKey[this.sortField](a);
        const bValue = this.sortValueForKey[this.sortField](b);
        if (this.sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else if (this.sortDirection === "desc") {
          return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
        return 0;
      }).filter(item => {
        const s = this.search.trim().toLowerCase();
        if (s != "") {
          return item?.sku?.toLowerCase().includes(s) || item?.asin?.toLowerCase().includes(s) || item?.product_name?.toLowerCase().includes(s) || item?.external_sku?.toLowerCase().includes(s);
        }
        return true;
      });
    },
    selectedFilteredSkus() {
      return this.selected.filter(sku => {
        return this.dfFiltered.find(el => el.sku == sku && el);
      });
    },
    selectedFilteredItems() {
      return this.dfFiltered.filter(item => this.selected.includes(item.sku));
    },
    productsToOrderFiltered() {
      let product_list_to_order = this.selectedFilteredItems.length ? this.selectedFilteredItems : this.dfFiltered;
      return product_list_to_order.filter(item => this.productPackageDimension(item) && (item.manual_override ? item.orderable_units : this.rawQuantityToSendRule(item, this.currentSettings)) > 0);
    },
    selectedSellerAccountId() {
      return this.dfSellersFilterValuesIdsGet().slice(-1).pop();
    },
    csvValueForHeader() {
      return {
        sku: this.attributeFromItem,
        asin: this.attributeFromItem,
        external_sku: this.attributeFromItem,
        product_name: this.attributeFromItem,
        main_image_link: this.attributeFromItem,
        product_package_dimension: this.productPackageDimension,
        product_package_length_cm: this.attributeConvertedValueFromItemCM,
        product_package_width_cm: this.attributeConvertedValueFromItemCM,
        product_package_height_cm: this.attributeConvertedValueFromItemCM,
        product_package_weight_kg: this.attributeConvertedValueFromItemLB,
        units_per_box: this.attributeFromItem,
        box_length_cm: this.attributeConvertedValueFromItemCM,
        box_width_cm: this.attributeConvertedValueFromItemCM,
        box_height_cm: this.attributeConvertedValueFromItemCM,
        box_weight_kg: this.attributeConvertedValueFromItemLB,
        expiration_date_required: this.attributeFromItem,
        preparation_required: this.attributeFromItem,
        quantity_to_order_unitary_sku: this.quantityUnitarySkuBoxNonRounded,
        quantity_unitary_sku_box_rounded: this.quantityUnitarySkuBox,
        quantity_boxes: this.quantityBoxes
      };
    },
    unitLength() {
      return this.scaleType === 'imperial' ? 'in' : 'cm';
    },
    unitWeight() {
      return this.scaleType === 'imperial' ? 'lb' : 'kg';
    },
    csv_headers() {
      return {
        seller_sku: "sku",
        asin: "asin",
        external_sku: "external_sku",
        product_name: "product_name",
        image_url: "main_image_link",
        product_package_dimension: "product_package_dimension",
        [`product_package_length_${this.unitLength}`]: "product_package_length_cm",
        [`product_package_width_${this.unitLength}`]: "product_package_width_cm",
        [`product_package_height_${this.unitLength}`]: "product_package_height_cm",
        [`product_package_weight_${this.unitWeight}`]: "product_package_weight_kg",
        units_per_box: "units_per_box",
        [`box_length_${this.unitLength}`]: "box_length_cm",
        [`box_width_${this.unitLength}`]: "box_width_cm",
        [`box_height_${this.unitLength}`]: "box_height_cm",
        [`box_weight_${this.unitWeight}`]: "box_weight_kg",
        expiration_date_required: "expiration_date_required",
        preparation_required: "preparation_required",
        quantity_to_order_unitary_sku: "quantity_to_order_unitary_sku",
        quantity_unitary_sku_box_rounded: "quantity_unitary_sku_box_rounded",
        "quantity_boxes": "quantity_boxes"
      };
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.initializeSellersFilter(this.demandForecastingSellersFilterSet);
      this.sellers_filter.selectOnly(this.sellers_filter.filterItems.slice(-1).pop(), "id");
      await this.refreshPage();
      window.$bus.on(EVENTS.CHANGE_PRODUCT_DATA_SCALE, this.handleProductDataScaleChange);
    }
  },
  beforeUnmount() {
    if (this.asyncUpdateInterval) {
      clearTimeout(this.asyncUpdateInterval);
    }
  },
  methods: {
    ...mapMutations(["demandForecastingListSet", "demandForecastingSellersFilterSet"]),
    ...mapActions(["accountList", "demandForecastingList", "authStatus", "workspaceList", "workspaceCurrent", "inventoryUpdateProductData", "inventoryProductDataList", "inventoryGetDemandForecastingSettings", "inventoryCreateOrderCsv", "inventoryCreateOrderGoogleSheet", "setScaleTypeFromUser"]),
    onSelect(values) {
      this.selected = values;
    },
    onIsSidebarCollapsed($event) {
      this.isSideBarCollapsed = $event;
    },
    sortByObjectValue(obj) {
      return obj[this.sortField];
    },
    sortByRawQtyValue(obj) {
      return Math.round(this.rawQuantityToSendRule(obj, this.currentSettings));
    },
    onSort(values) {
      this.sortField = values[0];
      this.sortDirection = values[1];
    },
    onUpdate(items) {
      this.demandForecastingListSet(items);
    },
    async onSaveProductData(data) {
      await this.inventoryUpdateProductData(data);
    },
    async changePage(newPage) {
      this.page = newPage;
      await this.refreshContent();
    },
    async changeElements(elementsPerPage) {
      this.page = 1;
      this.perPage = elementsPerPage;
      await this.refreshContent();
    },
    async refreshContent() {
      try {
        //this.dataLoading = true;
        this.currentSettings = this.demandForecastingSettingsForAccountGet();
        let total_coverage = Math.round(this.totalCoverageFromSettings(this.currentSettings));
        let total_lead_time = this.totalLeadTimeFromSettings(this.currentSettings);
        let settings_for_query = {
          ...this.currentSettings,
          total_lead_time: total_lead_time,
          total_coverage: total_coverage
        };
        let result = await this.demandForecastingList({
          seller_id: this.dfSellersFilterValuesIdsGet(),
          demand_forecasting_settings: settings_for_query
          //page: this.page,
          //page_size: this.perPage,
        });
        this.throwIfError(result);
        if (!isObjectEmpty(result)) {
          result = await this.inventoryProductDataList({
            seller_list: this.dfSellersFilterValuesIdsGet(),
            sku_list: this.dfGetProductSkus(),
            asin_list: this.dfGetProductAsins(),
            product_id_list: this.dfGetProductIds()
          });
          this.throwIfError(result);
        }
        //this.dataLoading = false;
        this.errorOccured = false;
      } catch (error) {
        this.errorOccured = true;
      }
    },
    async refreshSettings() {
      try {
        let result = await this.inventoryGetDemandForecastingSettings({
          seller_id: this.dfSellersFilterValuesIdsGet()
        });
        this.throwIfError(result);
        this.errorOccured = false;
      } catch (error) {
        this.errorOccured = true;
      }
    },
    async refreshPage() {
      await this.refreshSettings();
      await this.refreshContent();
      window.$bus.on(EVENTS.REFRESH_DEMAND_FORECASTING_SETTINGS, this.refreshSettings);
      window.$bus.on(EVENTS.REFRESH_DEMAND_FORECASTING_PAGE, this.refreshContent);
    },
    createFileNameAndCSVRows() {
      const fileName = "purchase_order_" + this.selectedSellerAccountId + "_" + moment().format("YYYY_MM_DD").toString();
      const csvRows = this.productsToOrderFiltered.map(row => Object.fromEntries(new Map(Object.keys(this.csv_headers).map(fieldName => [this.csv_headers[fieldName], this.csvValueForHeader[this.csv_headers[fieldName]](row, this.currentSettings, this.csv_headers[fieldName])]))));
      return {
        file_name: fileName,
        csv_rows: csvRows
      };
    },
    async onCreateInventoryOrder() {
      try {
        const fileNameAndCSVRows = this.createFileNameAndCSVRows();
        let csv_string = await this.generateCsvString(this.csv_headers, fileNameAndCSVRows.csv_rows);
        this.throwIfError(csv_string);
        this.downloadCsv(csv_string, fileNameAndCSVRows.file_name + ".csv");
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
      }
    },
    async onGenerateGoogleSheet(value) {
      const fileNameAndCSVRows = this.createFileNameAndCSVRows();
      const payload = {
        sheet_name: fileNameAndCSVRows.file_name,
        data: fileNameAndCSVRows.csv_rows,
        unit_type: this.scaleType,
        sheet_type: value,
        workspace_id: this.current_workspace.workspace_id,
        task_type: "google_sheet_generation",
        account_id: this.selectedSellerAccountId
      };
      console.log("[sending payload to server to translate]");
      console.log(payload);
      console.log("[task_id before sending to server]");
      console.log(`${this.current_workspace.workspace_id}_google_sheet_generation_${this.selectedSellerAccountId}`);
      console.log("[translation task identifier value]");
      console.log(this.sheet_generationTaskIdentifier);
      const data = await this.inventoryCreateOrderGoogleSheet(payload);
      if (data) {
        if (data.task_id === `${this.current_workspace.workspace_id}_google_sheet_generation_${this.selectedSellerAccountId}`) {
          console.log("[server has started running the google sheet process]");
          this.sheet_generationTaskIdentifier = `${this.current_workspace.workspace_id}_google_sheet_generation_${this.selectedSellerAccountId}`;
          console.log("[server task type]");
          this.executeTaskStatusHandler(data);
          this.checkTaskStatusPeriodically(this.sheet_generationTaskIdentifier);
        }
      }
    },
    async handleProductDataScaleChange(newScaleType) {
      console.log("[handleProductDataScaleChange]");
      await this.setScaleTypeFromUser(newScaleType);
    },
    handleIdle() {
      // this.getAsyncUpdate(this.sheet_generationTaskIdentifier);
    },
    handleRunning() {
      this.checkTaskStatusPeriodically(this.sheet_generationTaskIdentifier);
    },
    handleFailed(data) {
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showDanger", {
        visible: true,
        message: "Google sheet generation Task failed \n" + data.task_result,
        delay: 60 // Show alert less than 60 secs
      });
      throw new Error("Google sheet generation Task failed");
    },
    async handleFinished(data) {
      const result = data.task_result;
      console.log("sheet link: ", result.sheet_link);
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: "The google sheet has been successfully generated. A link has been sent to your email.",
        delay: 60 // Show alert less than 60 secs
      });
      await asyncWait(6000);
      window.open(result.sheet_link, "_blank");
    }
  }
};
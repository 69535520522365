import { mapState, mapMutations, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppForm from "@/components/AppForm.vue";
import AppInput from "@/components/AppInput.vue";
import AppButton from "@/components/AppButton.vue";
import TheWrapperAccountCreation from "@/components/TheWrapperAccountCreation.vue";
export default {
  name: "OnboardPage",
  mixins: [ValidateMixin],
  components: {
    AppForm,
    AppInput,
    AppButton,
    TheWrapperAccountCreation
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        password: "",
        confirm_password: "",
        should_receive_budget_end_notification: true,
        should_receive_budget_change_notification: true
      },
      loadingData: true,
      loading: false,
      showSuccessMessage: false,
      showFailedMessage: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token
    })

    // isLoggedIn() {
    //   return !! this.token
    // },
  },
  async mounted() {
    var is_authenticated = await this.authStatus(false);
    this.loadingData = true;
    this.showSuccessMessage = false;
    this.showFailedMessage = false;
    if (is_authenticated) {
      return this.$router.replace("/");
    }
    var member = await this.memberCheck();
    this.loadingData = false;
    if (!member || !member.email) {
      this.showFailedMessage = true;
      return; // stop execution as we had an error
    }
    this.form["email"] = member.email;
  },
  methods: {
    ...mapMutations(["authTokenSet"]),
    ...mapActions(["authCreate", "memberCheck", "authStatus"]),
    async onSubmit() {
      // Check if password and confirm password are the same
      if (this.form.password !== this.form.confirm_password) {
        window.$bus.trigger("showDanger", {
          visible: true,
          message: "New Password and confirm password are not the same."
        });
        return;
      }
      const valid = await this.mValidate();
      if (valid) {
        this.loading = true;
        const result = await this.authCreate(this.form);
        if (result) {
          this.showSuccessMessage = true;
        } else {
          this.showFailedMessage = true;
        }
        this.loading = false;
      }
    },
    onConnect() {
      //this.authTokenSet('auth-token')
      this.$router.push("/");
    }
  }
};
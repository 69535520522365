import { mapGetters } from 'vuex';
import moment from 'moment';
import AppIcon from '@/components/AppIcon';
import AlertsStatus from '@/components/AlertsStatus';
export default {
  name: 'AlertsSidebar',
  emits: ['update:modelValue', 'select', 'close', 'expand'],
  components: {
    AppIcon,
    AlertsStatus
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: [String, Number],
      default: null
    },
    selectedRowId: {
      type: String,
      default: null
    },
    selectedLevel: {
      type: String,
      default: null
    },
    selectedType: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(['getCountryFlag']),
    title() {
      switch (this.selectedLevel) {
        case '1_URGENCY':
          return 'Urgent';
        case '2_ALERT':
          return 'Alert';
        case '3_WARNING':
          return 'Warning';
        default:
          return 'Unknown';
      }
    },
    name() {
      const item = this.items.find(item => {
        const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
        return item[idField] == this.selectedRowId;
      });
      return item ? this.selectedType == 'sellers' ? item.seller_name : item.alerting_type : 'Unknown';
    },
    levelNumber() {
      return this.items.filter(item => {
        if (item.alerting_category == this.selectedLevel) {
          const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
          if (item[idField] == this.selectedRowId) {
            return true;
          }
        }
        return false;
      }).length;
    },
    typeNumber() {
      return this.items.filter(item => {
        const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
        if (item[idField] == this.selectedRowId) {
          return true;
        }
        return false;
      }).length;
    },
    contentItems() {
      const items = this.items.filter(item => {
        if (item.alerting_category == this.selectedLevel) {
          const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
          if (item[idField] == this.selectedRowId) {
            return true;
          }
        }
        return false;
      });
      return items.reduce((acc, value) => {
        const idField = this.selectedType == 'categories' ? 'seller_id' : 'alerting_type';
        const nameField = this.selectedType == 'categories' ? 'seller_name' : 'alerting_type';
        let el = acc.find(item => item.id === value[idField]);
        if (!el) {
          el = {
            id: value[idField],
            name: value[nameField],
            items: []
          };
        }
        el.items.push(value);
        return [...acc.filter(item => item.id !== el.id), el];
      }, []);
    },
    createdAt() {
      return date => {
        return moment(date).format('D MMM');
      };
    }
  },
  methods: {
    onToggleId(id) {
      this.$emit('select', id);
    },
    onClose() {
      this.$emit('close');
    },
    onExpand() {
      let rowId = this.selectedType == 'categories' ? this.selectedRowId : null;
      if (!rowId && this.selectedId) {
        rowId = this.items.find(item => item.id == this.selectedId).alerting_type;
      }
      this.$emit('expand', {
        rowId,
        tab: this.selectedLevel
      });
    }
  }
};
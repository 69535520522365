import ProfileInformation from "@/components/ProfileInformation";
import ProfilePassword from "@/components/ProfilePassword";
import ProfileNotificationSettings from "@/components/ProfileNotificationSettings.vue";
export default {
  name: "ProfilePage",
  components: {
    ProfileInformation,
    ProfilePassword,
    ProfileNotificationSettings
  }
};
export const ABORT_MESSAGES = {
  IN_PROGRESS: 'Request in progress'
};
export const STATUS = {
  ABORTED: 'aborted',
  COMPLETED: 'completed'
};
export const MODULES = {
  DASHBOARD: 'dashboard',
  PRODUCT_ANALYTICS: 'productAnalytics'
};
export const KPIS_TABS = ['Global', 'Product', 'Advertising', 'Sellers', 'Country'];
export const KPIS_VIEWS = ['Performance', 'Advertising', 'Pricing and Margin', 'Inventory Coverage', 'Targeting', 'Campaigns'];
export const ERRORS = {
  INVALID_ROUTE: 'Invalid route'
};
export const CREDENTIALS_ERROR = {
  WRONG_CREDENTIALS_ERROR: 'Wrong username or password. Please try again.',
  WRONG_CREDENTIALS_ERROR_SERVER: 'Sorry Login has failed due to internal error, please contact support.'
};
export const EVENTS = {
  REFRESH_APPLICATION: 'refreshApplication',
  GLOBAL_PERFORMANCE: 'refreshGlobalPerformance',
  GLOBAL_ADVERTISING: 'refreshGlobalAdvertising',
  COUNTRY_PERFORMANCE: 'refreshCountryPerformance',
  COUNTRY_ADVERTISING: 'refreshCountryAdvertising',
  PRODUCT_PERFORMANCE: 'refreshProductPerformance',
  PRODUCT_ADVERTISING: 'refreshProductAdvertising',
  PRODUCT_INVENTORY_COVERAGE: 'refreshInventoryCoverage',
  PRODUCT_PRICING_MARGIN: 'refreshPricingAndMargin',
  ADVERTISING_TARGETING: 'refreshAdvertisingTargeting',
  ADVERTISING_CAMPAIGNS: 'refreshAdvertisingCampaigns',
  SELLERS_PERFORMANCE: 'refreshSellersPerformance',
  SELLERS_ADVERTISING: 'refreshSellersAdvertising',
  SIDEBAR_CHANGED: 'sidebarChanged',
  CLOSE_SIDEBAR: 'closeSidebar',
  OPEN_SIDEBAR: 'openSidebar',
  RESET_DATE_PICKER: 'resetDatePicker',
  CALENDAR_AUTO_SCROLL: 'calendarAutoScroll',
  REFRESH_PPC_SELLER_FILTER: 'refreshPpcSellerFilter',
  REFRESH_PPC_MARKETPLACE_FILTER: 'refreshPpcMarketplaceFilter',
  REFRESH_PPC_PRODUCT_FILTER: 'refreshPpcProductFilter',
  REFRESH_PPC_MATCH_TYPE_FILTER: 'refreshPpcMatchTypeFilter',
  REFRESH_PPC_MANAGEMENT: 'refreshPpcManagement',
  REFRESH_SELLERS_SUB_FILTERS: 'refreshSellersSubFilters',
  REFRESH_SETTINGS_SELLERS_SUB_FILTERS: 'refreshSettingsSellersSubFilters',
  REFRESH_MARKETPLACES_SUB_FILTERS: 'refreshMarketplacesSubFilters',
  REFRESH_SETTINGS_MARKETPLACES_SUB_FILTERS: 'refreshSettingsMarketplacesSubFilters',
  REFRESH_PPC_TOP_SECTION_FILTERS: 'refreshPpcTopSectionFilters',
  CLOSE_FILTER_DROPDOWN: 'closeFilterDropdown',
  CLOSE_PRODUCT_TABLE_COLLAPSE: 'closeProductTableCollapse',
  OPEN_PRODUCT_TABLE_COLLAPSE: 'openProductTableCollapse',
  REFRESH_PPC_SETTINGS_BUDGET_STRATEGY: 'refreshPpcBudgetStrategy',
  REFRESH_PPC_SETTINGS: 'refreshPpcSettings',
  REFRESH_PPC_SETTINGS_BID_SETTINGS: 'refreshPpcBidSettings',
  REFRESH_PPC_SETTINGS_PRODUCT_PRIORITIZATION: 'refreshPpcProductPrioritization',
  REFRESH_PPC_SETTINGS_BID_SIMULATOR: 'refreshPpcBidSimulator',
  REFRESH_PPC_WEEKS_RANGE: 'refreshPpcWeeksRange',
  PPC_STRATEGY_AUTO_SCROLL: 'ppcStrategyAutoScroll',
  PPC_HANDLE_COLLAPSED: 'ppcHandleCollapsed',
  REFRESH_PPC_SETTINGS_SELLERS_FILTER: 'refreshSellersFilter',
  REFRESH_PPC_SETTINGS_MARKETPLACES_FILTER: 'refreshMarketPlacesFilter',
  // ProductTableGroupBy
  ADD_SELLER_GROUPBY: 'addSellersGroupBy',
  ADD_MARKETPLACE_GROUPBY: 'addMarketplacesGroupBy',
  // Profit and Loss
  REFRESH_PROFIT_AND_LOSS: 'refreshProfitAndLoss',
  REFRESH_PROFIT_AND_LOSS_SELLERS_SUB_FILTERS: 'refreshProfitAndLossSellersSubFilters',
  PROFIT_AND_LOSS_DATE_PICKER_SUBMIT: 'profitAndLossDatePickerSubmit',
  REFRESH_DEMAND_FORECASTING_PAGE: 'refreshDemandForecastingPage',
  REFRESH_PRODUCT_DATA_PAGE: 'refreshProductDataPage',
  REFRESH_COGS_DATA_PAGE: 'refreshCogsDataPage',
  CHANGE_PRODUCT_DATA_SCALE: 'handleProductDataScaleChange',
  REFRESH_DEMAND_FORECASTING_SETTINGS: 'refreshDemandForecastingSettings',
  // DashboardV2
  REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS: 'refreshDashboardV2SellersSubFilters',
  REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS: 'refreshMarketsV2SellersSubFilters',
  REFRESH_DASHBOARD_V2: 'refreshDashboardV2',
  OPEN_DASHBOARD_V2_PRODUCT_TABLE: 'openDashboardV2ProductTable',
  // Floating Compound Table
  FLOATING_COMPOUND_TABLE_CLOSE_COLLAPSE: 'FLOATING_COMPOUND_TABLE_CLOSE_COLLAPSE',
  FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE: 'FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE',
  FLOATING_COMPOUND_TABLE_UPDATE_TABLE_HEIGHT: 'FLOATING_COMPOUND_TABLE_UPDATE_TABLE_HEIGHT',
  // Datepicker
  SETUP_DATE_PICKER: "SETUP_DATE_PICKER",
  REFRESH_SEO_PAGE: "REFRESH_SEO_PAGE",
  REFRESH_SEO_PAGE_COUNTRIES: "REFRESH_SEO_PAGE_COUNTRIES",
  REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS: "REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS",
  REFRESH_SEO_PAGE_CONTENT_TABLE: "REFRESH_SEO_PAGE_CONTENT_TABLE",
  SEO_PUSH_MODAL_OPEN: "SEO_PUSH_MODAL_OPEN",
  SEO_PUSH_MODAL_CANCEL: "SEO_PUSH_MODAL_CANCEL",
  SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT: "SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT",
  REFRESH_SEO_PAGE_SUGGESTIONS: "REFRESH_SEO_PAGE_SUGGESTIONS",
  // Toggle More Filters for Advertising Page
  SET_MORE_FILTERS: 'SET_MORE_FILTERS',
  DELETE_PPC_STRATEGY: 'DELETE_PPC_STRATEGY'
};
export const DEFAULT_DATE_FORMATS = {
  WEEK: "MMM D, YYYY",
  WEEKLY: "MMM D, YYYY",
  MONTH: "MMM YYYY",
  MONTHLY: "MMM YYYY",
  DAY: "MMM D, YYYY",
  DAILY: "MMM D, YYYY"
};
export const PPC_V2_DATE_FORMATS = {
  WEEKLY: "MMM DD, 'YY",
  MONTHLY: "MMM, 'YY",
  DAILY: "MMM DD, 'YY"
};
export const BAR_CHART_MINIMUM_PERCENTAGE = 0.8;
export const CM_TO_INCH_COEF = 0.393701;
export const INCH_TO_CM_COEF = 2.54;
export const KG_TO_LB_COEF = 2.20462;
export const LB_TO_KG_COEF = 0.453592;
export const AI_VERSION_SUGGESTIONS = {
  'amazon_version': 'Amazon Version',
  'amazon_seller_central_version': 'Amazon Seller Central Version',
  'omny_version': 'OMNY Version'
};
export const COLORS = {
  EVOLUTION_GREEN: '#68B67D',
  EVOLUTION_RED: '#E7747A',
  GREEN: '#47D16C',
  DARK_GREEN: '#22863D',
  YELLOW: '#D7AA0F',
  RED: '#E7747A',
  GRAY: '#999999',
  GREEN600: '#5de481',
  GREEN500: '#81ea9c',
  GREEN400: '#9fefb4',
  GREEN300: '#C2F5CF',
  YELLOW300: '#FFE27B',
  YELLOW400: '#F5D114',
  YELLOW500: '#E0BC00',
  YELLOW600: '#d7aa0f',
  YELLOW700: '#8f710a',
  YELLOW800: '#f0c329',
  RED400: '#FF8F94',
  TRANSPARENT: 'transparent',
  GRAY_BLUE: '#E8EBF3',
  DARK_GRAY: '#54595E',
  DARK_RED: '#DB333B',
  COMPETITORS_PURPLE: "#9447FF",
  NON_BRANDED_BLUE: "#8AB4FF",
  BRANDED_PINK: "#F174AA",
  DISCOVERY_GREY: "#E8EBF3",
  PURPLE600: '#C194FF',
  PURPLE500: '#CEABFF',
  PURPLE400: '#DCC3FF',
  PURPLE300: '#EADAFF',
  PURPLE200: '#F7F1FF',
  PURPLE100: '#F7F1FF',
  BLUE700: '#4C8EFF',
  BLUE600: '#85B2FF',
  BLUE500: '#A0C3FF',
  BLUE400: '#BBD4FF',
  BLUE300: '#D5E5FF',
  BLUE200: '#EFF5FF',
  BLUE100: '#FFFFFF',
  BLUE150: '#E0ECFF',
  PINK600: '#FF91C1',
  PINK500: '#FFA5CC',
  PINK400: '#FFB8D7',
  PINK300: '#FFD4E7',
  PINK200: '#FFEEF5',
  PINK100: '#FFFFFF',
  GREY600: '#AAADB5',
  GREY500: '#BCBFC7',
  GREY400: '#CED1D9',
  GREY300: '#E0E3EB',
  GREY200: '#F1F4FC',
  GREY100: '#FFFFFF',
  PURPLE700: '#6F22DA',
  PINK700: '#9F2258',
  GREY700: '#4D4D4D',
  MAGENTA100: '#FFF0F7',
  COOL_GREY_100: '#FFFFFF',
  COOL_GREY_200: '#EBF7FF',
  COOL_GREY_300: '#D4E7F4',
  COOL_GREY_400: '#C2D5E2',
  COOL_GREY_500: '#B0C3D0',
  COOL_GREY_600: '#9EB1BE',
  COOL_GREY_700: '#3E515E',
  RED700: '#DB333B'
};
export const CPC_DECILE_COLORS = {
  0: COLORS.GREEN600,
  1: COLORS.GREEN500,
  2: COLORS.GREEN400,
  3: COLORS.GREEN300,
  4: COLORS.GREEN300,
  5: COLORS.YELLOW300,
  6: COLORS.YELLOW400,
  7: COLORS.YELLOW500
};
export const PROFIT_DECILE_COLORS = {
  0: "#FFF5F5",
  1: "#FEEFEF",
  2: "#FCE9E9",
  3: "#FBE3E3",
  4: "#F9DCDC",
  5: "#F8D6D6",
  6: "#F6D0D0",
  7: "#F4C9C9",
  8: "#F3C3C3",
  9: "#F1BDBD",
  10: "#F0B7B7",
  11: "#EEB0B0",
  12: "#EDAAAA",
  13: "#EBA4A4",
  14: "#EA9E9E"
};
export const PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS = {
  0: "#F8F5FF",
  1: "#EEE5FF",
  2: "#E3D8F8",
  3: "#D6C9ED",
  4: "#D6C9ED"
};
export const TARGETING_TYPE_TYPES = {
  COMPETITORS_PRODUCT: "A.Competitors Product",
  NON_BRANDED_KWS: "B.Non-Branded KW",
  BRANDED: "C.Branded",
  DISCOVERY: "D.Discovery"
};
export const TARGETING_SPLIT_TYPES_COLORS = {
  [TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT]: {
    0: COLORS.PURPLE200,
    1: COLORS.PURPLE300,
    2: COLORS.PURPLE400,
    3: COLORS.PURPLE500,
    4: COLORS.PURPLE600
  },
  [TARGETING_TYPE_TYPES.NON_BRANDED_KWS]: {
    0: COLORS.BLUE200,
    1: COLORS.BLUE300,
    2: COLORS.BLUE400,
    3: COLORS.BLUE500,
    4: COLORS.BLUE600
  },
  [TARGETING_TYPE_TYPES.BRANDED]: {
    0: COLORS.PINK200,
    1: COLORS.PINK300,
    2: COLORS.PINK400,
    3: COLORS.PINK500,
    4: COLORS.PINK600
  },
  [TARGETING_TYPE_TYPES.DISCOVERY]: {
    0: COLORS.GREY200,
    1: COLORS.GREY300,
    2: COLORS.GREY400,
    3: COLORS.GREY500,
    4: COLORS.GREY600
  }
};
export const ROAS_DECILE_COLORS = {
  0: COLORS.COOL_GREY_100,
  1: COLORS.COOL_GREY_200,
  2: COLORS.COOL_GREY_300,
  3: COLORS.COOL_GREY_400,
  4: COLORS.COOL_GREY_500,
  5: COLORS.COOL_GREY_600
};
export const KPIS_COLORS_BACKGROUND = {
  [COLORS.RED]: "#FFE5E7",
  [COLORS.GREEN]: "#E7F8E7",
  [COLORS.DARK_GREEN]: "#DEFBE6",
  [COLORS.TRANSPARENT]: "transparent",
  [COLORS.GRAY]: "#F5F5F5",
  [COLORS.GRAY_BLUE]: "#E8EBF3"
};
export const CAMPAIGNS_STATES = {
  PAUSED: COLORS.GRAY,
  ENABLED: COLORS.DARK_GREEN,
  ARCHIVED: COLORS.YELLOW
};
export const KPI_DIRECTION_TYPE = {
  SMALL_IS_GOOD: 'small_is_good',
  BIG_IS_GOOD: 'big_is_good'
};
export const TREND_DIRECTION_TYPE = {
  UP: 'up',
  DOWN: 'down',
  OFF: 'off'
};
export const GLOBAL_KPIS_DIRECTIONS = {
  TACOS: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  spend: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  sponsored_sales: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  CVR: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  CPC: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  ACOS: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  units_sold_clicks_14_d: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  clicks: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better,
  ROAS: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  revenue_excl_VAT: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  days_inventory_current_period: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  organic_CVR: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  traffic_by_asin_buy_box_percentage: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  share_sponsored_sales: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  lost_revenue_excl_VAT: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  last_year_revenue_excl_VAT: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_units_sold_clicks_14_d: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_total_quantity: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_days_inventory_current_period: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  last_year_lost_revenue_excl_VAT: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  last_year_TACOS: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  last_year_share_sponsored_sales: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_organic_CVR: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_traffic_by_asin_buy_box_percentage: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  total_quantity: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  page_views: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_page_views: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  active_asin_count: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_active_asin_count: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  last_year_spend: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  last_year_sponsored_sales: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  last_year_CVR: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better
  last_year_CPC: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  last_year_ACOS: KPI_DIRECTION_TYPE.SMALL_IS_GOOD,
  // meaning that the lower the better
  last_year_clicks: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better,
  last_year_ROAS: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher the better

  revenue_wtd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  revenue_mtd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  revenue_ytd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  revenue_yesterday: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher

  last_year_revenue_wtd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  last_year_revenue_mtd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  last_year_revenue_ytd: KPI_DIRECTION_TYPE.BIG_IS_GOOD,
  // meaning that the higher
  last_year_revenue_yesterday: KPI_DIRECTION_TYPE.BIG_IS_GOOD // meaning that the higher
};
export const CVR_HEATMAP_COLORS = [[[Number.NEGATIVE_INFINITY, 0.019], `${COLORS.RED400}FF`], [[0.02, 0.05], `${COLORS.YELLOW800}FF`], [[0.05, 0.08], `${COLORS.YELLOW400}FF`], [[0.08, 0.12], `${COLORS.YELLOW300}FF`], [[0.12, 0.16], COLORS.GREEN300], [[0.16, 0.2], COLORS.GREEN400], [[0.20, 0.25], COLORS.GREEN500], [[0.25, Number.POSITIVE_INFINITY], COLORS.GREEN600]];
export const DAYS_INVENTORY_HEATMAP_COLORS = [[[Number.NEGATIVE_INFINITY, 20], '#CEDED3'], [[20, 100], '#A8E9BB'], [[100, Number.POSITIVE_INFINITY], '#88A991']];
export const ORGANIC_CVR_HEATMAP_COLORS = [...CVR_HEATMAP_COLORS];
export const KEYWORD_BID_HEATMAP_COLORS = [[[0, 0.20], "#4C8EFF"], [[0.20, 0.35], "#669EFF"], [[0.35, 0.50], "#80AFFF"], [[0.50, 0.65], "#FFC69E"], [[0.65, 0.9], "#FFB17A"], [[0.9, 1.25], "#FF9D57"], [[1.25, 1.5], "#F174AA"], [[1.5, 2.0], "#FFA8BD"], [[2.0, Number.POSITIVE_INFINITY], "#FFAFD2"]];
export const BUYBOX_HEATMAP_COLORS = [[[Number.NEGATIVE_INFINITY, 0.85], COLORS.RED], [[0.85, 0.95], COLORS.YELLOW], [[0.95, Number.POSITIVE_INFINITY], COLORS.GREEN]];
export const BEST_POSITION_HEATMAP_COLORS = [[[0, 3], COLORS.GREEN], [[3, 8], COLORS.YELLOW], [[8, Number.POSITIVE_INFINITY], COLORS.RED]];
export const FLOATING_COMPOUND_TABLE_STATES = {
  CLOSED: "closed",
  INTERMEDIATE: "intermediate",
  FULL_SIZE: "full-size"
};
export const GROUP_BY_TAB_ELEMENTS = {
  SELLER: {
    name: "Seller",
    key: "seller"
  },
  MARKETPLACE: {
    name: "Marketplace",
    key: "marketplace"
  },
  PRODUCT: {
    name: "Product",
    key: "product"
  },
  PRODUCT_SKU: {
    name: "Sku",
    key: "product"
  },
  CAMPAIGN_GROUP: {
    name: "Campaign Group",
    key: "campaign_group"
  },
  CAMPAIGN: {
    name: "Campaign",
    key: "campaign"
  },
  TARGETING: {
    name: "Targeting",
    key: "targeting"
  },
  PRODUCT_ASIN: {
    name: "ASIN",
    key: "asin"
  },
  PRODUCT_PARENT: {
    name: "Parent ASIN",
    key: "parent_asin"
  },
  AD_FORMAT: {
    name: "Ad Format",
    key: "ad_format"
  }
};
export const APP_PERIOD_DROPDOWN_ELEMENTS = {
  DAILY: {
    text: "Daily",
    value: "daily"
  },
  WEEKLY: {
    text: "Weekly",
    value: "weekly"
  },
  MONTHLY: {
    text: "Monthly",
    value: "monthly"
  }
};
export const PERIOD_COLUMN_TITLES = {
  daily: "Day",
  weekly: "Week",
  monthly: "Month"
};
export const TARGETING_TYPE_SPLIT = [{
  name: "Competitors",
  short_name: "Competitors",
  keyword_name: "Competitors KWs",
  key: TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT,
  color: COLORS.COMPETITORS_PURPLE,
  tableColor: COLORS.COMPETITORS_PURPLE,
  bg: COLORS.PURPLE200
}, {
  name: "Non-Branded KWs",
  short_name: "Non-branded",
  keyword_name: "Non-Branded KWs",
  key: TARGETING_TYPE_TYPES.NON_BRANDED_KWS,
  color: COLORS.NON_BRANDED_BLUE,
  tableColor: COLORS.BLUE700,
  bg: COLORS.BLUE150
}, {
  name: "Branded",
  short_name: "Branded",
  keyword_name: "Branded KWs",
  key: TARGETING_TYPE_TYPES.BRANDED,
  color: COLORS.BRANDED_PINK,
  tableColor: COLORS.BRANDED_PINK,
  bg: COLORS.MAGENTA100
}, {
  name: "Discovery",
  short_name: "Discovery",
  keyword_name: "Discovery KWs",
  key: TARGETING_TYPE_TYPES.DISCOVERY,
  color: COLORS.DISCOVERY_GREY,
  tableColor: COLORS.GREY700,
  bg: COLORS.GREY200
}];
export const TARGETING_TYPE_SPLIT_KEYMAP = {
  [TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT]: {
    name: "Competitors",
    short_name: "Competitors",
    key: TARGETING_TYPE_TYPES.COMPETITORS_PRODUCT,
    color: COLORS.COMPETITORS_PURPLE,
    tableColor: COLORS.COMPETITORS_PURPLE,
    bg: COLORS.PURPLE200
  },
  [TARGETING_TYPE_TYPES.NON_BRANDED_KWS]: {
    name: "Non-Branded KWs",
    short_name: "Non-branded",
    key: TARGETING_TYPE_TYPES.NON_BRANDED_KWS,
    color: COLORS.NON_BRANDED_BLUE,
    tableColor: COLORS.BLUE700,
    bg: COLORS.BLUE150
  },
  [TARGETING_TYPE_TYPES.BRANDED]: {
    name: "Branded",
    short_name: "Branded",
    key: TARGETING_TYPE_TYPES.BRANDED,
    color: COLORS.BRANDED_PINK,
    tableColor: COLORS.BRANDED_PINK,
    bg: COLORS.MAGENTA100
  },
  [TARGETING_TYPE_TYPES.DISCOVERY]: {
    name: "Discovery",
    short_name: "Discovery",
    key: TARGETING_TYPE_TYPES.DISCOVERY,
    color: COLORS.DISCOVERY_GREY,
    tableColor: COLORS.GREY700,
    bg: COLORS.GREY200
  }
};
export const ANALYTICS_CSV_PAGE_SIZE = 500; // We are fetching 500 rows at time to reduce network calls to BQ

export const ANALYTICS_TABLE_CELL_HEIGHT = {
  [GROUP_BY_TAB_ELEMENTS.SELLER.key]: {
    '--lite-table-td-height': '32px'
  },
  [GROUP_BY_TAB_ELEMENTS.MARKETPLACE.key]: {
    '--lite-table-td-height': '32px'
  },
  [GROUP_BY_TAB_ELEMENTS.PRODUCT.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.PRODUCT_ASIN.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.PRODUCT_SKU.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.PRODUCT_PARENT.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.CAMPAIGN.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.TARGETING.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.CAMPAIGN_GROUP.key]: {
    '--lite-table-td-height': '47px'
  },
  [GROUP_BY_TAB_ELEMENTS.AD_FORMAT.key]: {
    '--lite-table-td-height': '32px'
  }
};
export const AD_PRODUCT_KEYMAP = {
  SPONSORED_BRANDS: "Sponsored Brands",
  SPONSORED_PRODUCTS: "Sponsored Products",
  SPONSORED_DISPLAY: "Sponsored Display"
};
export const PPC_EXTRA_FILTERS = {
  AD_FORMAT: "Ad Format",
  PORTFOLIO: "Portfolio",
  TARGETING: "Targeting",
  MATCH_TYPE: "Match Type",
  CAMPAIGN: "Campaigns"
};
export const HANDLE_ADD_FILTER_MODES = {
  SILENT: "SILENT",
  POPUP: "POPUP"
};
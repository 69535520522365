import PdfExportSelector from "@/components/PdfExportSelector.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import jsPDF from 'jspdf';
import { getGraphHeaderByTabView, getPeriodColumnName, getGraphTitle, defineComputedPeriod, getGraphId, getOptionsBasedOnPeriod } from "@/components/shared/chartHelpers";
import { computeMinMax } from '@/utils';
import roboto from '@/fonts/Roboto-Regular.ttf';
import robotoBold from '@/fonts/Roboto-Bold.ttf';
import { ABORT_MESSAGES } from '@/utils/constants';
import DashboardMixin from "../mixins/DashboardMixin.vue";
import DatePickerMixin from "../mixins/DatePickerMixin.vue";
export default {
  name: "PdfExportPopup",
  mixins: [DashboardMixin, DatePickerMixin],
  async mounted() {
    console.log("Pdf Export Popup: ", this.$props);
    await this.getOmnyAccountSellers();

    // Load `Roboto` font as base64
    const fonts = [{
      name: 'Roboto',
      font: roboto,
      weight: 'normal'
    }, {
      name: 'Roboto',
      font: robotoBold,
      weight: 'bold'
    }];

    // Await the fetch with Promises
    const promises = fonts.map(({
      font,
      name,
      weight
    }) => ({
      promise: fetch(font),
      name,
      weight
    }));

    // Wait for all promises to resolve and make `name` accessible in the response
    const responses = await Promise.all(promises.map(p => p.promise.then(r => r.arrayBuffer().then(buffer => ({
      buffer,
      name: p.name,
      weight: p.weight
    })))));
    // Await the blob() with Promises
    const data = responses.map(({
      buffer,
      name,
      weight
    }) => {
      const text = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      const fontName = `${name}.ttf`;
      return {
        fontName,
        text,
        weight,
        name
      };
    });
    this.transformedFonts = data;
  },
  data() {
    return {
      showPdfDropdown: false,
      selectedPages: {},
      selectedPeriod: null,
      downloading: false,
      transformedFonts: []
    };
  },
  computed: {
    ...mapState({
      isOmnyAdmin: state => state.auth.isOmnyAdmin
    }),
    ...mapGetters(['dashboardPdfPlotDataGet', "dashboardPdfDownloadAbortControllerGet"]),
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    },
    getPlotData() {
      return this.dashboardPdfPlotDataGet();
    },
    getSelectedPages() {
      return this.selectedPages;
    },
    getSelectedPeriod() {
      // Return the first period if none is selected
      return this.selectedPeriod;
    },
    disableDownload() {
      return Object.keys(this.selectedPages).length === 0 || this.selectedPeriod?.period === undefined;
    }
  },
  props: {
    pdfPages: {
      type: Object,
      default: () => {},
      required: true
    },
    periods: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  components: {
    PdfExportSelector
  },
  methods: {
    ...mapActions(["dashboardFetchTableDataWithPeriodAndPage", "dashboardAbortPdfDownloadRequest"]),
    ...mapMutations(["dashboardPdfPlotDataSet", "pdfRendererDimensionSet"]),
    handlePdfPopupClick() {
      this.showPdfDropdown = !this.showPdfDropdown;
    },
    handlePageSelect({
      key,
      page
    }) {
      // insert in index
      // if key is not in selectedPages, add it
      if (!(key in this.selectedPages)) {
        this.selectedPages[key] = page;
      } else {
        // remove it
        delete this.selectedPages[key];
      }
    },
    handlePeriodSelect(period) {
      this.selectedPeriod = period;
    },
    async handleDownloadClicked() {
      try {
        this.downloading = true;
        // Gather all tab/view
        const tabView = Object.values(this.selectedPages).map(({
          tab,
          view,
          tabView,
          ...rest
        }) => ({
          tab,
          view,
          tabView,
          ...rest
        }));
        // Use promise.all to fetch all data at once
        const promises = tabView.map(async ({
          tab,
          view,
          ...rest
        }) => {
          /*
              Here is where we do period computation.
              Note that since different tabs have different period computation we 
              do them here and set them in the store.
               Global -> string ['Weekly', 'Monthly', 'Daily']
              Country -> object { range: {}, compare: {}}
              Product -> object { range: {}, compare: {}}
          */

          const tabPeriods = {
            Global: () => {
              return this.selectedPeriod.period;
            },
            Country: () => {
              return this.getDateFromRangeCompare({
                period: this.selectedPeriod.simple_period
              });
            },
            Product: () => {
              return this.getDateFromRangeCompare({
                period: this.selectedPeriod.simple_period
              });
            }
          };
          const period = tabPeriods[tab]();
          const {
            graph,
            total
          } = await this.fetchKpis({
            period,
            ...rest,
            filters: this.filters,
            wrapWithCountries: true
          });
          return {
            tab,
            view,
            period,
            graph,
            total: total[0] || {},
            ...rest
          };
        });

        // Wait for all promises to resolve
        const data = await Promise.all(promises);
        // Create plot data
        let plotData = [];
        data.forEach(({
          tab,
          view,
          period,
          graph,
          limit,
          total,
          showTotal,
          periods
        }) => {
          if (limit) {
            graph = graph.slice(0, limit);
          }
          if (graph.length > 0) {
            const res = {
              ...this.createPlotData(tab, view, period, graph, showTotal),
              showTotal,
              total,
              currentViewPeriodOptions: typeof period === 'string' ? getOptionsBasedOnPeriod({
                selectedPeriod: period,
                period: periods
              }) : period
            };
            console.log("Pushing: ", res);
            plotData.push(res);
          }
        });

        // If plotData is empty, throw an error
        if (plotData.length === 0) {
          throw {
            message: "No data available for the current filter selection",
            title: "Warning",
            type: 'showWarning'
          };
        }
        this.dashboardPdfPlotDataSet(plotData);
        let pdfWrapper = null;
        let elementToCapture = null;

        // Wait for the DOM to update
        this.$nextTick(() => {
          pdfWrapper = document.querySelector(".pdf-wrapper");
          elementToCapture = document.querySelectorAll(".pdf-wrapper>div");
        });

        // This is to make sure that the DOM is updated with the new total widths
        this.$nextTick(async () => {
          await this.chartToPdf(pdfWrapper, elementToCapture.length);
          this.downloading = false;
          this.showPdfDropdown = false;
          window.$bus.trigger('showSuccess', {
            message: "PDF downloaded successfully",
            title: "Success",
            visible: true,
            dismissSecs: 5
          });
          this.handleClearAll();
          this.handlePeriodSelect(null);
        });
      } catch (error) {
        console.log("Error from handleDownloadClicked: ", error);
        if (error.message === ABORT_MESSAGES.IN_PROGRESS) {
          return;
        }
        const {
          type = 'showDanger',
          message = "Failed to download PDF"
        } = error;
        this.showMessage(type, {
          message
        });
        this.downloading = false;
      }
    },
    handleClearAll() {
      // Clear all selected pages
      this.selectedPages = {};
    },
    handleSelectAll() {
      // Select all pages
      console.log("Select All: ", this.pdfPages);
      const keys = Object.keys(this.pdfPages);
      keys.forEach(key => {
        this.selectedPages[key] = this.pdfPages[key];
      });
    },
    async chartToPdf(el, chartsLength) {
      const getFilename = () => {
        // Save to file with the name `OMNY-REPORT-<day_month_year>-<hour:minute>.pdf`
        // day -> number
        // month -> string (eg. January)
        // year -> number
        // hour -> number
        // minute -> number
        const date = new Date();
        const day = date.getDate();
        const month = date.toLocaleString('default', {
          month: 'long'
        });
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const fileName = `OMNY-REPORT-${day}_${month}_${year}-${hour}:${minute}.pdf`;
        return fileName;
      };
      try {
        const doc = new jsPDF({
          orientation: 'landscape',
          format: "a4",
          unit: 'px',
          compress: true,
          hotfixes: ["px_scaling"]
        });

        // Add fonts
        if (this.transformedFonts.length === 0) {
          throw {
            message: "No fonts loaded",
            title: "Warning"
          };
        }
        this.transformedFonts.forEach(({
          fontName,
          name,
          text,
          weight
        }) => {
          doc.addFileToVFS(fontName, text);
          doc.addFont(fontName, name, 'normal', weight);
        });
        doc.setFont('Roboto', 'normal', 'normal');
        doc.setFont('Roboto', 'normal', 'bold');

        // Ensure that `el` doesn't have empty spaces around
        doc.html(el, {
          callback: async function (pdf) {
            // Close dropdown here
            this.showPdfDropdown = false;

            // Output blob
            const pages = pdf.internal.getNumberOfPages();
            // Delete pages from the end of the chartLength to the last target in the pages
            for (let i = pages; i > chartsLength; i--) {
              pdf.deletePage(i);
            }
            pdf.save(getFilename());
          },
          x: 0,
          y: 0,
          html2canvas: {
            logging: false,
            // scale: 1,
            x: 0,
            y: 0,
            useCORS: true,
            scrollX: 0,
            scrollY: 0
          },
          filename: getFilename()
        });
      } catch (error) {
        console.log("Error from chartToPdf: ", error);
        if (error.message === ABORT_MESSAGES.IN_PROGRESS) {
          return;
        }
        this.showMessage({
          message: "Failed to process PDF"
        });
        return;
      }
    },
    createPlotData(tab, view, period, graph) {
      console.log("Creating Plot Data: ", tab, view, period, graph);
      const graphHeaders = getGraphHeaderByTabView(tab, view, getPeriodColumnName(period));
      const graphTitle = getGraphTitle(tab, view, defineComputedPeriod(period, view));
      const positions = graph;
      const countries = [];
      const minMax = computeMinMax(positions);
      const graphId = getGraphId({
        prefix: 'pdf',
        tab,
        view
      });
      return {
        graphHeaders,
        graphTitle,
        positions,
        countries,
        minMax,
        graphId
      };
    },
    showMessage(type = "showDanger", error) {
      window.$bus.trigger(type, {
        message: error.message,
        title: "Error",
        visible: true,
        dismissSecs: 5
      });
    },
    handleDownloadCancel() {
      this.showPdfDropdown = false;
      if (this.downloading) {
        this.dashboardAbortPdfDownloadRequest(ABORT_MESSAGES.IN_PROGRESS);
        window.$bus.trigger('showSuccess', {
          message: "PDF download cancelled",
          visible: true,
          dismissSecs: 5
        });
        this.handleClearAll();
        this.handlePeriodSelect(null);
        this.downloading = false;
      }
    }
  }
};
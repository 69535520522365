import FlexColumn from "@/components/FlexColumn.vue";
import FlexRow from "@/components/FlexRow.vue";
import { HugValue } from "@/components/tableElements";
import AnnoncementIcon from "@/components/icons/AnnoncementIcon.vue";
import { truncate } from "lodash";
import AppIcon from "@/components/AppIcon.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import StrokedLinkIcon from "@/components/icons/StrokedLinkIcon.vue";
import ImageOverlayer from "@/components/ImageOverlayer.vue";
export default {
  name: "ParentProductValue",
  components: {
    FlexColumn,
    FlexRow,
    HugValue,
    AnnoncementIcon,
    AppIcon,
    WrapWithPopover,
    StrokedLinkIcon,
    ImageOverlayer
  },
  emits: ['toggleChildren'],
  props: {
    value: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 100
    },
    loading: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    },
    sponsored: {
      type: Boolean,
      default: false
    },
    hasChildren: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean
    },
    count: {
      type: Number
    }
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth > 1488;
    },
    productName() {
      if (!this.value?.name) return `-`;
      return this.value.name;
    },
    sku() {
      return this.isLargeScreen ? this.value.sku : truncate(this.value.sku, 26);
    },
    asin() {
      if (!this.value?.asin) return `-`;
      return this.isLargeScreen ? this.value.asin : truncate(this.value.asin, 26);
    },
    padding() {
      if (this.hasChildren) return '4px 8px 4px 2px';
      return '4px 8px 4px 24px';
    }
  },
  methods: {
    handleToggle(e) {
      e.stopPropagation();
      this.$emit('toggleChildren');
    }
  }
};